import styles from './ContentTreeLeafNode.module.less'
import React, { ReactElement, useMemo } from 'react'
import { ContentTreeLeafNodeProps } from '@/components/ContentTreeNode/ContentTreeLeafNode/ContentTreeLeafNodes.types'
import { useRecoilValue } from 'recoil'
import { slimLeafNodeAtomFamily } from '@/atoms/slimLeafNode'
import { hashContentPath } from 'common/src/ContentPath'
import { LeafNodeContentEditorButton } from '@/components/ContentTreeNode/ContentTreeLeafNode/LeafNodeContentEditorButton/LeafNodeContentEditorButton'
import { OutlineEditor } from '@/components/ContentTreeNode/ContentTreeLeafNode/OutlineEditor/OutlineEditor'
import { SummaryEditor } from '@/components/ContentTreeNode/ContentTreeLeafNode/SummaryEditor/SummaryEditor'
import { PracticeProblemsEditor } from '@/components/ContentTreeNode/ContentTreeLeafNode/PracticeProblemsEditor/PracticeProblemsEditor'
import { NotecardsEditor } from '@/components/ContentTreeNode/ContentTreeLeafNode/NotecardsEditor/NotecardsEditor'
import { useDoesSpecificContentHaveUnsavedChanges } from '@/hooks/useDoesSpecificContentHaveUnsavedChanges'
import {
    SavableContentType,
    unsavedChangesSetAtom,
} from '@/atoms/unsavedChanges'
import { some } from 'lodash'
import { CopyLeafNodeButton } from '@/components/ContentTreeNode/ContentTreeLeafNode/CopyLeafNodeButton/CopyLeafNodeButton'
import { NodeStatsButton } from '@/components/ContentTreeNode/NodeStats/NodeStatsButton'
import { ViewingStateType } from '@/atoms/viewingState'
import { isStringifiedEditorStateEmpty } from 'magic-text-box-lexical/src/utils/isStringifiedEditorStateEmpty'
import { SourceMaterialsEditor } from '@/components/ContentTreeNode/ContentTreeLeafNode/SourceMaterialsEditor/SourceMaterialsEditor'
import { GenerateContentFromSourceMaterialButton } from '@/components/ContentTreeNode/ContentTreeLeafNode/GenerateContentFromSourceMaterialButton/GenerateContentFromSourceMaterialButton'
import { activeCourseConfigSelector } from '@/atoms/courseInfo'
import { MoveContentLeafNodeWithinSameCourseButton } from '@/components/ContentTreeNode/ContentTreeLeafNode/MoveContentLeafNodeWithinSameCourseButton/MoveContentLeafNodeWithinSameCourseButton'
import { ViewVideoButton } from '@/components/ContentTreeNode/ViewVideo/ViewVideoButton'
import { getMetadataForContentPath } from '@/frontendLogic/outlines/NodeMetadataTree/NodeMetadataTree'
import { nodeMetadataTreeSelector } from '@/atoms/nodeMetadata'

export const ContentTreeLeafNode: React.FC<ContentTreeLeafNodeProps> = (
    props
): ReactElement => {
    const hashedContentPath = useMemo(
        () => hashContentPath(props.contentPath),
        [props.contentPath]
    )
    const leafNodeData = useRecoilValue(
        slimLeafNodeAtomFamily(hashedContentPath)
    )
    const nodeMetadataTree = useRecoilValue(nodeMetadataTreeSelector)

    const { videoLink } = useMemo(
        () => getMetadataForContentPath(nodeMetadataTree, props.contentPath),
        [nodeMetadataTree, props.contentPath]
    )
    const unsavedChangesSet = useRecoilValue(unsavedChangesSetAtom)
    const doesSpecificContentHaveUnsavedChanges =
        useDoesSpecificContentHaveUnsavedChanges(unsavedChangesSet)

    const doesOutlineHaveUnsavedChanges = useMemo(
        (): boolean =>
            doesSpecificContentHaveUnsavedChanges({
                contentPath: props.contentPath,
                contentType: SavableContentType.outline,
            }),
        [doesSpecificContentHaveUnsavedChanges, props.contentPath]
    )

    const doesSummaryHaveUnsavedChanges = useMemo(
        (): boolean =>
            doesSpecificContentHaveUnsavedChanges({
                contentPath: props.contentPath,
                contentType: SavableContentType.summary,
            }),
        [doesSpecificContentHaveUnsavedChanges, props.contentPath]
    )

    const doAnySourceMaterialsHaveUnsavedChanges = useMemo(
        (): boolean =>
            some(
                leafNodeData.sourceMaterialIDs.map((id) =>
                    doesSpecificContentHaveUnsavedChanges({
                        contentPath: props.contentPath,
                        contentType: SavableContentType.sourceMaterial,
                        id,
                    })
                )
            ),
        [
            doesSpecificContentHaveUnsavedChanges,
            leafNodeData.sourceMaterialIDs,
            props.contentPath,
        ]
    )

    const doAnyNotecardsHaveUnsavedChanges = useMemo(
        (): boolean =>
            some(
                leafNodeData.notecardIDs.map((id) =>
                    doesSpecificContentHaveUnsavedChanges({
                        contentPath: props.contentPath,
                        id,
                        contentType: SavableContentType.notecard,
                    })
                )
            ),
        [
            doesSpecificContentHaveUnsavedChanges,
            leafNodeData.notecardIDs,
            props.contentPath,
        ]
    )

    const doAnyPracticeProblemsHaveUnsavedChanges = useMemo(
        (): boolean =>
            some(
                leafNodeData.practiceProblemIDs.map((id) =>
                    doesSpecificContentHaveUnsavedChanges({
                        contentPath: props.contentPath,
                        id,
                        contentType: SavableContentType.practiceProblem,
                    })
                )
            ),
        [
            doesSpecificContentHaveUnsavedChanges,
            leafNodeData.practiceProblemIDs,
            props.contentPath,
        ]
    )

    const courseConfig = useRecoilValue(activeCourseConfigSelector)

    return (
        <div className={styles.contentTreeLeafNode}>
            <LeafNodeContentEditorButton
                type={ViewingStateType.summary}
                buttonText={`Edit Summary${
                    isStringifiedEditorStateEmpty(leafNodeData.summary)
                        ? ' (EMPTY)'
                        : ''
                }`}
                modalContent={
                    <SummaryEditor hashedContentPath={hashedContentPath} />
                }
                hashedContentPath={hashedContentPath}
                hasUnsavedChanges={doesSummaryHaveUnsavedChanges}
            />

            <LeafNodeContentEditorButton
                type={ViewingStateType.outline}
                buttonText={`Edit Outline${
                    isStringifiedEditorStateEmpty(leafNodeData.outline)
                        ? ' (EMPTY)'
                        : ''
                }`}
                modalContent={
                    <OutlineEditor hashedContentPath={hashedContentPath} />
                }
                hashedContentPath={hashedContentPath}
                hasUnsavedChanges={doesOutlineHaveUnsavedChanges}
            />

            <LeafNodeContentEditorButton
                type={ViewingStateType.notecards}
                buttonText={`Edit Notecards (${
                    leafNodeData.notecardIDs?.length ?? 0
                })`}
                modalContent={
                    <NotecardsEditor hashedContentPath={hashedContentPath} />
                }
                hashedContentPath={hashedContentPath}
                hasUnsavedChanges={doAnyNotecardsHaveUnsavedChanges}
            />

            <LeafNodeContentEditorButton
                type={ViewingStateType.practiceProblems}
                buttonText={`Edit Practice Problems (${
                    leafNodeData.practiceProblemIDs?.length ?? 0
                })`}
                modalContent={
                    <PracticeProblemsEditor
                        hashedContentPath={hashedContentPath}
                    />
                }
                hashedContentPath={hashedContentPath}
                hasUnsavedChanges={doAnyPracticeProblemsHaveUnsavedChanges}
            />

            <LeafNodeContentEditorButton
                type={ViewingStateType.sourceMaterial}
                buttonText={`Edit Source Material (${
                    leafNodeData.sourceMaterialIDs.length ?? 0
                })`}
                modalContent={
                    <SourceMaterialsEditor
                        hashedContentPath={hashedContentPath}
                    />
                }
                hashedContentPath={hashedContentPath}
                hasUnsavedChanges={doAnySourceMaterialsHaveUnsavedChanges}
            />

            <NodeStatsButton contentPath={props.contentPath} />
            {courseConfig.hasVideos && (
                <ViewVideoButton
                    videoLink={videoLink}
                    contentPath={props.contentPath}
                />
            )}
            <MoveContentLeafNodeWithinSameCourseButton
                contentPath={props.contentPath}
            />
            <CopyLeafNodeButton contentPath={props.contentPath} />
            {courseConfig.canGenerateContentFromSourceMaterial && (
                <>
                    <GenerateContentFromSourceMaterialButton
                        contentPath={props.contentPath}
                        contentType={'practiceProblems'}
                    />
                    <GenerateContentFromSourceMaterialButton
                        contentPath={props.contentPath}
                        contentType={'notecards'}
                    />
                </>
            )}
        </div>
    )
}
