import { useRecoilValue, useSetRecoilState } from 'recoil'
import { sessionDataAtom } from '@/atoms/sessionData'
import { useEffect } from 'react'
import { authStateAtom } from '@/atoms/auth'
import { getSessionID } from '@/api/cb/contentFrontendAuthentication'

export const useSessionData = (): void => {
    const setSessionData = useSetRecoilState(sessionDataAtom)

    // set user info state items
    const authState = useRecoilValue(authStateAtom)
    useEffect(() => {
        setSessionData((sessionData) => ({
            ...sessionData,
            contentCreatorID: authState.contentCreatorID,
            displayName: authState.displayName,
            email: authState.email,
            courseName: authState.frontendDisplayedCourseName,
            isApplication: authState.isApplication,
        }))
    }, [setSessionData, authState])

    // set session id
    useEffect(() => {
        getSessionID().then((response) => {
            setSessionData((sessionData) => ({
                ...sessionData,
                sessionID: response.data.payload.sessionID,
            }))
        })
    }, [setSessionData])
}
