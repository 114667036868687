import styles from './CopyableID.module.less'
import React, { ReactElement, useCallback, useMemo } from 'react'
import { CopyableIDProps } from '@/components/CopyableID/CopyableID.types'
import { CopyOutlined } from '@ant-design/icons'
import { reusableCssClass } from '@/utils/reusableCssClasses'
import { message, Tooltip } from 'antd'
import { MessageKeys } from '@/utils/messageKeys'

// TODO: Could make this smarter about when to show ellipses
const ID_LENGTH_VISIBLITY_THRESHOLD = 12
export const CopyableID: React.FC<CopyableIDProps> = (props): ReactElement => {
    const contentToCopyToClipboard = useMemo(
        (): string => props.fullID ?? props.id,
        [props]
    )

    const copyIDToClipboard = useCallback(
        (event: React.MouseEvent<HTMLSpanElement>): void => {
            message.destroy(MessageKeys.COPY_ID)
            event.stopPropagation()
            navigator.clipboard.writeText(contentToCopyToClipboard).then(() =>
                message.success({
                    content: `Successfully copied ${contentToCopyToClipboard} to clipboard`,
                    duration: 3,
                    key: MessageKeys.COPY_ID,
                    className: reusableCssClass.clickMe,
                    onClick: () => message.destroy(MessageKeys.COPY_ID),
                })
            )
        },
        [contentToCopyToClipboard]
    )

    const visibleID = useMemo(
        (): string =>
            props.id.length > ID_LENGTH_VISIBLITY_THRESHOLD
                ? props.id.slice(0, ID_LENGTH_VISIBLITY_THRESHOLD - 3) + '...'
                : props.id,
        [props.id]
    )

    return (
        <div className={styles.copyableId}>
            <Tooltip
                title={`Full ID: ${contentToCopyToClipboard}`}
                overlayStyle={{
                    width: 'min-content',
                    maxWidth: 'unset',
                    whiteSpace: 'nowrap',
                }}
            >
                <div
                    className={`${styles.copyableIdTextContainer} ${reusableCssClass.clickMe}`}
                >
                    {props.label}: {visibleID}
                </div>
            </Tooltip>
            <div
                className={`${styles.copyableIdContainer} ${reusableCssClass.hoverMe}`}
            >
                <CopyOutlined onClick={copyIDToClipboard} />
            </div>
        </div>
    )
}
