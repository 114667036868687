import { hashContentPath } from 'common/src/ContentPath'
import React, { ReactElement, useMemo, useEffect } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import {
    nodeStatsAtomFamily,
    nodeStatsInvalidatorAtomFamily,
} from '@/atoms/nodeStats'
import { ContentStatsComponent } from '@/components/ContentStats/ContentStatsComponent'
import { NodeStatsProps } from '@/components/ContentTreeNode/NodeStats/NodeStats.types'

export const NodeStats: React.FC<NodeStatsProps> = (props): ReactElement => {
    const hashedContentPath = useMemo(
        (): string => hashContentPath(props.contentPath),
        [props]
    )
    const invalidate = useSetRecoilState(
        nodeStatsInvalidatorAtomFamily(hashedContentPath)
    )
    useEffect(() => invalidate((x) => x + 1), [invalidate])

    const contentStats = useRecoilValue(nodeStatsAtomFamily(hashedContentPath))

    return <ContentStatsComponent contentStats={contentStats} />
}
