import readCommentStyles from './ReadComment.module.less'
import React, {
    createElement,
    ReactElement,
    useCallback,
    useMemo,
    useState,
} from 'react'
import { ReadCommentProps } from '@/components/ContentCommentThread/ReadComment/ReadComment.types'
import { Tooltip } from 'antd'
import { displayStringForCommentPostTime } from '@/utils/dateTime'
import { UpvoteAction } from 'common/src/commentThread/types'
import { LikeFilled, LikeOutlined, UserOutlined } from '@ant-design/icons'
import { enactUpvoteActionOnComment } from '@/api/wb/commentThread'
import { UpvoteRequest } from 'common/src/api/websiteFrontendVsWebsiteBackend/commentThread/requests'
import { getSignedUrlForS3Key } from '@/api/cb/s3'
import { Comment } from '@ant-design/compatible'
import { UserType } from 'magic-text-box-lexical/src/context/AnnotatedContentContext/AnnotatedContent.types'
import { magicTextBoxErrorHandler } from '@/components/ContentTreeNode/ContentTreeLeafNode/MagicTextBoxEditor/MagicTextBoxErrorHandler'
import { getReactElementForPracticeProblemID } from '@/components/ContentTreeNode/ContentTreeLeafNode/MagicTextBoxEditor/ReactElementForPracticeProblemID/ReactElementForPracticeProblemID'
import { MagicTextBoxReadOnly } from 'magic-text-box-lexical/src/components/MagicTextBoxReadOnly/MagicTextBoxReadOnly'
import { useAccessUniversalContentLink } from '@/hooks/useAccessUniversalContentLink'
import { usePracticeProblemLinkProps } from '@/hooks/usePracticeProblemLinkProps'
import {
    mtbReadOnlyOptionsNoOp,
    practiceProblemLinkPropsNoOp,
} from '@/utils/mtbNoOps'

// DO NOT USE RECOIL HERE
export const ReadComment: React.FC<ReadCommentProps> = (
    props
): ReactElement => {
    const [likes, setLikes] = useState(
        props.commentMetadata.upvotedUserIds.length
    )
    const [isLiked, setIsLiked] = useState<boolean>(
        props.commentMetadata.upvotedUserIds.includes(props.userID)
    )

    const like = useCallback((): void => {
        const baseUpvoteActionOnComment: Omit<UpvoteRequest, 'upvoteAction'> = {
            contentType: props.contentType,
            contentId: props.contentId,
            commentId: props.commentMetadata.id,
            contentPath: props.contentPath,
            courseName: props.courseName,
        }

        if (isLiked) {
            setLikes(likes - 1)
            setIsLiked(false)
            enactUpvoteActionOnComment({
                ...baseUpvoteActionOnComment,
                upvoteAction: UpvoteAction.REMOVE,
            })
            return
        }
        setLikes(likes + 1)
        setIsLiked(true)
        enactUpvoteActionOnComment({
            ...baseUpvoteActionOnComment,
            upvoteAction: UpvoteAction.ADD,
        })
    }, [
        isLiked,
        likes,
        props.commentMetadata?.id,
        props.contentId,
        props.contentPath,
        props.contentType,
        props.courseName,
    ])

    const actions = useMemo((): ReactElement[] => {
        return [
            <Tooltip key={'comment-basic-like'} title={'Like'}>
                <span onClick={like}>
                    {createElement(isLiked ? LikeFilled : LikeOutlined)}
                    <span className={readCommentStyles.commentAction}>
                        {likes}
                    </span>
                </span>
            </Tooltip>,
        ]
    }, [isLiked, like, likes])
    const accessUniversalContentLink = useAccessUniversalContentLink()
    return (
        <div className={readCommentStyles.readComment}>
            <Comment
                actions={actions}
                content={
                    <MagicTextBoxReadOnly
                        initialEditorState={props.commentMetadata.text}
                        namespace={'comment'}
                        getPreSignedUrlForS3Key={getSignedUrlForS3Key}
                        onError={(error: Error) =>
                            magicTextBoxErrorHandler(error, 'problem question')
                        }
                        practiceProblemLinkProps={practiceProblemLinkPropsNoOp}
                        options={mtbReadOnlyOptionsNoOp}
                        accessUniversalContentLink={accessUniversalContentLink}
                    />
                }
                author={`${
                    props.commentMetadata.isAnonymous
                        ? 'Anonymous'
                        : props.commentMetadata.displayName
                }`}
                datetime={displayStringForCommentPostTime(
                    props.commentMetadata.createdAt
                )}
                avatar={
                    <div
                        className={`avatar ${
                            props.commentMetadata.userType === UserType.student
                                ? 'avatar-student'
                                : 'avatar-instructor'
                        }`}
                    >
                        <Tooltip title={props.commentMetadata.userType}>
                            <UserOutlined />
                        </Tooltip>
                    </div>
                }
            />
        </div>
    )
}
