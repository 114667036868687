import React, { ReactElement, useState, useMemo, useCallback } from 'react'
import styles from './FeatureFlagsEditor.module.less'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
    frontendDisplayedCourseSelector,
    isActiveCourseReadOnlySelector,
} from '@/atoms/auth'
import { featureFlagsSelectorFamily } from '@/atoms/featureFlags'
import { FeatureFlag, FeatureFlags } from 'common/src/featureFlags'
import { setFeatureFlags } from '@/api/cb/contentCreatorContent'
import { syncErrorMessage, syncSuccessMessage } from '@/utils/syncMessages'
import { Button, Switch } from 'antd'
import produce from 'immer'
import { startCase } from 'lodash'

export const FeatureFlagsEditor: React.FC<unknown> = (_props): ReactElement => {
    const courseName = useRecoilValue(frontendDisplayedCourseSelector)
    const isReadOnly = useRecoilValue(isActiveCourseReadOnlySelector)
    const [featureFlagsRecoil, setFeatureFlagsRecoil] = useRecoilState(
        featureFlagsSelectorFamily(courseName)
    )
    const [lastSavedFeatureFlags, setLastSavedFeatureFlags] =
        useState<FeatureFlags>(featureFlagsRecoil)
    const hasUnsavedChanges = useMemo((): boolean => {
        const areEqual =
            featureFlagsRecoil.length === lastSavedFeatureFlags.length &&
            featureFlagsRecoil.every((featureFlag) =>
                lastSavedFeatureFlags.includes(featureFlag)
            )
        return !areEqual
    }, [featureFlagsRecoil, lastSavedFeatureFlags])
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const save = useCallback(async (): Promise<void> => {
        setIsLoading(true)
        const response = await setFeatureFlags(courseName, featureFlagsRecoil)
        if (response.data.isError) {
            syncErrorMessage(
                'Error updating feature flags. You may want to try setting it again or refreshing the page.'
            )
        } else {
            setLastSavedFeatureFlags(featureFlagsRecoil)
            syncSuccessMessage(`Successfully updated feature flags`)
        }
        setIsLoading(false)
    }, [courseName, featureFlagsRecoil])

    return (
        <div className={styles.featureFlagsEditor}>
            <div className={styles.featureFlagSwitches}>
                {Object.values(FeatureFlag).map((featureFlag) => (
                    <FeatureFlagSwitch
                        key={featureFlag}
                        isReadOnly={isReadOnly}
                        featureFlag={featureFlag}
                        isActive={featureFlagsRecoil.includes(featureFlag)}
                        setIsActive={(isActive) =>
                            setFeatureFlagsRecoil((featureFlags) =>
                                isActive
                                    ? addFeatureFlag(featureFlags, featureFlag)
                                    : removeFeatureFlag(
                                          featureFlags,
                                          featureFlag
                                      )
                            )
                        }
                    />
                ))}
            </div>
            <Button
                onClick={save}
                type={'primary'}
                loading={isLoading}
                disabled={isReadOnly || !hasUnsavedChanges}
            >
                Save
            </Button>
        </div>
    )
}

export const FeatureFlagSwitch: React.FC<{
    isReadOnly: boolean
    featureFlag: FeatureFlag
    isActive: boolean
    setIsActive: (isActive: boolean) => void
}> = (props): ReactElement => {
    return (
        <div className={styles.featureFlagSwitch}>
            <div className={styles.featureFlagSwitchLabel}>
                {startCase(props.featureFlag) + ':'}
            </div>
            <Switch
                disabled={props.isReadOnly}
                checked={props.isActive}
                onChange={props.setIsActive}
            />
        </div>
    )
}

export const addFeatureFlag = produce(
    (featureFlags: FeatureFlags, featureFlag: FeatureFlag): void => {
        if (featureFlags.includes(featureFlag)) return
        featureFlags.push(featureFlag)
    }
)

export const removeFeatureFlag = produce(
    (featureFlags: FeatureFlags, featureFlag: FeatureFlag): void => {
        const index = featureFlags.indexOf(featureFlag)
        if (index === -1) return
        featureFlags.splice(index, 1)
    }
)
