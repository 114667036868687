import React, { ReactElement } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { reusableCssClass } from '@/utils/reusableCssClasses'
import { getPracticeProblemMeta } from '@/api/cb/contentCreatorContent'
import { frontendDisplayedCourseSelector } from '@/atoms/auth'
import { viewingStateAtom, ViewingStateType } from '@/atoms/viewingState'
import { practiceProblemIDsForCategorySelector } from '@/atoms/practiceProblemCategories'

interface PracticeProblemIDsListProps {
    category: string
}

export const PracticeProblemIDsList: React.FC<PracticeProblemIDsListProps> = (
    props
): ReactElement => {
    const practiceProblemIDs = useRecoilValue(
        practiceProblemIDsForCategorySelector(props.category)
    )

    const courseName = useRecoilValue(frontendDisplayedCourseSelector)
    const setViewingState = useSetRecoilState(viewingStateAtom)

    const redirectToPracticeProblemID = async (
        practiceProblemID: string
    ): Promise<void> => {
        const response = await getPracticeProblemMeta(
            courseName,
            practiceProblemID
        )
        setViewingState({
            contentPath: response.data.payload.contentPath,
            viewingStateType: ViewingStateType.practiceProblems,
            id: practiceProblemID,
        })
    }

    return (
        <ul className={'practiceProblem-ids-list'}>
            {practiceProblemIDs.map((practiceProblemID) => {
                return (
                    <li
                        key={practiceProblemID}
                        className={`practiceProblem-ids-list-item ${reusableCssClass.clickableText}`}
                        onClick={() =>
                            redirectToPracticeProblemID(practiceProblemID)
                        }
                    >
                        {practiceProblemID}
                    </li>
                )
            })}
        </ul>
    )
}
