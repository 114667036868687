import React, { ReactElement, useEffect } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import {
    activeCourseInfoInvalidatorAtom,
    activeCourseInfoAtomNonMemoizing,
} from '@/atoms/courseInfo'
import { ContentStatsComponent } from '@/components/ContentStats/ContentStatsComponent'

export const CourseStats: React.FC<unknown> = (): ReactElement => {
    // so it recalculates each time it is opened
    const courseInfo = useRecoilValue(activeCourseInfoAtomNonMemoizing)
    const invalidate = useSetRecoilState(activeCourseInfoInvalidatorAtom)
    useEffect(() => invalidate((x) => x + 1), [invalidate])

    return <ContentStatsComponent contentStats={courseInfo.contentStats} />
}
