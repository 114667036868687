import {
    AnnotatedContentProps,
    SavableProps,
} from 'magic-text-box-lexical/src/components/MagicTextBoxCore/MagicTextBoxCore.types'
import { EditorState } from 'lexical'
import React from 'react'

export enum MagicTextBoxEditorTab {
    editable = 'Edit View',
    nonEditable = 'Student View',
}

export interface MagicTextBoxEditorProps {
    id: string
    namespace: string
    placeholder: string
    editorStateRef?: React.MutableRefObject<EditorState>
    editorUpdateListener?: (editorState: EditorState) => void
    initialStringifiedEditorState: string
    savableProps: SavableProps
    annotatedContentProps?: AnnotatedContentProps
    cannotComment?: boolean
    canCopyAnnotationSelectionToClipboard?: boolean
}
