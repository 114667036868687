import React, { ReactElement, useCallback, useState } from 'react'
import styles from './FormulaSheetEntryComponent.module.less'
import { EditOutlined } from '@ant-design/icons'
import { Button, Modal } from 'antd'
import { DraggableFormulaSheetEntryData } from '@/components/utils/DraggableSortingList/DraggableIcon/DraggableIcon.types'
import { DefensiveDeleteButton } from '@/components/utils/DefensiveDeleteButton/DefensiveDeleteButton'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { deleteFormulaSheetEntry } from '@/components/FormulaSheetEditor/immer'
import { EditFormulaSheetEntry } from '@/components/FormulaSheetEditor/EditFormulaSheetEntry/EditFormulaSheetEntry'
import { isActiveCourseReadOnlySelector } from '@/atoms/auth'
import { formulaSheetEntriesAtom } from '@/atoms/formulaSheetEntries'

export type FormulaSheetEntryComponentProps = DraggableFormulaSheetEntryData

export const FormulaSheetEntryComponent: React.FC<
    FormulaSheetEntryComponentProps
> = (props): ReactElement => {
    const isReadOnly = useRecoilValue(isActiveCourseReadOnlySelector)
    const setFormulaSheetEntries = useSetRecoilState(formulaSheetEntriesAtom)
    const handleDelete = useCallback(async (): Promise<void> => {
        setFormulaSheetEntries((current) =>
            deleteFormulaSheetEntry(current, props.id)
        )
    }, [props.id, setFormulaSheetEntries])
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
    return (
        <div className={styles.formulaSheetEntry}>
            <div className={styles.leftSide}>
                <Button
                    disabled={isReadOnly}
                    onClick={() => setIsModalOpen(true)}
                >
                    <EditOutlined />
                </Button>
                <div className={styles.formulaSheetEntryTitle}>
                    {props.title}
                </div>
            </div>
            <Modal
                open={isModalOpen}
                footer={null}
                onCancel={() => setIsModalOpen(false)}
                destroyOnClose={false}
                className={styles.editFormulaSheetEntryModal}
            >
                <EditFormulaSheetEntry
                    closeModal={() => setIsModalOpen(false)}
                    formulaSheetEntryId={props.id}
                />
            </Modal>
            <DefensiveDeleteButton
                onDelete={handleDelete}
                title={'Warning: Dangerous Action'}
                areYouSureContent={
                    'Are you sure you want to delete this formula?'
                }
                okText={'Yes, delete formula'}
                cancelText={'Cancel'}
                placement={'right'}
            />
        </div>
    )
}
