import { INotecard } from 'common/src/notecards/types'
import produce from 'immer'
import { ContentCreatorEditableNotecardMetadata } from '@/components/ContentTreeNode/ContentTreeLeafNode/NotecardsEditor/NotecardEditor/NotecardMetadataEditor/NotecardMetadataEditor.types'

export const setNotecardFront = produce(
    (notecard: INotecard, notecardFront: string): void => {
        notecard.front = notecardFront
    }
)

export const setNotecardBack = produce(
    (notecard: INotecard, notecardBack: string): void => {
        notecard.back = notecardBack
    }
)

export const setContentCreatorEditableNotecardMetadata = (
    notecard: INotecard,
    contentCreatorEditableNotecardMetadata: ContentCreatorEditableNotecardMetadata
): INotecard => ({
    ...notecard,
    ...contentCreatorEditableNotecardMetadata,
})
