import styles from './LearningObjectivesEditor.module.less'
import React, { ReactElement, useMemo, useState, useCallback } from 'react'
import { LearningObjectivesEditorProps } from './LearningObjectivesEditor.types'
import { useRecoilState, useRecoilValue } from 'recoil'
import { contentTreeStateAtom, ContentTreeState } from '@/atoms/contentTree'
import { Modal, Button } from 'antd'
import { LearningObjectiveItem } from '@/components/ContentTreeNode/LearningObjectivesEditor/LearningObjectiveItem/LearningObjectiveItem'
import { PlusCircleOutlined } from '@ant-design/icons'
import produce from 'immer'
import { createLearningObjective } from '@/api/cb/contentCreatorContent'
import {
    frontendDisplayedCourseSelector,
    isActiveCourseReadOnlySelector,
} from '@/atoms/auth'
import { ILearningObjective } from 'common/src/LearningObjectives/types'

const createLearningObjectiveLocal = produce(
    (
        contentTreeState: ContentTreeState,
        topicID: string,
        learningObjective: ILearningObjective
    ): void => {
        contentTreeState.topicToLearningObjectivesMap
            .get(topicID)
            .push(learningObjective)
    }
)

// TODO: Add reordering functionality in the future
export const LearningObjectivesEditor: React.FC<
    LearningObjectivesEditorProps
> = (props): ReactElement => {
    const courseName = useRecoilValue(frontendDisplayedCourseSelector)
    const isReadOnly = useRecoilValue(isActiveCourseReadOnlySelector)

    const [contentTreeState, setContentTreeState] =
        useRecoilState(contentTreeStateAtom)
    const learningObjectives = useMemo(
        () => contentTreeState.topicToLearningObjectivesMap.get(props.topicID),
        [props.topicID, contentTreeState?.topicToLearningObjectivesMap]
    )

    const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
    const [isCreatingLearningObjective, setIsCreatingLearningObjective] =
        useState<boolean>(false)
    const addNewLearningObjective = useCallback(() => {
        const description = 'TBD'
        setIsCreatingLearningObjective(true)
        createLearningObjective(courseName, props.topicID, description).then(
            (response) => {
                setContentTreeState((contentTreeState) =>
                    createLearningObjectiveLocal(
                        contentTreeState,
                        props.topicID,
                        response.data.payload
                    )
                )
                setIsCreatingLearningObjective(false)
            }
        )
    }, [courseName, props.topicID, setContentTreeState])

    return (
        <div className={styles.learningObjectivesEditorButtonContainer}>
            <Modal
                open={isModalVisible}
                footer={null}
                onCancel={() => setIsModalVisible(false)}
            >
                <div className={styles.learningObjectivesEditor}>
                    <div className={styles.learningObjectivesEditorTitle}>
                        Learning Objectives
                    </div>
                    {learningObjectives.map((learningObjective) => (
                        <LearningObjectiveItem
                            key={learningObjective.id}
                            learningObjective={learningObjective}
                        />
                    ))}
                    <Button
                        loading={isCreatingLearningObjective}
                        onClick={addNewLearningObjective}
                        disabled={isReadOnly}
                    >
                        <PlusCircleOutlined /> Add learning objective
                    </Button>
                </div>
            </Modal>
            <Button
                onClick={() => setIsModalVisible(true)}
                size={'large'}
            >{`Edit Learning Objectives (${learningObjectives.length})`}</Button>
        </div>
    )
}
