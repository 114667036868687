import React, { ReactElement } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { isActiveCourseReadOnlySelector } from '@/atoms/auth'
import { Button } from 'antd'
import { PlusCircleOutlined } from '@ant-design/icons'
import styles from './AddFormulaSheetEntryButton.module.less'
import { formulaSheetEntriesAtom } from '@/atoms/formulaSheetEntries'
import {
    addFormulaSheetEntry,
    createEmptyFormulaSheetEntry,
} from '@/components/FormulaSheetEditor/immer'

export const AddFormulaSheetEntryButton: React.FC<
    unknown
> = (): ReactElement => {
    const isReadOnly = useRecoilValue(isActiveCourseReadOnlySelector)
    const setFormulaSheetEntries = useSetRecoilState(formulaSheetEntriesAtom)
    return (
        <div className={styles.editFormulaSheetEntryButtonContainer}>
            <Button
                onClick={() => {
                    const newFormulaSheetEntry = createEmptyFormulaSheetEntry()
                    setFormulaSheetEntries((current) =>
                        addFormulaSheetEntry(current, newFormulaSheetEntry)
                    )
                }}
                size={'large'}
                disabled={isReadOnly}
            >
                <PlusCircleOutlined /> <span>Add formula</span>
            </Button>
        </div>
    )
}
