import styles from './ApplicationInstructions.module.less'
import React, { ReactElement } from 'react'
import { ApplicationInstructionsProps } from '@/components/ApplicationInstructions/ApplicationInstructions.types'
import { reusableCssClass } from '@/utils/reusableCssClasses'
import { ValidEmailSender } from 'common/src/email'

export const ApplicationInstructions: React.FC<ApplicationInstructionsProps> = (
    props
): ReactElement => {
    return (
        <div className={styles.contentCreatorApplicationInstructions}>
            <h1>Become a Content Creator at Just Enough Prep</h1>

            <h2>Thank you for your interest. Instructions:</h2>
            <ol>
                {props.isSignupForm && (
                    <li>
                        Fill out the form below. After you click Submit, you
                        will be taken to our Content Dashboard.
                    </li>
                )}
                <li>
                    You will find two exams in the drop down: sample and your
                    version of the exam. You will be emailed sample source
                    material for the sample exam and 1 piece of source material
                    based on the track you select. The sample content will serve
                    as an example and your version of the exam will be an empty
                    slate for you to make your own.
                </li>
                <li>
                    On our Content Dashboard, please write:
                    <ul>
                        <li>one outline</li>
                        <li>one notecard</li>
                        <li>
                            one math-based practice problem (using Excel if
                            applicable)
                        </li>
                    </ul>
                    You can use the sample exam as a guide. These samples are
                    for us to understand your teaching style and also for you to
                    experience what it is like to create content for Just Enough
                    Prep.
                </li>
                <li>
                    Please complete your submission within 7 days of filling up
                    the sign up form. If you need more time, just let us know at{' '}
                    <a
                        href={`mailto:${ValidEmailSender.careers}`}
                        target={'_blank'}
                        className={reusableCssClass.clickableTextUnderlined}
                        rel="noreferrer"
                    >
                        {ValidEmailSender.careers}
                    </a>
                    .
                </li>
                <li>
                    A member of our team will get back to you within 2 days to
                    review your application!
                </li>
            </ol>
        </div>
    )
}
