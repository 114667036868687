import { logger } from '@/logging/FrontendLogger'
import { setRecoilExternalState } from '@/components/RecoilExternalStatePortal'
import { isLoggedInAtom } from '@/atoms/isLoggedIn'
import Router from 'next/router'
import { authStateAtom } from '@/atoms/auth'
import { ContentAuthResponse } from 'common/src/api/contentFrontendVsContentBackend/responses'

export const frontendLogin = async (
    auth: ContentAuthResponse,
    shouldAutoRoute = true
): Promise<void> => {
    logger.info(`Frontend logging in`)
    setRecoilExternalState(authStateAtom, auth)
    setRecoilExternalState(isLoggedInAtom, true)
    if (shouldAutoRoute) {
        await Router.push({
            pathname: Router.pathname,
            query: Router.query,
        })
    }
}
