import { courseInfoAtom } from '@/atoms/courseInfo'
import { DefaultValue, selectorFamily } from 'recoil'

export type ContentTierTitles = string[]
export const contentTierTitlesSelectorFamily = selectorFamily<
    ContentTierTitles,
    string
>({
    key: 'contentTierTitlesSelectorFamily',
    get:
        (courseName) =>
        ({ get }) => {
            const courseInfo = get(courseInfoAtom(courseName))
            return courseInfo.contentTierTitles as ContentTierTitles
        },
    set:
        (courseName) =>
        ({ set }, newContentTierTitles) => {
            if (newContentTierTitles instanceof DefaultValue) return

            set(courseInfoAtom(courseName), (courseInfo) => ({
                ...courseInfo,
                contentTierTitles: newContentTierTitles,
            }))
        },
})
