import { atomFamily, selectorFamily } from 'recoil'
import {
    getSamplePreviousExamAnalysisSignedUrl,
    getExistingPreviousExamAnalysisSignedUrl,
} from '@/api/cb/previousExamAnalysis'
import { SAMPLE_PREVIOUS_EXAM_ANALYSIS_COURSE_NAME } from 'common/src/api/contentFrontendVsContentBackend/previousExamContent'

export const previousExamAnalysisSignedDownloadUrlAtomFamily = atomFamily<
    string,
    string | null // null if nonexistent
>({
    key: 'previousExamAnalysisSignedDownloadUrlAtomFamily',
    default: selectorFamily<string, string | null>({
        key: 'previousExamAnalysisSignedDownloadUrlDefaultSelectorFamily',
        get: (courseName) => async () => {
            let response
            if (courseName === SAMPLE_PREVIOUS_EXAM_ANALYSIS_COURSE_NAME) {
                response = await getSamplePreviousExamAnalysisSignedUrl()
            } else {
                response =
                    await getExistingPreviousExamAnalysisSignedUrl(courseName)
            }
            return response.data.payload.signedUrl
        },
    }),
})
