import React, { ReactElement } from 'react'
import { Modal } from 'antd'
import { Authenticator } from '@/components/authentication/Authenticator/Authenticator'
import { AuthenticatorTabName } from '@/components/authentication/Authenticator/Authenticator.types'

export const SplashPage: React.FC<unknown> = (): ReactElement => {
    return (
        <Modal open={true} closable={false} footer={null}>
            <Authenticator defaultTab={AuthenticatorTabName.Login} />
        </Modal>
    )
}
