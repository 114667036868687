import { useRecoilCallback } from 'recoil'
import { practiceProblemAtomFamily } from '@/atoms/practiceProblem'
import { IGenericPracticeProblem } from 'common/src/practiceProblems/types'

/**
 * Useful for adding a blank practice problem without explicitly saving it to the backend yet
 */
export const useSetLocalOnlyPracticeProblemData = (): ((
    practiceProblemData: IGenericPracticeProblem
) => void) => {
    return useRecoilCallback(
        ({ set }) =>
            (practiceProblemData: IGenericPracticeProblem) => {
                set(
                    practiceProblemAtomFamily(practiceProblemData.id),
                    practiceProblemData
                )
            }
    )
}
