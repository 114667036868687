import { DefaultValue, selectorFamily } from 'recoil'
import { courseInfoAtom } from '@/atoms/courseInfo'

export const recommendedStudyHoursSelectorFamily = selectorFamily<
    number,
    string
>({
    key: 'recommendedStudyHoursSelectorFamily',
    get:
        (courseName) =>
        ({ get }) => {
            const courseInfo = get(courseInfoAtom(courseName))
            return courseInfo.recommendedStudyHours
        },
    set:
        (courseName) =>
        ({ set }, recommendedStudyHours) => {
            if (recommendedStudyHours instanceof DefaultValue) return

            set(courseInfoAtom(courseName), (courseInfo) => ({
                ...courseInfo,
                recommendedStudyHours,
            }))
        },
})
