import { MoveIndividualContentProps } from '@/components/ContentTreeNode/ContentTreeLeafNode/MoveIndividualContentButton/MoveIndividualContent/MoveIndividualContent.types'
import React, { ReactElement, useCallback, useMemo, useState } from 'react'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import {
    contentTreeStateAtom,
    contentTreeDepthSelector,
} from '@/atoms/contentTree'
import {
    areContentPathsEqual,
    ContentPath,
    hashContentPath,
} from 'common/src/ContentPath'
import { slimLeafNodeAtomFamily } from '@/atoms/slimLeafNode'
import { SelectContentPath } from '@/components/utils/SelectContentPath/SelectContentPath'
import { AreYouSureButton } from '@/components/utils/AreYouSureButton/AreYouSureButton'
import { getContentTreeChildForContentPath } from '@/utils/getContentTreeChildForContentPath'
import styles from './MoveIndividualContent.module.less'
import { moveIndividualContent } from '@/api/cb/contentCreatorContent'
import { syncErrorMessage, syncSuccessMessage } from '@/utils/syncMessages'
import assertNever from 'assert-never/index'
import {
    addNewNotecardID,
    addNewPracticeProblemID,
    deleteNotecardID,
    deletePracticeProblemID,
} from '@/components/ContentTreeNode/ContentTreeLeafNode/immer'
import { frontendDisplayedCourseSelector } from '@/atoms/auth'
import { viewingStateAtom } from '@/atoms/viewingState'
import { ISlimLeafNodeData } from 'common/src/api/backendToBackend/content/types'
import { useInvalidateContent } from '@/hooks/useInvalidateContent'

export const MoveIndividualContent: React.FC<MoveIndividualContentProps> = (
    props
): ReactElement => {
    const frontendDisplayedCourseName = useRecoilValue(
        frontendDisplayedCourseSelector
    )
    const contentTreeState = useRecoilValue(contentTreeStateAtom)
    const [selectedContentPath, setSelectedContentPath] = useState<ContentPath>(
        []
    )
    const contentTreeDepth = useRecoilValue(contentTreeDepthSelector)

    const hashedSelectedContentPath = useMemo((): string | null => {
        if (selectedContentPath.length < contentTreeDepth) return null
        return hashContentPath(selectedContentPath)
    }, [contentTreeDepth, selectedContentPath])

    const isSameContentPath = useMemo(
        (): boolean =>
            areContentPathsEqual(props.contentPath, selectedContentPath),
        [props.contentPath, selectedContentPath]
    )

    const hashedActiveContentPath = useMemo((): string | null => {
        if (props.contentPath.length < contentTreeDepth) return null
        return hashContentPath(props.contentPath)
    }, [contentTreeDepth, props.contentPath])
    const [activeLeafNode, setActiveLeafNode] = useRecoilState(
        slimLeafNodeAtomFamily(hashedActiveContentPath)
    )
    const [selectedLeafNode, setSelectedLeafNode] = useRecoilState(
        slimLeafNodeAtomFamily(hashedSelectedContentPath)
    )
    const setViewingState = useSetRecoilState(viewingStateAtom)
    const invalidateContent = useInvalidateContent(props.type, props.id)

    const moveIndividualContentLocally = useCallback((): void => {
        let newSelectedLeafNode: ISlimLeafNodeData
        let newActiveLeafNode: ISlimLeafNodeData

        switch (props.type) {
            case 'notecard':
                newSelectedLeafNode = addNewNotecardID(
                    selectedLeafNode,
                    props.id
                )
                newActiveLeafNode = deleteNotecardID(activeLeafNode, props.id)
                break
            case 'practiceProblem':
                newSelectedLeafNode = addNewPracticeProblemID(
                    selectedLeafNode,
                    props.id
                )
                newActiveLeafNode = deletePracticeProblemID(
                    activeLeafNode,
                    props.id
                )
                break
            default:
                assertNever(props.type)
        }
        invalidateContent()
        setSelectedLeafNode(newSelectedLeafNode)
        setActiveLeafNode(newActiveLeafNode)

        setViewingState((currentViewingState) => ({
            contentPath: selectedContentPath,
            viewingStateType: currentViewingState.viewingStateType,
            id: props.id,
        }))

        props.closeModal()
    }, [
        activeLeafNode,
        invalidateContent,
        props,
        selectedContentPath,
        selectedLeafNode,
        setActiveLeafNode,
        setSelectedLeafNode,
        setViewingState,
    ])

    const moveIndividualContentCallback =
        useCallback(async (): Promise<void> => {
            const response = await moveIndividualContent({
                courseName: frontendDisplayedCourseName,
                fromContentPath: props.contentPath,
                type: props.type,
                id: props.id,
                toContentPath: selectedContentPath,
            })

            if (response.data.isError) {
                syncErrorMessage(
                    'Error moving content. You may want to refresh the page, check what moved, if anything, and try again.'
                )
            } else if (response.data.payload.failureMessageOrNull) {
                syncErrorMessage(
                    `Error moving content: ${response.data.payload.failureMessageOrNull}`
                )
            } else {
                syncSuccessMessage('Successfully moved content.')
                moveIndividualContentLocally()
            }
        }, [
            frontendDisplayedCourseName,
            moveIndividualContentLocally,
            props.contentPath,
            props.id,
            props.type,
            selectedContentPath,
        ])

    return (
        <div className={styles.moveIndividualContent}>
            <div>Move {props.type}</div>
            <div>Select content path:</div>
            <SelectContentPath
                contentPath={selectedContentPath}
                setContentPath={setSelectedContentPath}
                contentTreeState={contentTreeState}
                maxDepth={3}
            />
            <AreYouSureButton
                performAction={moveIndividualContentCallback}
                areYouSureContent={`Are you sure you want to move this ${
                    props.type
                } to ${
                    getContentTreeChildForContentPath(
                        contentTreeState.contentTree,
                        selectedContentPath.slice(0, 1)
                    )?.name
                } / ${
                    getContentTreeChildForContentPath(
                        contentTreeState.contentTree,
                        selectedContentPath.slice(0, 2)
                    )?.name
                } / ${
                    getContentTreeChildForContentPath(
                        contentTreeState.contentTree,
                        selectedContentPath.slice(0, 3)
                    )?.name
                }? Double check that this is correct before continuing.`}
                buttonText={'Move Content'}
                disabled={selectedContentPath.length !== 3 || isSameContentPath}
                okText={"Yes, I'm sure"}
                cancelText={'Let me rethink'}
            />
        </div>
    )
}
