import React, {
    ReactElement,
    useCallback,
    useMemo,
    useState,
    useEffect,
} from 'react'
import styles from './SummaryEditor.module.less'
import { useRecoilValue, useRecoilState } from 'recoil'
import { slimLeafNodeAtomFamily } from '@/atoms/slimLeafNode'
import { SummaryEditorProps } from '@/components/ContentTreeNode/ContentTreeLeafNode/SummaryEditor/SummaryEditor.types'
import { overwriteSummaryAtContentPath } from '@/api/cb/contentCreatorContent'
import {
    frontendDisplayedCourseSelector,
    isActiveCourseReadOnlySelector,
} from '@/atoms/auth'
import { unHashContentPath } from 'common/src/ContentPath'
import { syncErrorMessage, syncSuccessMessage } from '@/utils/syncMessages'
import {
    useMarkContentWithUnsavedChanges,
    useMarkContentWithSavedChanges,
} from '@/hooks/useMarkContentWithUnsavedChanges'
import { SavableContent, SavableContentType } from '@/atoms/unsavedChanges'
import { OutlineMagicTextBoxEditor } from '@/components/ContentTreeNode/ContentTreeLeafNode/MagicTextBoxEditor/OutlineMagicTextBoxEditor/OutlineMagicTextBoxEditor'
import { setSummary } from '@/components/ContentTreeNode/ContentTreeLeafNode/immer'

export const SummaryEditor: React.FC<SummaryEditorProps> = (
    props
): ReactElement => {
    const [{ summary }, setSlimLeafNode] = useRecoilState(
        slimLeafNodeAtomFamily(props.hashedContentPath)
    )

    const courseName = useRecoilValue(frontendDisplayedCourseSelector)
    const isReadOnly = useRecoilValue(isActiveCourseReadOnlySelector)

    const contentPath = useMemo(
        () => unHashContentPath(props.hashedContentPath),
        [props.hashedContentPath]
    )

    const markContentWithUnsavedChanges = useMarkContentWithUnsavedChanges()
    const savableContent = useMemo(
        (): SavableContent => ({
            contentPath,
            contentType: SavableContentType.summary,
        }),
        [contentPath]
    )

    const markContentWithSavedChanges = useMarkContentWithSavedChanges()
    const saveSummary = useCallback(
        async (serializedEditorState: string): Promise<void> => {
            if (isReadOnly) return
            const response = await overwriteSummaryAtContentPath(
                courseName,
                contentPath,
                serializedEditorState
            )
            if (response.data.isError) {
                syncErrorMessage(
                    'Error saving summary. You may want to try saving the summary again.'
                )
            } else {
                syncSuccessMessage('Summary successfully saved')
                setSlimLeafNode((slimLeafNode) =>
                    setSummary(slimLeafNode, serializedEditorState)
                )
            }
        },
        [contentPath, courseName, isReadOnly, setSlimLeafNode]
    )

    const [isUnsaved, setIsUnsaved] = useState<boolean>(false)
    useEffect(() => {
        if (isUnsaved) {
            markContentWithUnsavedChanges(savableContent)
        } else {
            markContentWithSavedChanges(savableContent)
        }
    }, [
        isUnsaved,
        markContentWithSavedChanges,
        markContentWithUnsavedChanges,
        savableContent,
    ])
    return (
        <div className={styles.summaryEditor}>
            <div className={styles.summaryEditorTitle}>Edit Summary</div>
            <OutlineMagicTextBoxEditor
                id={`${props.hashedContentPath}-outline`}
                namespace={'summary'}
                placeholder={'Enter summary here.'}
                initialStringifiedEditorState={summary}
                savableProps={{
                    onSave: saveSummary,
                    setHasUnsavedChanges: setIsUnsaved,
                }}
                type={'summary'}
                contentPath={contentPath}
            />
        </div>
    )
}
