import React, { ReactElement, useMemo, PropsWithChildren } from 'react'
import { Button, Modal } from 'antd'
import { LoadingComponent } from '@/components/utils/LoadingComponent/LoadingComponent'
import { ButtonModalWrapperProps } from '@/components/utils/ButtonModalWrapper/ButtonModalWrapper.types'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import {
    viewingStateAtom,
    viewingStateTypeSelector,
} from '@/atoms/viewingState'
import { areContentPathsEqual } from 'common/src/ContentPath'

export const ButtonModalWrapper: React.FC<
    PropsWithChildren<ButtonModalWrapperProps>
> = (props): ReactElement => {
    const viewingState = useRecoilValue(viewingStateAtom)
    const setActiveViewType = useSetRecoilState(viewingStateTypeSelector)

    const isModalVisible = useMemo(
        (): boolean =>
            viewingState.viewingStateType === props.viewingStateType &&
            (props.contentPath
                ? areContentPathsEqual(
                      props.contentPath,
                      viewingState.contentPath
                  )
                : true),
        [viewingState, props]
    )

    return (
        <div className={props.buttonWrapperClassName}>
            <Button
                onClick={() => setActiveViewType(props.viewingStateType)}
                size={props.size}
                className={props.buttonClassName}
                disabled={props.disabled}
            >
                {props.buttonText}
            </Button>
            <Modal
                open={isModalVisible}
                onCancel={() => setActiveViewType(null)}
                footer={null}
                destroyOnClose={props.destroyOnClose ?? true}
                className={props.modalClassName}
            >
                <React.Suspense
                    fallback={<LoadingComponent useWhiteBackground={true} />}
                >
                    {props.children}
                </React.Suspense>
            </Modal>
        </div>
    )
}
