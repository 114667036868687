import styles from './HintsEditor.module.less'
import React, { ReactElement, useCallback, useState } from 'react'
import { DndProviderWrapper } from '@/components/utils/DraggableSortingList/DraggableSortingList'
import { DraggableItem } from '@/components/utils/DraggableItem/DraggableItem'
import produce from 'immer'
import { EditableText } from '@/components/EditableText/EditableText'
import { Button } from 'antd'
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { reusableCssClass } from '@/utils/reusableCssClasses'

interface HintsEditorProps {
    hints: string[]
    setHints: (newHints: string[]) => void
    isDisabled: boolean
}

export const HintsEditor: React.FC<HintsEditorProps> = (
    props
): ReactElement => {
    const changeIndex = useCallback(
        (startingIndex: number, endingIndex: number) => {
            props.setHints(reorder(props.hints, startingIndex, endingIndex))
            setActiveIndex(endingIndex)
        },
        [props]
    )

    const setHint = useCallback(
        (hint: string, index: number) => {
            props.setHints(setAtIndex(props.hints, hint, index))
        },
        [props]
    )

    const addNewHint = useCallback(() => {
        props.setHints(setAtIndex(props.hints, 'EDIT ME', props.hints.length))
    }, [props])

    const deleteHint = useCallback(
        (index: number) => {
            props.setHints(deleteAtIndex(props.hints, index))
        },
        [props]
    )

    const [activeIndex, setActiveIndex] = useState<number>(0)

    return (
        <div className={styles.hintsEditorContainer}>
            <div className={styles.hintsTitleContainer}>
                <div className={styles.hintsTitleText}>Hints: </div>
                <div className={styles.addHintButtonContainer}>
                    <Button
                        icon={<PlusOutlined />}
                        onClick={addNewHint}
                        disabled={props.isDisabled}
                    />
                </div>
            </div>
            <DndProviderWrapper className={styles.hintsEditor}>
                {props.hints.map((hint, index) => (
                    <DraggableItem
                        index={index}
                        changeIndex={changeIndex}
                        key={`${hint}-${index}`}
                        className={index === activeIndex && styles.activeHint}
                        onClick={
                            props.isDisabled
                                ? () => null
                                : () => setActiveIndex(index)
                        }
                    >
                        <DraggableHint
                            hint={hint}
                            setHint={(newHint) => setHint(newHint, index)}
                            deleteHint={() => deleteHint(index)}
                        />
                    </DraggableItem>
                ))}
            </DndProviderWrapper>
        </div>
    )
}

const DraggableHint: React.FC<{
    hint: string
    setHint: (newHint: string) => void
    deleteHint: () => void
}> = (props): ReactElement => {
    return (
        <div className={styles.draggableHint}>
            <div className={styles.editableTextContainer}>
                <EditableText onSave={props.setHint} initialText={props.hint} />
            </div>
            <div className={reusableCssClass.centerChildrenVertically}>
                <Button
                    icon={<DeleteOutlined />}
                    onClick={props.deleteHint}
                    danger={true}
                />
            </div>
        </div>
    )
}

const reorder = produce(
    (items: string[], startingIndex: number, endingIndex: number): void => {
        const movedContent = items.splice(startingIndex, 1)
        items.splice(endingIndex, 0, ...movedContent)
    }
)

const setAtIndex = produce(
    (items: string[], newValue: string, index: number): void => {
        items[index] = newValue
    }
)

const deleteAtIndex = produce((items: string[], index: number): void => {
    items.splice(index, 1)
})
