import React, { ReactElement } from 'react'
import { LoadingComponent } from '@/components/utils/LoadingComponent/LoadingComponent'
import { NodeStatsProps } from '@/components/ContentTreeNode/NodeStats/NodeStats.types'
import { NodeStats } from '@/components/ContentTreeNode/NodeStats/NodeStats'
import styles from './NodeStatsButton.module.less'
import { ButtonModalWrapper } from '@/components/utils/ButtonModalWrapper/ButtonModalWrapper'
import { ViewingStateType } from '@/atoms/viewingState'

export const NodeStatsButton: React.FC<NodeStatsProps> = (
    props
): ReactElement => {
    return (
        <ButtonModalWrapper
            viewingStateType={ViewingStateType.nodeStats}
            buttonText={'Node Stats'}
            size={'large'}
            buttonWrapperClassName={styles.nodeStatsButton}
            contentPath={props.contentPath}
        >
            <React.Suspense
                fallback={<LoadingComponent useWhiteBackground={true} />}
            >
                <NodeStats {...props} />
            </React.Suspense>
        </ButtonModalWrapper>
    )
}
