import React, {
    ReactElement,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react'
import { CopyLeafNodeProps } from '@/components/ContentTreeNode/ContentTreeLeafNode/CopyLeafNodeButton/CopyLeafNode/CopyLeafNode.types'
import styles from './CopyLeafNode.module.less'
import { useRecoilValue } from 'recoil'
import { courseNamesForContentCreatorAtom } from '@/atoms/courseNamesForContentCreator'
import { frontendDisplayedCourseSelector } from '@/atoms/auth'
import { contentTreeStateSelectorFamily } from '@/atoms/contentTree'
import { notification, Select } from 'antd'
import { ContentPath } from 'common/src/ContentPath'
import { SelectContentPath } from '@/components/utils/SelectContentPath/SelectContentPath'
import { AreYouSureButton } from '@/components/utils/AreYouSureButton/AreYouSureButton'
import { nodeMetadataTreeSelector } from '@/atoms/nodeMetadata'
import { getMetadataForContentPath } from '@/frontendLogic/outlines/NodeMetadataTree/NodeMetadataTree'
import { copyContentLeafNode } from '@/api/cb/contentCreatorContent'
import { syncErrorMessage, syncSuccessMessage } from '@/utils/syncMessages'
import { getContentTreeChildForContentPath } from '@/utils/getContentTreeChildForContentPath'

const { Option } = Select

// Currently not supporting copying within the same exam
export const CopyLeafNode: React.FC<CopyLeafNodeProps> = (
    props
): ReactElement => {
    const frontendDisplayedCourseName = useRecoilValue(
        frontendDisplayedCourseSelector
    )

    const courseNames = useRecoilValue(courseNamesForContentCreatorAtom).filter(
        (courseName) => courseName !== frontendDisplayedCourseName
    )

    const [selectedCourseName, setSelectedCourseName] = useState<string | null>(
        courseNames[0]
    )
    const selectedContentTreeState = useRecoilValue(
        contentTreeStateSelectorFamily(selectedCourseName)
    )

    const [selectedContentPath, setSelectedContentPath] = useState<ContentPath>(
        []
    )

    const isAlreadyPresent = useMemo((): boolean => {
        const fromLeafNodeID = props.contentPath[props.contentPath.length - 1]
        const targetContentTree = getContentTreeChildForContentPath(
            selectedContentTreeState.contentTree,
            [...selectedContentPath, fromLeafNodeID]
        )
        return !!targetContentTree
    }, [
        selectedContentPath,
        props.contentPath,
        selectedContentTreeState.contentTree,
    ])

    useEffect(() => {
        if (!isAlreadyPresent) return
        notification.info({
            message:
                'This content leaf node already exists at the target destination.',
        })
    }, [isAlreadyPresent])

    const nodeMetadataTree = useRecoilValue(nodeMetadataTreeSelector)
    const { title } = useMemo(
        () => getMetadataForContentPath(nodeMetadataTree, props.contentPath),
        [nodeMetadataTree, props.contentPath]
    )

    const copyContentLeafNodeCallback = useCallback(async (): Promise<void> => {
        const response = await copyContentLeafNode({
            fromCourseName: frontendDisplayedCourseName,
            fromContentPath: props.contentPath,
            toCourseName: selectedCourseName,
            toContentPath: [
                ...selectedContentPath,
                props.contentPath[props.contentPath.length - 1],
            ],
        })
        if (response.data.isError) {
            syncErrorMessage(
                'Error copying content. You may want to refresh the page, check what copied, if anything, and try again.'
            )
        } else if (response.data.payload.failureMessageOrNull) {
            syncErrorMessage(
                `Error copying content: ${response.data.payload.failureMessageOrNull}`
            )
        } else {
            syncSuccessMessage(
                'Successfully copied content. You may need to refresh the page to reflect these changes.'
            )
        }
    }, [
        selectedContentPath,
        frontendDisplayedCourseName,
        props.contentPath,
        selectedCourseName,
    ])

    return (
        <div className={styles.copyLeafNode}>
            <div className={styles.copyLeafNodeTitle}>
                Copy leaf node: {title} (summary, outline, and all notecards /
                practice problems)
            </div>
            <div>Select course:</div>
            <Select
                value={selectedCourseName}
                onChange={(courseName) => {
                    setSelectedContentPath([])
                    setSelectedCourseName(courseName)
                }}
            >
                {courseNames.map((courseName) => (
                    <Option value={courseName} key={courseName}>
                        {courseName}
                    </Option>
                ))}
            </Select>
            <div>Select content path:</div>
            <SelectContentPath
                key={selectedCourseName}
                contentPath={selectedContentPath}
                setContentPath={setSelectedContentPath}
                contentTreeState={selectedContentTreeState}
                maxDepth={2}
            />
            <AreYouSureButton
                performAction={copyContentLeafNodeCallback}
                areYouSureContent={`Are you sure you want to copy leaf node: ${title} to course: ${selectedCourseName} / ${
                    getContentTreeChildForContentPath(
                        selectedContentTreeState.contentTree,
                        selectedContentPath.slice(0, 1)
                    )?.name
                } / ${
                    getContentTreeChildForContentPath(
                        selectedContentTreeState.contentTree,
                        selectedContentPath.slice(0, 2)
                    )?.name
                }? Double check that this is correct before continuing.`}
                buttonText={'Copy Content'}
                disabled={selectedContentPath.length !== 2 || isAlreadyPresent}
                okText={"Yes, I'm sure"}
                cancelText={'Let me rethink'}
            />
        </div>
    )
}
