import React, { useEffect, useState, useRef, useCallback } from 'react'
import { useWindowSize } from '@/hooks/useWindowSize'
import { useInterval } from '@/hooks/useInterval'
import { useLogger } from '@/hooks/useLogger'
import { useIntervalWithExponentialDecay } from '@/hooks/useIntervalWithExponentialDecay'

// Due to issues with rendering and understanding when the page is fully loaded,
// this hook refreshes every 50ms until the state has stopped changing for MAX_NUM_ATTEMPTS intervals
// Hacky, but solves the issue until we come up with a better solution.
const MAX_NUM_ATTEMPTS = 120
const REFRESH_INTERVAL_MS = 100
const isBoxAreaZero = (domRect: DOMRect): boolean => {
    return (
        domRect.x === 0 &&
        domRect.y === 0 &&
        domRect.width === 0 &&
        domRect.height === 0
    )
}
export const useIsSingleColumn = (
    leftColumnRef: React.MutableRefObject<HTMLDivElement>,
    rightColumnRef: React.MutableRefObject<HTMLDivElement>
): boolean | null => {
    const [isSingleColumn, setIsSingleColumn] = useState<boolean>(null)
    const logger = useLogger(useIsSingleColumn.name)
    const windowSize = useWindowSize()
    const updateIsSingleColumn = useCallback((): void => {
        if (!leftColumnRef.current || !rightColumnRef.current) return
        const leftDOMRect = leftColumnRef.current.getBoundingClientRect()
        if (isBoxAreaZero(leftDOMRect)) return

        const rightDOMRect = rightColumnRef.current.getBoundingClientRect()
        if (isBoxAreaZero(rightDOMRect)) return

        const shouldBeIsSingleColumn = rightDOMRect.x <= leftDOMRect.x
        if (shouldBeIsSingleColumn !== isSingleColumn) {
            setIsSingleColumn(shouldBeIsSingleColumn)
        }
    }, [isSingleColumn, setIsSingleColumn, leftColumnRef, rightColumnRef])

    const [counter, setCounter] = useState<number>(0)
    const countOfLastChange = useRef<number>(0)
    const mostRecentVal = useRef<boolean>(false)
    const shouldPoll =
        isSingleColumn === null &&
        counter - countOfLastChange.current < MAX_NUM_ATTEMPTS

    useInterval(
        (): void => {
            if (isSingleColumn !== mostRecentVal.current) {
                countOfLastChange.current = counter
                mostRecentVal.current = isSingleColumn
            }
            setCounter((counter) => counter + 1)
        },
        shouldPoll ? REFRESH_INTERVAL_MS : null
    )

    useIntervalWithExponentialDecay(updateIsSingleColumn, {
        initialValue: 50,
        exponentialBase: 10,
        maximumValue: 5_000,
    })

    useEffect(() => {
        updateIsSingleColumn()
    }, [windowSize, counter, updateIsSingleColumn, shouldPoll])

    useEffect(() => {
        if (!shouldPoll)
            logger.info(
                `Patience is up, concluding polling for columnar state (counter: ${counter}).`
            )
    }, [counter, logger, shouldPoll])

    return isSingleColumn
}
