import React, { ReactElement, useCallback, useState, useMemo } from 'react'
import styles from './ReleaseInfoEditor.module.less'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
    frontendDisplayedCourseSelector,
    isActiveCourseReadOnlySelector,
} from '@/atoms/auth'
import { releaseInfoSelectorFamily } from '@/atoms/releaseInfo'
import { setReleaseInfoForCourse } from '@/api/cb/contentCreatorContent'
import { syncErrorMessage, syncSuccessMessage } from '@/utils/syncMessages'

import { Button, DatePicker, Input, Select, Tooltip } from 'antd'
import {
    getPrettyReleaseStatusForReleaseStatusEnum,
    getTooltipForReleaseStatusEnum,
} from '@/components/EditCourseMetadata/ReleaseInfoEditor/utils'
import { ReleaseInfo, ReleaseStatus } from 'common/src/api/core/courses/types'
import dayjs from 'dayjs'

const { Option } = Select

export const ReleaseInfoEditor: React.FC<unknown> = (): ReactElement => {
    const courseName = useRecoilValue(frontendDisplayedCourseSelector)
    const isReadOnly = useRecoilValue(isActiveCourseReadOnlySelector)

    const [releaseInfo, setReleaseInfo] = useRecoilState(
        releaseInfoSelectorFamily(courseName)
    )
    const [currentReleaseInfo, setCurrentReleaseInfo] =
        useState<ReleaseInfo>(releaseInfo)
    const hasUnsavedChanges = useMemo((): boolean => {
        return (
            currentReleaseInfo.releaseStatus !== releaseInfo.releaseStatus ||
            new Date(currentReleaseInfo.expectedReleaseDate).getTime() !==
                new Date(releaseInfo.expectedReleaseDate).getTime() ||
            currentReleaseInfo.extraDetail !== releaseInfo.extraDetail
        )
    }, [currentReleaseInfo, releaseInfo])

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const saveReleaseInfo = useCallback(async (): Promise<void> => {
        setIsLoading(true)

        const response = await setReleaseInfoForCourse({
            courseName,
            releaseInfo,
        })

        if (response.data.isError) {
            syncErrorMessage(
                'Error updating release information. You may want to try setting it again or refreshing the page.'
            )
        } else {
            setCurrentReleaseInfo(releaseInfo)
            syncSuccessMessage('Release information successfully updated')
        }

        setIsLoading(false)
    }, [courseName, releaseInfo])

    return (
        <div className={styles.releaseInfoEditor}>
            <div className={styles.releaseInfoEditorText}>Release info:</div>
            <div className={styles.releaseInfoEditorReleaseStatus}>
                <div className={styles.releaseInfoEditorReleaseStatusText}>
                    Release status:
                </div>
                <Select
                    value={releaseInfo.releaseStatus}
                    onChange={(newReleaseStatus) =>
                        setReleaseInfo((current) => ({
                            ...current,
                            releaseStatus: newReleaseStatus,
                        }))
                    }
                    disabled={isReadOnly}
                >
                    {Object.values(ReleaseStatus)
                        .filter(
                            (releaseStatus) =>
                                releaseStatus !== ReleaseStatus.notAvailable
                        )
                        .map((releaseStatus) => (
                            <Option value={releaseStatus} key={releaseStatus}>
                                <Tooltip
                                    title={getTooltipForReleaseStatusEnum(
                                        releaseStatus
                                    )}
                                    placement={'right'}
                                >
                                    <div>
                                        {getPrettyReleaseStatusForReleaseStatusEnum(
                                            releaseStatus
                                        )}
                                    </div>
                                </Tooltip>
                            </Option>
                        ))}
                </Select>
            </div>
            <div className={styles.releaseInfoEditorExpectedReleaseDate}>
                <div
                    className={styles.releaseInfoEditorExpectedReleaseDateText}
                >
                    Expected release date:
                </div>
                <DatePicker
                    value={dayjs(releaseInfo.expectedReleaseDate || new Date())}
                    onChange={(newDate) =>
                        setReleaseInfo((current) => ({
                            ...current,
                            expectedReleaseDate: newDate.toDate(),
                        }))
                    }
                    allowClear={false}
                    disabled={isReadOnly}
                />
            </div>
            <div className={styles.releaseInfoEditorExtraDetail}>
                <div className={styles.releaseInfoEditorExtraDetailText}>
                    Extra detail (optional):
                </div>
                <Input
                    value={releaseInfo.extraDetail}
                    onChange={(newExtraDetail) =>
                        setReleaseInfo((current) => ({
                            ...current,
                            extraDetail:
                                newExtraDetail.target.value || undefined,
                        }))
                    }
                    disabled={isReadOnly}
                />
            </div>
            <div className={styles.releaseInfoEditorSaveButtonContainer}>
                <Button
                    onClick={saveReleaseInfo}
                    type={'primary'}
                    loading={isLoading}
                    disabled={isReadOnly || !hasUnsavedChanges}
                >
                    Save
                </Button>
            </div>
        </div>
    )
}
