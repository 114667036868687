import {
    atomFamily,
    selectorFamily,
    useSetRecoilState,
    useRecoilTransaction_UNSTABLE,
} from 'recoil'
import { getSourceMaterial } from '@/api/cb/contentCreatorContent'
import { frontendDisplayedCourseSelector } from '@/atoms/auth'
import { SourceMaterialData } from 'common/src/api/contentFrontendVsContentBackend/sourceMaterial'
import { ContentPath } from 'common/src/ContentPath'
import { useCallback } from 'react'

export const sourceMaterialAtomFamily = atomFamily<
    SourceMaterialData | null,
    { id: string; contentPath: ContentPath } | null
>({
    key: 'sourceMaterialAtomFamily',
    default: selectorFamily<
        SourceMaterialData | null,
        { id: string; contentPath: ContentPath } | null
    >({
        key: 'sourceMaterialDefaultSelectorFamily',
        get:
            (data) =>
            async ({ get }) => {
                get(sourceMaterialInvalidatorAtomFamily(data?.id))
                if (!data) return null
                const frontendDisplayedCourse = get(
                    frontendDisplayedCourseSelector
                )
                const response = await getSourceMaterial({
                    courseName: frontendDisplayedCourse,
                    contentPath: data.contentPath,
                    id: data.id,
                })
                return response.data.payload
            },
    }),
})

export const lastSavedSourceMaterialDataAtomFamily = atomFamily<
    SourceMaterialData | null,
    { id: string; contentPath: ContentPath } | null
>({
    key: 'lastSavedSourceMaterialDataAtomFamily',
    default: null,
})

const sourceMaterialInvalidatorAtomFamily = atomFamily<number, string | null>({
    key: 'sourceMaterialInvalidatorAtomFamily',
    default: 0,
})

export const useInvalidateSourceMaterial = (
    practiceProblemID: string | null
): (() => void) => {
    const setSourceMaterialInvalidator = useSetRecoilState(
        sourceMaterialInvalidatorAtomFamily(practiceProblemID)
    )
    return useCallback(
        (): void =>
            setSourceMaterialInvalidator((currentValue) => currentValue + 1),
        [setSourceMaterialInvalidator]
    )
}

export const useInvalidateSourceMaterials = (
    sourceMaterialIDs: string[]
): (() => void) => {
    return useRecoilTransaction_UNSTABLE(({ set }) => () => {
        for (const sourceMaterialID of sourceMaterialIDs) {
            set(
                sourceMaterialInvalidatorAtomFamily(sourceMaterialID),
                (x) => x + 1
            )
        }
    })
}
