import { ContentPath } from 'common/src/ContentPath'
import { INotecard, NotecardMetadata } from 'common/src/notecards/types'

export type ContentCreatorEditableNotecardMetadata = Pick<
    NotecardMetadata,
    | 'hints'
    | 'categories'
    | 'importance'
    | 'isPreviousExamQuestion'
    | 'learningObjectiveID'
    | 'mnemonic'
>

export const parseContentCreatorEditableNotecardMetadata = (
    notecard: INotecard
): ContentCreatorEditableNotecardMetadata => ({
    hints: notecard.hints,
    categories: notecard.categories,
    importance: notecard.importance,
    isPreviousExamQuestion: notecard.isPreviousExamQuestion,
    learningObjectiveID: notecard.learningObjectiveID,
    mnemonic: notecard.mnemonic,
})

export interface NotecardMetadataEditorProps {
    contentPath: ContentPath
    contentCreatorEditableNotecardMetadata: ContentCreatorEditableNotecardMetadata
    setContentCreatorEditableNotecardMetadata: (
        contentCreatorEditableNotecardMetadata: ContentCreatorEditableNotecardMetadata
    ) => void
    notecardID: string
}
