import { useRecoilValue } from 'recoil'
import { notecardAtomFamily } from '@/atoms/notecard'
import {
    DraggableIconData,
    DraggableIconType,
} from '@/components/utils/DraggableSortingList/DraggableIcon/DraggableIcon.types'
import { practiceProblemAtomFamily } from '@/atoms/practiceProblem'
import React, { useMemo, ReactNode, useCallback } from 'react'
import assertNever from 'assert-never/index'
import {
    IGenericPracticeProblem,
    IGenericSinglePartPracticeProblem,
    IMultiPartProblemBase,
} from 'common/src/practiceProblems/types'
import { extractTextFromStringifiedEditorState } from 'magic-text-box-lexical/src/utils/extractTextFromStringifiedEditorState'
import { sourceMaterialAtomFamily } from '@/atoms/sourceMaterial'
import { FormulaSheetEntryComponent } from '@/components/FormulaSheetEditor/FormulaSheetEntryComponent/FormulaSheetEntryComponent'
import { activeCourseConfigSelector } from '@/atoms/courseInfo'

export const useDescriptionForContent = (
    data: DraggableIconData
): ReactNode => {
    const notecard = useRecoilValue(
        notecardAtomFamily(
            data.type === DraggableIconType.notecard ? data.id : null
        )
    )
    const practiceProblem = useRecoilValue(
        practiceProblemAtomFamily(
            data.type === DraggableIconType.practiceProblem ? data.id : null
        )
    )

    const superProblem = useRecoilValue(
        practiceProblemAtomFamily(
            data.type === DraggableIconType.subProblem
                ? data.superProblemID
                : null
        )
    )

    const subProblem = useMemo(
        (): IGenericSinglePartPracticeProblem =>
            data.type === DraggableIconType.subProblem
                ? (superProblem as IMultiPartProblemBase).subProblems[
                      data.subProblemIndex
                  ]
                : null,
        [data, superProblem]
    )
    const courseConfig = useRecoilValue(activeCourseConfigSelector)

    const sourceMaterial = useRecoilValue(
        sourceMaterialAtomFamily(
            data.type === DraggableIconType.sourceMaterial
                ? { id: data.id, contentPath: data.contentPath }
                : null
        )
    )
    const buildDescriptionForPracticeProblem = useCallback(
        (practiceProblem: IGenericPracticeProblem): string => {
            if (courseConfig.hasExplicitPracticeProblemTitle) {
                return practiceProblem?.topic
                    ? `${practiceProblem.topic} (${practiceProblem.id})`
                    : 'No topic'
            } else {
                return (
                    extractTextFromStringifiedEditorState(
                        practiceProblem.question
                    ) || 'Nothing written yet'
                )
            }
        },
        [courseConfig.hasExplicitPracticeProblemTitle]
    )

    return useMemo((): ReactNode => {
        switch (data.type) {
            case DraggableIconType.notecard:
                return notecard?.front
                    ? `${extractTextFromStringifiedEditorState(
                          notecard.front
                      )} (${notecard.id})`
                    : 'No text'
            case DraggableIconType.practiceProblem:
                return buildDescriptionForPracticeProblem(practiceProblem)
            case DraggableIconType.subProblem:
                return buildDescriptionForPracticeProblem(subProblem)
            case DraggableIconType.sourceMaterial:
                return sourceMaterial?.title ? sourceMaterial.title : 'No title'
            case DraggableIconType.formulaSheetEntry:
                return <FormulaSheetEntryComponent key={data.id} {...data} />
            case DraggableIconType.multipleChoiceOption: {
                const coreText =
                    extractTextFromStringifiedEditorState(
                        data.stringifiedMagicTextBox
                    ) || 'No text'
                if (data.isSolution) {
                    return `${coreText} (correct answer)`
                } else {
                    return coreText
                }
            }
            default:
                assertNever(data)
        }
    }, [
        buildDescriptionForPracticeProblem,
        data,
        notecard?.front,
        notecard?.id,
        practiceProblem,
        sourceMaterial?.title,
        subProblem,
    ])
}
