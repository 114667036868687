import { ReleaseInfo } from 'common/src/api/core/courses/types'
import { DefaultValue, selectorFamily } from 'recoil'
import { courseInfoAtom } from '@/atoms/courseInfo'

export const releaseInfoSelectorFamily = selectorFamily<ReleaseInfo, string>({
    key: 'releaseInfoSelectorFamily',
    get:
        (courseName) =>
        ({ get }) => {
            const courseInfo = get(courseInfoAtom(courseName))
            return courseInfo.releaseInfo
        },
    set:
        (courseName) =>
        ({ set }, newReleaseInfo) => {
            if (newReleaseInfo instanceof DefaultValue) return

            set(courseInfoAtom(courseName), (courseInfo) => ({
                ...courseInfo,
                releaseInfo: newReleaseInfo,
            }))
        },
})
