import {
    atomFamily,
    selectorFamily,
    useSetRecoilState,
    useRecoilTransaction_UNSTABLE,
} from 'recoil'
import { frontendDisplayedCourseSelector } from '@/atoms/auth'
import { getNotecard } from '@/api/cb/contentCreatorContent'
import { useCallback } from 'react'
import {
    SavableNotecardData,
    parseSavableNotecardData,
} from '@/components/ContentTreeNode/ContentTreeLeafNode/NotecardsEditor/NotecardEditor/saving'
import { INotecard } from 'common/src/notecards/types'

/**
 * Nulling allows one to pass null and get null to conditionally not use the atom
 */
export const notecardAtomFamily = atomFamily<INotecard | null, string | null>({
    key: 'notecardAtomFamily',
    default: selectorFamily<INotecard | null, string | null>({
        key: 'notecardDefaultSelectorFamily',
        get:
            (notecardID) =>
            async ({ get }) => {
                get(notecardInvalidatorAtomFamily(notecardID))
                if (!notecardID) return null
                const frontendDisplayedCourse = get(
                    frontendDisplayedCourseSelector
                )
                const response = await getNotecard(
                    frontendDisplayedCourse,
                    notecardID
                )
                return response.data.payload
            },
    }),
})

export const currentNotecardDataSelectorFamily = selectorFamily<
    SavableNotecardData | null,
    string | null
>({
    key: 'currentNotecardDataSelectorFamily',
    get:
        (notecardID) =>
        ({ get }) => {
            if (!notecardID) return null
            return parseSavableNotecardData(get(notecardAtomFamily(notecardID)))
        },
})

export const lastSavedNotecardDataAtomFamily = atomFamily<
    SavableNotecardData | null,
    string | null
>({
    key: 'lastSavedNotecardDataAtomFamily',
    default: null,
})

const notecardInvalidatorAtomFamily = atomFamily<number, string | null>({
    key: 'notecardInvalidatorAtomFamily',
    default: 0,
})

export const useInvalidateNotecard = (
    notecardID: string | null
): (() => void) => {
    const setNotecardInvalidator = useSetRecoilState(
        notecardInvalidatorAtomFamily(notecardID)
    )
    return useCallback(
        (): void => setNotecardInvalidator((currentValue) => currentValue + 1),
        [setNotecardInvalidator]
    )
}

export const useInvalidateNotecards = (notecardIDs: string[]): (() => void) => {
    return useRecoilTransaction_UNSTABLE(({ set }) => () => {
        for (const notecardID of notecardIDs) {
            set(notecardInvalidatorAtomFamily(notecardID), (x) => x + 1)
        }
    })
}
