import { EditorState } from 'lexical'
import React, {
    ReactElement,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react'
import styles from './PracticeProblemEditor.module.less'
import { Button, Tabs, Tooltip } from 'antd'
import { MagicTextBoxEditor } from '@/components/ContentTreeNode/ContentTreeLeafNode/MagicTextBoxEditor/MagicTextBoxEditor'
import {
    PracticeProblemEditorContentType,
    PracticeProblemEditorProps,
} from '@/components/ContentTreeNode/ContentTreeLeafNode/PracticeProblemsEditor/PracticeProblemEditor/PracticeProblemEditor.types'
import {
    ContentCreatorEditablePracticeProblemMetadata,
    parseContentCreatorEditablePracticeProblemMetadata,
} from '@/components/ContentTreeNode/ContentTreeLeafNode/PracticeProblemsEditor/PracticeProblemEditor/PracticeProblemMetadataEditor/PracticeProblemMetadataEditor.types'
import { PracticeProblemMetadataEditor } from '@/components/ContentTreeNode/ContentTreeLeafNode/PracticeProblemsEditor/PracticeProblemEditor/PracticeProblemMetadataEditor/PracticeProblemMetadataEditor'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import {
    lastSavedPracticeProblemDataAtomFamily,
    practiceProblemAtomFamily,
} from '@/atoms/practiceProblem'
import {
    IGenericPracticeProblem,
    IGenericSinglePartPracticeProblem,
    IMultiPartProblemBase,
} from 'common/src/practiceProblems/types'
import {
    setContentCreatorEditablePracticeProblemMetadata,
    setContentCreatorEditablePracticeProblemMetadataForSubProblem,
    setQuestion,
    setSolution,
    setSubProblemQuestion,
    setSubProblemSolution,
} from '@/components/ContentTreeNode/ContentTreeLeafNode/PracticeProblemsEditor/immer'
import { MultiPartProblemSubProblemsEditor } from '@/components/ContentTreeNode/ContentTreeLeafNode/PracticeProblemsEditor/PracticeProblemEditor/MultiPartProblemSubProblemsEditor/MultiPartProblemSubProblemsEditor'
import { PracticeProblemType } from 'common/src/practiceProblems'
import { overwritePracticeProblemAtContentPath } from '@/api/cb/contentCreatorContent'
import {
    authStateAtom,
    frontendDisplayedCourseSelector,
    isActiveCourseReadOnlySelector,
} from '@/atoms/auth'
import { syncErrorMessage, syncSuccessMessage } from '@/utils/syncMessages'
import {
    SavableContent,
    SavableContentType,
    specificContentHasUnsavedChangesSelector,
} from '@/atoms/unsavedChanges'
import { newlyCreatedAndUnsavedPracticeProblemIDsAtom } from '@/atoms/newlyCreatedAndUnsavedContentIDs'
import { CopyIndividualContentButton } from '@/components/ContentTreeNode/ContentTreeLeafNode/CopyIndividualContentButton/CopyIndividualContentButton'
import { MoveIndividualContentButton } from '@/components/ContentTreeNode/ContentTreeLeafNode/MoveIndividualContentButton/MoveIndividualContentButton'
import {
    activeSubPracticeProblemEditorContentTypeAtom,
    activeSuperPracticeProblemEditorContentTypeAtom,
} from '@/atoms/activePracticeProblemEditorContentType'
import { ContentType } from 'common/src/commentThread/types'
import { useRouter } from 'next/router'
import { ContentCommentThreadWrapper } from '@/components/ContentCommentThread/ContentCommentThreadWrapper'
import { useMonitorPracticeProblemChanges } from '@/components/ContentTreeNode/ContentTreeLeafNode/PracticeProblemsEditor/PracticeProblemEditor/useMonitorPracticeProblemChanges'
import {
    parseSavablePracticeProblemData,
    validatePracticeProblemData,
} from '@/components/ContentTreeNode/ContentTreeLeafNode/PracticeProblemsEditor/PracticeProblemEditor/saving'
import { serializeEditorStateToString } from 'magic-text-box-lexical/src/serialization/serializeEditorStateToString'
import { useDebounce } from 'magic-text-box-lexical/src/utils/useDebounce'
import { MultipleChoicePracticeProblemSolutionsEditor } from '@/components/ContentTreeNode/ContentTreeLeafNode/PracticeProblemsEditor/PracticeProblemEditor/MultipleChoicePracticeProblemSolutionsEditor/MultipleChoicePracticeProblemSolutionsEditor'
import { activeCourseConfigSelector } from '@/atoms/courseInfo'
import { extractTextFromStringifiedEditorState } from 'magic-text-box-lexical/src/utils/extractTextFromStringifiedEditorState'
import { viewingStateAtom, ViewingStateType } from '@/atoms/viewingState'

/**
 * Component can act as both "Sub-Problem editor" and "Non-Sub-Problem editor".
 * Behavior differs with respect to saving / syncing.
 * Non-Sub-Problem editor only "syncs" when save button is pressed (which saves to backend).
 * Sub-Problem editor always syncs, but this updates the super problem's local data (which can then be saved by hitting the corresponding save button).
 */
export const PracticeProblemEditor: React.FC<PracticeProblemEditorProps> = (
    props
): ReactElement => {
    const isSubProblem = useMemo(
        (): boolean => !!props.superProblemID,
        [props.superProblemID]
    )

    const [practiceProblem, setPracticeProblem] = useRecoilState(
        practiceProblemAtomFamily(
            props.superProblemID ?? props.practiceProblemID
        )
    )
    useMonitorPracticeProblemChanges(
        practiceProblem.id,
        practiceProblem.contentPath,
        isSubProblem
    )
    const authState = useRecoilValue(authStateAtom)
    const isReadOnly = useRecoilValue(isActiveCourseReadOnlySelector)

    const subProblemIndex = useMemo((): number | null => {
        if (!isSubProblem) return null
        return (practiceProblem as IMultiPartProblemBase).subProblems.findIndex(
            (subProblem) => subProblem.id === props.practiceProblemID
        )
    }, [isSubProblem, practiceProblem, props.practiceProblemID])

    const subProblem = useMemo((): IGenericSinglePartPracticeProblem | null => {
        if (subProblemIndex === null) return null
        return (practiceProblem as IMultiPartProblemBase).subProblems[
            subProblemIndex
        ]
    }, [practiceProblem, subProblemIndex])

    const contentCreatorEditablePracticeProblemMetadata = useMemo(
        (): ContentCreatorEditablePracticeProblemMetadata =>
            parseContentCreatorEditablePracticeProblemMetadata(
                isSubProblem ? subProblem : practiceProblem,
                isSubProblem ? (practiceProblem as IMultiPartProblemBase) : null
            ),
        [isSubProblem, practiceProblem, subProblem]
    )
    const setContentCreatorEditablePracticeProblemMetadataCallback =
        useCallback(
            (
                contentCreatorEditablePracticeProblemMetadata: ContentCreatorEditablePracticeProblemMetadata
            ) => {
                setPracticeProblem((practiceProblem) => {
                    return isSubProblem
                        ? setContentCreatorEditablePracticeProblemMetadataForSubProblem(
                              practiceProblem,
                              subProblemIndex,
                              contentCreatorEditablePracticeProblemMetadata
                          )
                        : setContentCreatorEditablePracticeProblemMetadata(
                              practiceProblem,
                              contentCreatorEditablePracticeProblemMetadata
                          )
                })
            },
            [isSubProblem, setPracticeProblem, subProblemIndex]
        )

    const courseName = useRecoilValue(frontendDisplayedCourseSelector)
    const [isSaving, setIsSaving] = useState<boolean>(false)
    const [
        newlyCreatedAndUnsavedPracticeProblemIDs,
        setNewlyCreatedAndUnsavedPracticeProblemIDs,
    ] = useRecoilState(newlyCreatedAndUnsavedPracticeProblemIDsAtom)
    const hasNeverBeenSaved = useMemo(
        (): boolean =>
            newlyCreatedAndUnsavedPracticeProblemIDs.has(practiceProblem.id),
        [practiceProblem, newlyCreatedAndUnsavedPracticeProblemIDs]
    )
    const setLastSavedPracticeProblemData = useSetRecoilState(
        lastSavedPracticeProblemDataAtomFamily(practiceProblem.id)
    )
    const courseConfig = useRecoilValue(activeCourseConfigSelector)

    const reasonWhyProblemCannotBeSaved = useMemo((): string | null => {
        if (practiceProblem.type !== PracticeProblemType.MULTIPLE_CHOICE) {
            return null
        }
        if (courseConfig.hasPracticeProblemCategories) {
            if (!practiceProblem.categories?.length) {
                return 'Please add a practice problem category'
            }
        }
        const isSolutionAValidChoice = practiceProblem.choices
            .map((choice) => choice.id)
            .includes(practiceProblem.solution)
        if (!isSolutionAValidChoice)
            return 'Please select a valid solution from the choices'

        const doAnyChoicesHaveNoText = !!practiceProblem.choices.filter(
            (choice) =>
                !extractTextFromStringifiedEditorState(
                    choice.stringifiedMagicTextBox
                ).length
        ).length
        if (doAnyChoicesHaveNoText) return 'One or more choices have no text'
        const atLeastTwoChoices = practiceProblem.choices.length >= 2
        if (!atLeastTwoChoices)
            return 'Make sure there are at least two choices'
        return null
    }, [courseConfig.hasPracticeProblemCategories, practiceProblem])
    const savePracticeProblemChanges = useCallback(async () => {
        if (reasonWhyProblemCannotBeSaved) {
            syncErrorMessage(
                `Cannot save practice problem: ${reasonWhyProblemCannotBeSaved}`
            )
            return
        }
        if (isReadOnly || !!reasonWhyProblemCannotBeSaved) {
            throw new Error('Cannot be saved currently')
        }
        setIsSaving(true)
        try {
            validatePracticeProblemData(practiceProblem)
        } catch (e) {
            setIsSaving(false)
            syncErrorMessage(`Error saving practice problem: ${e}`)
            return
        }
        const response = await overwritePracticeProblemAtContentPath(
            courseName,
            practiceProblem.contentPath,
            practiceProblem as IGenericPracticeProblem
        )
        if (response.data.isError) {
            syncErrorMessage(
                'Error saving practice problem. You may want to try saving the practice problem again.'
            )
        } else {
            setLastSavedPracticeProblemData(
                parseSavablePracticeProblemData(practiceProblem, null)
            )
            syncSuccessMessage('Practice problem successfully saved')
            setNewlyCreatedAndUnsavedPracticeProblemIDs((current) =>
                current.remove(practiceProblem.id)
            )
        }
        setIsSaving(false)
    }, [
        courseName,
        isReadOnly,
        practiceProblem,
        reasonWhyProblemCannotBeSaved,
        setLastSavedPracticeProblemData,
        setNewlyCreatedAndUnsavedPracticeProblemIDs,
    ])

    const isMultipartProblem = useMemo(
        (): boolean =>
            isSubProblem
                ? false
                : practiceProblem.type === PracticeProblemType.MULTI_PART,
        [isSubProblem, practiceProblem.type]
    )

    const savableContent = useMemo(
        (): SavableContent => ({
            contentPath: practiceProblem.contentPath,
            contentType: SavableContentType.practiceProblem,
            id: practiceProblem.id,
        }),
        [practiceProblem.contentPath, practiceProblem.id]
    )

    const isUnsaved = useRecoilValue(
        specificContentHasUnsavedChangesSelector(savableContent)
    )

    const [
        activeSuperPracticeProblemEditorContentType,
        setActiveSuperPracticeProblemEditorContentType,
    ] = useRecoilState(activeSuperPracticeProblemEditorContentTypeAtom)

    const [
        activeSubPracticeProblemEditorContentType,
        setActiveSubPracticeProblemEditorContentType,
    ] = useRecoilState(activeSubPracticeProblemEditorContentTypeAtom)

    // quick trick to quickly route to the comment thread
    const router = useRouter()
    useEffect(() => {
        if (router.query.showCommentThread && !isSubProblem) {
            setActiveSuperPracticeProblemEditorContentType(
                PracticeProblemEditorContentType.commentThread
            )
            const newRouterQuery = { ...router.query }
            delete newRouterQuery.showCommentThread
            router.replace({
                pathname: router.pathname,
                query: newRouterQuery,
            })
        }
    }, [isSubProblem, router, setActiveSuperPracticeProblemEditorContentType])

    // don't allow sub problem menu to be open for non-multi-part problems
    const viewingState = useRecoilValue(viewingStateAtom)
    const isActive = useMemo((): boolean => {
        if (viewingState.viewingStateType !== ViewingStateType.practiceProblems)
            return false
        return viewingState.id === props.practiceProblemID
    }, [
        props.practiceProblemID,
        viewingState.id,
        viewingState.viewingStateType,
    ])
    useEffect((): void => {
        if (
            isActive &&
            !isMultipartProblem &&
            !isSubProblem &&
            activeSuperPracticeProblemEditorContentType ===
                PracticeProblemEditorContentType.subProblems
        ) {
            setActiveSuperPracticeProblemEditorContentType(
                PracticeProblemEditorContentType.metadata
            )
        }
    }, [
        activeSuperPracticeProblemEditorContentType,
        isActive,
        isMultipartProblem,
        isSubProblem,
        setActiveSuperPracticeProblemEditorContentType,
    ])

    const debouncedProblemQuestionUpdateListener = useDebounce(
        (editorState: EditorState): void => {
            const newProblemQuestion = serializeEditorStateToString(editorState)
            setPracticeProblem((practiceProblem) =>
                isSubProblem
                    ? setSubProblemQuestion(
                          practiceProblem,
                          subProblemIndex,
                          newProblemQuestion
                      )
                    : setQuestion(practiceProblem, newProblemQuestion)
            )
        },
        250,
        1_000
    )

    const debouncedProblemSolutionUpdateListener = useDebounce(
        (editorState: EditorState): void => {
            const newProblemSolution = serializeEditorStateToString(editorState)
            setPracticeProblem((practiceProblem) =>
                isSubProblem
                    ? setSubProblemSolution(
                          practiceProblem,
                          subProblemIndex,
                          newProblemSolution
                      )
                    : setSolution(practiceProblem, newProblemSolution)
            )
        },
        250,
        1_000
    )

    const tabItems = [
        {
            key: PracticeProblemEditorContentType.metadata,
            label: PracticeProblemEditorContentType.metadata,
            disabled: false,
            children: (
                <PracticeProblemMetadataEditor
                    contentCreatorEditablePracticeProblemMetadata={
                        contentCreatorEditablePracticeProblemMetadata
                    }
                    setContentCreatorEditablePracticeProblemMetadata={
                        setContentCreatorEditablePracticeProblemMetadataCallback
                    }
                    shouldDisableMultiPartProblem={isSubProblem}
                    contentPath={practiceProblem.contentPath}
                    practiceProblemID={props.practiceProblemID}
                />
            ),
        },
        {
            key: PracticeProblemEditorContentType.question,
            label: PracticeProblemEditorContentType.question,
            disabled: false,
            children: (
                <MagicTextBoxEditor
                    id={`${props.practiceProblemID}-question`}
                    namespace={'practice problem question'}
                    placeholder={'Enter practice problem question.'}
                    initialStringifiedEditorState={
                        subProblem?.question ?? practiceProblem.question
                    }
                    editorUpdateListener={
                        debouncedProblemQuestionUpdateListener
                    }
                    savableProps={{
                        onSave: savePracticeProblemChanges,
                        setHasUnsavedChanges: () => undefined,
                    }}
                />
            ),
        },
        {
            key: PracticeProblemEditorContentType.solution,
            label: PracticeProblemEditorContentType.solution,
            disabled: false,
            children:
                practiceProblem.type === PracticeProblemType.MULTIPLE_CHOICE ? (
                    <MultipleChoicePracticeProblemSolutionsEditor
                        practiceProblemID={practiceProblem.id}
                        onSave={{
                            onSave: savePracticeProblemChanges,
                            setHasUnsavedChanges: () => undefined,
                        }}
                    />
                ) : (
                    <MagicTextBoxEditor
                        id={`${props.practiceProblemID}-solution`}
                        namespace={'practice problem solution'}
                        placeholder={'Enter practice problem solution.'}
                        initialStringifiedEditorState={
                            subProblem?.solution ?? practiceProblem.solution
                        }
                        editorUpdateListener={
                            debouncedProblemSolutionUpdateListener
                        }
                        savableProps={{
                            onSave: savePracticeProblemChanges,
                            setHasUnsavedChanges: () => undefined,
                        }}
                    />
                ),
        },
    ]

    if (isMultipartProblem) {
        tabItems.push({
            key: PracticeProblemEditorContentType.subProblems,
            label: PracticeProblemEditorContentType.subProblems,
            disabled: false,
            children: (
                <MultiPartProblemSubProblemsEditor
                    practiceProblemID={props.practiceProblemID}
                />
            ),
        })
    }

    if (!isSubProblem) {
        tabItems.push({
            key: PracticeProblemEditorContentType.commentThread,
            label: PracticeProblemEditorContentType.commentThread,
            disabled: hasNeverBeenSaved || authState.isApplication,
            children: (
                <ContentCommentThreadWrapper
                    contentType={ContentType.PRACTICE_PROBLEM}
                    contentId={props.practiceProblemID}
                    contentPath={practiceProblem.contentPath}
                />
            ),
        })
    }

    const savePracticeProblemButtonTooltipIfDisabled = useMemo(():
        | string
        | null => {
        if (isReadOnly) return 'Course is read-only'
        if (!isUnsaved) return 'No unsaved changes'
        return null
    }, [isReadOnly, isUnsaved])
    return (
        <div className={styles.practiceProblemEditor}>
            {!isSubProblem && (
                <>
                    <div>
                        <Tooltip
                            title={savePracticeProblemButtonTooltipIfDisabled}
                        >
                            <Button
                                onClick={savePracticeProblemChanges}
                                type={'primary'}
                                loading={isSaving}
                                disabled={
                                    !!savePracticeProblemButtonTooltipIfDisabled
                                }
                            >
                                Save problem changes
                            </Button>
                        </Tooltip>
                    </div>
                    <div>
                        <CopyIndividualContentButton
                            contentPath={practiceProblem.contentPath}
                            type={'practiceProblem'}
                            id={practiceProblem.id}
                        />
                    </div>
                    <div>
                        <MoveIndividualContentButton
                            contentPath={practiceProblem.contentPath}
                            type={'practiceProblem'}
                            id={practiceProblem.id}
                        />
                    </div>
                </>
            )}
            <Tabs
                activeKey={
                    isSubProblem
                        ? activeSubPracticeProblemEditorContentType
                        : activeSuperPracticeProblemEditorContentType
                }
                onChange={(newTab) => {
                    isSubProblem
                        ? setActiveSubPracticeProblemEditorContentType(
                              newTab as PracticeProblemEditorContentType
                          )
                        : setActiveSuperPracticeProblemEditorContentType(
                              newTab as PracticeProblemEditorContentType
                          )
                }}
                items={tabItems}
            />
        </div>
    )
}
