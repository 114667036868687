import { atom } from 'recoil'
import { PracticeProblemEditorContentType } from '@/components/ContentTreeNode/ContentTreeLeafNode/PracticeProblemsEditor/PracticeProblemEditor/PracticeProblemEditor.types'

export const activeSuperPracticeProblemEditorContentTypeAtom =
    atom<PracticeProblemEditorContentType>({
        key: 'activeSuperPracticeProblemEditorContentTypeAtom',
        default: PracticeProblemEditorContentType.metadata,
    })

export const activeSubPracticeProblemEditorContentTypeAtom =
    atom<PracticeProblemEditorContentType>({
        key: 'activeSubPracticeProblemEditorContentTypeAtom',
        default: PracticeProblemEditorContentType.metadata,
    })
