import styles from './NotecardCategories.module.less'
import React, { ReactElement, useEffect } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import {
    notecardCategoryStatsAtomNonMemoizing,
    notecardCategoryStatsInvalidatorAtom,
} from '@/atoms/notecardCategories'
import { NotecardCategory } from '@/components/NotecardCategories/NotecardCategory/NotecardCategory'
import { Collapse } from 'antd'
import { AddNotecardCategoryButton } from '@/components/NotecardCategories/AddNotecardCategoryButton/AddNotecardCategoryButton'

export const NotecardCategories: React.FC<unknown> = (): ReactElement => {
    // so it recalculates each time it is opened
    const notecardCategoryStats = useRecoilValue(
        notecardCategoryStatsAtomNonMemoizing
    )
    const invalidate = useSetRecoilState(notecardCategoryStatsInvalidatorAtom)
    useEffect(() => invalidate((x) => x + 1), [invalidate])

    return (
        <div className={styles.notecardCategories}>
            <div className={styles.notecardCategoriesTitle}>
                Notecard Categories
            </div>
            <AddNotecardCategoryButton />
            <Collapse accordion={true} expandIconPosition={'end'}>
                {Object.entries(notecardCategoryStats).map(
                    ([category, notecardIDs]) => (
                        <NotecardCategory
                            key={category}
                            category={category}
                            count={notecardIDs.length}
                        />
                    )
                )}
            </Collapse>
        </div>
    )
}
