import styles from './SourceMaterialType.module.less'
import React, { ReactElement, useMemo } from 'react'
import { LoadingComponent } from '@/components/utils/LoadingComponent/LoadingComponent'
import CollapsePanel from 'antd/lib/collapse/CollapsePanel'
import { DefensiveDeleteButton } from '@/components/utils/DefensiveDeleteButton/DefensiveDeleteButton'
import { deleteSourceMaterialTypeForCourse } from '@/api/cb/contentCreatorContent'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { frontendDisplayedCourseSelector } from '@/atoms/auth'
import { syncErrorMessage, syncSuccessMessage } from '@/utils/syncMessages'
import {
    sourceMaterialTypesInvalidatorAtom,
    sourceMaterialTypeStatsInvalidatorAtom,
    sourceMaterialIDsForTypeSelector,
} from '@/atoms/sourceMaterialTypes'
import { useInvalidateSourceMaterials } from '@/atoms/sourceMaterial'
import { SourceMaterialIDsList } from './SourceMaterialIDsList'

interface SourceMaterialTypeProps {
    type: string
    count: number
}

export const SourceMaterialType: React.FC<SourceMaterialTypeProps> = (
    props
): ReactElement => {
    const { type, count, ...restProps } = props

    const courseName = useRecoilValue(frontendDisplayedCourseSelector)

    const invalidateSourceMaterialTypes = useSetRecoilState(
        sourceMaterialTypesInvalidatorAtom
    )
    const invalidateSourceMaterialTypeStats = useSetRecoilState(
        sourceMaterialTypeStatsInvalidatorAtom
    )
    const sourceMaterialIDAndContentPaths = useRecoilValue(
        sourceMaterialIDsForTypeSelector(props.type)
    )
    const sourceMaterialIDs = useMemo(
        (): string[] => sourceMaterialIDAndContentPaths.map(({ id }) => id),
        [sourceMaterialIDAndContentPaths]
    )
    const invalidateSourceMaterials =
        useInvalidateSourceMaterials(sourceMaterialIDs)
    const deleteSourceMaterialCategory = async (): Promise<void> => {
        const response = await deleteSourceMaterialTypeForCourse({
            courseName,
            sourceMaterialType: type,
        })
        if (response.data.isError) {
            syncErrorMessage(
                `Failed to delete source material type: ${type}. Please refresh the page and try again.`
            )
            return
        }

        syncSuccessMessage(`Successfully deleted source material type: ${type}`)
        invalidateSourceMaterialTypes((x) => x + 1)
        invalidateSourceMaterialTypeStats((x) => x + 1)
        invalidateSourceMaterials()
    }

    return (
        <CollapsePanel
            {...restProps}
            key={type}
            header={
                <div className={styles.sourceMaterialTypePanelHeader}>
                    <div
                        className={styles.sourceMaterialTypePanelHeaderLeftSide}
                    >
                        <span className={styles.sourceMaterialTypeTitle}>
                            {type}:
                        </span>
                        <span>{count}</span>
                    </div>
                    <DefensiveDeleteButton
                        onDelete={deleteSourceMaterialCategory}
                        areYouSureContent={
                            <p>
                                Are you sure you want to delete the source
                                material type <b>{type}</b>? This will remove
                                the type from all <b>{count}</b> source
                                materials that currently have this type set.
                            </p>
                        }
                        okText={'Delete'}
                        cancelText={'Cancel'}
                        title={'Are you sure?'}
                    />
                </div>
            }
            className={styles.sourceMaterialType}
        >
            <React.Suspense
                fallback={<LoadingComponent useWhiteBackground={false} />}
            >
                <SourceMaterialIDsList type={type} />
            </React.Suspense>
        </CollapsePanel>
    )
}
