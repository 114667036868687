import React, {
    ReactElement,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react'
import styles from './EditableText.module.less'
import { EditableTextProps } from '@/components/EditableText/EditableText.types'
import { Input, message, Spin } from 'antd'
import { isPromise } from '@/utils/isPromise'
import { MessageKeys } from '@/utils/messageKeys'
import { reusableCssClass } from '@/utils/reusableCssClasses'
import { TextAreaRef } from 'antd/lib/input/TextArea'
import { useLogger } from '@/hooks/useLogger'
import { trim } from 'lodash'

export const EditableText: React.FC<EditableTextProps> = (
    props
): ReactElement => {
    const logger = useLogger(EditableText.name)
    const [isEditing, setIsEditing] = useState<boolean>(false)
    const [text, setText] = useState<string>(props.initialText)
    const backendText = useRef<string>(props.initialText)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const editableTextBoxRef = useRef<TextAreaRef>(null)

    useEffect(() => {
        if (isEditing) {
            editableTextBoxRef.current.focus({ cursor: 'end' })
        }
    }, [isEditing])

    const save = useCallback(
        async (e: React.SyntheticEvent): Promise<void> => {
            e.preventDefault()
            if (!isEditing) return

            if (backendText.current !== text) {
                // only send api request if text actually changes
                const response = props.onSave(text)
                if (isPromise(response)) {
                    setIsLoading(true)
                    try {
                        await response
                        backendText.current = text
                    } catch (e) {
                        logger.error(`Failed to save text with error: ${e}`)
                        setText(backendText.current)
                    } finally {
                        setIsLoading(false)
                    }
                }
            } else {
                message.info({
                    content: 'No changes made',
                    duration: 3,
                    key: MessageKeys.NO_CHANGES_MADE,
                    className: reusableCssClass.clickMe,
                    onClick: () => message.destroy(MessageKeys.NO_CHANGES_MADE),
                })
            }

            setIsEditing(false)
        },
        [isEditing, logger, props, text]
    )

    const className = useMemo((): string => {
        return `${styles.editableText} ${
            isEditing ? styles.editing : styles.notEditing
        } ${isLoading ? styles.loading : ''}`
    }, [isEditing, isLoading])
    const notEditingClickHandler = useCallback(
        (event: React.MouseEvent<HTMLSpanElement>): void => {
            event.stopPropagation()
            if (!isEditing) setIsEditing(true)
        },
        [isEditing]
    )
    return (
        <span className={className}>
            {isEditing ? (
                <Input.TextArea
                    ref={editableTextBoxRef}
                    onPressEnter={save}
                    onBlur={save}
                    value={text}
                    onChange={(event) => {
                        setText(event.target.value)
                    }}
                    autoSize={true}
                    onClick={(e) => e.stopPropagation()}
                />
            ) : trim(text) ? (
                <span
                    className={styles.editableClickableText}
                    onClick={notEditingClickHandler}
                >
                    {text}
                </span>
            ) : (
                <span
                    className={styles.editableClickableText}
                    onClick={notEditingClickHandler}
                >
                    EDIT ME
                </span>
            )}

            {isLoading && (
                <span className={styles.editableTextLoadingOverlay}>
                    <Spin />
                </span>
            )}
        </span>
    )
}
