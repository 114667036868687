import styles from './RecommendedStudyHoursEditor.module.less'
import React, { ReactElement, useCallback, useState, useMemo } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
    frontendDisplayedCourseSelector,
    isActiveCourseReadOnlySelector,
} from '@/atoms/auth'
import { recommendedStudyHoursSelectorFamily } from '@/atoms/recommendedStudyHours'
import { setRecommendedStudyHoursForCourse } from '@/api/cb/contentCreatorContent'
import { syncErrorMessage, syncSuccessMessage } from '@/utils/syncMessages'
import { Button, InputNumber } from 'antd'

export const RecommendedStudyHoursEditor: React.FC<
    unknown
> = (): ReactElement => {
    const courseName = useRecoilValue(frontendDisplayedCourseSelector)
    const isReadOnly = useRecoilValue(isActiveCourseReadOnlySelector)

    const [recommendedStudyHours, setRecommendedStudyHours] = useRecoilState(
        recommendedStudyHoursSelectorFamily(courseName)
    )
    const [lastSavedRecommendedStudyHours, setLastSavedRecommendedStudyHours] =
        useState<number>(recommendedStudyHours)
    const hasUnsavedChanges = useMemo(
        (): boolean => lastSavedRecommendedStudyHours !== recommendedStudyHours,
        [lastSavedRecommendedStudyHours, recommendedStudyHours]
    )
    const [
        isSetRecommendedStudyHoursButtonLoading,
        setIsSetRecommendedStudyHoursButtonLoading,
    ] = useState<boolean>(false)
    const saveRecommendedStudyHours = useCallback(async (): Promise<void> => {
        setIsSetRecommendedStudyHoursButtonLoading(true)
        const response = await setRecommendedStudyHoursForCourse({
            courseName,
            recommendedStudyHours,
        })
        if (response.data.isError) {
            syncErrorMessage(
                'Error updating recommended study hours. You may want to try setting it again or refreshing the page.'
            )
        } else {
            setLastSavedRecommendedStudyHours(recommendedStudyHours)
            syncSuccessMessage(
                `Successfully updated recommended study hours to: ${recommendedStudyHours}`
            )
        }
        setIsSetRecommendedStudyHoursButtonLoading(false)
    }, [courseName, recommendedStudyHours])

    return (
        <div className={styles.recommendedStudyHoursEditor}>
            <div className={styles.recommendedStudyHoursEditorText}>
                Recommended Study Hours:
            </div>
            <InputNumber
                value={recommendedStudyHours}
                onChange={(value) => setRecommendedStudyHours(value as number)}
                min={0}
                step={5}
                disabled={isReadOnly}
            />
            <Button
                onClick={saveRecommendedStudyHours}
                type={'primary'}
                loading={isSetRecommendedStudyHoursButtonLoading}
                disabled={isReadOnly || !hasUnsavedChanges}
            >
                Save
            </Button>
        </div>
    )
}
