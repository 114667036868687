import {
    ContentCreatorEditablePracticeProblemMetadata,
    parseContentCreatorEditablePracticeProblemMetadata,
} from '@/components/ContentTreeNode/ContentTreeLeafNode/PracticeProblemsEditor/PracticeProblemEditor/PracticeProblemMetadataEditor/PracticeProblemMetadataEditor.types'
import { PracticeProblemType } from 'common/src/practiceProblems'
import {
    IGenericPracticeProblem,
    IMultiPartProblemBase,
    MultipleChoiceOptionData,
} from 'common/src/practiceProblems/types'
import assert from 'assert'

export type SavablePracticeProblemData =
    | SavablePracticeProblemDataSinglePart
    | SavablePracticeProblemDataMultipleChoice
    | SavablePracticeProblemDataMultiPart

export interface SavablePracticeProblemDataMultiPart
    extends SavablePracticeProblemDataSinglePart {
    subProblems: SavablePracticeProblemDataSinglePart[]
}

export interface SavablePracticeProblemDataSinglePart {
    contentCreatorEditablePracticeProblemMetadata: ContentCreatorEditablePracticeProblemMetadata
    stringifiedProblemQuestion: string
    stringifiedProblemSolution: string
}

export interface SavablePracticeProblemDataMultipleChoice
    extends SavablePracticeProblemDataSinglePart {
    stringifiedSolutionChoices: MultipleChoiceOptionData[]
    shouldShuffleAnswers: boolean
    stringifiedSolutionExplanation: string
}

export const parseSavablePracticeProblemData = (
    practiceProblem: IGenericPracticeProblem,
    superProblem: IMultiPartProblemBase | null
): SavablePracticeProblemData => {
    if (practiceProblem.type === PracticeProblemType.MULTI_PART) {
        return {
            contentCreatorEditablePracticeProblemMetadata:
                parseContentCreatorEditablePracticeProblemMetadata(
                    practiceProblem,
                    superProblem
                ),
            stringifiedProblemQuestion: practiceProblem.question,
            stringifiedProblemSolution: practiceProblem.solution,
            subProblems: practiceProblem.subProblems.map((subProblem) =>
                parseSavablePracticeProblemData(subProblem, practiceProblem)
            ),
        }
    } else if (practiceProblem.type === PracticeProblemType.MULTIPLE_CHOICE) {
        return {
            contentCreatorEditablePracticeProblemMetadata:
                parseContentCreatorEditablePracticeProblemMetadata(
                    practiceProblem,
                    superProblem
                ),
            stringifiedProblemQuestion: practiceProblem.question,
            stringifiedProblemSolution: practiceProblem.solution,
            shouldShuffleAnswers: practiceProblem.shouldShuffleAnswers,
            stringifiedSolutionChoices: practiceProblem.choices,
            stringifiedSolutionExplanation: practiceProblem.explanation,
        }
    } else {
        return {
            contentCreatorEditablePracticeProblemMetadata:
                parseContentCreatorEditablePracticeProblemMetadata(
                    practiceProblem,
                    superProblem
                ),
            stringifiedProblemQuestion: practiceProblem.question,
            stringifiedProblemSolution: practiceProblem.solution,
        }
    }
}

export const validatePracticeProblemData = (
    practiceProblem: IGenericPracticeProblem
): void => {
    assert(practiceProblem.points > 0, 'Must set points > 0.')
    if (practiceProblem.type === PracticeProblemType.MULTI_PART) {
        assert(
            practiceProblem.subProblems.length > 0,
            'Must have at least one subproblem for a multi-part problem.'
        )
    }
}
