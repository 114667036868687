import { flattenContentTree } from 'common/src/ContentTree'
import { ContentPath } from 'common/src/ContentPath'
import { getContentTree } from '@/api/cb/contentCreatorContent'
import { LoggerSelectorFamily } from '@/atoms/logger'
import { frontendDisplayedCourseSelector } from '@/atoms/auth'
import { atom, selector, selectorFamily } from 'recoil'
import { deserializeMap } from 'common/src/utils/mapSerialization'
import {
    ContentTreeResponse,
    TopicToLearningObjectivesMap,
} from 'common/src/api/core/contentTree/responses'
import { ContentTree } from 'common/src/ContentTree/types'
import { getForeverPromise } from 'common/src/utils/getForeverPromise'

export interface ContentTreeState {
    contentTree: ContentTree
    contentTierTitles: string[]
    flattenedContentTree: ContentPath[]
    topicToLearningObjectivesMap: TopicToLearningObjectivesMap
}

export const fetchContentTreeState = async (
    courseName: string
): Promise<ContentTreeState> => {
    const response = await getContentTree(courseName)
    return convertContentTreeResponseToContentTreeState(response.data.payload)
}

export const convertContentTreeResponseToContentTreeState = (
    contentTreeResponse: ContentTreeResponse
): ContentTreeState => {
    const flattenedContentTree = flattenContentTree(
        contentTreeResponse.contentTree
    )
    return {
        contentTree: contentTreeResponse.contentTree,
        contentTierTitles: contentTreeResponse.contentTierTitles,
        flattenedContentTree,
        topicToLearningObjectivesMap: deserializeMap(
            contentTreeResponse.topicToLearningObjectivesMap
        ),
    }
}

export const contentTreeStateAtom = atom<ContentTreeState>({
    key: 'contentTreeStateAtom',
    default: selector<ContentTreeState>({
        key: 'contentTreeStateFetcher',
        get: async ({ get }) => {
            const frontendDisplayedCourse = get(frontendDisplayedCourseSelector)
            const logger = get(LoggerSelectorFamily(contentTreeStateAtom.key))
            if (!frontendDisplayedCourse) {
                logger.info(
                    `No frontend displayed course, so content tree state will not be retrieved (returning null instead)`
                )
                return null
            }
            logger.info(`Retrieving initial content tree state`)
            return await fetchContentTreeState(frontendDisplayedCourse)
        },
    }),
})

export const contentTreeDepthSelector = selector<number>({
    key: 'contentTreeDepthSelector',
    get: ({ get }) => {
        if (!get(frontendDisplayedCourseSelector)) {
            get(LoggerSelectorFamily(contentTreeDepthSelector.key)).info(
                `Attempted to access content tree depth selector, but no frontend displayed course exists (returning forever promise instead)`
            )
            return getForeverPromise()
        }
        return get(contentTreeStateAtom).contentTierTitles.length
    },
})

export const contentTreeTierTitlesSelector = selector<string[]>({
    key: 'contentTreeTierTitlesSelector',
    get: ({ get }) => {
        if (!get(frontendDisplayedCourseSelector)) {
            get(LoggerSelectorFamily(contentTreeDepthSelector.key)).info(
                `Attempted to access content tree tier titles selector, but no frontend displayed course exists (returning fake data instead)`
            )
            return ['Topic', 'Subtopic', 'Chapter']
        }
        return get(contentTreeStateAtom).contentTierTitles
    },
})

// for copying content
export const contentTreeStateSelectorFamily = selectorFamily<
    ContentTreeState,
    string
>({
    key: 'contentTreeStateAtomFamily',
    get: (courseName: string) => async () => {
        return await fetchContentTreeState(courseName)
    },
})
