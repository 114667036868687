import styles from './Error404.module.less'
import React, { ReactElement } from 'react'
import { Error404Props, ERROR_404_GLOBAL_ID } from './Error404.types'
import { reusableCssClass } from '@/utils/reusableCssClasses'
import { useRouter } from 'next/router'
import { UiLayoutConstant } from '@/globals/uiLayoutConstant'
import { ErrorImage } from '@/components/images/ErrorImage'

export const Error404: React.FC<Error404Props> = (): ReactElement => {
    const router = useRouter()
    const redirectToSplashPage = async (): Promise<void> => {
        await router.push(UiLayoutConstant.SPLASH_PAGE)
    }

    return (
        <>
            <div className={styles.error404} id={ERROR_404_GLOBAL_ID}>
                <div
                    className={`${styles.header} ${reusableCssClass.responsiveTextLarge}`}
                >
                    Oops. 404. Page not found.
                </div>
                <div
                    className={`${styles.text} ${reusableCssClass.responsiveTextSmall}`}
                >
                    Maybe this page moved? Got deleted? Is hiding out in
                    quarantine? Never existed in the first place? Let&apos;s
                    go&nbsp;
                    <span
                        className={reusableCssClass.clickableTextUnderlined}
                        onClick={redirectToSplashPage}
                    >
                        home
                    </span>{' '}
                    and try again from there.
                </div>
                <ErrorImage />
            </div>
        </>
    )
}
