import React, { ReactElement, useCallback, useMemo, useState } from 'react'
import styles from './MultiPartProblemSubProblemsEditor.module.less'
import { MultiPartProblemSubProblemsEditorProps } from '@/components/ContentTreeNode/ContentTreeLeafNode/PracticeProblemsEditor/PracticeProblemEditor/MultiPartProblemSubProblemsEditor/MultiPartProblemSubProblemsEditor.types'
import { Button } from 'antd'
import {
    IGenericSinglePartPracticeProblem,
    IMultiPartProblemBase,
} from 'common/src/practiceProblems/types'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
    frontendDisplayedCourseSelector,
    isActiveCourseReadOnlySelector,
} from '@/atoms/auth'
import { DraggableSortingList } from '@/components/utils/DraggableSortingList/DraggableSortingList'
import { PracticeProblemEditor } from '@/components/ContentTreeNode/ContentTreeLeafNode/PracticeProblemsEditor/PracticeProblemEditor/PracticeProblemEditor'
import { DeleteSubProblemButton } from '@/components/ContentTreeNode/ContentTreeLeafNode/PracticeProblemsEditor/PracticeProblemEditor/MultiPartProblemSubProblemsEditor/DeleteSubProblemButton/DeleteSubProblemButton'
import { practiceProblemAtomFamily } from '@/atoms/practiceProblem'
import { createNewDefaultPracticeProblem } from '@/components/ContentTreeNode/ContentTreeLeafNode/PracticeProblemsEditor/createNewDefaultPracticeProblem'
import {
    addNewSubProblem,
    deleteSubProblem,
    reorderSubProblemID,
} from '@/components/ContentTreeNode/ContentTreeLeafNode/PracticeProblemsEditor/immer'
import { LoadingComponent } from '@/components/utils/LoadingComponent/LoadingComponent'
import {
    DraggableIconType,
    DraggableSubProblemData,
} from '@/components/utils/DraggableSortingList/DraggableIcon/DraggableIcon.types'
import { activeCourseConfigSelector } from '@/atoms/courseInfo'

// TODO: Need to handle adding / removing subproblems as "change"
// TODO: Change handler should modify in recoil immediately, then we can use recoil to detect whether a change has been made
// TODO: Should be able to extract out the change handler stuff into a separate hook
export const MultiPartProblemSubProblemsEditor: React.FC<
    MultiPartProblemSubProblemsEditorProps
> = (props): ReactElement => {
    const courseName = useRecoilValue(frontendDisplayedCourseSelector)
    const isReadOnly = useRecoilValue(isActiveCourseReadOnlySelector)

    const [practiceProblem, setPracticeProblem] = useRecoilState(
        practiceProblemAtomFamily(props.practiceProblemID)
    )
    const subProblems = useMemo(
        (): IGenericSinglePartPracticeProblem[] =>
            (practiceProblem as IMultiPartProblemBase).subProblems,
        [practiceProblem]
    )
    const [activeSubProblemIndex, setActiveSubProblemIndex] =
        useState<number>(0)

    const [isAddingNewSubProblem, setIsAddingNewSubProblem] =
        useState<boolean>(false)
    const courseConfig = useRecoilValue(activeCourseConfigSelector)
    const addNewSubProblemCallback = useCallback((): void => {
        setIsAddingNewSubProblem(true)
        const newSubProblem = createNewDefaultPracticeProblem(
            subProblems.length,
            practiceProblem.contentPath,
            courseName,
            courseConfig
        )
        setPracticeProblem((practiceProblem) =>
            addNewSubProblem(
                practiceProblem as IMultiPartProblemBase,
                newSubProblem as IGenericSinglePartPracticeProblem
            )
        )
        setActiveSubProblemIndex(newSubProblem.index)
        setIsAddingNewSubProblem(false)
    }, [
        courseName,
        practiceProblem.contentPath,
        setPracticeProblem,
        subProblems.length,
        courseConfig,
    ])
    const activeSubProblem = useMemo(
        () => subProblems[activeSubProblemIndex],
        [subProblems, activeSubProblemIndex]
    )

    const deleteActiveSubProblem = useCallback((): void => {
        if (activeSubProblemIndex === subProblems.length - 1)
            setActiveSubProblemIndex(subProblems.length - 2)
        setPracticeProblem((practiceProblem) =>
            deleteSubProblem(
                practiceProblem as IMultiPartProblemBase,
                activeSubProblemIndex
            )
        )
    }, [activeSubProblemIndex, setPracticeProblem, subProblems.length])

    const subProblemsData = useMemo(
        (): DraggableSubProblemData[] =>
            subProblems.map((subProblem, index) => ({
                type: DraggableIconType.subProblem,
                superProblemID: props.practiceProblemID,
                subProblemIndex: index,
                hasUnsavedChanges: false, // not bothering with sub problems right now
            })),
        [props.practiceProblemID, subProblems]
    )

    const [isReordering, setIsReordering] = useState<boolean>(false)
    const reorderSubProblemsCallback = useCallback(
        (startingIndex: number, endingIndex: number): void => {
            setIsReordering(true)
            setPracticeProblem((practiceProblem) =>
                reorderSubProblemID(
                    practiceProblem as IMultiPartProblemBase,
                    startingIndex,
                    endingIndex
                )
            )
            setActiveSubProblemIndex(endingIndex)
            setIsReordering(false)
        },
        [setPracticeProblem]
    )

    return (
        <div className={styles.multiPartProblemSubProblemsEditor}>
            <div>Edit Sub Problems</div>
            <Button
                type={'primary'}
                onClick={addNewSubProblemCallback}
                loading={isAddingNewSubProblem}
                disabled={isReadOnly}
            >
                Add New Sub Problem
            </Button>
            <div>
                <div className={styles.subProblemListTitle}>
                    Select sub problem to edit (can reorder by dragging and
                    dropping):
                </div>
                <div className={styles.subProblemList}>
                    <React.Suspense
                        fallback={
                            <LoadingComponent useWhiteBackground={true} />
                        }
                    >
                        <DraggableSortingList
                            itemData={subProblemsData}
                            changeIndex={reorderSubProblemsCallback}
                            activeIndex={activeSubProblemIndex}
                            setActiveIndex={setActiveSubProblemIndex}
                            isLoading={isReordering}
                            isDisabled={isReadOnly}
                        />
                    </React.Suspense>
                </div>
            </div>
            {subProblems.length ? (
                <React.Suspense
                    fallback={<LoadingComponent useWhiteBackground={true} />}
                >
                    <DeleteSubProblemButton
                        deleteSubProblem={deleteActiveSubProblem}
                    />
                    <PracticeProblemEditor
                        key={activeSubProblem.id}
                        practiceProblemID={activeSubProblem.id}
                        superProblemID={props.practiceProblemID}
                    />
                </React.Suspense>
            ) : (
                <div>No active sub problems to display.</div>
            )}
        </div>
    )
}
