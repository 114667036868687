import React, { ReactElement, useState } from 'react'
import { CopyIndividualContentButtonProps } from '@/components/ContentTreeNode/ContentTreeLeafNode/CopyIndividualContentButton/CopyIndividualContentButton.types'
import { Button, Modal } from 'antd'
import { LoadingComponent } from '@/components/utils/LoadingComponent/LoadingComponent'
import { useRecoilValue } from 'recoil'
import { courseNamesForContentCreatorAtom } from '@/atoms/courseNamesForContentCreator'
import { CopyIndividualContent } from '@/components/ContentTreeNode/ContentTreeLeafNode/CopyIndividualContentButton/CopyIndividualContent/CopyIndividualContent'

export const CopyIndividualContentButton: React.FC<
    CopyIndividualContentButtonProps
> = (props): ReactElement => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
    const courseNames = useRecoilValue(courseNamesForContentCreatorAtom)

    return (
        <>
            <Modal
                footer={null}
                open={isModalOpen}
                onCancel={() => setIsModalOpen(false)}
            >
                <React.Suspense
                    fallback={<LoadingComponent useWhiteBackground={true} />}
                >
                    <CopyIndividualContent
                        id={props.id}
                        contentPath={props.contentPath}
                        type={props.type}
                        closeModal={() => setIsModalOpen(false)}
                    />
                </React.Suspense>
            </Modal>
            <Button
                onClick={() => setIsModalOpen(true)}
                disabled={courseNames.length < 2}
            >
                Copy to other section (different exam)
            </Button>
        </>
    )
}
