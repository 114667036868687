import { marked } from 'marked'
import React, {
    ReactElement,
    useMemo,
    useCallback,
    useState,
    useEffect,
    useRef,
} from 'react'
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil'
import {
    sourceMaterialAtomFamily,
    lastSavedSourceMaterialDataAtomFamily,
} from '@/atoms/sourceMaterial'
import {
    frontendDisplayedCourseSelector,
    isActiveCourseReadOnlySelector,
} from '@/atoms/auth'
import {
    SavableContent,
    SavableContentType,
    specificContentHasUnsavedChangesSelector,
} from '@/atoms/unsavedChanges'
import { areContentPathsEqual, ContentPath } from 'common/src/ContentPath'
import { saveSourceMaterial } from '@/api/cb/contentCreatorContent'
import { syncErrorMessage, syncSuccessMessage } from '@/utils/syncMessages'
import TextArea, { TextAreaRef } from 'antd/lib/input/TextArea'
import styles from './SourceMaterialEditor.module.less'
import { Button, Input, Tabs, Tooltip, Select, Modal } from 'antd'
import { viewingStateAtom, ViewingStateType } from '@/atoms/viewingState'
import { encoding_for_model, Tiktoken } from '@dqbd/tiktoken'
import { useDebounce } from 'magic-text-box-lexical/src/utils/useDebounce'
import { useMonitorSourceMaterialChanges } from '@/components/ContentTreeNode/ContentTreeLeafNode/SourceMaterialsEditor/SourceMaterialEditor/useMonitorSourceMaterialChanges'
import { newlyCreatedAndUnsavedSourceMaterialIDsAtom } from '@/atoms/newlyCreatedAndUnsavedContentIDs'
import {
    buildSourceMaterialText,
    isSourceMaterialTruthy,
} from 'common/src/api/contentFrontendVsContentBackend/sourceMaterial'
import { reusableCssClass } from '@/utils/reusableCssClasses'
import { TokenLimit, QUESTION_CONTEXT_MODEL } from 'common/src/llm/limits'
import { activeCourseConfigSelector } from '@/atoms/courseInfo'
import { sourceMaterialTypesAtom } from '@/atoms/sourceMaterialTypes'
import { PlusOutlined } from '@ant-design/icons'
import { LoadingComponent } from '@/components/utils/LoadingComponent/LoadingComponent'
import { SourceMaterialTypes } from '@/components/SourceMaterialTypes/SourceMaterialTypes'

interface SourceMaterialEditorProps {
    contentPath: ContentPath
    id: string
}

enum SourceMaterialContentEditorTab {
    editable = 'editable',
    readOnly = 'readOnly',
}

// thanks chat gpt: https://chat.openai.com/share/49e6f74b-48e6-4cf9-b394-36ba4c561091
const htmlTableToMarkdown = (html: string): string | null => {
    // Use a DOM parser to parse the HTML string
    const parser = new DOMParser()
    const doc = parser.parseFromString(html, 'text/html')

    // Find the table in the HTML
    const table = doc.querySelector('table')
    if (!table) {
        return null
    }

    let markdown = ''

    // Process header rows (<th> elements)
    const headerRows = table.querySelectorAll('tr')
    if (headerRows.length > 0) {
        let headerRow = '|'
        let separatorRow = '|'
        headerRows[0].querySelectorAll('th').forEach((header) => {
            const headerText = header.textContent?.trim() || ''
            headerRow += ` ${headerText} |`
            separatorRow += ' --- |'
        })
        markdown += headerRow + '\n' + separatorRow + '\n'
    }

    // Process data rows (<td> elements)
    for (let i = 1; i < headerRows.length; i++) {
        let rowMarkdown = '|'
        headerRows[i].querySelectorAll('td').forEach((cell) => {
            const cellText = cell.textContent?.trim() || ''
            rowMarkdown += ` ${cellText} |`
        })
        markdown += rowMarkdown + '\n'
    }

    return markdown
}
const handlePaste = (
    event: React.ClipboardEvent<HTMLTextAreaElement>
): void => {
    const pastedHtml = event.clipboardData.getData('text/html')
    const pastedMarkdown = htmlTableToMarkdown(pastedHtml)
    if (pastedMarkdown) {
        event.preventDefault() // overwrite current behavior
        // insertAtCursor(pastedMarkdown)
        document.execCommand('insertText', false, pastedMarkdown)
    }
}
const TOKEN_LIMIT = TokenLimit.CF_CONTEXT
export const SourceMaterialEditor: React.FC<SourceMaterialEditorProps> = (
    props
): ReactElement => {
    const [sourceMaterial, setSourceMaterial] = useRecoilState(
        sourceMaterialAtomFamily(props)
    )
    useMonitorSourceMaterialChanges(sourceMaterial.id, props.contentPath)
    const encoderRef = useRef<Tiktoken | null>(null)
    const [tokenCount, setTokenCount] = useState<number>(0)
    const debouncedUpdateTotalTokens = useDebounce(
        () => {
            if (encoderRef.current === null) return
            const sourceMaterialText = buildSourceMaterialText(sourceMaterial)
            const tokens = encoderRef.current.encode(sourceMaterialText)
            setTokenCount(tokens.length)
        },
        250,
        1_000
    )
    useEffect(() => {
        encoderRef.current = encoding_for_model(QUESTION_CONTEXT_MODEL)
    }, [])
    useEffect(() => {
        debouncedUpdateTotalTokens()
    }, [debouncedUpdateTotalTokens, sourceMaterial])
    const { hasSourceMaterialType } = useRecoilValue(activeCourseConfigSelector)

    const courseName = useRecoilValue(frontendDisplayedCourseSelector)
    const isReadOnly = useRecoilValue(isActiveCourseReadOnlySelector)

    const [isSaving, setIsSaving] = useState<boolean>(false)
    const [
        newlyCreatedAndUnsavedSourceMaterialIDs,
        setNewlyCreatedAndUnsavedSourceMaterialIDs,
    ] = useRecoilState(newlyCreatedAndUnsavedSourceMaterialIDsAtom)

    // currently unused
    const hasNeverBeenSaved = useMemo(
        (): boolean =>
            newlyCreatedAndUnsavedSourceMaterialIDs.has(sourceMaterial.id),
        [sourceMaterial, newlyCreatedAndUnsavedSourceMaterialIDs]
    )

    const setLastSavedSourceMaterialData = useSetRecoilState(
        lastSavedSourceMaterialDataAtomFamily({
            id: sourceMaterial.id,
            contentPath: props.contentPath,
        })
    )
    const saveSourceMaterialChanges = useCallback(async (): Promise<void> => {
        if (isReadOnly) return
        setIsSaving(true)
        const response = await saveSourceMaterial({
            courseName,
            contentPath: props.contentPath,
            id: sourceMaterial.id,
            sourceMaterial,
        })
        if (response.data.isError) {
            syncErrorMessage(
                'Error saving source material. You may want to try saving the source material again.'
            )
        } else {
            setLastSavedSourceMaterialData(sourceMaterial)
            syncSuccessMessage('Source material successfully saved')
            setNewlyCreatedAndUnsavedSourceMaterialIDs((current) =>
                current.remove(sourceMaterial.id)
            )
        }
        setIsSaving(false)
    }, [
        courseName,
        isReadOnly,
        props.contentPath,
        setLastSavedSourceMaterialData,
        setNewlyCreatedAndUnsavedSourceMaterialIDs,
        sourceMaterial,
    ])

    const savableContent = useMemo(
        (): SavableContent => ({
            contentPath: props.contentPath,
            contentType: SavableContentType.sourceMaterial,
            id: sourceMaterial.id,
        }),
        [props.contentPath, sourceMaterial.id]
    )
    const isUnsaved = useRecoilValue(
        specificContentHasUnsavedChangesSelector(savableContent)
    )
    const hasTooManyTokens = useMemo(
        (): boolean => tokenCount > TOKEN_LIMIT,
        [tokenCount]
    )

    // ctrl+s to save
    const viewingState = useRecoilValue(viewingStateAtom)
    const isModalVisible = useMemo(
        (): boolean =>
            viewingState.viewingStateType === ViewingStateType.sourceMaterial &&
            areContentPathsEqual(viewingState.contentPath, props.contentPath),
        [viewingState, props]
    )

    const disabledSaveButtonReason = useMemo((): string => {
        if (isReadOnly) return `Read-only - cannot save`
        if (!isUnsaved) return 'No unsaved changes'
        if (hasTooManyTokens)
            return `Too many tokens: ${tokenCount.toLocaleString()} / ${TOKEN_LIMIT.toLocaleString()}`
        if (!isSourceMaterialTruthy(sourceMaterial))
            return `Either title or content is empty`
        return ``
    }, [hasTooManyTokens, isReadOnly, isUnsaved, sourceMaterial, tokenCount])
    const handleSaveShortcut = useCallback(
        (e: KeyboardEvent) => {
            if (!isModalVisible) return
            if (e.key === 's' && e.ctrlKey) {
                e.preventDefault()
                if (!disabledSaveButtonReason) saveSourceMaterialChanges()
            }
        },
        [disabledSaveButtonReason, isModalVisible, saveSourceMaterialChanges]
    )
    useEffect(() => {
        document.addEventListener('keydown', handleSaveShortcut)
        return () => {
            document.removeEventListener('keydown', handleSaveShortcut)
        }
    }, [handleSaveShortcut])

    const inputRef = useRef<TextAreaRef>(null)
    const setSourceMaterialContent = useCallback(
        (newSourceMaterialContent: string) =>
            setSourceMaterial((current) => ({
                ...current,
                content: newSourceMaterialContent,
            })),
        [setSourceMaterial]
    )
    const getMarkdownText = useCallback(() => {
        const rawMarkup = marked(sourceMaterial.content, {})
        return { __html: rawMarkup }
    }, [sourceMaterial.content])
    const [
        activeSourceMaterialContentEditorTab,
        setActiveSourceMaterialContentEditorTab,
    ] = useState<SourceMaterialContentEditorTab>(
        SourceMaterialContentEditorTab.editable
    )
    const [
        isSourceMaterialTypeModalVisible,
        setIsSourceMaterialTypeModalVisible,
    ] = useState<boolean>(false)
    const sourceMaterialTypes = useRecoilValue(sourceMaterialTypesAtom)
    return (
        <div className={styles.sourceMaterialEditor}>
            <div className={styles.sourceMaterialEditorTitle}>
                Edit Source Material
            </div>
            <div className={styles.saveButtonContainer}>
                <Tooltip title={disabledSaveButtonReason}>
                    <Button
                        onClick={saveSourceMaterialChanges}
                        type={'primary'}
                        loading={isSaving}
                        disabled={!!disabledSaveButtonReason}
                    >
                        Save source material changes
                    </Button>
                </Tooltip>
                <div
                    className={`${reusableCssClass.centerChildrenVertically} ${
                        hasTooManyTokens ? styles.tooManyTokens : ''
                    }`}
                >
                    Tokens: {tokenCount.toLocaleString()} /{' '}
                    {TOKEN_LIMIT.toLocaleString()}
                </div>
            </div>
            <div className={styles.sourceMaterialEditorSection}>
                <div className={styles.sourceMaterialEditorLabel}>Title</div>
                <Input
                    disabled={isReadOnly}
                    placeholder={'Enter source material title here.'}
                    value={sourceMaterial.title}
                    onChange={(e) =>
                        setSourceMaterial((current) => ({
                            ...current,
                            title: e.target.value,
                        }))
                    }
                />
            </div>
            {hasSourceMaterialType && (
                <div className={styles.sourceMaterialEditorSection}>
                    <div className={styles.sourceMaterialEditorLabel}>Type</div>
                    <div
                        style={{
                            display: 'flex',
                            columnGap: '1rem',
                            width: '100%',
                        }}
                    >
                        <Select
                            disabled={isReadOnly}
                            placeholder={'Select source material type'}
                            value={sourceMaterial.type}
                            onChange={(newType) =>
                                setSourceMaterial((current) => ({
                                    ...current,
                                    type: newType,
                                }))
                            }
                        >
                            {sourceMaterialTypes.map((sourceMaterialType) => (
                                <Select.Option
                                    value={sourceMaterialType}
                                    key={sourceMaterialType}
                                >
                                    {sourceMaterialType}
                                </Select.Option>
                            ))}
                        </Select>
                        <Button
                            icon={<PlusOutlined />}
                            onClick={() =>
                                setIsSourceMaterialTypeModalVisible(true)
                            }
                            disabled={isReadOnly}
                        />
                    </div>
                    <Modal
                        open={isSourceMaterialTypeModalVisible}
                        onCancel={() =>
                            setIsSourceMaterialTypeModalVisible(false)
                        }
                        destroyOnClose={true}
                        footer={null}
                    >
                        <React.Suspense
                            fallback={
                                <LoadingComponent useWhiteBackground={true} />
                            }
                        >
                            <SourceMaterialTypes />
                        </React.Suspense>
                    </Modal>
                </div>
            )}
            <div className={styles.sourceMaterialEditorSection}>
                <div className={styles.sourceMaterialEditorLabel}>Content</div>
                <Tabs
                    activeKey={activeSourceMaterialContentEditorTab}
                    onChange={(newTab) =>
                        setActiveSourceMaterialContentEditorTab(
                            newTab as SourceMaterialContentEditorTab
                        )
                    }
                    items={[
                        {
                            key: SourceMaterialContentEditorTab.editable,
                            label: 'Editable',
                            children: (
                                <TextArea
                                    className={styles.sourceMaterialEditorText}
                                    disabled={isReadOnly}
                                    placeholder={
                                        'Enter source material content here.'
                                    }
                                    value={sourceMaterial.content}
                                    onChange={(
                                        e: React.ChangeEvent<HTMLTextAreaElement>
                                    ) =>
                                        setSourceMaterialContent(e.target.value)
                                    }
                                    autoSize={true}
                                    onPaste={handlePaste}
                                    ref={inputRef}
                                />
                            ),
                        },
                        {
                            key: SourceMaterialContentEditorTab.readOnly,
                            label: 'Rendered',
                            children: (
                                <div
                                    className={styles.markdownRenderer}
                                    dangerouslySetInnerHTML={getMarkdownText()}
                                />
                            ),
                        },
                    ]}
                />
            </div>
        </div>
    )
}
