import styles from './FormulaSheetDownloadButton.module.scss'
import React, { ReactElement, useCallback, useRef, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { DownloadOutlined } from '@ant-design/icons'
import { useReactToPrint } from 'react-to-print'
import { FormulaSheetEntry } from 'common/src/formulaSheet/types'
import { FormulaSheetHTMLMule } from '@/components/FormulaSheetEditor/FormulaSheetDownloadButton/FormulaSheetHTMLMule'
import { Button } from 'antd'
import { formulaSheetEntriesAtom } from '@/atoms/formulaSheetEntries'

export const FormulaSheetDownloadButton: React.FC<
    unknown
> = (): ReactElement => {
    const formulaSheetEntries = useRecoilValue(formulaSheetEntriesAtom)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [data, setData] = useState<FormulaSheetEntry[] | null>(null)
    const printableContentRef = useRef<HTMLDivElement | null>(null)
    const print = useReactToPrint({
        content: () => printableContentRef.current,
    })

    const download = useCallback(async (): Promise<void> => {
        setIsLoading(true)
        setData(formulaSheetEntries)
        setTimeout(() => {
            print()
            setData(null)
            setIsLoading(false)
        }, 2_000)
    }, [formulaSheetEntries, print])

    return (
        <div
            className={`${styles.formulaSheetDownloadButtonContainer} ${
                isLoading ? styles.loading : ''
            }`}
        >
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <Button onClick={download} loading={isLoading}>
                    <>
                        <span style={{ marginRight: '0.5rem' }}>
                            <DownloadOutlined />
                        </span>
                        Preview Downloaded Formula Sheet
                    </>
                </Button>
            </div>
            {data !== null && (
                <div className={styles.muleContainer} ref={printableContentRef}>
                    <FormulaSheetHTMLMule formulaSheetEntries={data} />
                </div>
            )}
        </div>
    )
}
