import styles from './PracticeProblemCategories.module.less'
import React, { ReactElement, useEffect } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import {
    practiceProblemCategoryStatsAtomNonMemoizing,
    practiceProblemCategoryStatsInvalidatorAtom,
} from '@/atoms/practiceProblemCategories'
import { PracticeProblemCategory } from '@/components/PracticeProblemCategories/PracticeProblemCategory/PracticeProblemCategory'
import { Collapse } from 'antd'
import { AddPracticeProblemCategoryButton } from '@/components/PracticeProblemCategories/AddPracticeProblemCategoryButton/AddPracticeProblemCategoryButton'

export const PracticeProblemCategories: React.FC<
    unknown
> = (): ReactElement => {
    // so it recalculates each time it is opened
    const practiceProblemCategoryStats = useRecoilValue(
        practiceProblemCategoryStatsAtomNonMemoizing
    )
    const invalidate = useSetRecoilState(
        practiceProblemCategoryStatsInvalidatorAtom
    )
    useEffect(() => invalidate((x) => x + 1), [invalidate])

    return (
        <div className={styles.practiceProblemCategories}>
            <div className={styles.practiceProblemCategoriesTitle}>
                Practice Problem Categories
            </div>
            <AddPracticeProblemCategoryButton />
            <Collapse accordion={true} expandIconPosition={'end'}>
                {Object.entries(practiceProblemCategoryStats).map(
                    ([category, practiceProblemIDs]) => (
                        <PracticeProblemCategory
                            key={category}
                            category={category}
                            count={practiceProblemIDs.length}
                        />
                    )
                )}
            </Collapse>
        </div>
    )
}
