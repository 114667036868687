import React, {
    ReactElement,
    useCallback,
    useMemo,
    useState,
    useEffect,
} from 'react'
import styles from './OutlineEditor.module.less'
import { OutlineEditorProps } from '@/components/ContentTreeNode/ContentTreeLeafNode/OutlineEditor/OutlineEditor.types'
import { useRecoilValue, useRecoilState } from 'recoil'
import { slimLeafNodeAtomFamily } from '@/atoms/slimLeafNode'
import { overwriteOutlineAtContentPath } from '@/api/cb/contentCreatorContent'
import {
    frontendDisplayedCourseSelector,
    isActiveCourseReadOnlySelector,
} from '@/atoms/auth'
import { unHashContentPath } from 'common/src/ContentPath'
import { syncErrorMessage, syncSuccessMessage } from '@/utils/syncMessages'
import {
    useMarkContentWithSavedChanges,
    useMarkContentWithUnsavedChanges,
} from '@/hooks/useMarkContentWithUnsavedChanges'
import { SavableContentType, SavableContent } from '@/atoms/unsavedChanges'
import { OutlineMagicTextBoxEditor } from '@/components/ContentTreeNode/ContentTreeLeafNode/MagicTextBoxEditor/OutlineMagicTextBoxEditor/OutlineMagicTextBoxEditor'
import { setOutline } from '@/components/ContentTreeNode/ContentTreeLeafNode/immer'

export const OutlineEditor: React.FC<OutlineEditorProps> = (
    props
): ReactElement => {
    const [{ outline }, setSlimLeafNode] = useRecoilState(
        slimLeafNodeAtomFamily(props.hashedContentPath)
    )

    const courseName = useRecoilValue(frontendDisplayedCourseSelector)
    const isReadOnly = useRecoilValue(isActiveCourseReadOnlySelector)

    const contentPath = useMemo(
        () => unHashContentPath(props.hashedContentPath),
        [props.hashedContentPath]
    )

    const markContentWithUnsavedChanges = useMarkContentWithUnsavedChanges()
    const savableContent = useMemo(
        (): SavableContent => ({
            contentPath,
            contentType: SavableContentType.outline,
        }),
        [contentPath]
    )

    const markContentWithSavedChanges = useMarkContentWithSavedChanges()
    const saveOutline = useCallback(
        async (serializedEditorState: string): Promise<void> => {
            if (isReadOnly) return
            const response = await overwriteOutlineAtContentPath(
                courseName,
                contentPath,
                serializedEditorState
            )
            if (response.data.isError) {
                syncErrorMessage(
                    'Error saving outline. You may want to try saving the outline again.'
                )
            } else {
                syncSuccessMessage('Outline successfully saved')
                setSlimLeafNode((slimLeafNode) =>
                    setOutline(slimLeafNode, serializedEditorState)
                )
            }
        },
        [contentPath, courseName, isReadOnly, setSlimLeafNode]
    )

    const [isUnsaved, setIsUnsaved] = useState<boolean>(false)
    useEffect(() => {
        if (isUnsaved) {
            markContentWithUnsavedChanges(savableContent)
        } else {
            markContentWithSavedChanges(savableContent)
        }
    }, [
        isUnsaved,
        markContentWithSavedChanges,
        markContentWithUnsavedChanges,
        savableContent,
    ])
    return (
        <div className={styles.outlineEditor}>
            <div className={styles.outlineEditorTitle}>Edit Outline</div>
            <OutlineMagicTextBoxEditor
                id={`${props.hashedContentPath}-outline`}
                namespace={'outline'}
                placeholder={'Enter outline here.'}
                initialStringifiedEditorState={outline}
                savableProps={{
                    onSave: saveOutline,
                    setHasUnsavedChanges: setIsUnsaved,
                }}
                type={'outline'}
                contentPath={contentPath}
            />
        </div>
    )
}
