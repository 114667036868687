import styles from './NotecardsAsPracticeProblems.module.less'
import React, { ReactElement, useEffect } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import {
    notecardsWithoutPracticeProblemNonMemoizing,
    notecardsWithoutPracticeProblemInvalidatorAtom,
} from '@/atoms/notecardsWithoutPracticeProblem'
import { NotecardIDsList } from '@/components/NotecardCategories/NotecardCategory/NotecardIDsList/NotecardIDsList'

export const NotecardsWithoutPracticeProblem: React.FC<
    unknown
> = (): ReactElement => {
    // so it recalculates each time it is opened
    const notecardsWithoutPracticeProblem = useRecoilValue(
        notecardsWithoutPracticeProblemNonMemoizing
    )
    const invalidate = useSetRecoilState(
        notecardsWithoutPracticeProblemInvalidatorAtom
    )
    useEffect(() => invalidate((x) => x + 1), [invalidate])

    return (
        <div className={styles.notecardsWithoutPracticeProblem}>
            <div className={styles.notecardsWithoutPracticeProblemTitle}>
                Notecards without Practice Problem
            </div>
            <NotecardIDsList notecardIDs={notecardsWithoutPracticeProblem} />
        </div>
    )
}
