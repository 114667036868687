import { ContentType } from 'common/src/commentThread/types'
import { ContentPath } from 'common/src/ContentPath'

export interface ContentCommentThreadWrapperProps {
    contentType: ContentType
    contentId: string
    contentPath: ContentPath
}

export const COMMENT_ANCHOR_LINK = 'comments'

export const TOP_ANCHOR_LINK = 'top'
