import styles from './DeleteExcelFileButton.module.less'
import React, { ReactElement, useCallback, useMemo } from 'react'
import { DeleteExcelFileButtonProps } from '@/components/ContentTreeNode/ContentTreeLeafNode/PracticeProblemsEditor/ExcelFileUploader/DeleteExcelFileButton/DeleteExcelFileButton.types'
import {
    deleteUploadedExcelFile,
    deleteUploadedExcelSolutionFile,
} from '@/api/cb/s3'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { frontendDisplayedCourseSelector } from '@/atoms/auth'
import { DefensiveDeleteButton } from '@/components/utils/DefensiveDeleteButton/DefensiveDeleteButton'
import { practiceProblemAtomFamily } from '@/atoms/practiceProblem'
import {
    setExcelS3Key,
    setExcelSolutionS3Key,
} from '@/components/ContentTreeNode/ContentTreeLeafNode/PracticeProblemsEditor/immer'
import { syncErrorMessage, syncSuccessMessage } from '@/utils/syncMessages'

export const DeleteExcelFileButton: React.FC<DeleteExcelFileButtonProps> = (
    props
): ReactElement => {
    const courseName = useRecoilValue(frontendDisplayedCourseSelector)
    const setPracticeProblem = useSetRecoilState(
        practiceProblemAtomFamily(props.practiceProblemID)
    )

    const deleteUploadedExcelFileCallback = useMemo(
        () =>
            props.isSolution
                ? deleteUploadedExcelSolutionFile
                : deleteUploadedExcelFile,
        [props.isSolution]
    )
    const setExcelS3KeyLocally = useMemo(
        () => (props.isSolution ? setExcelSolutionS3Key : setExcelS3Key),
        [props.isSolution]
    )
    const deleteExcelFile = useCallback(async () => {
        const response = await deleteUploadedExcelFileCallback(
            courseName,
            props.practiceProblemID
        )
        if (response.data.isError) {
            syncErrorMessage(
                'Error deleting excel file. You may want to try again or refreshing the page.'
            )
        } else {
            setPracticeProblem((practiceProblem) =>
                setExcelS3KeyLocally(practiceProblem, undefined)
            )
            syncSuccessMessage('Successfully deleted excel file')
        }
    }, [
        courseName,
        deleteUploadedExcelFileCallback,
        props.practiceProblemID,
        setExcelS3KeyLocally,
        setPracticeProblem,
    ])

    return (
        <div className={styles.deleteExcelFileButtonContainer}>
            <DefensiveDeleteButton
                onDelete={deleteExcelFile}
                title={'Warning: Dangerous Action'}
                areYouSureContent={
                    'Are you sure you want to delete this excel file? This cannot be undone.'
                }
                okText={'Yes, delete excel file'}
                cancelText={'Cancel'}
                buttonText={'Delete uploaded excel file'}
                placement={'right'}
            />
        </div>
    )
}
