import { INotecard } from 'common/src/notecards/types'
import { useRecoilCallback } from 'recoil'
import { notecardAtomFamily } from '@/atoms/notecard'

/**
 * Useful for adding a blank notecard without explicitly saving it to the backend yet
 */
export const useSetLocalOnlyNotecardData = (): ((
    notecardData: INotecard
) => void) => {
    return useRecoilCallback(({ set }) => (notecardData: INotecard) => {
        set(notecardAtomFamily(notecardData.id), notecardData)
    })
}
