import styles from './DeleteSubProblemButton.module.less'
import React, { ReactElement, useCallback } from 'react'
import { DeleteSubProblemButtonProps } from '@/components/ContentTreeNode/ContentTreeLeafNode/PracticeProblemsEditor/PracticeProblemEditor/MultiPartProblemSubProblemsEditor/DeleteSubProblemButton/DeleteSubProblemButton.types'
import { DefensiveDeleteButton } from '@/components/utils/DefensiveDeleteButton/DefensiveDeleteButton'
import { syncErrorMessage } from '@/utils/syncMessages'

export const DeleteSubProblemButton: React.FC<DeleteSubProblemButtonProps> = (
    props
): ReactElement => {
    /**
     * Not actually async due to how we do sub-problems locally
     */
    const deleteSubProblem = useCallback(async (): Promise<void> => {
        try {
            props.deleteSubProblem()
        } catch (e) {
            syncErrorMessage(
                'Error deleting sub-problem. You may want to try again or refresh the page.'
            )
        }
    }, [props])

    return (
        <div className={styles.deleteSubProblemButtonContainer}>
            <DefensiveDeleteButton
                onDelete={deleteSubProblem}
                title={'Warning: Dangerous Action'}
                areYouSureContent={
                    'Are you sure you want to delete this sub-problem? This can only be undone by not saving the problem after deleting the sub-problem.'
                }
                okText={'Yes, delete sub-problem'}
                cancelText={'Cancel'}
                buttonText={'Delete selected sub-problem'}
                placement={'right'}
            />
        </div>
    )
}
