import { ISlimLeafNodeData } from 'common/src/api/backendToBackend/content/types'
import produce from 'immer'

export const deletePracticeProblemID = produce(
    (slimLeafNode: ISlimLeafNodeData, practiceProblemID: string): void => {
        slimLeafNode.practiceProblemIDs =
            slimLeafNode.practiceProblemIDs.filter(
                (arrayID) => arrayID !== practiceProblemID
            )
    }
)

export const deleteNotecardID = produce(
    (slimLeafNode: ISlimLeafNodeData, notecardID: string): void => {
        slimLeafNode.notecardIDs = slimLeafNode.notecardIDs.filter(
            (arrayID) => arrayID !== notecardID
        )
    }
)

export const addNewPracticeProblemID = produce(
    (slimLeafNode: ISlimLeafNodeData, practiceProblemID: string): void => {
        slimLeafNode.practiceProblemIDs.push(practiceProblemID)
    }
)

export const addNewNotecardID = produce(
    (slimLeafNode: ISlimLeafNodeData, notecardID: string): void => {
        slimLeafNode.notecardIDs.push(notecardID)
    }
)

export const reorderPracticeProblemID = produce(
    (
        slimLeafNode: ISlimLeafNodeData,
        startingIndex: number,
        endingIndex: number
    ): void => {
        const movedContent = slimLeafNode.practiceProblemIDs.splice(
            startingIndex,
            1
        )
        slimLeafNode.practiceProblemIDs.splice(endingIndex, 0, ...movedContent)
    }
)

export const reorderNotecardID = produce(
    (
        slimLeafNode: ISlimLeafNodeData,
        startingIndex: number,
        endingIndex: number
    ): void => {
        const movedContent = slimLeafNode.notecardIDs.splice(startingIndex, 1)
        slimLeafNode.notecardIDs.splice(endingIndex, 0, ...movedContent)
    }
)

export const setSummary = produce(
    (slimLeafNode: ISlimLeafNodeData, summary: string): void => {
        slimLeafNode.summary = summary
    }
)

export const setOutline = produce(
    (slimLeafNode: ISlimLeafNodeData, outline: string): void => {
        slimLeafNode.outline = outline
    }
)
