import React, { ReactElement, Suspense, useCallback, useMemo } from 'react'
import { Collapse } from 'antd'
import { ContentTreePanelHeader } from '@/components/ContentTreeNode/ContentTreePanelHeader/ContentTreePanelHeader'
import {
    ContentPath,
    hashContentPath,
    unHashContentPath,
} from 'common/src/ContentPath'
import { useRecoilState, useRecoilValue } from 'recoil'
import { contentTreeDepthSelector } from '@/atoms/contentTree'
import { nodeMetadataTreeSelector } from '@/atoms/nodeMetadata'
import { ContentTreeNodeProps } from '@/components/ContentTreeNode/ContentTreeNodes.types'
import { ContentTreeLeafNode } from '@/components/ContentTreeNode/ContentTreeLeafNode/ContentTreeLeafNode'
import { LoadingComponent } from '@/components/utils/LoadingComponent/LoadingComponent'
import { AddContentTreeNode } from '@/components/ContentTreeNode/AddContentTreeNode/AddContentTreeNode'
import { LearningObjectivesEditor } from '@/components/ContentTreeNode/LearningObjectivesEditor/LearningObjectivesEditor'
import { viewingStateAtom } from '@/atoms/viewingState'
import { getSubtreeFromContentPath } from 'common/src/ContentTree'
import { NodeStatsButton } from '@/components/ContentTreeNode/NodeStats/NodeStatsButton'
import { ViewVideoButton } from '@/components/ContentTreeNode/ViewVideo/ViewVideoButton'
import { getMetadataForContentPath } from '@/frontendLogic/outlines/NodeMetadataTree/NodeMetadataTree'
import { activeCourseConfigSelector } from '@/atoms/courseInfo'

const { Panel } = Collapse
export const ContentTreeNode: React.FC<ContentTreeNodeProps> = (
    props
): ReactElement => {
    const nodeMetadataTree = useRecoilValue(nodeMetadataTreeSelector)
    const contentTreeDepth = useRecoilValue(contentTreeDepthSelector)
    const childContentPaths = useMemo((): ContentPath[] => {
        const traversalReturn = getSubtreeFromContentPath(
            nodeMetadataTree.contentTree,
            props.contentPath
        )
        return Array.from(
            traversalReturn?.children.map((child) => child.id) || []
        ).map((nodeID) => [...props.contentPath, nodeID])
    }, [nodeMetadataTree.contentTree, props.contentPath])
    // allow accordion to open up where content creator left off after a reload
    const [viewingState, setViewingState] = useRecoilState(viewingStateAtom)
    const activeKey = useMemo(
        (): string | null =>
            hashContentPath(
                viewingState.contentPath.slice(0, props.contentPath.length + 1)
            ),
        [props.contentPath.length, viewingState.contentPath]
    )
    const setActiveKey = useCallback(
        (newActiveKey: string) => {
            if (newActiveKey === undefined) {
                // closing
                setViewingState((viewingState) => ({
                    ...viewingState,
                    contentPath: props.contentPath,
                }))
            } else {
                // opening
                setViewingState((viewingState) => ({
                    ...viewingState,
                    contentPath: unHashContentPath(newActiveKey),
                }))
            }
        },
        [props.contentPath, setViewingState]
    )
    const courseConfig = useRecoilValue(activeCourseConfigSelector)

    const { videoLink } = useMemo(
        () => getMetadataForContentPath(nodeMetadataTree, props.contentPath),
        [nodeMetadataTree, props.contentPath]
    )
    // recursive tsx...yep!
    const content = (
        <>
            <div className={'collapse-footer'}>
                <AddContentTreeNode contentPath={props.contentPath} />
                {props.contentPath.length === 1 && (
                    <LearningObjectivesEditor topicID={props.contentPath[0]} />
                )}
                {/*Hide for root level (it's at the top)*/}
                {props.contentPath.length ? (
                    <NodeStatsButton contentPath={props.contentPath} />
                ) : null}
                {props.contentPath.length > 0 && courseConfig.hasVideos ? (
                    <ViewVideoButton
                        videoLink={videoLink}
                        contentPath={props.contentPath}
                    />
                ) : null}
            </div>
            <Collapse
                accordion={true}
                expandIconPosition={'end'}
                activeKey={activeKey}
                onChange={(newActiveKey: string | string[]) =>
                    setActiveKey((newActiveKey as string[])[0])
                }
            >
                {childContentPaths.map((contentPath): ReactElement => {
                    if (contentPath.length > contentTreeDepth - 1) {
                        return (
                            <Panel
                                key={hashContentPath(contentPath)}
                                header={
                                    <ContentTreePanelHeader
                                        contentPath={contentPath}
                                    />
                                }
                            >
                                <Suspense
                                    fallback={
                                        <LoadingComponent
                                            useWhiteBackground={true}
                                        />
                                    }
                                >
                                    <ContentTreeLeafNode
                                        contentPath={contentPath}
                                    />
                                </Suspense>
                            </Panel>
                        )
                    }
                    return (
                        <Panel
                            key={hashContentPath(contentPath)}
                            header={
                                <ContentTreePanelHeader
                                    contentPath={contentPath}
                                />
                            }
                        >
                            <ContentTreeNode contentPath={contentPath} />
                        </Panel>
                    )
                })}
            </Collapse>
        </>
    )

    if (props.contentPath.length === 0) {
        return <div className={'content-tree-root'}>{content}</div>
    }
    return content
}
