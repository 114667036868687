import React, { ReactElement, useCallback, useMemo, useState } from 'react'
import styles from './PracticeProblemMetadataEditor.module.less'
import { Input, InputNumber, Radio, Select, Switch, Modal, Button } from 'antd'
import _, { startCase } from 'lodash'
import { PracticeProblemMetadataEditorProps } from '@/components/ContentTreeNode/ContentTreeLeafNode/PracticeProblemsEditor/PracticeProblemEditor/PracticeProblemMetadataEditor/PracticeProblemMetadataEditor.types'
import { PracticeProblemType } from 'common/src/practiceProblems'
import { roundToNearestFraction } from '@/utils/rounding'
import { useRecoilValue } from 'recoil'
import { contentTreeStateAtom } from '@/atoms/contentTree'
import { ContentLearningObjectiveEditor } from '@/components/ContentTreeNode/ContentTreeLeafNode/ContentLearningObjectiveEditor/ContentLearningObjectiveEditor'
import { CopyableID } from '@/components/CopyableID/CopyableID'
import { hashContentPath } from 'common/src/ContentPath'
import { isActiveCourseReadOnlySelector } from '@/atoms/auth'
import { PreviousExamIDEditor } from '@/components/ContentTreeNode/ContentTreeLeafNode/PracticeProblemsEditor/PracticeProblemEditor/PracticeProblemMetadataEditor/PreviousExamIDEditor/PreviousExamIDEditor'
import { PreviousExamID } from 'common/src/previousExamAnalysis/types'
import { QuestionLanguage } from 'common/src/practiceProblems/types'
import { HintsEditor } from '@/components/utils/HintsEditor/HintsEditor'
import { activeCourseConfigSelector } from '@/atoms/courseInfo'
import { PracticeProblemCategories } from '@/components/PracticeProblemCategories/PracticeProblemCategories'
import { PlusOutlined } from '@ant-design/icons'
import { practiceProblemCategoriesAtom } from '@/atoms/practiceProblemCategories'
import { LoadingComponent } from '@/components/utils/LoadingComponent/LoadingComponent'
import { RequiredAsterisk } from '@/components/utils/RequiredAsterisk/RequiredAsterisk'
import { practiceProblemAtomFamily } from '@/atoms/practiceProblem'
import { SourceMaterialIDLinks } from '@/components/ContentTreeNode/ContentTreeLeafNode/PracticeProblemsEditor/PracticeProblemEditor/PracticeProblemMetadataEditor/SourceMaterialIDsLink/SourceMaterialIDsLink'

const { Option } = Select

export const PracticeProblemMetadataEditor: React.FC<
    PracticeProblemMetadataEditorProps
> = (props): ReactElement => {
    const practiceProblemCategories = useRecoilValue(
        practiceProblemCategoriesAtom
    )

    const isMultiPart = useMemo(
        (): boolean =>
            props.contentCreatorEditablePracticeProblemMetadata.type ===
            PracticeProblemType.MULTI_PART,
        [props.contentCreatorEditablePracticeProblemMetadata]
    )
    const isReadOnly = useRecoilValue(isActiveCourseReadOnlySelector)

    const setType = useCallback(
        (newType: PracticeProblemType): void => {
            props.setContentCreatorEditablePracticeProblemMetadata({
                ...props.contentCreatorEditablePracticeProblemMetadata,
                type: newType,
            })
        },
        [props]
    )

    const setIsPreviousExamQuestion = useCallback(
        (newIsPreviousExamQuestion: boolean): void => {
            props.setContentCreatorEditablePracticeProblemMetadata({
                ...props.contentCreatorEditablePracticeProblemMetadata,
                isPreviousExamQuestion: newIsPreviousExamQuestion,
            })
        },
        [props]
    )

    const setPreviousExamID = useCallback(
        (newPreviousExamID: PreviousExamID): void => {
            props.setContentCreatorEditablePracticeProblemMetadata({
                ...props.contentCreatorEditablePracticeProblemMetadata,
                previousExamID: newPreviousExamID,
            })
        },
        [props]
    )

    const setIsGoldStandard = useCallback(
        (newIsGoldStandard: boolean): void => {
            props.setContentCreatorEditablePracticeProblemMetadata({
                ...props.contentCreatorEditablePracticeProblemMetadata,
                isGoldStandard: newIsGoldStandard,
            })
        },
        [props]
    )

    const setIsMathematical = useCallback(
        (newIsMathematical: boolean): void => {
            props.setContentCreatorEditablePracticeProblemMetadata({
                ...props.contentCreatorEditablePracticeProblemMetadata,
                isMathematical: newIsMathematical,
            })
        },
        [props]
    )

    const setIsConceptual = useCallback(
        (newIsConceptual: boolean): void => {
            props.setContentCreatorEditablePracticeProblemMetadata({
                ...props.contentCreatorEditablePracticeProblemMetadata,
                isConceptual: newIsConceptual,
            })
        },
        [props]
    )

    const setIsSynthesis = useCallback(
        (newIsSynthesis: boolean): void => {
            props.setContentCreatorEditablePracticeProblemMetadata({
                ...props.contentCreatorEditablePracticeProblemMetadata,
                isSynthesis: newIsSynthesis,
            })
        },
        [props]
    )

    const setHints = useCallback(
        (newHints: string[]): void => {
            props.setContentCreatorEditablePracticeProblemMetadata({
                ...props.contentCreatorEditablePracticeProblemMetadata,
                hints: newHints,
            })
        },
        [props]
    )

    const setTopic = useCallback(
        (newTopic: string): void => {
            props.setContentCreatorEditablePracticeProblemMetadata({
                ...props.contentCreatorEditablePracticeProblemMetadata,
                topic: newTopic,
            })
        },
        [props]
    )

    const setPoints = useCallback(
        (newPoints: number): void => {
            props.setContentCreatorEditablePracticeProblemMetadata({
                ...props.contentCreatorEditablePracticeProblemMetadata,
                points: newPoints,
            })
        },
        [props]
    )

    const setDifficultyScore = useCallback(
        (newDifficultyScore: number): void => {
            props.setContentCreatorEditablePracticeProblemMetadata({
                ...props.contentCreatorEditablePracticeProblemMetadata,
                difficultyScore: newDifficultyScore,
            })
        },
        [props]
    )

    const setLearningObjectiveID = useCallback(
        (newLearningObjectiveID: string): void => {
            props.setContentCreatorEditablePracticeProblemMetadata({
                ...props.contentCreatorEditablePracticeProblemMetadata,
                learningObjectiveID: newLearningObjectiveID,
            })
        },
        [props]
    )

    const setQuestionLanguage = useCallback(
        (newQuestionLanguage: QuestionLanguage): void => {
            props.setContentCreatorEditablePracticeProblemMetadata({
                ...props.contentCreatorEditablePracticeProblemMetadata,
                questionLanguage: newQuestionLanguage,
            })
        },
        [props]
    )

    const roundPoints = useCallback((): void => {
        const points =
            props.contentCreatorEditablePracticeProblemMetadata.points
        if (points % 0.25 !== 0) {
            setPoints(roundToNearestFraction(points, 0.25))
            return
        }
    }, [props.contentCreatorEditablePracticeProblemMetadata.points, setPoints])

    const roundDifficultyScore = useCallback((): void => {
        const difficultyScore =
            props.contentCreatorEditablePracticeProblemMetadata.difficultyScore
        if (difficultyScore % 0.25 !== 0) {
            setDifficultyScore(roundToNearestFraction(difficultyScore, 0.25))
            return
        }
    }, [
        props.contentCreatorEditablePracticeProblemMetadata.difficultyScore,
        setDifficultyScore,
    ])

    const contentTreeState = useRecoilValue(contentTreeStateAtom)
    const hashedContentPath = useMemo(
        (): string => hashContentPath(props.contentPath),
        [props.contentPath]
    )
    const setCategories = useCallback(
        (newCategories: string[]): void => {
            props.setContentCreatorEditablePracticeProblemMetadata({
                ...props.contentCreatorEditablePracticeProblemMetadata,
                categories: newCategories,
            })
        },
        [props]
    )
    const courseConfig = useRecoilValue(activeCourseConfigSelector)
    const [
        isPracticeProblemCategoryModalVisible,
        setIsPracticeProblemCategoryModalVisible,
    ] = useState<boolean>(false)

    const practiceProblem = useRecoilValue(
        practiceProblemAtomFamily(props.practiceProblemID)
    )

    return (
        <div className={styles.practiceProblemMetadataEditor}>
            <CopyableID label="ID" id={props.practiceProblemID} />
            <CopyableID label="Content Path" id={hashedContentPath} />
            <div className={styles.metadatumEditor}>
                <div className={styles.metadatumEditorTitleContainer}>
                    Type:
                </div>
                <Select
                    value={
                        props.contentCreatorEditablePracticeProblemMetadata.type
                    }
                    onChange={setType}
                    disabled={isReadOnly}
                >
                    {courseConfig.practiceProblemTypes
                        .filter(
                            (type) =>
                                !(
                                    props.shouldDisableMultiPartProblem &&
                                    type === PracticeProblemType.MULTI_PART
                                )
                        )
                        .map((practiceProblemType) => (
                            <Option
                                value={practiceProblemType}
                                key={practiceProblemType}
                                disabled={false}
                            >
                                {_.startCase(_.toLower(practiceProblemType))}
                            </Option>
                        ))}
                </Select>
            </div>
            {courseConfig.hasPracticeProblemCategories && (
                <div className={styles.metadatumEditor}>
                    <div className={styles.metadatumEditorTitleContainer}>
                        <div style={{ display: 'flex' }}>
                            Categories:
                            <RequiredAsterisk />
                        </div>
                    </div>
                    <Select
                        mode={'multiple'}
                        value={
                            props.contentCreatorEditablePracticeProblemMetadata
                                .categories
                        }
                        onChange={setCategories}
                        disabled={isReadOnly}
                    >
                        {practiceProblemCategories.map(
                            (practiceProblemCategory) => (
                                <Option
                                    value={practiceProblemCategory}
                                    key={practiceProblemCategory}
                                >
                                    {practiceProblemCategory}
                                </Option>
                            )
                        )}
                    </Select>
                    <Button
                        icon={<PlusOutlined />}
                        onClick={() =>
                            setIsPracticeProblemCategoryModalVisible(true)
                        }
                        disabled={isReadOnly}
                    />
                </div>
            )}
            {!isMultiPart && !!courseConfig.questionLanguages.length && (
                <div className={styles.metadatumEditor}>
                    <div className={styles.metadatumEditorTitleContainer}>
                        Question Language:
                    </div>
                    <Radio.Group
                        value={
                            props.contentCreatorEditablePracticeProblemMetadata
                                .questionLanguage || QuestionLanguage.calculate
                        }
                        onChange={(e) =>
                            setQuestionLanguage(
                                e.target.value as QuestionLanguage
                            )
                        }
                        buttonStyle={'solid'}
                    >
                        {courseConfig.questionLanguages.map(
                            (questionLanguage) => (
                                <Radio.Button
                                    value={questionLanguage}
                                    key={questionLanguage}
                                >
                                    {startCase(questionLanguage)}
                                </Radio.Button>
                            )
                        )}
                    </Radio.Group>
                </div>
            )}
            {courseConfig.hasPreviousExamQuestionToggle && (
                <>
                    <div className={styles.metadatumEditor}>
                        <div className={styles.metadatumEditorTitleContainer}>
                            Is Previous Exam Question?{' '}
                        </div>
                        <Switch
                            checkedChildren={'Yes'}
                            unCheckedChildren={'No'}
                            checked={
                                props
                                    .contentCreatorEditablePracticeProblemMetadata
                                    .isPreviousExamQuestion
                            }
                            onChange={setIsPreviousExamQuestion}
                            disabled={
                                props
                                    .contentCreatorEditablePracticeProblemMetadata
                                    .isSubProblem || isReadOnly
                            }
                        />
                    </div>
                    {props.contentCreatorEditablePracticeProblemMetadata
                        .isPreviousExamQuestion &&
                        !props.contentCreatorEditablePracticeProblemMetadata
                            .isSubProblem && (
                            <PreviousExamIDEditor
                                previousExamID={
                                    props
                                        .contentCreatorEditablePracticeProblemMetadata
                                        .previousExamID
                                }
                                setPreviousExamID={setPreviousExamID}
                            />
                        )}
                </>
            )}
            {courseConfig.hasIsGoldStandardToggle && (
                <div className={styles.metadatumEditor}>
                    <div className={styles.metadatumEditorTitleContainer}>
                        Is Gold Standard?{' '}
                    </div>
                    <Switch
                        checkedChildren={'Yes'}
                        unCheckedChildren={'No'}
                        checked={
                            props.contentCreatorEditablePracticeProblemMetadata
                                .isGoldStandard
                        }
                        onChange={setIsGoldStandard}
                        disabled={
                            props.contentCreatorEditablePracticeProblemMetadata
                                .isSubProblem || isReadOnly
                        }
                    />
                </div>
            )}
            {courseConfig.hasIsMathematicalToggle && (
                <div className={styles.metadatumEditor}>
                    <div className={styles.metadatumEditorTitleContainer}>
                        Is Mathematical?{' '}
                    </div>
                    <Switch
                        checkedChildren={'Yes'}
                        unCheckedChildren={'No'}
                        checked={
                            props.contentCreatorEditablePracticeProblemMetadata
                                .isMathematical
                        }
                        onChange={setIsMathematical}
                        disabled={isMultiPart || isReadOnly}
                    />
                </div>
            )}
            {courseConfig.hasIsConceptualToggle && (
                <div className={styles.metadatumEditor}>
                    <div className={styles.metadatumEditorTitleContainer}>
                        Is Conceptual?{' '}
                    </div>
                    <Switch
                        checkedChildren={'Yes'}
                        unCheckedChildren={'No'}
                        checked={
                            props.contentCreatorEditablePracticeProblemMetadata
                                .isConceptual
                        }
                        onChange={setIsConceptual}
                        disabled={isMultiPart || isReadOnly}
                    />
                </div>
            )}
            {courseConfig.hasIsSynthesisToggle && (
                <div className={styles.metadatumEditor}>
                    <div className={styles.metadatumEditorTitleContainer}>
                        Is Synthesis?{' '}
                    </div>
                    <Switch
                        checkedChildren={'Yes'}
                        unCheckedChildren={'No'}
                        checked={
                            props.contentCreatorEditablePracticeProblemMetadata
                                .isSynthesis
                        }
                        onChange={setIsSynthesis}
                        disabled={isMultiPart || isReadOnly}
                    />
                </div>
            )}
            {courseConfig.hasPracticeProblemHints && (
                <div className={styles.metadatumEditor}>
                    <HintsEditor
                        hints={
                            props.contentCreatorEditablePracticeProblemMetadata
                                .hints
                        }
                        setHints={setHints}
                        isDisabled={isReadOnly}
                    />
                </div>
            )}
            {courseConfig.hasExplicitPracticeProblemTitle && (
                <div className={styles.metadatumEditor}>
                    <div className={styles.metadatumEditorTitleContainer}>
                        Topic:
                    </div>
                    <Input
                        value={
                            props.contentCreatorEditablePracticeProblemMetadata
                                .topic
                        }
                        onChange={(event) => setTopic(event.target.value)}
                        disabled={isReadOnly}
                    />
                </div>
            )}
            <div className={styles.metadatumEditor}>
                <div className={styles.metadatumEditorTitleContainer}>
                    Points:
                </div>
                <InputNumber
                    value={
                        props.contentCreatorEditablePracticeProblemMetadata
                            .points
                    }
                    onChange={(value) => setPoints(value as number)}
                    min={0.25}
                    precision={2}
                    step={0.25}
                    type={'number'}
                    // onStep={onStep}
                    disabled={isMultiPart || isReadOnly}
                    onBlur={roundPoints}
                />
            </div>
            {courseConfig.hasPracticeProblemDifficultyScore && (
                <div className={styles.metadatumEditor}>
                    <div className={styles.metadatumEditorTitleContainer}>
                        Difficulty Score:
                    </div>
                    <InputNumber
                        value={
                            props.contentCreatorEditablePracticeProblemMetadata
                                .difficultyScore
                        }
                        onChange={(value) =>
                            setDifficultyScore(value as number)
                        }
                        min={0.25}
                        max={5.0}
                        precision={2}
                        step={0.25}
                        type={'number'}
                        // onStep={onStep}
                        disabled={isMultiPart || isReadOnly}
                        onBlur={roundDifficultyScore}
                    />
                </div>
            )}
            {!isMultiPart && courseConfig.hasLearningObjectives && (
                <ContentLearningObjectiveEditor
                    learningObjectives={contentTreeState.topicToLearningObjectivesMap.get(
                        props.contentPath[0]
                    )}
                    localLearningObjectiveID={
                        props.contentCreatorEditablePracticeProblemMetadata
                            .learningObjectiveID
                    }
                    setLocalLearningObjectiveID={setLearningObjectiveID}
                />
            )}
            {practiceProblem?.relevantSourceMaterialIDs?.length ? (
                <SourceMaterialIDLinks
                    sourceMaterialIDs={
                        practiceProblem.relevantSourceMaterialIDs
                    }
                    contentPath={props.contentPath}
                />
            ) : null}
            <Modal
                open={isPracticeProblemCategoryModalVisible}
                onCancel={() => setIsPracticeProblemCategoryModalVisible(false)}
                destroyOnClose={true}
                footer={null}
            >
                <React.Suspense
                    fallback={<LoadingComponent useWhiteBackground={true} />}
                >
                    <PracticeProblemCategories />
                </React.Suspense>
            </Modal>
        </div>
    )
}
