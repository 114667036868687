import { PracticeProblemLinkProps } from 'magic-text-box-lexical/src/atoms/practiceProblemLink/practiceProblemLink.types'
import { getReactElementForPracticeProblemID } from '@/components/ContentTreeNode/ContentTreeLeafNode/MagicTextBoxEditor/ReactElementForPracticeProblemID/ReactElementForPracticeProblemID'
import { mtbRenderIfError } from '@/utils/mtbRenderIfError'
import { useMtbGetPracticeProblemMetadata } from '@/hooks/useMtbGetPracticeProblemMetadata'

export const usePracticeProblemLinkProps = (): PracticeProblemLinkProps => {
    const getPracticeProblemMetadata = useMtbGetPracticeProblemMetadata()

    return {
        getPracticeProblemContent: getReactElementForPracticeProblemID,
        getPracticeProblemMetadata,
        renderIfError: mtbRenderIfError,
    }
}
