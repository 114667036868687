import React, { ReactElement } from 'react'
import { PreviousExamAnalysisUploader } from '@/components/UploadPreviousExamAnalysis/PreviousExamAnalysisUploader'
import { PreviousExamAnalysisDownloader } from '@/components/UploadPreviousExamAnalysis/PreviousExamAnalysisDownloader'
import { useRecoilValue } from 'recoil'
import { frontendDisplayedCourseSelector } from '@/atoms/auth'
import { LoadingComponent } from '@/components/utils/LoadingComponent/LoadingComponent'
import styles from './UploadPreviousExamAnalysis.module.less'

export const UploadPreviousExamAnalysis: React.FC<
    unknown
> = (): ReactElement => {
    const courseName = useRecoilValue(frontendDisplayedCourseSelector)
    return (
        <div className={styles.uploadPreviousExamAnalysis} key={courseName}>
            <div className={styles.uploadPreviousExamAnalysisTitle}>
                Upload previous exam analysis
            </div>
            <PreviousExamAnalysisUploader />
            <React.Suspense
                fallback={<LoadingComponent useWhiteBackground={true} />}
            >
                <PreviousExamAnalysisDownloader isSample={true} />
            </React.Suspense>
            <React.Suspense
                fallback={<LoadingComponent useWhiteBackground={true} />}
            >
                <PreviousExamAnalysisDownloader isSample={false} />
            </React.Suspense>
        </div>
    )
}
