import { ContentPath } from 'common/src/ContentPath'
import { MultipleChoiceOptionData } from 'common/src/practiceProblems/types'

export enum DraggableIconType {
    notecard = 'notecard',
    practiceProblem = 'practiceProblem',
    subProblem = 'subProblem',
    sourceMaterial = 'sourceMaterial',
    formulaSheetEntry = 'formulaSheetEntry',
    multipleChoiceOption = 'multipleChoiceOption',
}

interface BaseDraggableData {
    type: DraggableIconType
    hasUnsavedChanges: boolean
}

export interface DraggableNotecardData extends BaseDraggableData {
    type: DraggableIconType.notecard
    id: string
}

export interface DraggablePracticeProblemData extends BaseDraggableData {
    type: DraggableIconType.practiceProblem
    id: string
}

export interface DraggableSubProblemData extends BaseDraggableData {
    type: DraggableIconType.subProblem
    superProblemID: string
    subProblemIndex: number
}

export interface DraggableSourceMaterialData extends BaseDraggableData {
    type: DraggableIconType.sourceMaterial
    id: string
    contentPath: ContentPath
}

export interface DraggableFormulaSheetEntryData extends BaseDraggableData {
    type: DraggableIconType.formulaSheetEntry
    id: string
    title: string
}

export interface DraggableMultipleChoiceOptionData
    extends BaseDraggableData,
        MultipleChoiceOptionData {
    type: DraggableIconType.multipleChoiceOption
    isSolution: boolean
}

export type DraggableIconData =
    | DraggableNotecardData
    | DraggablePracticeProblemData
    | DraggableSubProblemData
    | DraggableSourceMaterialData
    | DraggableFormulaSheetEntryData
    | DraggableMultipleChoiceOptionData
