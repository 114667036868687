import styles from './ExcelFileUploader.module.less'
import React, { ReactElement, useState } from 'react'
import { ExcelFileUploaderProps } from '@/components/ContentTreeNode/ContentTreeLeafNode/PracticeProblemsEditor/ExcelFileUploader/ExcelFileUploader.types'
import { DownloadExcelFileButton } from '@/components/ContentTreeNode/ContentTreeLeafNode/PracticeProblemsEditor/ExcelFileUploader/DownloadExcelFileButton/DownloadExcelFileButton'
import { UploadExcelFileButton } from '@/components/ContentTreeNode/ContentTreeLeafNode/PracticeProblemsEditor/ExcelFileUploader/UploadExcelFileButton/UploadExcelFileButton'
import { DeleteExcelFileButton } from '@/components/ContentTreeNode/ContentTreeLeafNode/PracticeProblemsEditor/ExcelFileUploader/DeleteExcelFileButton/DeleteExcelFileButton'
import { useRecoilValue } from 'recoil'
import {
    isExcelFilePresentSelectorFamily,
    isExcelSolutionFilePresentSelectorFamily,
} from '@/atoms/practiceProblem'

export const ExcelFileUploader: React.FC<ExcelFileUploaderProps> = (
    props
): ReactElement => {
    const isExcelFilePresent = useRecoilValue(
        props.isSolution
            ? isExcelSolutionFilePresentSelectorFamily(props.practiceProblemID)
            : isExcelFilePresentSelectorFamily(props.practiceProblemID)
    )
    const [forceRemountIndex, setForceRemountIndex] = useState<number>(0)

    return (
        <div className={styles.excelFileUploader}>
            <div className={styles.excelFileUploaderHeader}>
                {`Upload excel file${
                    props.isSolution ? ' solution' : ''
                } (Optional)`}
            </div>
            <UploadExcelFileButton
                practiceProblemID={props.practiceProblemID}
                isSolution={props.isSolution}
                onUpload={() => setForceRemountIndex((current) => current + 1)}
            />
            <DownloadExcelFileButton
                practiceProblemID={props.practiceProblemID}
                isSolution={props.isSolution}
                key={`download-${forceRemountIndex}-${props.practiceProblemID}`}
            />
            {isExcelFilePresent && (
                <DeleteExcelFileButton
                    practiceProblemID={props.practiceProblemID}
                    isSolution={props.isSolution}
                    key={`delete-${forceRemountIndex}-${props.practiceProblemID}`}
                />
            )}
        </div>
    )
}
