import React, { ReactElement, useState, useCallback } from 'react'
import styles from './AddContentTreeNode.module.less'
import { AddContentTreeNodeProps } from '@/components/ContentTreeNode/AddContentTreeNode/AddContentTreeNode.types'
import { PlusCircleOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { addEmptyNodeAtContentPath } from '@/api/cb/contentCreatorContent'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import {
    frontendDisplayedCourseSelector,
    isActiveCourseReadOnlySelector,
} from '@/atoms/auth'
import { nanoid } from 'nanoid'
import { syncErrorMessage, syncSuccessMessage } from '@/utils/syncMessages'
import {
    convertContentTreeResponseToContentTreeState,
    contentTreeStateAtom,
} from '@/atoms/contentTree'
import { nodeMetadataAtom } from '@/atoms/nodeMetadata'

export const AddContentTreeNode: React.FC<AddContentTreeNodeProps> = (
    props
): ReactElement => {
    const courseName = useRecoilValue(frontendDisplayedCourseSelector)
    const isReadOnly = useRecoilValue(isActiveCourseReadOnlySelector)

    const setContentTreeState = useSetRecoilState(contentTreeStateAtom)
    const setNodeMetadata = useSetRecoilState(nodeMetadataAtom)
    const addContentTreeNode = useCallback(async (): Promise<void> => {
        setIsLoading(true)
        const newContentPath = [...props.contentPath, nanoid()]
        const response = await addEmptyNodeAtContentPath(
            courseName,
            newContentPath
        )
        if (response.data.error) {
            syncErrorMessage(
                'Error adding node. You may want to try again or refresh the page.'
            )
        } else {
            syncSuccessMessage('Successfully added node.')
            setNodeMetadata(response.data.payload.nodeMetadata)
            setContentTreeState(
                convertContentTreeResponseToContentTreeState(
                    response.data.payload.contentTreeResponse
                )
            )
        }
        setIsLoading(false)
    }, [courseName, props.contentPath, setContentTreeState, setNodeMetadata])

    const [isLoading, setIsLoading] = useState<boolean>(false)

    return (
        <div className={styles.addContentTreeNode}>
            <Button
                loading={isLoading}
                onClick={addContentTreeNode}
                size={'large'}
                disabled={isReadOnly}
            >
                <PlusCircleOutlined /> <span>Add Node</span>
            </Button>
        </div>
    )
}
