import React, { ReactElement, useCallback, useMemo, useState } from 'react'
import styles from './NotecardMetadataEditor.module.less'
import { NotecardMetadataEditorProps } from '@/components/ContentTreeNode/ContentTreeLeafNode/NotecardsEditor/NotecardEditor/NotecardMetadataEditor/NotecardMetadataEditor.types'
import { Select, Switch, Button, Modal, Input } from 'antd'
import _ from 'lodash'
import { useRecoilValue } from 'recoil'
import { contentTreeStateAtom } from '@/atoms/contentTree'
import { ContentLearningObjectiveEditor } from '@/components/ContentTreeNode/ContentTreeLeafNode/ContentLearningObjectiveEditor/ContentLearningObjectiveEditor'
import { NotecardPriority } from 'common/src/notecards/consts'
import { CopyableID } from '@/components/CopyableID/CopyableID'
import { hashContentPath } from 'common/src/ContentPath'
import { notecardCategoriesAtom } from '@/atoms/notecardCategories'
import { isActiveCourseReadOnlySelector } from '@/atoms/auth'
import { HintsEditor } from '@/components/utils/HintsEditor/HintsEditor'
import { activeCourseConfigSelector } from '@/atoms/courseInfo'
import { PlusOutlined } from '@ant-design/icons'
import { NotecardCategories } from '@/components/NotecardCategories/NotecardCategories'
import { LoadingComponent } from '@/components/utils/LoadingComponent/LoadingComponent'
import { SourceMaterialIDLinks } from '@/components/ContentTreeNode/ContentTreeLeafNode/PracticeProblemsEditor/PracticeProblemEditor/PracticeProblemMetadataEditor/SourceMaterialIDsLink/SourceMaterialIDsLink'
import { notecardAtomFamily } from '@/atoms/notecard'
import { generateNotecardMnemonic } from '@/api/cb/search'
import { syncErrorMessage, syncSuccessMessage } from '@/utils/syncMessages'
import { Mnemonic } from 'common/src/notecards/types'

const { Option } = Select

export const NotecardMetadataEditor: React.FC<NotecardMetadataEditorProps> = (
    props
): ReactElement => {
    const notecardCategories = useRecoilValue(notecardCategoriesAtom)
    const isReadOnly = useRecoilValue(isActiveCourseReadOnlySelector)
    const notecard = useRecoilValue(notecardAtomFamily(props.notecardID))

    const setHints = useCallback(
        (newHints: string[]): void => {
            props.setContentCreatorEditableNotecardMetadata({
                ...props.contentCreatorEditableNotecardMetadata,
                hints: newHints,
            })
        },
        [props]
    )

    const setMnemonic = useCallback(
        (newMnemonic: Mnemonic): void => {
            props.setContentCreatorEditableNotecardMetadata({
                ...props.contentCreatorEditableNotecardMetadata,
                mnemonic: newMnemonic,
            })
        },
        [props]
    )

    const setCategories = useCallback(
        (newCategories: string[]): void => {
            props.setContentCreatorEditableNotecardMetadata({
                ...props.contentCreatorEditableNotecardMetadata,
                categories: newCategories,
            })
        },
        [props]
    )

    const setImportance = useCallback(
        (newImportance: NotecardPriority): void => {
            props.setContentCreatorEditableNotecardMetadata({
                ...props.contentCreatorEditableNotecardMetadata,
                importance: newImportance,
            })
        },
        [props]
    )

    const setIsPreviousExamQuestion = useCallback(
        (newIsPreviousExamQuestion: boolean): void => {
            props.setContentCreatorEditableNotecardMetadata({
                ...props.contentCreatorEditableNotecardMetadata,
                isPreviousExamQuestion: newIsPreviousExamQuestion,
            })
        },
        [props]
    )

    const setLearningObjectiveID = useCallback(
        (newLearningObjectiveID: string): void => {
            props.setContentCreatorEditableNotecardMetadata({
                ...props.contentCreatorEditableNotecardMetadata,
                learningObjectiveID: newLearningObjectiveID,
            })
        },
        [props]
    )

    const contentTreeState = useRecoilValue(contentTreeStateAtom)
    const hashedContentPath = useMemo(
        (): string => hashContentPath(props.contentPath),
        [props.contentPath]
    )
    const courseConfig = useRecoilValue(activeCourseConfigSelector)

    const [isNotecardCategoryModalVisible, setIsNotecardCategoryModalVisible] =
        useState<boolean>(false)

    const [isRegeneratingMnemonic, setIsRegeneratingMnemonic] =
        useState<boolean>(false)
    const regenerateMnemonic = useCallback(async (): Promise<void> => {
        setIsRegeneratingMnemonic(true)
        try {
            const response = await generateNotecardMnemonic({
                courseName: notecard.courseName,
                notecardID: props.notecardID,
            })
            if (!response.data.success) {
                syncErrorMessage('Failed to generate notecard mnemonic')
                return
            }
            syncSuccessMessage('Successfully generated notecard mnemonic')
            setMnemonic(response.data.payload)
        } finally {
            setIsRegeneratingMnemonic(false)
        }
    }, [notecard.courseName, props.notecardID, setMnemonic])
    return (
        <div className={styles.notecardMetadataEditor}>
            <CopyableID label="ID" id={props.notecardID} />
            <CopyableID label="Content Path" id={hashedContentPath} />
            {courseConfig.hasNotecardHints && (
                <div className={styles.metadatumEditor}>
                    <HintsEditor
                        hints={
                            props.contentCreatorEditableNotecardMetadata.hints
                        }
                        setHints={setHints}
                        isDisabled={isReadOnly}
                    />
                </div>
            )}

            <div className={styles.metadatumEditor}>
                <div className={styles.metadatumEditorTitle}>
                    Mnemonic Phrase:
                </div>
                <Input.TextArea
                    value={
                        props.contentCreatorEditableNotecardMetadata.mnemonic
                            ?.mnemonic
                    }
                    onChange={(e) =>
                        setMnemonic({
                            explanation:
                                props.contentCreatorEditableNotecardMetadata
                                    .mnemonic.explanation,
                            mnemonic: e.target.value,
                        })
                    }
                />
            </div>
            <div className={styles.metadatumEditor}>
                <div className={styles.metadatumEditorTitle}>
                    Mnemonic Explanation:
                </div>
                <Input.TextArea
                    value={
                        props.contentCreatorEditableNotecardMetadata.mnemonic
                            ?.explanation
                    }
                    onChange={(e) =>
                        setMnemonic({
                            mnemonic:
                                props.contentCreatorEditableNotecardMetadata
                                    .mnemonic.mnemonic,
                            explanation: e.target.value,
                        })
                    }
                />
            </div>

            <div className={styles.metadatumEditor}>
                <Button
                    onClick={regenerateMnemonic}
                    disabled={isReadOnly}
                    loading={isRegeneratingMnemonic}
                    type={'primary'}
                >
                    Regenerate Mnemonic
                </Button>
            </div>

            <div className={styles.metadatumEditor}>
                <div className={styles.metadatumEditorTitle}>Categories:</div>
                <Select
                    mode={'multiple'}
                    value={
                        props.contentCreatorEditableNotecardMetadata.categories
                    }
                    onChange={setCategories}
                    disabled={isReadOnly}
                >
                    {notecardCategories.map((notecardCategory) => (
                        <Option value={notecardCategory} key={notecardCategory}>
                            {notecardCategory}
                        </Option>
                    ))}
                </Select>
                <Button
                    icon={<PlusOutlined />}
                    onClick={() => setIsNotecardCategoryModalVisible(true)}
                    disabled={isReadOnly}
                />
            </div>
            <div className={styles.metadatumEditor}>
                <div className={styles.metadatumEditorTitle}>Importance:</div>
                <Select
                    value={
                        props.contentCreatorEditableNotecardMetadata.importance
                    }
                    onChange={setImportance}
                    disabled={isReadOnly}
                >
                    {Object.values(NotecardPriority).map((notecardPriority) => (
                        <Option value={notecardPriority} key={notecardPriority}>
                            {_.startCase(_.toLower(notecardPriority))}
                        </Option>
                    ))}
                </Select>
            </div>
            {courseConfig.hasPreviousExamQuestionToggle && (
                <div className={styles.metadatumEditor}>
                    <div className={styles.metadatumEditorTitle}>
                        Is Previous Exam Question?{' '}
                    </div>
                    <Switch
                        checkedChildren={'Yes'}
                        unCheckedChildren={'No'}
                        checked={
                            props.contentCreatorEditableNotecardMetadata
                                .isPreviousExamQuestion
                        }
                        onChange={setIsPreviousExamQuestion}
                        disabled={isReadOnly}
                    />
                </div>
            )}
            {courseConfig.hasLearningObjectives && (
                <ContentLearningObjectiveEditor
                    learningObjectives={contentTreeState.topicToLearningObjectivesMap.get(
                        props.contentPath[0]
                    )}
                    localLearningObjectiveID={
                        props.contentCreatorEditableNotecardMetadata
                            .learningObjectiveID
                    }
                    setLocalLearningObjectiveID={setLearningObjectiveID}
                />
            )}
            {notecard?.relevantSourceMaterialIDs?.length ? (
                <SourceMaterialIDLinks
                    sourceMaterialIDs={notecard.relevantSourceMaterialIDs}
                    contentPath={props.contentPath}
                />
            ) : null}
            <Modal
                open={isNotecardCategoryModalVisible}
                onCancel={() => setIsNotecardCategoryModalVisible(false)}
                destroyOnClose={true}
                footer={null}
            >
                <React.Suspense
                    fallback={<LoadingComponent useWhiteBackground={true} />}
                >
                    <NotecardCategories />
                </React.Suspense>
            </Modal>
        </div>
    )
}
