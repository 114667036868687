import styles from './DraggableIcon.module.less'
import React, { ReactElement } from 'react'
import { DraggableIconData } from './DraggableIcon.types'
import { useDescriptionForContent } from '@/hooks/useDescriptionForContent'

export const DraggableIcon: React.FC<DraggableIconData> = (
    props
): ReactElement => {
    const description = useDescriptionForContent(props)

    return (
        <div
            className={`${styles.draggableIcon} ${
                props.hasUnsavedChanges ? styles.hasUnsavedChanges : ''
            }`}
        >
            {description}
        </div>
    )
}
