import { ContentPath } from 'common/src/ContentPath'
import { CourseConfig, CourseConfigType } from 'common/src/courseConfig'
import { PracticeProblemType } from 'common/src/practiceProblems'
import { IGenericPracticeProblem } from 'common/src/practiceProblems/types'
import { nanoid } from 'nanoid'
import assertNever from 'assert-never/index'
import { buildSimpleEditorStateStringWithText } from 'magic-text-box-lexical/src/mocks/buildSimpleEditorStateStringWithText'

/**
 * Also used for sub-problems
 */
export const createNewDefaultPracticeProblem = (
    currentNumPracticeProblems: number,
    contentPath: ContentPath,
    courseName: string,
    courseConfig: CourseConfig
): IGenericPracticeProblem => {
    const basePracticeProblem = {
        id: nanoid(),
        index: currentNumPracticeProblems,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        type: courseConfig.practiceProblemTypes[0],
        isPreviousExamQuestion: false,
        isGoldStandard: false,
        isMathematical: true,
        isConceptual: false,
        isSynthesis: false,
        isSubProblem: false,
        isNotecardProblem: false,
        topic: 'INSERT TOPIC HERE',
        points: courseConfig.defaultPracticeProblemPoints,
        courseName: courseName,
        contentPath: contentPath,
        question: buildSimpleEditorStateStringWithText('Insert question here'),
        solution: buildSimpleEditorStateStringWithText('Insert solution here'),
        questionLanguage: courseConfig.questionLanguages[0],
    }

    switch (courseConfig.type) {
        case CourseConfigType.fsaHealth:
            return {
                ...basePracticeProblem,
                type: PracticeProblemType.SHORT_ANSWER,
                hints: [],
            }
        case CourseConfigType.pharmacyTechnician:
            return {
                ...basePracticeProblem,
                type: PracticeProblemType.MULTIPLE_CHOICE,
                choices: [],
                explanation: undefined,
                shouldShuffleAnswers: true,
            }
        default:
            return assertNever(courseConfig.type)
    }
}
