import { APIResponseWrapper, CB_BASE_UNAUTHENTICATED_PREFIX } from '@/api/types'
import { callBackend } from '@/api/callBackend'
import { ApiMethod } from 'common/src/api/types'
import { GetSignedUrlAndS3KeyResponse } from 'common/src/api/s3/responses'

const PREFIX = CB_BASE_UNAUTHENTICATED_PREFIX + '/publicS3'

export const getSignedUrlForResumeUpload =
    async (): APIResponseWrapper<GetSignedUrlAndS3KeyResponse> => {
        return await callBackend<null, GetSignedUrlAndS3KeyResponse>(
            PREFIX + '/getSignedUrlForResumeUpload',
            ApiMethod.GET,
            null
        )
    }
