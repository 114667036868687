import React, { ReactElement, useState } from 'react'
import { AreYouSureButtonProps } from '@/components/utils/AreYouSureButton/AreYouSureButton.types'
import { Button, Modal } from 'antd'

export const AreYouSureButton: React.FC<AreYouSureButtonProps> = (
    props
): ReactElement => {
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isAreYouSureModalVisible, setIsAreYouSureModalVisible] =
        useState<boolean>(false)

    return (
        <>
            <Modal
                open={isAreYouSureModalVisible}
                onOk={(e) => {
                    e.stopPropagation()
                    setIsLoading(true)
                    props.performAction().then(() => {
                        setIsLoading(false)
                        setIsAreYouSureModalVisible(false)
                    })
                }}
                closable={false}
                okText={props.okText}
                cancelText={props.cancelText}
                okButtonProps={{ type: 'primary' }}
                confirmLoading={isLoading}
                onCancel={(e) => {
                    e.stopPropagation()
                    setIsAreYouSureModalVisible(false)
                }}
            >
                {props.areYouSureContent}
            </Modal>
            <div>
                <Button
                    type={'primary'}
                    onClick={(e) => {
                        e.stopPropagation()
                        if (!isLoading) setIsAreYouSureModalVisible(true)
                    }}
                    disabled={props.disabled}
                >
                    {props.buttonText}
                </Button>
            </div>
        </>
    )
}
