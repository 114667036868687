import React, { ReactElement } from 'react'
import { SelectContentPathProps } from '@/components/utils/SelectContentPath/SelectContentPath.types'
import { Select } from 'antd'
import { getContentTreeChildForContentPath } from '@/utils/getContentTreeChildForContentPath'

const { Option } = Select

export const SelectContentPath: React.FC<SelectContentPathProps> = (
    props
): ReactElement => {
    return (
        <div className={'select-content-path'}>
            <div className={'select-content-path-item'}>
                <div className={'select-content-path-item-title'}>
                    {props.contentTreeState.contentTierTitles[0]}
                </div>
                <Select
                    value={props.contentPath[0]}
                    onChange={(newValue: string) =>
                        props.setContentPath([newValue])
                    }
                >
                    {props.contentTreeState.contentTree.children.map(
                        (child) => (
                            <Option value={child.id} key={child.id}>
                                {child.name}
                            </Option>
                        )
                    )}
                </Select>
            </div>
            {props.maxDepth >= 2 && (
                <div className={'select-content-path-item'}>
                    <div className={'select-content-path-item-title'}>
                        {props.contentTreeState.contentTierTitles[1]}
                    </div>
                    <Select
                        value={props.contentPath[1]}
                        onChange={(newValue: string) =>
                            props.setContentPath([
                                props.contentPath[0],
                                newValue,
                            ])
                        }
                    >
                        {props.contentPath[0] &&
                            getContentTreeChildForContentPath(
                                props.contentTreeState.contentTree,
                                props.contentPath.slice(0, 1)
                            )?.children.map((child) => (
                                <Option value={child.id} key={child.id}>
                                    {child.name}
                                </Option>
                            ))}
                    </Select>
                </div>
            )}
            {props.maxDepth >= 3 && (
                <div className={'select-content-path-item'}>
                    <div className={'select-content-path-item-title'}>
                        {props.contentTreeState.contentTierTitles[2]}
                    </div>
                    <Select
                        value={props.contentPath[2]}
                        onChange={(newValue: string) =>
                            props.setContentPath([
                                props.contentPath[0],
                                props.contentPath[1],
                                newValue,
                            ])
                        }
                    >
                        {props.contentPath[1] &&
                            getContentTreeChildForContentPath(
                                props.contentTreeState.contentTree,
                                props.contentPath.slice(0, 2)
                            )?.children.map((child) => (
                                <Option value={child.id} key={child.id}>
                                    {child.name}
                                </Option>
                            ))}
                    </Select>
                </div>
            )}
        </div>
    )
}
