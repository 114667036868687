import React, { ReactElement } from 'react'
import { DefensiveDeleteButton } from '@/components/utils/DefensiveDeleteButton/DefensiveDeleteButton'

interface DeleteSourceMaterialButtonProps {
    deleteSourceMaterial: () => Promise<void>
}

export const DeleteSourceMaterialButton: React.FC<
    DeleteSourceMaterialButtonProps
> = (props): ReactElement => {
    return (
        <div>
            <DefensiveDeleteButton
                onDelete={props.deleteSourceMaterial}
                title={'Warning: Dangerous Action'}
                areYouSureContent={
                    'Are you sure you want to delete this source material? This cannot be undone.'
                }
                okText={'Yes, delete source material'}
                cancelText={'Cancel'}
                buttonText={'Delete selected source material'}
                placement={'right'}
            />
        </div>
    )
}
