import { atom, selector } from 'recoil'
import { getNotecardsWithoutPracticeProblemForCourse } from '@/api/cb/contentCreatorContent'
import { frontendDisplayedCourseSelector } from '@/atoms/auth'

export const notecardsWithoutPracticeProblemInvalidatorAtom = atom<number>({
    key: 'notecardsWithoutPracticeProblemInvalidatorAtom',
    default: 0,
})

export const notecardsWithoutPracticeProblemNonMemoizing = atom<string[]>({
    key: 'notecardsWithoutPracticeProblemNonMemoizing',
    default: selector<string[]>({
        key: 'notecardsWithoutPracticeProblemNonMemoizingFetcher',
        get: async ({ get }) => {
            get(notecardsWithoutPracticeProblemInvalidatorAtom)
            return (
                await getNotecardsWithoutPracticeProblemForCourse({
                    courseName: get(frontendDisplayedCourseSelector),
                })
            ).data.payload.notecardsWithoutPracticeProblem
        },
    }),
})
