import { atom, selector, selectorFamily } from 'recoil'
import {
    getPracticeProblemCategoryStatsForCourse,
    getPracticeProblemCategoriesForCourse,
} from '@/api/cb/contentCreatorContent'
import { frontendDisplayedCourseSelector } from '@/atoms/auth'
import { PracticeProblemCategoryStats } from 'common/src/api/contentFrontendVsContentBackend/responses'

export const practiceProblemCategoryStatsInvalidatorAtom = atom<number>({
    key: 'practiceProblemCategoryStatsInvalidatorAtom',
    default: 0,
})

export const practiceProblemCategoryStatsAtomNonMemoizing =
    atom<PracticeProblemCategoryStats>({
        key: 'practiceProblemCategoryStatsAtomNonMemoizing',
        default: selector<PracticeProblemCategoryStats>({
            key: 'practiceProblemCategoryStatsAtomNonMemoizingFetcher',
            get: async ({ get }) => {
                get(practiceProblemCategoryStatsInvalidatorAtom)
                return (
                    await getPracticeProblemCategoryStatsForCourse({
                        courseName: get(frontendDisplayedCourseSelector),
                    })
                ).data.payload.practiceProblemCategoryStats
            },
        }),
    })

export const practiceProblemIDsForCategorySelector = selectorFamily<
    string[],
    string
>({
    key: 'practiceProblemIDsForCategorySelector',
    get:
        (category) =>
        ({ get }) => {
            return get(practiceProblemCategoryStatsAtomNonMemoizing)[category]
        },
})

export const practiceProblemCategoriesInvalidatorAtom = atom<number>({
    key: 'practiceProblemCategoriesInvalidatorAtom',
    default: 0,
})
export const practiceProblemCategoriesAtom = atom<string[]>({
    key: 'practiceProblemCategoriesAtom',
    default: selector<string[]>({
        key: 'practiceProblemCategoriesFetcher',
        get: async ({ get }) => {
            get(practiceProblemCategoriesInvalidatorAtom)
            return (
                await getPracticeProblemCategoriesForCourse({
                    courseName: get(frontendDisplayedCourseSelector),
                })
            ).data.payload.practiceProblemCategories
        },
    }),
})
