import { CB_BASE_PREFIX } from '@/api/types'
import {
    ConvertImageToTextRequest,
    ConvertImageToTextResponse,
} from 'magic-text-box-lexical/src/components/MagicTextBoxCore/MagicTextBoxCore.types'
import { callBackend } from '@/api/callBackend'
import { ApiMethod } from 'common/src/api/types'
import { syncErrorMessage } from '@/utils/syncMessages'

const PREFIX = CB_BASE_PREFIX + '/llmUtils'

export const convertImageToText = async (
    payload: ConvertImageToTextRequest
): Promise<ConvertImageToTextResponse> => {
    const response = await callBackend<
        ConvertImageToTextRequest,
        ConvertImageToTextResponse
    >(PREFIX + '/convertImageToText', ApiMethod.POST, payload)
    if (!response.data.success) {
        syncErrorMessage(
            `Error converting image to text: ${response.data.error}`,
            10
        )
    }
    return response.data.payload
}
