import { atom, selectorFamily } from 'recoil'
import { Set } from 'immutable'

export const newlyCreatedAndUnsavedPracticeProblemIDsAtom = atom<Set<string>>({
    key: 'newlyCreatedAndUnsavedPracticeProblemIDsAtom',
    default: Set(),
})

export const isUnsavedPracticeProblemSelectorFamily = selectorFamily<
    boolean,
    string
>({
    key: 'isUnsavedPracticeProblemSelectorFamily',
    get:
        (id: string) =>
        ({ get }) =>
            get(newlyCreatedAndUnsavedPracticeProblemIDsAtom).has(id),
})

export const newlyCreatedAndUnsavedNotecardIDsAtom = atom<Set<string>>({
    key: 'newlyCreatedAndUnsavedNotecardIDsAtom',
    default: Set(),
})

export const isUnsavedNotecardSelectorFamily = selectorFamily<boolean, string>({
    key: 'isUnsavedNotecardSelectorFamily',
    get:
        (id: string) =>
        ({ get }) =>
            get(newlyCreatedAndUnsavedNotecardIDsAtom).has(id),
})

export const newlyCreatedAndUnsavedSourceMaterialIDsAtom = atom<Set<string>>({
    key: 'newlyCreatedAndUnsavedSourceMaterialIDsAtom',
    default: Set(),
})

export const isUnsavedSourceMaterialSelectorFamily = selectorFamily<
    boolean,
    string
>({
    key: 'isUnsavedSourceMaterialSelectorFamily',
    get:
        (id: string) =>
        ({ get }) =>
            get(newlyCreatedAndUnsavedSourceMaterialIDsAtom).has(id),
})
