import styles from './ContentLearningObjectiveEditor.module.less'
import React, { ReactElement } from 'react'
import { ContentLearningObjectiveEditorProps } from './ContentLearningObjectiveEditor.types'
import { Select } from 'antd'
import { useRecoilValue } from 'recoil'
import { isActiveCourseReadOnlySelector } from '@/atoms/auth'

const { Option } = Select

export const ContentLearningObjectiveEditor: React.FC<
    ContentLearningObjectiveEditorProps
> = (props): ReactElement => {
    const isReadOnly = useRecoilValue(isActiveCourseReadOnlySelector)

    return (
        <div className={styles.learningObjectiveEditor}>
            <div className={styles.learningObjectiveEditorTitle}>
                Learning Objective:{' '}
            </div>
            <Select
                value={props.localLearningObjectiveID}
                onChange={props.setLocalLearningObjectiveID}
                disabled={isReadOnly}
                placement={'topLeft'}
            >
                {props.learningObjectives
                    .sort((a, b) => a.index - b.index)
                    .map((learningObjective) => (
                        <Option
                            value={learningObjective.id}
                            key={learningObjective.id}
                        >
                            {learningObjective.description}
                        </Option>
                    ))}
            </Select>
        </div>
    )
}
