import { ContentTreeState } from '@/atoms/contentTree'
import { ContentNodeMoveType } from 'common/src/api/contentFrontendVsContentBackend/requests'
import { ContentPath } from 'common/src/ContentPath'
import produce from 'immer'
import { getContentTreeChildForContentPath } from '@/utils/getContentTreeChildForContentPath'
import assertNever from 'assert-never/index'
import { move } from 'common/src/utils/move'
import assert from 'assert'

export const moveContentNodeFrontend = produce(
    (
        contentTreeState: ContentTreeState,
        contentPath: ContentPath,
        direction: ContentNodeMoveType
    ): void => {
        const childTree = getContentTreeChildForContentPath(
            contentTreeState.contentTree,
            contentPath.slice(0, contentPath.length - 1)
        )

        const siblings = childTree.children.map((child) => child.id)
        const startingIndex = siblings.indexOf(
            contentPath[contentPath.length - 1]
        )
        switch (direction) {
            case 'up':
                assert(startingIndex > 0)
                childTree.children = move(
                    childTree.children,
                    startingIndex,
                    startingIndex - 1
                )
                return
            case 'down':
                assert(startingIndex < childTree.children.length - 1)
                childTree.children = move(
                    childTree.children,
                    startingIndex,
                    startingIndex + 1
                )
                return
            default:
                assertNever(direction)
        }
    }
)
