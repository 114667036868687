import styles from './NotecardCategory.module.less'
import React, { ReactElement } from 'react'
import { NotecardIDsList } from '@/components/NotecardCategories/NotecardCategory/NotecardIDsList/NotecardIDsList'
import CollapsePanel from 'antd/lib/collapse/CollapsePanel'
import { DefensiveDeleteButton } from '@/components/utils/DefensiveDeleteButton/DefensiveDeleteButton'
import { deleteNotecardCategoryForCourse } from '@/api/cb/contentCreatorContent'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { frontendDisplayedCourseSelector } from '@/atoms/auth'
import { syncErrorMessage, syncSuccessMessage } from '@/utils/syncMessages'
import {
    notecardCategoriesInvalidatorAtom,
    notecardCategoryStatsInvalidatorAtom,
    notecardIDsForCategorySelector,
} from '@/atoms/notecardCategories'
import { useInvalidateNotecards } from '@/atoms/notecard'

interface NotecardCategoryProps {
    category: string
    count: number
}

export const NotecardCategory: React.FC<NotecardCategoryProps> = (
    props
): ReactElement => {
    const { category, count, ...restProps } = props

    const courseName = useRecoilValue(frontendDisplayedCourseSelector)

    const invalidateNotecardCategories = useSetRecoilState(
        notecardCategoriesInvalidatorAtom
    )
    const invalidateNotecardCategoryStats = useSetRecoilState(
        notecardCategoryStatsInvalidatorAtom
    )
    const notecardIDs = useRecoilValue(
        notecardIDsForCategorySelector(props.category)
    )
    const invalidateNotecards = useInvalidateNotecards(notecardIDs)
    const deleteNotecardCategory = async (): Promise<void> => {
        const response = await deleteNotecardCategoryForCourse({
            courseName,
            notecardCategory: category,
        })
        if (response.data.isError) {
            syncErrorMessage(
                `Failed to delete notecard category: ${category}. Please refresh the page and try again.`
            )
            return
        }

        syncSuccessMessage(
            `Successfully deleted notecard category: ${category}`
        )
        invalidateNotecardCategories((x) => x + 1)
        invalidateNotecardCategoryStats((x) => x + 1)
        invalidateNotecards()
    }

    return (
        <CollapsePanel
            {...restProps}
            key={category}
            header={
                <div className={styles.notecardCategoryPanelHeader}>
                    <div className={styles.notecardCategoryPanelHeaderLeftSide}>
                        <span className={styles.notecardCategoryTitle}>
                            {category}:
                        </span>
                        <span>{count}</span>
                    </div>
                    <DefensiveDeleteButton
                        onDelete={deleteNotecardCategory}
                        areYouSureContent={
                            <p>
                                Are you sure you want to delete the notecard
                                category <b>{category}</b>? This will remove the
                                category from all <b>{count}</b> notecards that
                                currently have this category set.
                            </p>
                        }
                        okText={'Delete'}
                        cancelText={'Cancel'}
                        title={'Are you sure?'}
                    />
                </div>
            }
            className={styles.notecardCategory}
        >
            <NotecardIDsList notecardIDs={notecardIDs} />
        </CollapsePanel>
    )
}
