import styles from './AddPracticeProblemCategory.module.less'
import React, { ReactElement, useState, useCallback } from 'react'
import { Input, Button } from 'antd'
import { PlusCircleOutlined } from '@ant-design/icons'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import {
    practiceProblemCategoriesAtom,
    practiceProblemCategoriesInvalidatorAtom,
    practiceProblemCategoryStatsInvalidatorAtom,
} from '@/atoms/practiceProblemCategories'
import { addPracticeProblemCategoryForCourse } from '@/api/cb/contentCreatorContent'
import {
    frontendDisplayedCourseSelector,
    isActiveCourseReadOnlySelector,
} from '@/atoms/auth'
import { syncSuccessMessage, syncErrorMessage } from '@/utils/syncMessages'

interface AddPracticeProblemCategoryProps {
    closeModal: () => void
}

export const AddPracticeProblemCategory: React.FC<
    AddPracticeProblemCategoryProps
> = (props): ReactElement => {
    const isReadOnly = useRecoilValue(isActiveCourseReadOnlySelector)
    const [newPracticeProblemCategory, setNewPracticeProblemCategory] =
        useState<string>('')
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const practiceProblemCategories = useRecoilValue(
        practiceProblemCategoriesAtom
    )
    const courseName = useRecoilValue(frontendDisplayedCourseSelector)
    const invalidatePracticeProblemCategories = useSetRecoilState(
        practiceProblemCategoriesInvalidatorAtom
    )
    const invalidatePracticeProblemCategoryStats = useSetRecoilState(
        practiceProblemCategoryStatsInvalidatorAtom
    )

    const handleError = useCallback((error: string): void => {
        syncErrorMessage(error)
        setIsLoading(false)
    }, [])

    const tryAddPracticeProblemCategory = async (): Promise<void> => {
        setIsLoading(true)

        if (!newPracticeProblemCategory?.length) {
            handleError('No practiceProblem category set')
            return
        }

        if (practiceProblemCategories.includes(newPracticeProblemCategory)) {
            handleError(
                `PracticeProblem category: ${newPracticeProblemCategory} already exists.`
            )
            return
        }

        const response = await addPracticeProblemCategoryForCourse({
            courseName,
            practiceProblemCategory: newPracticeProblemCategory,
        })
        if (response.data.isError) {
            handleError(`Something unexpected happened: ${response.data.error}`)
            return
        }

        props.closeModal()
        syncSuccessMessage(
            `Successfully added practiceProblem category: ${newPracticeProblemCategory}`
        )
        invalidatePracticeProblemCategories((x) => x + 1)
        invalidatePracticeProblemCategoryStats((x) => x + 1)
        setIsLoading(false)
    }

    return (
        <div className={styles.addPracticeProblemCategory}>
            <div className={styles.addPracticeProblemCategoryTitle}>
                Add PracticeProblem Category
            </div>
            <div className={styles.addPracticeProblemCategoryBody}>
                <Input
                    placeholder={'Enter new practiceProblem category here'}
                    value={newPracticeProblemCategory}
                    onChange={(event) =>
                        setNewPracticeProblemCategory(event.target.value)
                    }
                    onPressEnter={tryAddPracticeProblemCategory}
                />
                <Button
                    onClick={tryAddPracticeProblemCategory}
                    loading={isLoading}
                    disabled={!newPracticeProblemCategory.length || isReadOnly}
                >
                    <PlusCircleOutlined /> Add
                </Button>
            </div>
        </div>
    )
}
