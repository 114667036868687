import {
    IGenericPracticeProblem,
    IMultiPartProblemBase,
    IProblemMetadata,
} from 'common/src/practiceProblems/types'
import { ContentPath } from 'common/src/ContentPath'
import { isProblemMultiPart } from 'common/src/practiceProblems/typeguards'

export type ContentCreatorEditablePracticeProblemMetadata = Pick<
    IProblemMetadata,
    | 'type'
    | 'isPreviousExamQuestion'
    | 'previousExamID'
    | 'isGoldStandard'
    | 'isMathematical'
    | 'isConceptual'
    | 'isSynthesis'
    | 'hints'
    | 'topic'
    | 'points'
    | 'learningObjectiveID'
    | 'questionLanguage'
    | 'categories'
    | 'difficultyScore'
> & { isSubProblem: boolean }

export const parseContentCreatorEditablePracticeProblemMetadata = (
    practiceProblem: IGenericPracticeProblem,
    superProblem: IMultiPartProblemBase | null
): ContentCreatorEditablePracticeProblemMetadata => {
    const isMultiPart = isProblemMultiPart(practiceProblem)

    return {
        type: practiceProblem.type,
        // decided by multi-part problem if sub-problem
        isPreviousExamQuestion: superProblem
            ? superProblem.isPreviousExamQuestion
            : practiceProblem.isPreviousExamQuestion,
        // decided by multi-part problem if sub-problem
        isGoldStandard: superProblem
            ? superProblem.isGoldStandard
            : practiceProblem.isGoldStandard,
        // decided by "logical any" of subproblems if multi-part problem
        isMathematical: isMultiPart
            ? practiceProblem.subProblems.some(
                  (subProblem) => subProblem.isMathematical
              )
            : practiceProblem.isMathematical,
        // decided by "logical any" of subproblems if multi-part problem
        isConceptual: isMultiPart
            ? practiceProblem.subProblems.some(
                  (subProblem) => subProblem.isConceptual
              )
            : practiceProblem.isConceptual,
        // decided by "logical any" of subproblems if multi-part problem
        isSynthesis: isMultiPart
            ? practiceProblem.subProblems.some(
                  (subProblem) => subProblem.isSynthesis
              )
            : practiceProblem.isSynthesis,
        hints: practiceProblem.hints,
        topic: practiceProblem.topic,
        points: practiceProblem.points,
        learningObjectiveID: practiceProblem.learningObjectiveID,
        isSubProblem: !!superProblem,
        previousExamID: practiceProblem.previousExamID,
        questionLanguage: practiceProblem.questionLanguage,
        categories: practiceProblem.categories,
        difficultyScore: practiceProblem.difficultyScore,
    }
}

export interface PracticeProblemMetadataEditorProps {
    contentCreatorEditablePracticeProblemMetadata: ContentCreatorEditablePracticeProblemMetadata
    setContentCreatorEditablePracticeProblemMetadata: (
        contentCreatorEditablePracticeProblemMetadata: ContentCreatorEditablePracticeProblemMetadata
    ) => void
    shouldDisableMultiPartProblem: boolean
    contentPath: ContentPath
    practiceProblemID: string
}
