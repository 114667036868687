import { logger } from '@/logging/FrontendLogger'

export const magicTextBoxErrorHandler = (
    error: Error,
    namespace: string
): void => {
    logger.error(
        `[${namespace}] Something went wrong with magic text box: ${error}`
    )
}
