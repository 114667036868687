import styles from './DeletePracticeProblemButton.module.less'
import React, { ReactElement } from 'react'
import { DeletePracticeProblemButtonProps } from '@/components/ContentTreeNode/ContentTreeLeafNode/PracticeProblemsEditor/DeletePracticeProblemButton/DeletePracticeProblemButton.types'
import { DefensiveDeleteButton } from '@/components/utils/DefensiveDeleteButton/DefensiveDeleteButton'

export const DeletePracticeProblemButton: React.FC<
    DeletePracticeProblemButtonProps
> = (props): ReactElement => {
    return (
        <div className={styles.deletePracticeProblemButtonContainer}>
            <DefensiveDeleteButton
                onDelete={props.deletePracticeProblem}
                title={'Warning: Dangerous Action'}
                areYouSureContent={
                    'Are you sure you want to delete this practice problem? This cannot be undone.'
                }
                okText={'Yes, delete problem'}
                cancelText={'Cancel'}
                buttonText={'Delete selected problem'}
                placement={'right'}
            />
        </div>
    )
}
