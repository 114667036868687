import React, { ReactElement } from 'react'
import { GetFormulaSheetEntriesResponse } from 'common/src/formulaSheet/types'
import styles from './FormulaSheetHTMLMule.module.scss'
import { useRecoilValue } from 'recoil'
import { MagicTextBoxReadOnly } from 'magic-text-box-lexical/src/components/MagicTextBoxReadOnly/MagicTextBoxReadOnly'
import { frontendDisplayedCourseSelector } from '@/atoms/auth'
import { courseInfoAtom } from '@/atoms/courseInfo'
import { getSignedUrlForS3Key } from '@/api/cb/s3'
import {
    accessUniversalContentLinkNoOp,
    mtbReadOnlyOptionsNoOp,
    practiceProblemLinkPropsNoOp,
} from '@/utils/mtbNoOps'
import { useLogger } from '@/hooks/useLogger'

export const FormulaSheetHTMLMule: React.FC<GetFormulaSheetEntriesResponse> = (
    props
): ReactElement => {
    const courseName = useRecoilValue(frontendDisplayedCourseSelector)
    const courseInfo = useRecoilValue(courseInfoAtom(courseName))
    const logger = useLogger(FormulaSheetHTMLMule.name)
    return (
        <div style={{ padding: '24px' }}>
            <div
                className={styles.overallTitle}
            >{`${courseInfo.prettyName} Formula Sheet`}</div>
            <div className={styles.body}>
                {props.formulaSheetEntries.map((formulaSheetEntry) => {
                    return (
                        <div
                            className={`${styles.formulaSheetEntry} ${
                                formulaSheetEntry.shouldForcePageBreakBefore
                                    ? styles.shouldForcePageBreakBefore
                                    : ''
                            }`}
                            key={formulaSheetEntry.id}
                        >
                            <div
                                className={styles.title}
                                key={formulaSheetEntry.id}
                            >
                                {formulaSheetEntry.title}
                            </div>
                            {formulaSheetEntry.description && (
                                <div
                                    className={styles.description}
                                    key={formulaSheetEntry.id}
                                >
                                    {formulaSheetEntry.description}
                                </div>
                            )}
                            <MagicTextBoxReadOnly
                                initialEditorState={
                                    formulaSheetEntry.formulaEditorState
                                }
                                practiceProblemLinkProps={
                                    practiceProblemLinkPropsNoOp
                                }
                                getPreSignedUrlForS3Key={getSignedUrlForS3Key}
                                onError={(error) =>
                                    logger.error(
                                        `Error occurred in formula sheet html mule: ${error}`
                                    )
                                }
                                options={mtbReadOnlyOptionsNoOp}
                                accessUniversalContentLink={
                                    accessUniversalContentLinkNoOp
                                }
                            />
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
