export interface FileUploaderProps {
    allowedFileTypes: string[]
    handleFileUpload: (dataURL: string) => void
    // TODO: can extend this later if we want to
    buttonText: string
    className?: string | undefined
    disabledMessage?: string
    uploadedFileMessage?: string
}

export enum DraggedOverState {
    dragging = 'dragging',
    notDragging = 'notDragging',
    error = 'error',
}
