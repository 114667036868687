import React, {
    ReactElement,
    useState,
    useCallback,
    useRef,
    useMemo,
} from 'react'
import { Button, Form, Input, notification } from 'antd'
import { reusableCssClass } from '@/utils/reusableCssClasses'
import styles from './ForgotPasswordForm.module.less'
import {
    ForgotPasswordFormProps,
    IForgotPasswordForm,
} from '@/components/authentication/ForgotPasswordForm/ForgotPasswordForm.types'
import {
    clearAuthenticationError,
    handleAuthenticationError,
} from '@/components/authentication/handleAuthenticationError'
import { NotificationKeys } from '@/utils/NotificationKeys'
import { useIsSingleColumn } from '@/hooks/useIsSingleColumn'
import { sendPasswordRecoveryEmail } from '@/api/cb/contentFrontendAuthentication'

const handleChange = (): void => {
    clearAuthenticationError()
}
export const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = (
    props
): ReactElement => {
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const handleSubmit = useCallback(
        async (values: IForgotPasswordForm) => {
            setIsLoading(true)
            clearAuthenticationError()

            const response = await sendPasswordRecoveryEmail(
                values.forgotPasswordEmail
            )
            if (!response.data.success) {
                handleAuthenticationError(response.data.error, {
                    message: 'Issue recovering password',
                    description:
                        'Something went wrong trying to recover your password. Please refresh the page and try again.',
                })
            } else {
                notification.success({
                    message: 'Email successfully sent',
                    description:
                        'Be sure to follow the instructions in the email to recover your password',
                    duration: 10, // seconds
                    onClick: () => {
                        notification.destroy(
                            NotificationKeys.PASSWORD_RECOVERY_EMAIL_SUCCESSFULLY_SENT
                        )
                    },
                    className: reusableCssClass.clickMe,
                    key: NotificationKeys.PASSWORD_RECOVERY_EMAIL_SUCCESSFULLY_SENT,
                })
                props.changeToLogin()
            }
            setIsLoading(false)
        },
        [props]
    )

    const forgotPasswordContainerRef = useRef<HTMLDivElement>(null)
    const loginButtonContainerRef = useRef<HTMLDivElement>(null)
    const isSingleColumn = useIsSingleColumn(
        forgotPasswordContainerRef,
        loginButtonContainerRef
    )
    const columnClassName = useMemo(
        (): string =>
            isSingleColumn ? styles.isSingleColumn : styles.isDualColumn,
        [isSingleColumn]
    )

    return (
        <div className={styles.forgotPasswordForm}>
            <Form onFinish={handleSubmit} onChange={handleChange}>
                <div className={styles.forgotPasswordInstructions}>
                    <Form.Item>
                        <span>
                            Please enter the email associated with your account
                        </span>
                    </Form.Item>
                </div>
                <Form.Item
                    name={'forgotPasswordEmail'}
                    hasFeedback
                    rules={[
                        { required: true, message: 'Email is required' },
                        {
                            type: 'email',
                            message: 'Please enter a valid email address',
                        },
                    ]}
                >
                    <Input placeholder="Email" />
                </Form.Item>
                <div className={styles.forgotPasswordAndLoginButtonContainer}>
                    <div
                        className={`${reusableCssClass.clickableText} ${styles.forgotPasswordContainer}`}
                        onClick={props.changeToLogin}
                    >
                        Return to Login
                    </div>
                    <Form.Item>
                        <div
                            className={`${styles.btnContainer} ${columnClassName}`}
                            ref={loginButtonContainerRef}
                        >
                            <Button
                                type={'primary'}
                                htmlType={'submit'}
                                id={'cypress-send-recovery-email-button'}
                                loading={isLoading}
                            >
                                Send Recovery Email
                            </Button>
                        </div>
                    </Form.Item>
                </div>
            </Form>
        </div>
    )
}
