import { Button, DatePicker } from 'antd'
import React, { ReactElement, useCallback, useState, useMemo } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import styles from './NextSittingDateEditor.module.less'
import { NextSittingDateEditorProps } from '@/components/EditCourseMetadata/NextSittingDateEditor/NextSittingDateEditor.types'
import { nextSittingDateSelector } from '@/atoms/nextSittingDate'
import { setNextSittingDateForCourse } from '@/api/cb/contentCreatorContent'
import {
    frontendDisplayedCourseSelector,
    isActiveCourseReadOnlySelector,
} from '@/atoms/auth'
import { syncErrorMessage, syncSuccessMessage } from '@/utils/syncMessages'
import dayjs, { Dayjs } from 'dayjs'

// see https://github.com/ant-design/ant-design/issues/26190#issuecomment-703673400
import weekday from 'dayjs/plugin/weekday'
import localeData from 'dayjs/plugin/localeData'

dayjs.extend(weekday)
dayjs.extend(localeData)

export const NextSittingDateEditor: React.FC<
    NextSittingDateEditorProps
> = (): ReactElement => {
    const courseName = useRecoilValue(frontendDisplayedCourseSelector)
    const isReadOnly = useRecoilValue(isActiveCourseReadOnlySelector)

    const [nextSittingDate, setNextSittingDate] = useRecoilState(
        nextSittingDateSelector(courseName)
    )
    const [currentNextSittingDate, setCurrentNextSittingDate] =
        useState<Dayjs>(nextSittingDate)
    const hasUnsavedChanges = useMemo(
        (): boolean =>
            currentNextSittingDate.toDate().getTime() !==
            nextSittingDate.toDate().getTime(),
        [nextSittingDate, currentNextSittingDate]
    )

    const [
        isSetNextSittingDateButtonLoading,
        setIsSetNextSittingDateButtonLoading,
    ] = useState<boolean>(false)
    const saveNextSittingDate = useCallback(async (): Promise<void> => {
        setIsSetNextSittingDateButtonLoading(true)

        const response = await setNextSittingDateForCourse(
            courseName,
            nextSittingDate.toDate().toUTCString()
        )
        if (response.data.isError) {
            syncErrorMessage(
                'Error updating next sitting date. You may want to try setting it again or refreshing the page.'
            )
        } else {
            setCurrentNextSittingDate(nextSittingDate)
            syncSuccessMessage(
                `Successfully updated next sitting date to: ${nextSittingDate
                    .toDate()
                    .toDateString()}`
            )
        }

        setIsSetNextSittingDateButtonLoading(false)
    }, [courseName, nextSittingDate])

    return (
        <div className={styles.nextSittingDateEditor}>
            <div className={styles.nextSittingDateEditorText}>
                Next sitting date:
            </div>
            <DatePicker
                value={nextSittingDate}
                onChange={(newDate) =>
                    setNextSittingDate(newDate as dayjs.Dayjs)
                }
                allowClear={false}
                disabled={isReadOnly}
            />
            <Button
                onClick={saveNextSittingDate}
                type={'primary'}
                loading={isSetNextSittingDateButtonLoading}
                disabled={isReadOnly || !hasUnsavedChanges}
            >
                Save
            </Button>
        </div>
    )
}
