import styles from './ContentTreePanelHeader.module.less'
import React, { ReactElement, useCallback, useMemo, useState } from 'react'
import { ContentTreePanelHeaderProps } from '@/components/ContentTreeNode/ContentTreePanelHeader/ContentTreePanelHeader.types'
import { getMetadataForContentPath } from '@/frontendLogic/outlines/NodeMetadataTree/NodeMetadataTree'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import {
    fetchAllNodeMetadata,
    nodeMetadataAtom,
    nodeMetadataTreeSelector,
} from '@/atoms/nodeMetadata'
import { EditableText } from '@/components/EditableText/EditableText'
import {
    deleteNodeAtContentPath,
    overwriteDescriptionAtContentPath,
    overwriteNodeReleaseStatusAtContentPath,
    overwriteNodeVideoLinkAtContentPath,
    overwriteTitleAtContentPath,
} from '@/api/cb/contentCreatorContent'
import { frontendDisplayedCourseSelector } from '@/atoms/auth'
import { CopyableID } from '@/components/CopyableID/CopyableID'
import { hashContentPath } from 'common/src/ContentPath'
import { DefensiveDeleteButton } from '@/components/utils/DefensiveDeleteButton/DefensiveDeleteButton'
import { syncErrorMessage, syncSuccessMessage } from '@/utils/syncMessages'
import {
    contentTreeStateAtom,
    convertContentTreeResponseToContentTreeState,
} from '@/atoms/contentTree'
import { specificContentPathHasUnsavedChangesSelectorFamily } from '@/atoms/unsavedChanges'
import { ContentNodeReorderer } from '@/components/ContentTreeNode/ContentTreePanelHeader/ContentNodeReorderer/ContentNodeReorderer'
import { NodeReleaseStatus } from 'common/src/api/core/courses/types'
import { reusableCssClass } from '@/utils/reusableCssClasses'
import { Select } from 'antd'
import { activeCourseConfigSelector } from '@/atoms/courseInfo'

export const ContentTreePanelHeader: React.FC<ContentTreePanelHeaderProps> = (
    props
): ReactElement => {
    const hasUnsavedChanges = useRecoilValue(
        specificContentPathHasUnsavedChangesSelectorFamily(props.contentPath)
    )
    const courseConfig = useRecoilValue(activeCourseConfigSelector)
    const nodeMetadataTree = useRecoilValue(nodeMetadataTreeSelector)
    const courseName = useRecoilValue(frontendDisplayedCourseSelector)

    const [nodeReleaseStatus, setNodeReleaseStatus] =
        useState<NodeReleaseStatus>(
            getMetadataForContentPath(nodeMetadataTree, props.contentPath)
                .nodeReleaseStatus
        )
    const [videoLink, setVideoLink] = useState<string | null>(
        getMetadataForContentPath(nodeMetadataTree, props.contentPath).videoLink
    )
    const { title, description } = useMemo(
        () => getMetadataForContentPath(nodeMetadataTree, props.contentPath),
        [nodeMetadataTree, props.contentPath]
    )
    const saveTitle = useCallback(
        async (title: string): Promise<void> => {
            const response = await overwriteTitleAtContentPath(
                courseName,
                props.contentPath,
                title
            )
            if (response.data.isError) {
                syncErrorMessage(
                    'Error saving title. You may want to try again or refresh the page.'
                )
                throw new Error(response.data.error)
            } else {
                syncSuccessMessage('Successfully saved title')
            }
        },
        [courseName, props.contentPath]
    )

    const saveDescription = useCallback(
        async (description: string): Promise<void> => {
            const response = await overwriteDescriptionAtContentPath(
                courseName,
                props.contentPath,
                description
            )
            if (response.data.isError) {
                syncErrorMessage(
                    'Error saving description. You may want to retry or refresh the page.'
                )
                throw new Error(response.data.error)
            } else {
                syncSuccessMessage('Successfully saved description')
            }
        },
        [courseName, props.contentPath]
    )

    const saveNodeReleaseStatus = useCallback(
        async (nodeReleaseStatus: NodeReleaseStatus): Promise<void> => {
            const response = await overwriteNodeReleaseStatusAtContentPath(
                courseName,
                props.contentPath,
                nodeReleaseStatus
            )
            if (response.data.isError) {
                syncErrorMessage(
                    'Error saving node release status. You may want to retry or refresh the page.'
                )
                throw new Error(response.data.error)
            } else {
                setNodeReleaseStatus(nodeReleaseStatus)
                syncSuccessMessage(
                    `Successfully saved node release status of ${
                        getMetadataForContentPath(
                            nodeMetadataTree,
                            props.contentPath
                        ).title
                    }: ${nodeReleaseStatus}`
                )
            }
        },
        [courseName, nodeMetadataTree, props.contentPath]
    )

    const saveVideoLink = useCallback(
        async (videoLink: string): Promise<void> => {
            const response = await overwriteNodeVideoLinkAtContentPath(
                courseName,
                props.contentPath,
                videoLink
            )
            if (response.data.isError) {
                syncErrorMessage(
                    'Error saving video link on node. You may want to retry or refresh the page.'
                )
                throw new Error(response.data.error)
            } else {
                // refresh node metadata
                const updatedNodeMetadata =
                    await fetchAllNodeMetadata(courseName)
                setNodeMetadata(updatedNodeMetadata)
                setVideoLink(videoLink)
                syncSuccessMessage(
                    `Successfully saved video link of ${
                        getMetadataForContentPath(
                            nodeMetadataTree,
                            props.contentPath
                        ).title
                    }`
                )
            }
        },
        [courseName, nodeMetadataTree, props.contentPath]
    )

    const setContentTreeState = useSetRecoilState(contentTreeStateAtom)
    const setNodeMetadata = useSetRecoilState(nodeMetadataAtom)
    const deleteNode = useCallback(async (): Promise<void> => {
        const response = await deleteNodeAtContentPath(
            courseName,
            props.contentPath
        )
        if (response.data.isError) {
            syncErrorMessage(
                'Error deleting node. You may want to try again or refresh the page.'
            )
        } else {
            // TODO: Figure this out in a better way later
            syncSuccessMessage(
                'Successfully deleted node. Please refresh the page, as the unsaved changes tracking might be out of sync.'
            )
            setContentTreeState(
                convertContentTreeResponseToContentTreeState(
                    response.data.payload.contentTreeResponse
                )
            )
            setNodeMetadata(response.data.payload.nodeMetadata)
        }
    }, [courseName, props.contentPath, setContentTreeState, setNodeMetadata])

    const hashedContentPath = useMemo(
        (): string => hashContentPath(props.contentPath),
        [props.contentPath]
    )

    return (
        <div
            className={`${styles.outlineProgressTreePanelHeader} ${
                hasUnsavedChanges ? styles.hasUnsavedChanges : ''
            }`}
        >
            <div className={styles.outlineProgressTreePanelTextContainer}>
                <div>
                    <div className={styles.outlineProgressTreePanelHeaderTitle}>
                        <EditableText onSave={saveTitle} initialText={title} />
                    </div>
                    <div
                        className={
                            styles.outlineProgressTreePanelHeaderDescription
                        }
                    >
                        <EditableText
                            onSave={saveDescription}
                            initialText={description}
                        />
                    </div>
                    {courseConfig.hasVideos && (
                        <div
                            className={
                                styles.outlineProgressTreePanelHeaderDescription
                            }
                        >
                            <EditableText
                                onSave={saveVideoLink}
                                initialText={
                                    videoLink ?? 'INSERT VIDEO LINK HERE'
                                }
                            />
                        </div>
                    )}
                    {courseConfig.hasUnreleasedContent && (
                        <div>
                            Release Status&nbsp;
                            <Select
                                value={nodeReleaseStatus}
                                onChange={saveNodeReleaseStatus}
                                className={
                                    hasUnsavedChanges
                                        ? reusableCssClass.hasUnsavedChanges
                                        : ''
                                }
                                dropdownMatchSelectWidth={false}
                                options={Object.values(NodeReleaseStatus).map(
                                    (item) => ({
                                        label: item,
                                        value: item,
                                    })
                                )}
                                onClick={(event) => event.stopPropagation()}
                            />
                        </div>
                    )}
                </div>
                <div className={styles.reorderNodeOuterContainer}>
                    <ContentNodeReorderer contentPath={props.contentPath} />
                </div>
                <div className={styles.farRightContainer}>
                    <div className={styles.copyableIdOuterContainer}>
                        <CopyableID
                            label={'ID'}
                            id={props.contentPath[props.contentPath.length - 1]}
                            fullID={hashedContentPath}
                        />
                    </div>
                    <div className={styles.deleteButtonOuterContainer}>
                        <div className={styles.deleteButtonInnerContainer}>
                            <DefensiveDeleteButton
                                title={'Warning: Dangerous Action!'}
                                onDelete={deleteNode}
                                okText={'Yes, Delete Node'}
                                cancelText={'Cancel'}
                                placement={'left'}
                                areYouSureContent={
                                    'Are you sure you want to delete this node? Note: This cannot be undone and may take a significant amount of time to complete.'
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
