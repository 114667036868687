import { courseInfoAtom } from '@/atoms/courseInfo'
import { FeatureFlag, FeatureFlags } from 'common/src/featureFlags'
import { selectorFamily, DefaultValue } from 'recoil'

export const featureFlagsSelectorFamily = selectorFamily<FeatureFlags, string>({
    key: 'featureFlagsSelectorFamily',
    get:
        (courseName) =>
        ({ get }) => {
            const courseInfo = get(courseInfoAtom(courseName))
            return courseInfo.featureFlags
        },
    set:
        (courseName) =>
        ({ set }, featureFlags) => {
            if (featureFlags instanceof DefaultValue) return

            set(courseInfoAtom(courseName), (courseInfo) => ({
                ...courseInfo,
                featureFlags,
            }))
        },
})

export const isFeatureFlagActiveSelectorFamily = selectorFamily<
    boolean,
    { courseName: string; featureFlag: FeatureFlag }
>({
    key: 'isFeatureFlagActiveSelectorFamily',
    get:
        ({ courseName, featureFlag }) =>
        ({ get }) => {
            if (!courseName) {
                return false
            }
            const featureFlags = get(featureFlagsSelectorFamily(courseName))
            return featureFlags.includes(featureFlag)
        },
})
