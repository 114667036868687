import React, { ReactElement, useMemo } from 'react'
import { useRecoilValue } from 'recoil'
import { practiceProblemAtomFamily } from '@/atoms/practiceProblem'
import { ReactElementForPracticeProblemIDProps } from '@/components/ContentTreeNode/ContentTreeLeafNode/MagicTextBoxEditor/ReactElementForPracticeProblemID/ReactElementForPracticeProblemID.types'
import styles from './ReactElementForPracticeProblemID.module.less'
import { LoadingComponent } from '@/components/utils/LoadingComponent/LoadingComponent'
import { getSignedUrlForS3Key } from '@/api/cb/s3'
import {
    nodeMetadataTreeSelector,
    getMetadataForContentPath,
} from '@/atoms/nodeMetadata'
import { MagicTextBoxReadOnly } from 'magic-text-box-lexical/src/components/MagicTextBoxReadOnly/MagicTextBoxReadOnly'
import { magicTextBoxErrorHandler } from '@/components/ContentTreeNode/ContentTreeLeafNode/MagicTextBoxEditor/MagicTextBoxErrorHandler'
import { useAccessUniversalContentLink } from '@/hooks/useAccessUniversalContentLink'
import {
    mtbReadOnlyOptionsNoOp,
    practiceProblemLinkPropsNoOp,
} from '@/utils/mtbNoOps'

// TODO: I just forcefully made this async...will see if it works correctly
export const getReactElementForPracticeProblemID = async (
    practiceProblemID: string
): Promise<ReactElement> => (
    <React.Suspense fallback={<LoadingComponent useWhiteBackground={true} />}>
        <ReactElementForPracticeProblemID
            practiceProblemID={practiceProblemID}
        />
    </React.Suspense>
)
const ReactElementForPracticeProblemID: React.FC<
    ReactElementForPracticeProblemIDProps
> = (props): ReactElement => {
    const problem = useRecoilValue(
        practiceProblemAtomFamily(props.practiceProblemID)
    )
    const accessUniversalContentLink = useAccessUniversalContentLink()
    const nodeMetadataTree = useRecoilValue(nodeMetadataTreeSelector)
    const nodeMetadataTitles = useMemo(
        (): string[] =>
            problem?.contentPath.map(
                (contentPath, index) =>
                    getMetadataForContentPath(
                        nodeMetadataTree,
                        problem?.contentPath.slice(0, index + 1)
                    ).title
            ),
        [nodeMetadataTree, problem?.contentPath]
    )
    const nodeMetadataTitlesElement = useMemo(
        (): ReactElement => (
            <div className={styles.nodeMetadataTitlesContainer}>
                {nodeMetadataTitles?.map((title) => (
                    <span key={title}>{title}</span>
                ))}
            </div>
        ),
        [nodeMetadataTitles]
    )

    if (!problem)
        return (
            <div className={styles.reactElementForPracticeProblemIdError}>
                Practice problem ID: {props.practiceProblemID} not found!
            </div>
        )

    return (
        <div className={styles.reactElementForPracticeProblemId}>
            <span>
                <b>Sample content for practice problem ID:</b>{' '}
                {props.practiceProblemID}
            </span>
            <hr />
            <span>
                <b>Topic:</b> {problem.topic}
            </span>
            <span>
                <b>Content path:</b> {nodeMetadataTitlesElement}
            </span>
            <span>
                <b>Is mathematical:</b> {problem.isMathematical ? 'YES' : 'NO'}
            </span>
            <hr />
            <span>
                <b>Question: </b>
                <MagicTextBoxReadOnly
                    initialEditorState={problem.question}
                    namespace={'problem question'}
                    getPreSignedUrlForS3Key={getSignedUrlForS3Key}
                    onError={(error: Error) =>
                        magicTextBoxErrorHandler(error, 'problem question')
                    }
                    practiceProblemLinkProps={practiceProblemLinkPropsNoOp}
                    options={mtbReadOnlyOptionsNoOp}
                    accessUniversalContentLink={accessUniversalContentLink}
                />
            </span>
            <hr />
            <span>
                <b>Solution: </b>
                <MagicTextBoxReadOnly
                    initialEditorState={problem.solution}
                    namespace={'problem question'}
                    getPreSignedUrlForS3Key={getSignedUrlForS3Key}
                    onError={(error: Error) =>
                        magicTextBoxErrorHandler(error, 'problem question')
                    }
                    practiceProblemLinkProps={practiceProblemLinkPropsNoOp}
                    options={mtbReadOnlyOptionsNoOp}
                    accessUniversalContentLink={accessUniversalContentLink}
                />
            </span>
        </div>
    )
}
