import React, { ReactElement } from 'react'
import styles from './PrimaryDashboard.module.less'
import { ContentTreeNode } from '@/components/ContentTreeNode/ContentTreeNode'
import { useRecoilValue } from 'recoil'
import { globalIsLoadingAtom } from '@/atoms/globalIsLoading'
import { FullScreenLoadingComponent } from '@/components/utils/LoadingComponent/LoadingComponent'
import { useViewingState } from '@/hooks/useViewingState'
import { usePreventExitWithUnsavedChanges } from '@/hooks/usePreventExitWithUnsavedChanges'
import { ButtonModalWrapper } from '@/components/utils/ButtonModalWrapper/ButtonModalWrapper'
import { CourseStats } from '@/components/CourseStats/CourseStats'
import { UploadPreviousExamAnalysis } from '@/components/UploadPreviousExamAnalysis/UploadPreviousExamAnalysis'
import { EditCourseMetadata } from '@/components/EditCourseMetadata/EditCourseMetadata'
import { NotecardCategories } from '@/components/NotecardCategories/NotecardCategories'
import { ViewingStateType } from '@/atoms/viewingState'
import { useForceReloadAround3AMLocal } from '@/hooks/useForceReloadAt3AMLocal'
import { AuthState, authStateAtom } from '@/atoms/auth'
import { ApplicationInstructions } from '@/components/ApplicationInstructions/ApplicationInstructions'
import uploadPreviousExamAnalysisStyles from '../UploadPreviousExamAnalysis/UploadPreviousExamAnalysis.module.less'
import editCourseMetadataStyles from '../EditCourseMetadata/EditCourseMetadata.module.less'
import formulaSheetEditorStyles from '../FormulaSheetEditor/FormulaSheetEditor.module.less'
import { FormulaSheetEditor } from '@/components/FormulaSheetEditor/FormulaSheetEditor'
import { reusableCssClass } from '@/utils/reusableCssClasses'
import { formulaSheetEntriesHaveUnsavedChangesSelector } from '@/atoms/formulaSheetEntries'
import { useInitializeLastSavedFormulaSheetEntries } from '@/hooks/useInitializeLastSavedFormulaSheetEntries'
import { activeCourseConfigSelector } from '@/atoms/courseInfo'
import { PracticeProblemCategories } from '@/components/PracticeProblemCategories/PracticeProblemCategories'
import { SourceMaterialTypes } from '../SourceMaterialTypes/SourceMaterialTypes'
import { NotecardsWithoutPracticeProblem } from '@/components/NotecardsAsPracticeProblems/NotecardsWithoutPracticeProblem'
import { useSessionData } from '@/hooks/useSessionData'

export const PrimaryDashboard: React.FC<unknown> = (): ReactElement => {
    useViewingState()
    usePreventExitWithUnsavedChanges()
    useForceReloadAround3AMLocal()
    useSessionData()

    const authState = useRecoilValue<AuthState>(authStateAtom)
    const globalIsLoading = useRecoilValue(globalIsLoadingAtom)
    const formulaSheetEntriesHaveUnsavedChanges = useRecoilValue(
        formulaSheetEntriesHaveUnsavedChangesSelector
    )
    const courseConfig = useRecoilValue(activeCourseConfigSelector)
    useInitializeLastSavedFormulaSheetEntries()
    if (globalIsLoading) {
        return <FullScreenLoadingComponent useWhiteBackground={true} />
    }
    return (
        <div className={styles.primaryDashboard}>
            <div className={styles.primaryDashboardTopButtonsContainer}>
                {authState?.isApplication && (
                    <ButtonModalWrapper
                        buttonText={'Instructions'}
                        viewingStateType={
                            ViewingStateType.applicationInstructions
                        }
                    >
                        <ApplicationInstructions isSignupForm={false} />
                    </ButtonModalWrapper>
                )}
                <ButtonModalWrapper
                    buttonText={'Edit Course Metadata'}
                    viewingStateType={ViewingStateType.editCourseMetadata}
                    modalClassName={
                        editCourseMetadataStyles.editCourseMetadataModal
                    }
                >
                    <EditCourseMetadata />
                </ButtonModalWrapper>
                <ButtonModalWrapper
                    buttonText={'Upload Previous Exam Analysis'}
                    modalClassName={
                        uploadPreviousExamAnalysisStyles.uploadPreviousExamAnalysisModal
                    }
                    viewingStateType={
                        ViewingStateType.uploadPreviousExamAnalysis
                    }
                >
                    <UploadPreviousExamAnalysis />
                </ButtonModalWrapper>
                <ButtonModalWrapper
                    buttonText={'Course Stats'}
                    viewingStateType={ViewingStateType.courseStats}
                >
                    <CourseStats />
                </ButtonModalWrapper>
                <ButtonModalWrapper
                    buttonText={'Notecard Categories'}
                    viewingStateType={ViewingStateType.notecardCategories}
                    destroyOnClose={true}
                >
                    <NotecardCategories />
                </ButtonModalWrapper>
                <ButtonModalWrapper
                    buttonText={'Notecards without Practice Problem'}
                    viewingStateType={
                        ViewingStateType.notecardsWithoutPracticeProblem
                    }
                    destroyOnClose={true}
                >
                    <NotecardsWithoutPracticeProblem />
                </ButtonModalWrapper>
                {courseConfig.hasPracticeProblemCategories && (
                    <ButtonModalWrapper
                        buttonText={'Practice Problem Categories'}
                        viewingStateType={
                            ViewingStateType.practiceProblemCategories
                        }
                        destroyOnClose={true}
                    >
                        <PracticeProblemCategories />
                    </ButtonModalWrapper>
                )}
                {courseConfig.hasSourceMaterialType && (
                    <ButtonModalWrapper
                        buttonText={'Source Material Types'}
                        viewingStateType={ViewingStateType.sourceMaterialTypes}
                        destroyOnClose={true}
                    >
                        <SourceMaterialTypes />
                    </ButtonModalWrapper>
                )}
                <ButtonModalWrapper
                    buttonText={'Formula Sheet'}
                    viewingStateType={ViewingStateType.formulaSheet}
                    modalClassName={
                        formulaSheetEditorStyles.formulaSheetEditorModal
                    }
                    buttonClassName={
                        formulaSheetEntriesHaveUnsavedChanges &&
                        reusableCssClass.hasUnsavedChanges
                    }
                    destroyOnClose={false}
                >
                    <FormulaSheetEditor />
                </ButtonModalWrapper>
            </div>
            <ContentTreeNode contentPath={[]} />
        </div>
    )
}
