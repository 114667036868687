import _ from 'lodash'
import styles from './FileUploader.module.less'
import React, {
    ReactElement,
    useState,
    useRef,
    useCallback,
    useMemo,
    useEffect,
} from 'react'
import {
    DraggedOverState,
    FileUploaderProps,
} from '@/components/utils/FileUploader/FileUploader.types'
import { Button, Tooltip } from 'antd'

export const FileUploader: React.FC<FileUploaderProps> = (
    props
): ReactElement => {
    const [file, setFile] = useState<File>(null)
    const fileUploadRef = useRef<HTMLInputElement>(null)
    const [isUploading, setIsUploading] = useState<boolean>(false)
    const [draggedOverState, setDraggedOverState] = useState<DraggedOverState>(
        DraggedOverState.notDragging
    )
    const [errorMsg, setErrorMsg] = useState<string>('')
    const isAllowedFileType = useCallback(
        (fileType: string): boolean => {
            return props.allowedFileTypes.includes(fileType)
        },
        [props.allowedFileTypes]
    )

    const handleFileUploadFromClick = useCallback((): void => {
        if (!isAllowedFileType(fileUploadRef.current.files[0].type)) return
        setFile(fileUploadRef.current.files[0])
    }, [isAllowedFileType])

    const draggedClass = useMemo((): string => {
        switch (draggedOverState) {
            case DraggedOverState.notDragging:
                return ''
            case DraggedOverState.dragging:
                return styles.dragging
            case DraggedOverState.error:
                return styles.draggingError
        }
    }, [draggedOverState])

    const buttonText = useMemo((): string => {
        if (file && isUploading) return 'Uploading...'
        if (file && errorMsg) return errorMsg
        if (file && !isUploading)
            return (
                props.uploadedFileMessage ?? 'Overwrite by uploading a new file'
            )
        switch (draggedOverState) {
            case DraggedOverState.notDragging:
                return props.buttonText
            case DraggedOverState.dragging:
                return 'Drop to upload'
            case DraggedOverState.error:
                return 'Invalid file type'
        }
    }, [
        draggedOverState,
        errorMsg,
        file,
        isUploading,
        props.buttonText,
        props.uploadedFileMessage,
    ])

    // helps make button not shrink when text changes
    const paddedButtonText = useMemo((): string => {
        return _.pad(buttonText, props.buttonText.length, '\u00a0')
    }, [buttonText, props.buttonText.length])

    const handleDrop = useCallback(
        (event: React.DragEvent<unknown>) => {
            event.preventDefault()
            event.stopPropagation()

            setDraggedOverState(DraggedOverState.notDragging)
            if (!isAllowedFileType(event.dataTransfer.files[0].type)) return

            setFile(event.dataTransfer.files[0])
        },
        [isAllowedFileType]
    )

    const handleDragOver = useCallback(
        (event: React.DragEvent<unknown>) => {
            event.preventDefault()
            event.stopPropagation()

            // only proceed if not currently dragging
            if (draggedOverState !== DraggedOverState.notDragging) return

            if (isAllowedFileType(event.dataTransfer.items[0].type)) {
                setDraggedOverState(DraggedOverState.dragging)
            } else {
                setDraggedOverState(DraggedOverState.error)
            }
        },
        [draggedOverState, isAllowedFileType]
    )

    const handleDragLeave = useCallback(
        (event: React.DragEvent<unknown>): void => {
            event.preventDefault()
            event.stopPropagation()

            setDraggedOverState(DraggedOverState.notDragging)
        },
        []
    )

    const handleClick = useCallback((): void => {
        fileUploadRef.current.click()
    }, [])

    useEffect(() => {
        if (!file) return
        setIsUploading(true) // begin loading externally
        try {
            const reader = new FileReader()
            reader.readAsDataURL(file) // not quite as good as binary (by a factor of 25% apparently), but simplifying this for now
            reader.onload = async () => {
                await props.handleFileUpload(reader.result as string)
                setIsUploading(false)
            }
        } catch (e) {
            setErrorMsg(e as string)
            setIsUploading(false)
        }
    }, [file])

    return (
        <div className={styles.fileUploader}>
            <input
                type={'file'}
                accept={props.allowedFileTypes.join(', ')}
                onChange={handleFileUploadFromClick}
                ref={fileUploadRef}
                style={{ display: 'none' }}
            />
            <Tooltip title={props.disabledMessage}>
                <Button
                    className={`${styles.dropZoneButton} ${draggedClass} ${
                        props.className || ''
                    }`}
                    type={'dashed'}
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                    onDragLeave={handleDragLeave}
                    onClick={handleClick}
                    loading={isUploading}
                    disabled={!!props.disabledMessage}
                >
                    {paddedButtonText}
                </Button>
            </Tooltip>
        </div>
    )
}
