import { AnnotatedNodeMetadata, NodeMetadata } from 'common/src/outlines/types'
import { atom, selector } from 'recoil'
import { contentTreeStateAtom } from '@/atoms/contentTree'
import { frontendDisplayedCourseSelector, authStateAtom } from '@/atoms/auth'
import { getAllNodeMetadata } from '@/api/cb/contentCreatorContent'
import { LoggerSelectorFamily } from '@/atoms/logger'
import { NodeMetadataTree } from '@/frontendLogic/outlines/NodeMetadataTree/NodeMetadataTree.types'
import { createNodeMetadataTree } from '@/frontendLogic/outlines/NodeMetadataTree/NodeMetadataTree'
import { ContentPath, hashContentPath } from 'common/src/ContentPath'

export const fetchAllNodeMetadata = async (
    courseName: string
): Promise<AnnotatedNodeMetadata[]> => {
    return (await getAllNodeMetadata(courseName)).data.payload.nodeMetadata
}
export const nodeMetadataAtom = atom<AnnotatedNodeMetadata[]>({
    key: 'nodeMetadataAtom',
    default: selector<AnnotatedNodeMetadata[]>({
        key: 'nodeMetadataFetcher',
        get: async ({ get }) => {
            const frontendDisplayedCourse = get(frontendDisplayedCourseSelector)
            const logger = get(LoggerSelectorFamily(nodeMetadataAtom.key))
            if (!frontendDisplayedCourse) {
                logger.info(
                    `No frontend displayed course, so node metadata will not be retrieved`
                )
                return null
            }
            get(authStateAtom) // forces update upon new login
            logger.info(`Retrieving initial node metadata`)
            return await fetchAllNodeMetadata(frontendDisplayedCourse)
        },
    }),
})

export const nodeMetadataTreeSelector = selector<NodeMetadataTree>({
    key: 'nodeMetadataTreeSelector',
    get: ({ get }) => {
        const contentTreeState = get(contentTreeStateAtom)
        const logger = get(LoggerSelectorFamily(nodeMetadataTreeSelector.key))
        if (!contentTreeState) {
            logger.info(
                `No content tree, so node metadata tree will not be retrieved`
            )
            return null
        }

        const nodeMetadata = get(nodeMetadataAtom)
        if (!nodeMetadata) {
            logger.info(
                `No node metadata, so node metadata tree will not be retrieved`
            )
            return null
        }
        return createNodeMetadataTree(
            nodeMetadata,
            contentTreeState.contentTree
        )
    },
})

export const getMetadataForContentPath = (
    nodeMetadataTree: NodeMetadataTree,
    contentPath: ContentPath
): NodeMetadata | undefined => {
    return nodeMetadataTree.metadataMap.get(hashContentPath(contentPath))
}
