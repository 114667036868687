import { ContentStats } from 'common/src/api/core/courses/types'
import { atomFamily, selectorFamily } from 'recoil'
import { frontendDisplayedCourseSelector } from '@/atoms/auth'
import { ContentPath, unHashContentPath } from 'common/src/ContentPath'
import { getNodeStats } from '@/api/cb/contentCreatorContent'

const fetchNodeStats = async (
    courseName: string,
    contentPath: ContentPath
): Promise<ContentStats> => {
    const response = await getNodeStats(courseName, contentPath)
    return response.data.payload
}

export const nodeStatsInvalidatorAtomFamily = atomFamily<number, string>({
    key: 'nodeStatsInvalidatorAtomFamily',
    default: 0,
})

export const nodeStatsAtomFamily = atomFamily<
    ContentStats | null,
    string | null
>({
    key: 'nodeStatsAtomFamily',
    default: selectorFamily<ContentStats | null, string | null>({
        key: 'nodeStatsDefaultSelectorFamily',
        get:
            (hashedContentPath) =>
            async ({ get }) => {
                if (hashedContentPath === null) return null
                get(nodeStatsInvalidatorAtomFamily(hashedContentPath))
                const frontendDisplayedCourse = get(
                    frontendDisplayedCourseSelector
                )
                return await fetchNodeStats(
                    frontendDisplayedCourse,
                    unHashContentPath(hashedContentPath)
                )
            },
    }),
})
