import React, { ReactElement, useMemo, useState } from 'react'
import { Button, Modal } from 'antd'
import { LoadingComponent } from '@/components/utils/LoadingComponent/LoadingComponent'
import { useRecoilValue } from 'recoil'
import { contentTreeStateAtom } from '@/atoms/contentTree'
import { isActiveCourseReadOnlySelector } from '@/atoms/auth'
import { MoveContentLeafNodeWithinSameCourse } from '@/components/ContentTreeNode/ContentTreeLeafNode/MoveContentLeafNodeWithinSameCourseButton/MoveContentLeafNodeWithinSameCourse/MoveContentLeafNodeWithinSameCourse'
import { ContentPath } from 'common/src/ContentPath'
import { ViewingStateType } from '@/atoms/viewingState'
import styles from '@/components/ContentTreeNode/ContentTreeLeafNode/CopyLeafNodeButton/CopyLeafNodeButton.module.less'
import { ButtonModalWrapper } from '@/components/utils/ButtonModalWrapper/ButtonModalWrapper'

export const MoveContentLeafNodeWithinSameCourseButton: React.FC<{
    contentPath: ContentPath
}> = (props): ReactElement => {
    const { flattenedContentTree } = useRecoilValue(contentTreeStateAtom)
    const hasMoreThanOneSection = useMemo(
        (): boolean => flattenedContentTree.length > 1,
        [flattenedContentTree]
    )
    const isReadOnly = useRecoilValue(isActiveCourseReadOnlySelector)

    return (
        <ButtonModalWrapper
            viewingStateType={ViewingStateType.moveLeafNodeWithinSameExam}
            buttonText={`Move to other section (same exam)`}
            size={'large'}
            disabled={!hasMoreThanOneSection || isReadOnly}
            buttonWrapperClassName={styles.copyLeafNodeButton}
            contentPath={props.contentPath}
        >
            <React.Suspense
                fallback={<LoadingComponent useWhiteBackground={true} />}
            >
                <MoveContentLeafNodeWithinSameCourse
                    contentPath={props.contentPath}
                />
            </React.Suspense>
        </ButtonModalWrapper>
    )
}
