import React, { ReactElement, useCallback, useRef, useState } from 'react'
import { useRouter } from 'next/router'
import { Button, Form, Input, notification } from 'antd'
import styles from './ResetPasswordPage.module.less'
import { reusableCssClass } from '@/utils/reusableCssClasses'
import { UiLayoutConstant } from '@/globals/uiLayoutConstant'
import { FormInstance } from 'antd/lib/form'
import {
    ResetPasswordForm,
    ResetPasswordPageProps,
} from '@/components/ResetPasswordPage/ResetPasswordPage.types'
import {
    clearAuthenticationError,
    handleAuthenticationError,
} from '@/components/authentication/handleAuthenticationError'
import { NotificationKeys } from '@/utils/NotificationKeys'
import { resetPassword } from '@/api/cb/contentFrontendAuthentication'
import {
    validateConfirmPasswordPromise,
    validatePasswordPromise,
} from '@/utils/passwordValidation'

export const ResetPasswordPage: React.FC<
    ResetPasswordPageProps
> = (): ReactElement => {
    const formRef = useRef<FormInstance<ResetPasswordForm>>(null)

    const router = useRouter()
    const contentCreatorID = router.query.contentCreatorID as string
    const token = router.query.token as string

    const validateConfirmPassword = useCallback(
        async (_: unknown, confirmPassword: string): Promise<void> => {
            const otherPassword = formRef.current.getFieldValue('password')
            return validateConfirmPasswordPromise(
                confirmPassword,
                otherPassword
            )
        },
        []
    )
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const handleSubmit = useCallback(
        async (values: ResetPasswordForm): Promise<void> => {
            setIsLoading(true)
            clearAuthenticationError()

            const response = await resetPassword(
                contentCreatorID,
                token,
                values.password
            )
            if (!response.data.success) {
                handleAuthenticationError(response.data.error, {
                    message: 'Issue resetting password',
                    description:
                        'Something went wrong trying to reset your password. Please refresh the page and try again.',
                })
            } else {
                notification.success({
                    message: 'Password successfully reset',
                    duration: 5, // seconds
                    onClick: () => {
                        notification.destroy(
                            NotificationKeys.RESET_PASSWORD_SUCCESS
                        )
                    },
                    className: reusableCssClass.clickMe,
                    key: NotificationKeys.RESET_PASSWORD_SUCCESS,
                })
                await router.push({
                    pathname: UiLayoutConstant.PRIMARY_DASHBOARD_PAGE,
                })
            }
            setIsLoading(false)
        },
        [router, token, contentCreatorID]
    )

    return (
        <div className={styles.resetPasswordOuterContainer}>
            <div className={`${styles.resetPasswordContainer} container`}>
                <div className={'row'}>
                    <h3>Reset your password</h3>
                </div>
                <div className={'row'}>
                    <span>
                        Please enter and confirm your new password below to
                        access your account.
                    </span>
                </div>
                <div className={'row'}>
                    <Form onFinish={handleSubmit} ref={formRef}>
                        <Form.Item
                            name={'password'}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input a strong password',
                                },
                                { validator: validatePasswordPromise },
                            ]}
                        >
                            <Input.Password placeholder="Password" />
                        </Form.Item>
                        <Form.Item
                            name={'confirmPassword'}
                            hasFeedback
                            dependencies={['password']}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please confirm your password',
                                },
                                { validator: validateConfirmPassword },
                            ]}
                        >
                            <Input.Password placeholder="Confirm Password" />
                        </Form.Item>
                        <div className={'row'}>
                            <div className={'column'}>
                                <Form.Item>
                                    <div className={styles.btnContainer}>
                                        <Button
                                            type={'primary'}
                                            htmlType={'submit'}
                                            id={
                                                'cypress-reset-your-password-button'
                                            }
                                            loading={isLoading}
                                        >
                                            Reset Password
                                        </Button>
                                    </div>
                                </Form.Item>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    )
}
