import { ContentPath } from 'common/src/ContentPath'
import { ContentTree } from 'common/src/ContentTree/types'

export const getContentTreeChildForContentPath = (
    contentTree: ContentTree,
    contentPath: ContentPath
): ContentTree | null => {
    let returnedContentTree = contentTree
    for (let i = 0; i < contentPath.length; i++) {
        returnedContentTree = returnedContentTree.children.filter(
            (child) => child.id === contentPath[i]
        )[0]

        if (!returnedContentTree) return null
    }

    return returnedContentTree
}
