import React, { ReactElement, useCallback, useRef, useState } from 'react'
import {
    MagicTextBoxEditorProps,
    MagicTextBoxEditorTab,
} from '@/components/ContentTreeNode/ContentTreeLeafNode/MagicTextBoxEditor/MagicTextBoxEditor.types'
import { getPreSignedUrlForPut, getSignedUrlForS3Key } from '@/api/cb/s3'
import { Tabs } from 'antd'
import { activeMTBEditorTabAtom } from '@/atoms/activeMTBEditorTab'
import { useRecoilState, useRecoilValue } from 'recoil'
import { MagicTextBoxEditable } from 'magic-text-box-lexical/src/components/MagicTextBoxEditable/MagicTextBoxEditable'
import { MagicTextBoxReadOnly } from 'magic-text-box-lexical/src/components/MagicTextBoxReadOnly/MagicTextBoxReadOnly'
import { magicTextBoxErrorHandler } from '@/components/ContentTreeNode/ContentTreeLeafNode/MagicTextBoxEditor/MagicTextBoxErrorHandler'
import { EditorState } from 'lexical'
import { serializeEditorStateToString } from 'magic-text-box-lexical/src/serialization/serializeEditorStateToString'
import { viewingStateAtom } from '@/atoms/viewingState'
import { useAccessUniversalContentLink } from '@/hooks/useAccessUniversalContentLink'
import { convertImageToText } from '@/api/cb/llmUtils'
import styles from './MagicTextBoxEditor.module.scss'
import { usePracticeProblemLinkProps } from '@/hooks/usePracticeProblemLinkProps'

export const MagicTextBoxEditor: React.FC<MagicTextBoxEditorProps> = (
    props
): ReactElement => {
    const onError = useCallback(
        (error: Error) => magicTextBoxErrorHandler(error, props.namespace),
        [props.namespace]
    )
    const [resetIndex, setResetIndex] = useState<number>(0)
    const editorStateRef = useRef<EditorState | null>(null)
    const [activeMTBEditorTab, setActiveMTBEditorTab] = useRecoilState(
        activeMTBEditorTabAtom
    )
    const currentStringifiedEditorStateRef = useRef<string>(
        props.initialStringifiedEditorState
    )
    const handleTabChange = useCallback(
        (newTab: string): void => {
            setResetIndex((currentValue) => currentValue + 1)
            const nextEditorState =
                props.editorStateRef?.current ?? editorStateRef.current
            if (nextEditorState) {
                currentStringifiedEditorStateRef.current =
                    serializeEditorStateToString(nextEditorState)
            }

            setActiveMTBEditorTab(newTab as MagicTextBoxEditorTab)
        },
        [props.editorStateRef, setActiveMTBEditorTab]
    )
    const accessUniversalContentLink = useAccessUniversalContentLink()

    const viewingState = useRecoilValue(viewingStateAtom)
    const practiceProblemLinkProps = usePracticeProblemLinkProps()
    return (
        <div className={styles.magicTextBoxEditor}>
            <Tabs
                activeKey={activeMTBEditorTab}
                onChange={handleTabChange}
                items={[
                    {
                        key: MagicTextBoxEditorTab.editable,
                        label: MagicTextBoxEditorTab.editable,

                        children: (
                            <div className={styles.editableView}>
                                <MagicTextBoxEditable
                                    initialEditorState={
                                        props.initialStringifiedEditorState
                                    }
                                    namespace={props.namespace}
                                    onError={onError}
                                    practiceProblemLinkProps={
                                        practiceProblemLinkProps
                                    }
                                    getPreSignedUrlForS3Key={
                                        getSignedUrlForS3Key
                                    }
                                    options={{
                                        annotatedContentProps:
                                            props.annotatedContentProps,
                                        canInsertPracticeProblemLink: true,
                                        canToggleEditability: false, // not using this right now
                                        imageUploadProps: {
                                            getPreSignedUrlForPut,
                                        },
                                        ocrProps: {
                                            convertImageToText,
                                        },
                                        canInsertUniversalContentLink: true,
                                    }}
                                    editableCoreProps={{
                                        placeholder: props.placeholder,
                                        savableProps: props.savableProps,
                                        editorStateRef:
                                            props.editorStateRef ??
                                            editorStateRef,
                                        editorUpdateListener:
                                            props.editorUpdateListener,
                                    }}
                                    shouldHideComments={props.cannotComment}
                                    accessUniversalContentLink={
                                        accessUniversalContentLink
                                    }
                                />
                            </div>
                        ),
                    },
                    {
                        key: MagicTextBoxEditorTab.nonEditable,
                        label: MagicTextBoxEditorTab.nonEditable,
                        children: (
                            <div
                                className={styles.nonEditableView}
                                key={`${props.id}-${resetIndex}`}
                            >
                                <MagicTextBoxReadOnly
                                    initialEditorState={
                                        currentStringifiedEditorStateRef.current
                                    }
                                    options={{
                                        annotatedContentProps:
                                            props.annotatedContentProps,
                                        canComment: !props.cannotComment,
                                        canStar: false,
                                        cannotPostAnonymously: true,
                                        canCopyAnnotationSelectionToClipboard:
                                            props.canCopyAnnotationSelectionToClipboard,
                                        canInsertUniversalContentLink: true,
                                    }}
                                    namespace={props.namespace}
                                    onError={onError}
                                    practiceProblemLinkProps={
                                        practiceProblemLinkProps
                                    }
                                    getPreSignedUrlForS3Key={
                                        getSignedUrlForS3Key
                                    }
                                    // Hides floating comment button when modal is closed
                                    shouldHideComments={
                                        props.cannotComment ||
                                        !viewingState.viewingStateType
                                    }
                                    accessUniversalContentLink={
                                        accessUniversalContentLink
                                    }
                                />
                            </div>
                        ),
                    },
                ]}
            />
        </div>
    )
}
