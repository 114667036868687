import { FormulaSheetEntry } from 'common/src/formulaSheet/types'
import produce from 'immer'
import { nanoid } from 'nanoid'

export const createEmptyFormulaSheetEntry = (): FormulaSheetEntry => ({
    id: nanoid(4),
    title: 'Edit Me!',
    description: '',
    formulaEditorState: undefined,
    shouldForcePageBreakBefore: false,
})

export const addFormulaSheetEntry = produce(
    (
        formulaSheetEntries: FormulaSheetEntry[],
        newFormulaSheetEntry: FormulaSheetEntry
    ): void => {
        formulaSheetEntries.push(newFormulaSheetEntry)
    }
)

export const reorderFormulaSheetEntries = produce(
    (
        formulaSheetEntries: FormulaSheetEntry[],
        startingIndex: number,
        endingIndex: number
    ): void => {
        const movedContent = formulaSheetEntries.splice(startingIndex, 1)
        formulaSheetEntries.splice(endingIndex, 0, ...movedContent)
    }
)

export const deleteFormulaSheetEntry = produce(
    (
        formulaSheetEntries: FormulaSheetEntry[],
        formulaSheetIdToDelete: string
    ): void => {
        formulaSheetEntries.splice(
            formulaSheetEntries.findIndex(
                (entry) => entry.id === formulaSheetIdToDelete
            ),
            1
        )
    }
)

export const setFormulaSheetEntryAtIndex = produce(
    (
        formulaSheetEntries: FormulaSheetEntry[],
        newFormulaSheetEntry: FormulaSheetEntry,
        index: number
    ): void => {
        formulaSheetEntries[index] = newFormulaSheetEntry
    }
)
