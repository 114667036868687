import { DefaultValue, selectorFamily } from 'recoil'
import { courseInfoAtom } from '@/atoms/courseInfo'
import dayjs, { Dayjs } from 'dayjs'

export const nextSittingDateSelector = selectorFamily<Dayjs, string>({
    key: 'nextSittingDateSelector',
    get:
        (courseName) =>
        ({ get }) => {
            const courseInfo = get(courseInfoAtom(courseName))
            return dayjs(courseInfo.nextSittingDate)
        },
    set:
        (courseName) =>
        ({ set }, newNextSittingDate) => {
            if (newNextSittingDate instanceof DefaultValue) return

            set(courseInfoAtom(courseName), (courseInfo) => ({
                ...courseInfo,
                nextSittingDate: newNextSittingDate.toDate(),
            }))
        },
})
