import { EditorState } from 'lexical'
import React, {
    ReactElement,
    useCallback,
    useMemo,
    useState,
    useEffect,
} from 'react'
import styles from './NotecardEditor.module.less'
import {
    NotecardEditorContentType,
    NotecardEditorProps,
} from '@/components/ContentTreeNode/ContentTreeLeafNode/NotecardsEditor/NotecardEditor/NotecardEditor.types'
import { Button, Tabs, Tooltip } from 'antd'
import { MagicTextBoxEditor } from '@/components/ContentTreeNode/ContentTreeLeafNode/MagicTextBoxEditor/MagicTextBoxEditor'
import { NotecardMetadataEditor } from '@/components/ContentTreeNode/ContentTreeLeafNode/NotecardsEditor/NotecardEditor/NotecardMetadataEditor/NotecardMetadataEditor'
import {
    ContentCreatorEditableNotecardMetadata,
    parseContentCreatorEditableNotecardMetadata,
} from '@/components/ContentTreeNode/ContentTreeLeafNode/NotecardsEditor/NotecardEditor/NotecardMetadataEditor/NotecardMetadataEditor.types'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import {
    notecardAtomFamily,
    lastSavedNotecardDataAtomFamily,
} from '@/atoms/notecard'
import {
    setContentCreatorEditableNotecardMetadata,
    setNotecardFront,
    setNotecardBack,
} from '@/components/ContentTreeNode/ContentTreeLeafNode/NotecardsEditor/immer'
import {
    authStateAtom,
    frontendDisplayedCourseSelector,
    isActiveCourseReadOnlySelector,
} from '@/atoms/auth'
import { overwriteNotecardAtContentPath } from '@/api/cb/contentCreatorContent'
import { syncErrorMessage, syncSuccessMessage } from '@/utils/syncMessages'
import { CopyIndividualContentButton } from '@/components/ContentTreeNode/ContentTreeLeafNode/CopyIndividualContentButton/CopyIndividualContentButton'
import { MoveIndividualContentButton } from '@/components/ContentTreeNode/ContentTreeLeafNode/MoveIndividualContentButton/MoveIndividualContentButton'
import { activeNotecardEditorContentTypeAtom } from '@/atoms/activeNotecardEditorContentType'
import { ContentType } from 'common/src/commentThread/types'
import { useRouter } from 'next/router'
import { ContentCommentThreadWrapper } from '@/components/ContentCommentThread/ContentCommentThreadWrapper'
import {
    newlyCreatedAndUnsavedNotecardIDsAtom,
    newlyCreatedAndUnsavedPracticeProblemIDsAtom,
} from '@/atoms/newlyCreatedAndUnsavedContentIDs'
import { useMonitorNotecardChanges } from '@/components/ContentTreeNode/ContentTreeLeafNode/NotecardsEditor/NotecardEditor/useMonitorNotecardChanges'
import { parseSavableNotecardData } from '@/components/ContentTreeNode/ContentTreeLeafNode/NotecardsEditor/NotecardEditor/saving'
import {
    specificContentHasUnsavedChangesSelector,
    SavableContent,
    SavableContentType,
} from '@/atoms/unsavedChanges'
import { serializeEditorStateToString } from 'magic-text-box-lexical/src/serialization/serializeEditorStateToString'
import { useDebounce } from 'magic-text-box-lexical/src/utils/useDebounce'
import { PracticeProblemEditor } from '@/components/ContentTreeNode/ContentTreeLeafNode/PracticeProblemsEditor/PracticeProblemEditor/PracticeProblemEditor'
import { convertNotecardToPracticeProblem } from '@/api/cb/search'
import { practiceProblemAtomFamily } from '@/atoms/practiceProblem'
import { useMarkContentWithUnsavedChanges } from '@/hooks/useMarkContentWithUnsavedChanges'

export const NotecardEditor: React.FC<NotecardEditorProps> = (
    props
): ReactElement => {
    const [notecard, setNotecard] = useRecoilState(
        notecardAtomFamily(props.notecardID)
    )
    useMonitorNotecardChanges(notecard.id, notecard.contentPath)
    const authState = useRecoilValue(authStateAtom)
    const isReadOnly = useRecoilValue(isActiveCourseReadOnlySelector)

    const contentCreatorEditableNotecardMetadata = useMemo(
        (): ContentCreatorEditableNotecardMetadata =>
            parseContentCreatorEditableNotecardMetadata(notecard),
        [notecard]
    )

    const setContentCreatorEditableNotecardMetadataCallback = useCallback(
        (
            contentCreatorEditableNotecardMetadata: ContentCreatorEditableNotecardMetadata
        ) => {
            setNotecard((notecard) =>
                setContentCreatorEditableNotecardMetadata(
                    notecard,
                    contentCreatorEditableNotecardMetadata
                )
            )
        },
        [setNotecard]
    )

    const courseName = useRecoilValue(frontendDisplayedCourseSelector)
    const [isSaving, setIsSaving] = useState<boolean>(false)
    const [
        newlyCreatedAndUnsavedNotecardIDs,
        setNewlyCreatedAndUnsavedNotecardIDs,
    ] = useRecoilState(newlyCreatedAndUnsavedNotecardIDsAtom)
    const hasNeverBeenSaved = useMemo(
        (): boolean => newlyCreatedAndUnsavedNotecardIDs.has(notecard.id),
        [notecard, newlyCreatedAndUnsavedNotecardIDs]
    )
    const setLastSavedNotecardData = useSetRecoilState(
        lastSavedNotecardDataAtomFamily(notecard.id)
    )
    const saveNotecardChanges = useCallback(async (): Promise<void> => {
        if (isReadOnly) return
        setIsSaving(true)
        const response = await overwriteNotecardAtContentPath(
            courseName,
            notecard.contentPath,
            notecard
        )
        if (response.data.isError) {
            syncErrorMessage(
                'Error saving notecard. You may want to try saving the notecard again.'
            )
        } else {
            setLastSavedNotecardData(parseSavableNotecardData(notecard))
            syncSuccessMessage('Notecard successfully saved')
            setNewlyCreatedAndUnsavedNotecardIDs((current) =>
                current.remove(notecard.id)
            )
        }
        setIsSaving(false)
    }, [
        courseName,
        isReadOnly,
        notecard,
        setLastSavedNotecardData,
        setNewlyCreatedAndUnsavedNotecardIDs,
    ])

    const [
        activeNotecardEditorContentType,
        setActiveNotecardEditorContentType,
    ] = useRecoilState(activeNotecardEditorContentTypeAtom)

    // quick trick to quickly route to the comment thread
    const router = useRouter()
    useEffect(() => {
        if (router.query.showCommentThread) {
            setActiveNotecardEditorContentType(
                NotecardEditorContentType.commentThread
            )
            const newRouterQuery = { ...router.query }
            delete newRouterQuery.showCommentThread
            router.replace({
                pathname: router.pathname,
                query: newRouterQuery,
            })
        }
    }, [router, setActiveNotecardEditorContentType])

    const savableContent = useMemo(
        (): SavableContent => ({
            contentPath: notecard.contentPath,
            contentType: SavableContentType.notecard,
            id: notecard.id,
        }),
        [notecard.id, notecard.contentPath]
    )
    const isUnsaved = useRecoilValue(
        specificContentHasUnsavedChangesSelector(savableContent)
    )

    const debouncedNotecardFrontUpdateListener = useDebounce(
        (editorState: EditorState): void => {
            const newNotecardFront = serializeEditorStateToString(editorState)
            setNotecard((notecard) =>
                setNotecardFront(notecard, newNotecardFront)
            )
        },
        250,
        1_000
    )

    const debouncedNotecardBackUpdateListener = useDebounce(
        (editorState: EditorState): void => {
            const newNotecardFront = serializeEditorStateToString(editorState)
            setNotecard((notecard) =>
                setNotecardBack(notecard, newNotecardFront)
            )
        },
        250,
        1_000
    )

    const [notecardAsPracticeProblem, setNotecardAsPracticeProblem] =
        useRecoilState(practiceProblemAtomFamily(props.notecardID))
    const setNewlyCreatedAndUnsavedPracticeProblemIDs = useSetRecoilState(
        newlyCreatedAndUnsavedPracticeProblemIDsAtom
    )
    const [
        isConvertingNotecardToPracticeProblem,
        setIsConvertingNotecardToPracticeProblem,
    ] = useState<boolean>(false)
    const [practiceProblemEditorResetKey, setPracticeProblemEditorResetKey] =
        useState<number>(0)
    const markContentWithUnsavedChanges = useMarkContentWithUnsavedChanges()
    const convertNotecardToPracticeProblemCallback =
        useCallback(async (): Promise<void> => {
            setIsConvertingNotecardToPracticeProblem(true)
            const response = await convertNotecardToPracticeProblem(
                courseName,
                props.notecardID
            )
            if (!response.data.success) {
                syncErrorMessage(
                    'Something went wrong converting notecard to practice problem. Please try again.'
                )
            } else {
                syncSuccessMessage(
                    'Successfully converted notecard to practice problem.'
                )
                if (notecardAsPracticeProblem === null) {
                    setNewlyCreatedAndUnsavedPracticeProblemIDs((current) =>
                        current.add(props.notecardID)
                    )
                }
                const newNotecardAsPracticeProblem = response.data.payload
                setNotecardAsPracticeProblem(newNotecardAsPracticeProblem)
                markContentWithUnsavedChanges({
                    contentPath: newNotecardAsPracticeProblem.contentPath,
                    contentType: SavableContentType.practiceProblem,
                    id: newNotecardAsPracticeProblem.id,
                })
                setPracticeProblemEditorResetKey((current) => current + 1)
            }
            setIsConvertingNotecardToPracticeProblem(false)
        }, [
            courseName,
            markContentWithUnsavedChanges,
            notecardAsPracticeProblem,
            props.notecardID,
            setNewlyCreatedAndUnsavedPracticeProblemIDs,
            setNotecardAsPracticeProblem,
        ])
    const doesNotecardAsPracticeProblemExist = useMemo(
        (): boolean => notecardAsPracticeProblem !== null,
        [notecardAsPracticeProblem]
    )
    const convertNotecardToPracticeProblemButtonDisabledText =
        useMemo((): string => {
            if (isUnsaved || hasNeverBeenSaved) {
                return 'Please save notecard before converting to practice problem'
            } else {
                return ''
            }
        }, [hasNeverBeenSaved, isUnsaved])

    return (
        <div className={styles.notecardEditor}>
            <div>
                <Button
                    onClick={saveNotecardChanges}
                    type={'primary'}
                    loading={isSaving}
                    disabled={!isUnsaved || isReadOnly}
                >
                    Save notecard changes
                </Button>
            </div>
            <div>
                <CopyIndividualContentButton
                    contentPath={notecard.contentPath}
                    type={'notecard'}
                    id={notecard.id}
                />
            </div>
            <div>
                <MoveIndividualContentButton
                    contentPath={notecard.contentPath}
                    type={'notecard'}
                    id={notecard.id}
                />
            </div>
            <Tabs
                activeKey={activeNotecardEditorContentType}
                onChange={(newTab) =>
                    setActiveNotecardEditorContentType(
                        newTab as NotecardEditorContentType
                    )
                }
                items={[
                    {
                        key: NotecardEditorContentType.metadata,
                        label: NotecardEditorContentType.metadata,
                        children: (
                            <NotecardMetadataEditor
                                contentCreatorEditableNotecardMetadata={
                                    contentCreatorEditableNotecardMetadata
                                }
                                setContentCreatorEditableNotecardMetadata={
                                    setContentCreatorEditableNotecardMetadataCallback
                                }
                                contentPath={notecard.contentPath}
                                notecardID={props.notecardID}
                            />
                        ),
                    },
                    {
                        key: NotecardEditorContentType.front,
                        label: NotecardEditorContentType.front,
                        children: (
                            <MagicTextBoxEditor
                                id={`${props.notecardID}-front`}
                                namespace={'notecard front'}
                                placeholder={'Enter notecard front.'}
                                initialStringifiedEditorState={notecard.front}
                                editorUpdateListener={
                                    debouncedNotecardFrontUpdateListener
                                }
                                savableProps={{
                                    onSave: saveNotecardChanges,
                                    setHasUnsavedChanges: () => undefined,
                                }}
                            />
                        ),
                    },
                    {
                        key: NotecardEditorContentType.back,
                        label: NotecardEditorContentType.back,
                        children: (
                            <MagicTextBoxEditor
                                id={`${props.notecardID}-back`}
                                namespace={'notecard back'}
                                placeholder={'Enter notecard back.'}
                                initialStringifiedEditorState={notecard.back}
                                editorUpdateListener={
                                    debouncedNotecardBackUpdateListener
                                }
                                savableProps={{
                                    onSave: saveNotecardChanges,
                                    setHasUnsavedChanges: () => undefined,
                                }}
                            />
                        ),
                    },
                    {
                        key: NotecardEditorContentType.commentThread,
                        label: NotecardEditorContentType.commentThread,
                        disabled: hasNeverBeenSaved || authState.isApplication,
                        children: (
                            <ContentCommentThreadWrapper
                                contentType={ContentType.NOTECARD}
                                contentId={props.notecardID}
                                contentPath={notecard.contentPath}
                            />
                        ),
                    },
                    {
                        key: NotecardEditorContentType.convertToPracticeProblem,
                        label: NotecardEditorContentType.convertToPracticeProblem,
                        children: (
                            <>
                                <div style={{ paddingBottom: '1rem' }}>
                                    <Tooltip
                                        title={
                                            convertNotecardToPracticeProblemButtonDisabledText
                                        }
                                        placement={'right'}
                                    >
                                        <Button
                                            type={'primary'}
                                            disabled={
                                                !!convertNotecardToPracticeProblemButtonDisabledText
                                            }
                                            onClick={
                                                convertNotecardToPracticeProblemCallback
                                            }
                                            loading={
                                                isConvertingNotecardToPracticeProblem
                                            }
                                        >
                                            Generate
                                        </Button>
                                    </Tooltip>
                                </div>
                                {doesNotecardAsPracticeProblemExist && (
                                    <PracticeProblemEditor
                                        key={practiceProblemEditorResetKey}
                                        practiceProblemID={props.notecardID}
                                    />
                                )}
                            </>
                        ),
                    },
                ]}
            />
        </div>
    )
}
