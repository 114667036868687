import React, { ReactElement, useCallback, useState, useMemo } from 'react'
import { ContentPath, hashContentPath } from 'common/src/ContentPath'
import { Button } from 'antd'
import styles from './GenerateContentFromSourceMaterialButton.module.less'
import {
    generatePracticeProblemsFromSourceMaterialAtContentPath,
    generateNotecardsFromSourceMaterialAtContentPath,
} from '@/api/cb/search'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { frontendDisplayedCourseSelector } from '@/atoms/auth'
import { syncErrorMessage, syncSuccessMessage } from '@/utils/syncMessages'
import { viewingStateAtom, ViewingStateType } from '@/atoms/viewingState'
import { slimLeafNodeAtomFamily, fetchSlimLeafNode } from '@/atoms/slimLeafNode'

export interface GenerateContentFromSourceMaterialProps {
    contentPath: ContentPath
    contentType: 'notecards' | 'practiceProblems'
}

export const GenerateContentFromSourceMaterialButton: React.FC<
    GenerateContentFromSourceMaterialProps
> = (props): ReactElement => {
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const courseName = useRecoilValue(frontendDisplayedCourseSelector)
    const setViewingState = useSetRecoilState(viewingStateAtom)
    const hashedContentPath = useMemo(
        (): string => hashContentPath(props.contentPath),
        [props.contentPath]
    )
    const setSlimLeafNodeAtomFamily = useSetRecoilState(
        slimLeafNodeAtomFamily(hashedContentPath)
    )
    const resetSlimLeafNode = useCallback(async (): Promise<void> => {
        const newSlimLeafNode = await fetchSlimLeafNode(
            courseName,
            props.contentPath
        )
        setSlimLeafNodeAtomFamily(newSlimLeafNode)
    }, [courseName, props.contentPath, setSlimLeafNodeAtomFamily])

    const contentWord = useMemo(
        (): string =>
            props.contentType === 'notecards' ? 'notecard' : 'practice problem',
        [props.contentType]
    )
    const handleGeneratePracticeProblemsClick =
        useCallback(async (): Promise<void> => {
            setIsLoading(true)
            const response =
                await generatePracticeProblemsFromSourceMaterialAtContentPath(
                    courseName,
                    props.contentPath
                )
            if (response.data.success) {
                const generatedIDs =
                    response.data.payload.generatedPracticeProblemIDs
                if (generatedIDs.length) {
                    syncSuccessMessage(
                        `Successfully created: ${generatedIDs.length} new ${contentWord}s. Redirecting you to the first new ${contentWord} now.`
                    )
                    await resetSlimLeafNode()
                    setViewingState({
                        contentPath: props.contentPath,
                        viewingStateType: ViewingStateType.practiceProblems,
                        id: response.data.payload
                            .generatedPracticeProblemIDs[0],
                    })
                } else {
                    syncSuccessMessage(
                        `No opportunities were found for new ${contentWord}s in this section, so no new ${contentWord} were created.`
                    )
                }
            } else {
                syncErrorMessage(
                    `Something went wrong trying to generate ${contentWord}s. It's possible some content was still added, so feel free to check this out.`
                )
            }
            setIsLoading(false)
        }, [
            contentWord,
            courseName,
            props.contentPath,
            resetSlimLeafNode,
            setViewingState,
        ])
    const handleGenerateNotecardsClick =
        useCallback(async (): Promise<void> => {
            setIsLoading(true)
            const response =
                await generateNotecardsFromSourceMaterialAtContentPath(
                    courseName,
                    props.contentPath
                )
            if (response.data.success) {
                const generatedIDs = response.data.payload.generatedNotecardIDs
                if (generatedIDs.length) {
                    syncSuccessMessage(
                        `Successfully created: ${generatedIDs.length} new notecards. Redirecting you to the first new notecard now.`
                    )
                    await resetSlimLeafNode()
                    setViewingState({
                        contentPath: props.contentPath,
                        viewingStateType: ViewingStateType.notecards,
                        id: response.data.payload.generatedNotecardIDs[0],
                    })
                } else {
                    syncSuccessMessage(
                        `No opportunities were found for new notecards in this section, so no notecards were created.`
                    )
                }
            } else {
                syncErrorMessage(
                    "Something went wrong trying to generate content. It's possible some problems were still added, so feel free to check this out."
                )
            }
            setIsLoading(false)
        }, [courseName, props.contentPath, resetSlimLeafNode, setViewingState])

    return (
        <div className={styles.buttonContainer}>
            <Button
                size={'large'}
                onClick={
                    props.contentType === 'notecards'
                        ? handleGenerateNotecardsClick
                        : handleGeneratePracticeProblemsClick
                }
                loading={isLoading}
            >
                Generate{' '}
                {props.contentType === 'notecards'
                    ? 'Notecards'
                    : 'Practice Problems'}
            </Button>
        </div>
    )
}
