import {
    useMarkContentWithUnsavedChanges,
    useMarkContentWithSavedChanges,
} from '@/hooks/useMarkContentWithUnsavedChanges'
import { ContentPath } from 'common/src/ContentPath'
import { useMemo, useEffect } from 'react'
import { SavableContent, SavableContentType } from '@/atoms/unsavedChanges'
import { useRecoilValue, useRecoilState } from 'recoil'
import { isEqual } from 'lodash'
import {
    sourceMaterialAtomFamily,
    lastSavedSourceMaterialDataAtomFamily,
} from '@/atoms/sourceMaterial'

export const useMonitorSourceMaterialChanges = (
    sourceMaterialID: string,
    contentPath: ContentPath
): void => {
    const markContentWithUnsavedChanges = useMarkContentWithUnsavedChanges()
    const markContentWithSavedChanges = useMarkContentWithSavedChanges()

    const savableContent = useMemo(
        (): SavableContent => ({
            contentPath: contentPath,
            contentType: SavableContentType.sourceMaterial,
            id: sourceMaterialID,
        }),
        [contentPath, sourceMaterialID]
    )

    const currentSourceMaterialSavableData = useRecoilValue(
        sourceMaterialAtomFamily({ id: sourceMaterialID, contentPath })
    )
    const [lastSavedSourceMaterialData, setLastSavedSourceMaterialData] =
        useRecoilState(
            lastSavedSourceMaterialDataAtomFamily({
                id: sourceMaterialID,
                contentPath,
            })
        )

    useEffect(() => {
        if (lastSavedSourceMaterialData === null) {
            setLastSavedSourceMaterialData(currentSourceMaterialSavableData)
        }
    }, [
        currentSourceMaterialSavableData,
        lastSavedSourceMaterialData,
        setLastSavedSourceMaterialData,
    ])

    const hasChanges = useMemo((): boolean => {
        return !isEqual(
            currentSourceMaterialSavableData,
            lastSavedSourceMaterialData
        )
    }, [currentSourceMaterialSavableData, lastSavedSourceMaterialData])
    useEffect(() => {
        if (lastSavedSourceMaterialData === null) return
        if (hasChanges) {
            markContentWithUnsavedChanges(savableContent)
        } else {
            markContentWithSavedChanges(savableContent)
        }
    }, [
        hasChanges,
        markContentWithSavedChanges,
        markContentWithUnsavedChanges,
        savableContent,
    ])
}
