import styles from './ContentTierTitlesEditor.module.less'
import React, { ReactElement, useCallback, useState, useMemo } from 'react'
import { Button, Input } from 'antd'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
    frontendDisplayedCourseSelector,
    isActiveCourseReadOnlySelector,
} from '@/atoms/auth'
import { setContentTierTitlesForCourse } from '@/api/cb/contentCreatorContent'
import { ContentTierTitlesEditorProps } from '@/components/EditCourseMetadata/ContentTierTitlesEditor/ContentTierTitlesEditor.types'
import produce from 'immer'
import { contentTierTitlesSelectorFamily } from '@/atoms/contentTierTitles'
import { syncErrorMessage, syncSuccessMessage } from '@/utils/syncMessages'
import { areContentPathsEqual } from 'common/src/ContentPath'

const updateContentTierTitlesAtIndex = produce(
    (contentTierTitles: string[], newTitle: string, index: number): void => {
        contentTierTitles[index] = newTitle
    }
)

export const ContentTierTitlesEditor: React.FC<
    ContentTierTitlesEditorProps
> = (): ReactElement => {
    const courseName = useRecoilValue(frontendDisplayedCourseSelector)
    const isReadOnly = useRecoilValue(isActiveCourseReadOnlySelector)

    const [contentTierTitles, setContentTierTitles] = useRecoilState(
        contentTierTitlesSelectorFamily(courseName)
    )
    const [currentContentTierTitles, setCurrentContentTierTitles] =
        useState<string[]>(contentTierTitles)
    const hasUnsavedChanges = useMemo(
        (): boolean =>
            !areContentPathsEqual(currentContentTierTitles, contentTierTitles),
        [contentTierTitles, currentContentTierTitles]
    )
    const [
        isSetContentTierTitlesButtonLoading,
        setIsContentTierTitlesButtonLoading,
    ] = useState<boolean>(false)

    const saveContentTierTitles = useCallback(async (): Promise<void> => {
        setIsContentTierTitlesButtonLoading(true)
        const response = await setContentTierTitlesForCourse(
            courseName,
            contentTierTitles
        )
        if (response.data.isError) {
            syncErrorMessage(
                'Error updating content tier titles. You may want to try setting it again or refreshing the page.'
            )
        } else {
            setCurrentContentTierTitles(contentTierTitles)
            syncSuccessMessage('Content tier titles successfully updated')
        }
        setIsContentTierTitlesButtonLoading(false)
    }, [contentTierTitles, courseName])

    return (
        <div className={styles.contentTierTitlesEditor}>
            <div className={styles.contentTierTitlesEditorText}>
                Content tier titles:
            </div>
            <div className={styles.contentTierTitlesEditorInputsContainer}>
                {contentTierTitles.map((title, index) => {
                    return (
                        <Input
                            key={index}
                            value={title}
                            onChange={(e) => {
                                setContentTierTitles(
                                    updateContentTierTitlesAtIndex(
                                        contentTierTitles,
                                        e.target.value,
                                        index
                                    )
                                )
                            }}
                            disabled={isReadOnly}
                        />
                    )
                })}
            </div>
            <Button
                onClick={saveContentTierTitles}
                type={'primary'}
                loading={isSetContentTierTitlesButtonLoading}
                disabled={isReadOnly || !hasUnsavedChanges}
            >
                Save
            </Button>
        </div>
    )
}
