import {
    ContentCreatorEditableNotecardMetadata,
    parseContentCreatorEditableNotecardMetadata,
} from '@/components/ContentTreeNode/ContentTreeLeafNode/NotecardsEditor/NotecardEditor/NotecardMetadataEditor/NotecardMetadataEditor.types'
import { INotecard } from 'common/src/notecards/types'

export interface SavableNotecardData {
    contentCreatorEditableNotecardMetadata: ContentCreatorEditableNotecardMetadata
    stringifiedNotecardFront: string
    stringifiedNotecardBack: string
}

export const parseSavableNotecardData = (
    notecard: INotecard
): SavableNotecardData => ({
    contentCreatorEditableNotecardMetadata:
        parseContentCreatorEditableNotecardMetadata(notecard),
    stringifiedNotecardFront: notecard.front,
    stringifiedNotecardBack: notecard.back,
})
