import styles from './UploadExcelFileButton.module.less'
import React, { ReactElement, useCallback, useMemo, useState } from 'react'
import { FileUploader } from '@/components/utils/FileUploader/FileUploader'
import {
    getSignedUrlAndS3KeyForPracticeProblemExcelFileUpload,
    getSignedUrlAndS3KeyForPracticeProblemExcelSolutionFileUpload,
} from '@/api/cb/s3'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import {
    frontendDisplayedCourseSelector,
    isActiveCourseReadOnlySelector,
} from '@/atoms/auth'
import { UploadExcelFileButtonProps } from '@/components/ContentTreeNode/ContentTreeLeafNode/PracticeProblemsEditor/ExcelFileUploader/UploadExcelFileButton/UploadExcelFileButton.types'
import { practiceProblemAtomFamily } from '@/atoms/practiceProblem'
import { getContentTypeFromDataURL } from 'common/src/api/s3/utils'
import {
    setExcelS3Key,
    setExcelSolutionS3Key,
} from '@/components/ContentTreeNode/ContentTreeLeafNode/PracticeProblemsEditor/immer'
import { useLogger } from '@/hooks/useLogger'
import { syncErrorMessage, syncSuccessMessage } from '@/utils/syncMessages'
import { isUnsavedPracticeProblemSelectorFamily } from '@/atoms/newlyCreatedAndUnsavedContentIDs'

const ALLOWED_EXCEL_FILE_TYPES: string[] = [
    'application/vnd.ms-excel', // .xls
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
]

export const UploadExcelFileButton: React.FC<UploadExcelFileButtonProps> = (
    props
): ReactElement => {
    const courseName = useRecoilValue(frontendDisplayedCourseSelector)
    const isReadOnly = useRecoilValue(isActiveCourseReadOnlySelector)
    const [forceRemountIndex, setForceRemountIndex] = useState<number>(0)

    const setPracticeProblem = useSetRecoilState(
        practiceProblemAtomFamily(props.practiceProblemID)
    )
    const logger = useLogger(UploadExcelFileButton.name)

    const getSignedUrlAndS3KeyCallback = useMemo(
        () =>
            props.isSolution
                ? getSignedUrlAndS3KeyForPracticeProblemExcelSolutionFileUpload
                : getSignedUrlAndS3KeyForPracticeProblemExcelFileUpload,
        [props.isSolution]
    )
    const setS3KeyLocally = useMemo(
        () => (props.isSolution ? setExcelSolutionS3Key : setExcelS3Key),
        [props.isSolution]
    )

    /**
     * https://fullstackdojo.medium.com/s3-upload-with-presigned-url-react-and-nodejs-b77f348d54cc
     * https://stackoverflow.com/questions/43871637/no-access-control-allow-origin-header-is-present-on-the-requested-resource-whe
     */
    const handleFileUpload = useCallback(
        async (dataURL: string): Promise<void> => {
            const response = await getSignedUrlAndS3KeyCallback(
                courseName,
                props.practiceProblemID
            )
            if (response.data.isError) {
                logger.error(response.data.error)
                syncErrorMessage(
                    'Error uploading excel file. Please try again or refresh the page.'
                )
                return
            }
            const preSignedURL = response.data.payload.signedUrl
            const excelS3Key = response.data.payload.s3Key

            const base64StringData = dataURL.split(',')[1]
            const buffer = Buffer.from(base64StringData, 'base64')

            try {
                await fetch(preSignedURL, {
                    method: 'PUT',
                    headers: new Headers({
                        'Content-Type': getContentTypeFromDataURL(dataURL),
                    }),
                    mode: 'cors',
                    body: buffer,
                })
                setPracticeProblem((practiceProblem) =>
                    setS3KeyLocally(practiceProblem, excelS3Key)
                )
                syncSuccessMessage('Successfully uploaded excel file')
                props.onUpload && props.onUpload()
                setForceRemountIndex((current) => current + 1)
            } catch (e) {
                logger.error(`Error uploading excel file: ${e}`)
                syncErrorMessage(
                    'Error uploading excel file. Please try again or refresh the page.'
                )
            }
        },
        [
            courseName,
            getSignedUrlAndS3KeyCallback,
            logger,
            props,
            setPracticeProblem,
            setS3KeyLocally,
        ]
    )

    const isDisabled = useRecoilValue(
        isUnsavedPracticeProblemSelectorFamily(props.practiceProblemID)
    )

    const disabledMessage = useMemo((): string | undefined => {
        if (isDisabled) return 'Practice problem has not yet been saved'
        if (isReadOnly) return 'Course is read-only'
        return undefined
    }, [isDisabled, isReadOnly])
    return (
        <FileUploader
            allowedFileTypes={ALLOWED_EXCEL_FILE_TYPES}
            handleFileUpload={handleFileUpload}
            buttonText={
                'Click or drop here to upload excel file (.xls or .xlsx)'
            }
            className={styles.excelUploader}
            disabledMessage={disabledMessage}
            key={`${props.practiceProblemID}-${forceRemountIndex}`}
        />
    )
}
