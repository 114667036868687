import styles from './AddSourceMaterialTypeButton.module.less'
import React, { ReactElement, useState } from 'react'
import { Button, Modal } from 'antd'
import { PlusCircleOutlined } from '@ant-design/icons'
import { useRecoilValue } from 'recoil'
import { isActiveCourseReadOnlySelector } from '@/atoms/auth'
import { AddSourceMaterialType } from './AddSourceMaterialType/AddSourceMaterialType'

export const AddSourceMaterialTypeButton: React.FC<
    unknown
> = (): ReactElement => {
    const isReadOnly = useRecoilValue(isActiveCourseReadOnlySelector)
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false)

    return (
        <div className={styles.addSourceMaterialTypeButtonContainer}>
            <Button
                onClick={() => setIsModalVisible(true)}
                size={'large'}
                disabled={isReadOnly}
            >
                <PlusCircleOutlined /> <span>Add source material type</span>
            </Button>
            <Modal
                open={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                footer={null}
                destroyOnClose={true}
            >
                <AddSourceMaterialType
                    closeModal={() => setIsModalVisible(false)}
                />
            </Modal>
        </div>
    )
}
