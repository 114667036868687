import { CancelToken } from 'axios'
import { APIResponseWrapper, CB_BASE_PREFIX } from '@/api/types'
import {
    AddPreviousExamContentRequest,
    AddPreviousExamContentResponse,
} from 'common/src/api/contentFrontendVsContentBackend/previousExamContent'
import { callBackend } from '@/api/callBackend'
import { ApiMethod } from 'common/src/api/types'
import { GetSignedUrlResponse } from 'common/src/api/s3/responses'
import { GenericContentRequest } from 'common/src/api/core/courses/requests'

const PREFIX = CB_BASE_PREFIX + '/previousExamAnalysis'

export const uploadPreviousExamAnalysis = async (
    courseName: string,
    csvFileString: string,
    cancelToken: CancelToken | null = null
): APIResponseWrapper<AddPreviousExamContentResponse> => {
    return await callBackend<
        AddPreviousExamContentRequest,
        AddPreviousExamContentResponse
    >(
        PREFIX + '/uploadPreviousExamAnalysis',
        ApiMethod.POST,
        { courseName, csvFileString },
        cancelToken
    )
}

export const getExistingPreviousExamAnalysisSignedUrl = async (
    courseName: string,
    cancelToken: CancelToken | null = null
): APIResponseWrapper<GetSignedUrlResponse> => {
    return await callBackend<GenericContentRequest, GetSignedUrlResponse>(
        PREFIX + '/getExistingPreviousExamAnalysisSignedUrl',
        ApiMethod.GET,
        { courseName },
        cancelToken
    )
}

export const getSamplePreviousExamAnalysisSignedUrl = async (
    cancelToken: CancelToken | null = null
): APIResponseWrapper<GetSignedUrlResponse> => {
    return await callBackend<null, GetSignedUrlResponse>(
        PREFIX + '/getSamplePreviousExamAnalysisSignedUrl',
        ApiMethod.GET,
        null,
        cancelToken
    )
}
