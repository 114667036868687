import React, { ReactElement, useCallback, useMemo, useState } from 'react'
import { LeafNodeContentEditorButtonProps } from '@/components/ContentTreeNode/ContentTreeLeafNode/LeafNodeContentEditorButton/LeafNodeContentEditorButton.types'
import { Button, Checkbox, Modal } from 'antd'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import {
    viewingStateAtom,
    viewingStateTypeSelector,
} from '@/atoms/viewingState'
import { hashContentPath } from 'common/src/ContentPath'
import { reusableCssClass } from '@/utils/reusableCssClasses'
import styles from './LeafNodeContentEditorButton.module.less'
import { authStateAtom } from '@/atoms/auth'
import { WarningOutlined } from '@ant-design/icons'
import { setReceivePopups } from '@/api/cb/contentCreatorAccount'
import { logger } from '@/logging/FrontendLogger'

export const LeafNodeContentEditorButton: React.FC<
    LeafNodeContentEditorButtonProps
> = (props): ReactElement => {
    const viewingState = useRecoilValue(viewingStateAtom)
    const setActiveViewType = useSetRecoilState(viewingStateTypeSelector)
    const [isAreYouSureModalVisible, setIsAreYouSureModalVisible] =
        useState<boolean>(false)
    const [authState, setAuthState] = useRecoilState(authStateAtom)
    const isModalVisible = useMemo(
        (): boolean =>
            viewingState.viewingStateType === props.type &&
            hashContentPath(viewingState.contentPath) ===
                props.hashedContentPath,
        [viewingState, props]
    )
    const [receivePopupsFrontend, setReceivePopupsFrontend] = useState(true)
    const clickOkOnWarningModalHandler = useCallback(
        async (
            e: React.MouseEvent<HTMLButtonElement, MouseEvent>
        ): Promise<void> => {
            e.stopPropagation()

            if (receivePopupsFrontend !== authState.receivePopups) {
                try {
                    setAuthState((authState) => ({
                        ...authState,
                        receivePopups: receivePopupsFrontend,
                    }))
                    setReceivePopups(receivePopupsFrontend)
                } catch (error) {
                    logger.info(`Failed to set receive popups on backend`)
                }
            }

            setIsAreYouSureModalVisible(false)
            setActiveViewType(null)
        },
        [
            authState.receivePopups,
            receivePopupsFrontend,
            setActiveViewType,
            setAuthState,
        ]
    )

    return (
        <div className={styles.leafNodeContentEditorButton}>
            <Modal
                className={'defensive-delete-button-modal'}
                title={
                    <>
                        <div className={'warning-container'}>
                            <WarningOutlined />
                        </div>
                        <div
                            className={
                                'defensive-delete-button-modal-title-text-container'
                            }
                        >
                            {'Warning: Unsaved Changes'}
                        </div>
                        <div className={'warning-container'}>
                            <WarningOutlined />
                        </div>
                    </>
                }
                open={isAreYouSureModalVisible}
                onOk={clickOkOnWarningModalHandler}
                closable={false}
                okType={'danger'}
                okText={'Yes, close now'}
                okButtonProps={{ type: 'primary' }}
                cancelText={'No, go back'}
                onCancel={(e) => {
                    e.stopPropagation()
                    setIsAreYouSureModalVisible(false)
                }}
            >
                <div>
                    {
                        'You have unsaved changes. Are you sure you want to close?'
                    }
                    <br />
                    <Checkbox
                        onChange={(event) =>
                            setReceivePopupsFrontend(!event.target.checked)
                        }
                    />{' '}
                    {` Don't ask me again`}
                </div>
            </Modal>
            <Modal
                className={styles.leafNodeContentEditorModal}
                open={isModalVisible}
                onCancel={() => {
                    if (authState.receivePopups && props.hasUnsavedChanges) {
                        setIsAreYouSureModalVisible(true)
                    } else {
                        setActiveViewType(null)
                    }
                }}
                footer={null}
                destroyOnClose={false}
            >
                {props.modalContent}
            </Modal>
            <Button
                onClick={() => setActiveViewType(props.type)}
                className={
                    props.hasUnsavedChanges &&
                    reusableCssClass.hasUnsavedChanges
                }
                size={'large'}
            >
                {props.buttonText}
            </Button>
        </div>
    )
}
