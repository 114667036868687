import React, {
    ReactElement,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react'
import styles from './PracticeProblemsEditor.module.less'
import { PracticeProblemsEditorProps } from '@/components/ContentTreeNode/ContentTreeLeafNode/PracticeProblemsEditor/PracticeProblemsEditor.types'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { slimLeafNodeAtomFamily } from '@/atoms/slimLeafNode'
import {
    frontendDisplayedCourseSelector,
    isActiveCourseReadOnlySelector,
} from '@/atoms/auth'
import { unHashContentPath } from 'common/src/ContentPath'
import { Button } from 'antd'
import { DraggableSortingList } from '@/components/utils/DraggableSortingList/DraggableSortingList'
import { PracticeProblemEditor } from '@/components/ContentTreeNode/ContentTreeLeafNode/PracticeProblemsEditor/PracticeProblemEditor/PracticeProblemEditor'
import { DeletePracticeProblemButton } from '@/components/ContentTreeNode/ContentTreeLeafNode/PracticeProblemsEditor/DeletePracticeProblemButton/DeletePracticeProblemButton'
import { ExcelFileUploader } from '@/components/ContentTreeNode/ContentTreeLeafNode/PracticeProblemsEditor/ExcelFileUploader/ExcelFileUploader'
import {
    deletePracticeProblemAtContentPath,
    reorderPracticeProblems,
} from '@/api/cb/contentCreatorContent'
import { createNewDefaultPracticeProblem } from '@/components/ContentTreeNode/ContentTreeLeafNode/PracticeProblemsEditor/createNewDefaultPracticeProblem'
import {
    addNewPracticeProblemID,
    deletePracticeProblemID,
    reorderPracticeProblemID,
} from '@/components/ContentTreeNode/ContentTreeLeafNode/immer'
import { LoadingComponent } from '@/components/utils/LoadingComponent/LoadingComponent'
import {
    DraggableIconType,
    DraggablePracticeProblemData,
} from '@/components/utils/DraggableSortingList/DraggableIcon/DraggableIcon.types'
import { syncErrorMessage, syncSuccessMessage } from '@/utils/syncMessages'
import { activePracticeProblemIDSelector } from '@/atoms/viewingState'
import { useSetLocalOnlyPracticeProblemData } from '@/hooks/useSetLocalOnlyPracticeProblemData'
import { useDoesSpecificContentHaveUnsavedChanges } from '@/hooks/useDoesSpecificContentHaveUnsavedChanges'
import {
    SavableContentType,
    unsavedChangesSetAtom,
} from '@/atoms/unsavedChanges'
import {
    useMarkContentWithUnsavedChanges,
    useMarkContentWithSavedChanges,
} from '@/hooks/useMarkContentWithUnsavedChanges'
import { newlyCreatedAndUnsavedPracticeProblemIDsAtom } from '@/atoms/newlyCreatedAndUnsavedContentIDs'
import { activeCourseConfigSelector } from '@/atoms/courseInfo'
import { reusableCssClass } from '@/utils/reusableCssClasses'

export const PracticeProblemsEditor: React.FC<PracticeProblemsEditorProps> = (
    props
): ReactElement => {
    const [{ practiceProblemIDs }, setSlimLeafNode] = useRecoilState(
        slimLeafNodeAtomFamily(props.hashedContentPath)
    )
    const contentPath = useMemo(
        () => unHashContentPath(props.hashedContentPath),
        [props.hashedContentPath]
    )
    const courseName = useRecoilValue(frontendDisplayedCourseSelector)
    const isReadOnly = useRecoilValue(isActiveCourseReadOnlySelector)

    const [recoilActivePracticeProblemID, setRecoilActivePracticeProblemID] =
        useRecoilState(activePracticeProblemIDSelector)
    const [
        localActivePracticeProblemIndex,
        setLocalActivePracticeProblemIndex,
    ] = useState<number>(
        Math.max(practiceProblemIDs.indexOf(recoilActivePracticeProblemID), 0)
    )

    // keep effective and active in sync. BE VERY CAREFUL when touching.
    const [localActivePracticeProblemID, setLocalActivePracticeProblemID] =
        useState<string>(practiceProblemIDs[localActivePracticeProblemIndex])
    useEffect(() => {
        if (isReordering) return
        if (recoilActivePracticeProblemID !== localActivePracticeProblemID) {
            setRecoilActivePracticeProblemID(localActivePracticeProblemID)
        }
    }, [localActivePracticeProblemID, practiceProblemIDs])
    useEffect(() => {
        if (isReordering) return
        if (localActivePracticeProblemIndex === undefined) return

        const newLocalActivePracticeProblemID =
            practiceProblemIDs[localActivePracticeProblemIndex]
        if (newLocalActivePracticeProblemID === undefined) return

        if (newLocalActivePracticeProblemID !== localActivePracticeProblemID) {
            setLocalActivePracticeProblemID(newLocalActivePracticeProblemID)
        }
    }, [localActivePracticeProblemIndex, practiceProblemIDs])
    useEffect(() => {
        if (isReordering) return
        if (
            recoilActivePracticeProblemID &&
            recoilActivePracticeProblemID !==
                practiceProblemIDs[localActivePracticeProblemIndex]
        ) {
            const newActiveIndex = practiceProblemIDs.indexOf(
                recoilActivePracticeProblemID
            )
            if (newActiveIndex >= 0) {
                setLocalActivePracticeProblemIndex(newActiveIndex)
            }
        }
    }, [recoilActivePracticeProblemID, practiceProblemIDs])

    const markContentWithUnsavedChanges = useMarkContentWithUnsavedChanges()

    const setLocalOnlyPracticeProblemData = useSetLocalOnlyPracticeProblemData()
    const setNewlyCreatedAndUnsavedPracticeProblemIDs = useSetRecoilState(
        newlyCreatedAndUnsavedPracticeProblemIDsAtom
    )
    const courseConfig = useRecoilValue(activeCourseConfigSelector)

    const addNewPracticeProblem = useCallback((): void => {
        const newPracticeProblem = createNewDefaultPracticeProblem(
            practiceProblemIDs.length,
            contentPath,
            courseName,
            courseConfig
        )
        setLocalOnlyPracticeProblemData(newPracticeProblem)
        setSlimLeafNode((slimLeafNode) =>
            addNewPracticeProblemID(slimLeafNode, newPracticeProblem.id)
        )
        markContentWithUnsavedChanges({
            contentPath: newPracticeProblem.contentPath,
            contentType: SavableContentType.practiceProblem,
            id: newPracticeProblem.id,
        })
        setLocalActivePracticeProblemIndex(practiceProblemIDs.length)
        setLocalActivePracticeProblemID(newPracticeProblem.id)
        setRecoilActivePracticeProblemID(newPracticeProblem.id)
        setNewlyCreatedAndUnsavedPracticeProblemIDs((current) =>
            current.add(newPracticeProblem.id)
        )
    }, [
        contentPath,
        courseName,
        markContentWithUnsavedChanges,
        practiceProblemIDs.length,
        setLocalOnlyPracticeProblemData,
        setNewlyCreatedAndUnsavedPracticeProblemIDs,
        setRecoilActivePracticeProblemID,
        setSlimLeafNode,
        courseConfig,
    ])

    const [isReordering, setIsReordering] = useState<boolean>(false)
    const reorderPracticeProblemsCallback = useCallback(
        async (startingIndex: number, endingIndex: number): Promise<void> => {
            setIsReordering(true)
            const practiceProblemIDToBeReordered =
                practiceProblemIDs[startingIndex]
            const response = await reorderPracticeProblems(
                courseName,
                practiceProblemIDToBeReordered,
                contentPath,
                startingIndex,
                endingIndex
            )
            if (response.data.isError) {
                syncErrorMessage(
                    'Error reordering problems. You may want to try reordering the problem again or refreshing the page.'
                )
            } else {
                setSlimLeafNode((slimLeafNode) =>
                    reorderPracticeProblemID(
                        slimLeafNode,
                        startingIndex,
                        endingIndex
                    )
                )
                setLocalActivePracticeProblemIndex(endingIndex)
                setLocalActivePracticeProblemID(practiceProblemIDToBeReordered)
                setRecoilActivePracticeProblemID(practiceProblemIDToBeReordered)
                syncSuccessMessage('Successfully reordered problems')
            }
            setIsReordering(false)
        },
        [
            practiceProblemIDs,
            courseName,
            contentPath,
            setSlimLeafNode,
            setRecoilActivePracticeProblemID,
        ]
    )

    const unsavedChangesSet = useRecoilValue(unsavedChangesSetAtom)
    const doesSpecificContentHaveUnsavedChanges =
        useDoesSpecificContentHaveUnsavedChanges(unsavedChangesSet)
    const practiceProblemsData = useMemo(
        (): DraggablePracticeProblemData[] =>
            practiceProblemIDs.map((id) => ({
                type: DraggableIconType.practiceProblem,
                id,
                hasUnsavedChanges: doesSpecificContentHaveUnsavedChanges({
                    contentPath,
                    contentType: SavableContentType.practiceProblem,
                    id,
                }),
            })),
        [contentPath, doesSpecificContentHaveUnsavedChanges, practiceProblemIDs]
    )

    const markContentWithSavedChanges = useMarkContentWithSavedChanges()
    const deletePracticeProblem = useCallback(async (): Promise<void> => {
        const response = await deletePracticeProblemAtContentPath(
            courseName,
            contentPath,
            localActivePracticeProblemID
        )
        if (response.data.isError) {
            syncErrorMessage(
                'Error deleting problem. You may want to try deleting the problem again or refreshing the page.'
            )
        } else {
            setLocalActivePracticeProblemIndex(
                Math.max(0, localActivePracticeProblemIndex - 1)
            )
            setSlimLeafNode((slimLeafNode) =>
                deletePracticeProblemID(
                    slimLeafNode,
                    localActivePracticeProblemID
                )
            )
            markContentWithSavedChanges({
                contentPath,
                contentType: SavableContentType.practiceProblem,
                id: localActivePracticeProblemID,
            })
            syncSuccessMessage('Successfully deleted problem')
        }
    }, [
        courseName,
        contentPath,
        localActivePracticeProblemID,
        localActivePracticeProblemIndex,
        setSlimLeafNode,
        markContentWithSavedChanges,
    ])

    const unsavedChangesWarning = useMemo((): ReactElement => {
        if (
            !doesSpecificContentHaveUnsavedChanges({
                contentPath,
                contentType: SavableContentType.practiceProblem,
                id: localActivePracticeProblemID,
            })
        ) {
            return
        }
        return (
            <div className={styles.warningText}>
                {doesSpecificContentHaveUnsavedChanges &&
                    'WARNING: THERE ARE UNSAVED CHANGES'}
            </div>
        )
    }, [
        contentPath,
        doesSpecificContentHaveUnsavedChanges,
        localActivePracticeProblemID,
    ])

    return (
        <div className={styles.practiceProblemsEditor}>
            <div className={styles.practiceProblemsEditorTitle}>
                Edit Practice Problems ({practiceProblemIDs.length})
            </div>
            <div>
                <Button
                    type={'primary'}
                    onClick={addNewPracticeProblem}
                    disabled={isReadOnly}
                >
                    Add New Problem
                </Button>
            </div>
            <div>
                Select problem to edit (can reorder by dragging and dropping):
                <div className={styles.practiceProblemList}>
                    <React.Suspense
                        fallback={
                            <LoadingComponent useWhiteBackground={true} />
                        }
                    >
                        <DraggableSortingList
                            itemData={practiceProblemsData}
                            changeIndex={reorderPracticeProblemsCallback}
                            activeIndex={localActivePracticeProblemIndex}
                            setActiveIndex={setLocalActivePracticeProblemIndex}
                            isLoading={isReordering}
                            isDisabled={isReadOnly}
                        />
                    </React.Suspense>
                </div>
            </div>
            {practiceProblemIDs.length ? (
                <React.Suspense
                    fallback={<LoadingComponent useWhiteBackground={true} />}
                >
                    <div className={styles.practiceProblemSubContainer}>
                        <div
                            className={
                                reusableCssClass.centerChildrenVertically
                            }
                        >
                            <DeletePracticeProblemButton
                                deletePracticeProblem={deletePracticeProblem}
                            />
                        </div>
                        {unsavedChangesWarning}
                    </div>
                    {courseConfig.hasExcel && (
                        <>
                            <ExcelFileUploader
                                practiceProblemID={localActivePracticeProblemID}
                                isSolution={false}
                            />
                            <ExcelFileUploader
                                practiceProblemID={localActivePracticeProblemID}
                                isSolution={true}
                            />
                        </>
                    )}
                    <PracticeProblemEditor
                        key={localActivePracticeProblemID}
                        practiceProblemID={localActivePracticeProblemID}
                    />
                </React.Suspense>
            ) : (
                <div>No active problems to display.</div>
            )}
        </div>
    )
}
