import { atomFamily, selectorFamily } from 'recoil'
import { frontendDisplayedCourseSelector } from '@/atoms/auth'
import { ISlimLeafNodeData } from 'common/src/api/backendToBackend/content/types'
import { getSlimLeafNode } from '@/api/cb/contentCreatorContent'
import { ContentPath, unHashContentPath } from 'common/src/ContentPath'

/**
 * Notecard practice problems are already filtered out
 */
export const fetchSlimLeafNode = async (
    courseName: string,
    contentPath: ContentPath
): Promise<ISlimLeafNodeData> => {
    const response = await getSlimLeafNode(courseName, contentPath)
    return response.data.payload
}

export const slimLeafNodeAtomFamily = atomFamily<
    ISlimLeafNodeData | null,
    string | null
>({
    key: 'slimLeafNodeAtomFamily',
    default: selectorFamily<ISlimLeafNodeData | null, string | null>({
        key: 'slimLeafNodeDefaultSelectorFamily',
        get:
            (hashedContentPath) =>
            async ({ get }) => {
                if (hashedContentPath === null) return null
                const frontendDisplayedCourse = get(
                    frontendDisplayedCourseSelector
                )
                return await fetchSlimLeafNode(
                    frontendDisplayedCourse,
                    unHashContentPath(hashedContentPath)
                )
            },
    }),
})

export const slimLeafNodeForOtherCoursesAtomFamily = atomFamily<
    ISlimLeafNodeData | null,
    { courseName: string; contentPath: ContentPath }
>({
    key: 'slimLeafNodeAtomFamily',
    default: selectorFamily<
        ISlimLeafNodeData | null,
        { courseName: string; contentPath: ContentPath }
    >({
        key: 'slimLeafNodeForOtherCoursesAtomFamily',
        get:
            ({ courseName, contentPath }) =>
            async () => {
                if (contentPath.length < 3) return null
                return await fetchSlimLeafNode(courseName, contentPath)
            },
    }),
})
