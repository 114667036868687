import React, { ReactElement, useCallback, useMemo } from 'react'
import { UiLayoutConstant } from '@/globals/uiLayoutConstant'
import NProgress from 'nprogress'
import { useRecoilValue } from 'recoil'
import { isLoggedInAtom } from '@/atoms/isLoggedIn'
import { useLogger } from '@/hooks/useLogger'
import { Error404 } from '@/components/Error404/Error404'
import { FrameProps } from '@/components/layout/Frame/Frame.types'
import { SplashPage } from '@/components/SplashPage/SplashPage'
import { PrimaryDashboard } from '@/components/PrimaryDashboard/PrimaryDashboard'
import { SignupPage } from '@/components/SignupPage/SignupPage'
import { ResetPasswordPage } from '@/components/ResetPasswordPage/ResetPasswordPage'

NProgress.configure({ parent: '.loading-bar-container' })
export const Frame: React.FC<FrameProps> = (props): ReactElement => {
    const logger = useLogger(Frame.name)

    const isLoggedIn = useRecoilValue(isLoggedInAtom)

    const renderPage = useCallback(
        (page: UiLayoutConstant): ReactElement => {
            if (isLoggedIn) {
                switch (page) {
                    case UiLayoutConstant.PRIMARY_DASHBOARD_PAGE:
                    case UiLayoutConstant.SPLASH_PAGE:
                    case UiLayoutConstant.SIGN_UP:
                        return <PrimaryDashboard />
                }
            }

            // Fall through even if logged in
            switch (page) {
                case UiLayoutConstant.PRIMARY_DASHBOARD_PAGE:
                case UiLayoutConstant.SPLASH_PAGE:
                    return <SplashPage />
                case UiLayoutConstant.ERROR_404_PAGE:
                    return <Error404 />
                case UiLayoutConstant.SIGN_UP:
                    return <SignupPage />
                case UiLayoutConstant.RESET_PASSWORD_PAGE:
                    return <ResetPasswordPage />
                default:
                    logger.error(`Invalid page requested: ${page}`)
                    return <SplashPage />
            }
        },
        [isLoggedIn, logger]
    )

    return useMemo(
        (): ReactElement => renderPage(props.page),
        [props.page, renderPage]
    )
}
