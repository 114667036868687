import React, { ReactElement, useMemo, useRef } from 'react'
import { Button, Form, Input } from 'antd'
import { cbLogin } from '@/api/cb/contentFrontendAuthentication'
import {
    ILoginForm,
    LoginFormProps,
} from '@/components/authentication/LoginForm/LoginForm.types'
import { frontendLogin } from '@/auth/frontendLogin'
import { useIsSingleColumn } from '@/hooks/useIsSingleColumn'
import styles from './LoginForm.module.less'
import { wbLogin } from '@/api/wb/authentication'
import { reusableCssClass } from '@/utils/reusableCssClasses'
import { logger } from '@/logging/FrontendLogger'

// TODO: Handle errors?
export const LoginForm: React.FC<LoginFormProps> = (props): ReactElement => {
    // login to both cb and wb
    const handleSubmit = async (values: ILoginForm): Promise<void> => {
        const cbResponse = await cbLogin(
            values.loginEmail,
            values.loginPassword
        )
        if (!cbResponse.data.success) {
            return
        }

        const wbResponse = await wbLogin(
            values.loginEmail,
            values.loginPassword
        )
        if (!wbResponse.data.success) {
            const isDev = process.env.NEXT_PUBLIC_JEP_MODE !== 'prod'

            if (isDev) {
                logger.info(
                    `WB login failed, but this is dev, so allowing it to continue.`
                )
            } else {
                return
            }
        }
        await frontendLogin(cbResponse.data.payload)
    }

    const forgotPasswordContainerRef = useRef<HTMLDivElement>(null)
    const loginButtonContainerRef = useRef<HTMLDivElement>(null)
    const isSingleColumn = useIsSingleColumn(
        forgotPasswordContainerRef,
        loginButtonContainerRef
    )
    const columnClassName = useMemo(
        (): string =>
            isSingleColumn ? styles.isSingleColumn : styles.isDualColumn,
        [isSingleColumn]
    )

    return (
        <div className={styles.loginForm}>
            <div className={styles.loginFormTitle}>Sign In</div>
            <Form onFinish={handleSubmit}>
                <Form.Item
                    name={'loginEmail'}
                    rules={[{ required: true, message: 'Email is required' }]}
                >
                    <Input placeholder="Email" />
                </Form.Item>
                <Form.Item
                    name={'loginPassword'}
                    rules={[
                        { required: true, message: 'Password is required' },
                    ]}
                >
                    <Input.Password type="password" placeholder="Password" />
                </Form.Item>
                <div className={styles.forgotPasswordAndLoginButtonContainer}>
                    <div
                        className={`${reusableCssClass.clickableText} ${styles.forgotPasswordContainer} ${columnClassName}`}
                        onClick={props.changeToForgotPassword}
                        id={'cypress-forgot-your-password-link'}
                        ref={forgotPasswordContainerRef}
                    >
                        Forgot your password?
                    </div>
                    <Form.Item>
                        <div
                            className={`${styles.btnContainer} ${columnClassName}`}
                            ref={loginButtonContainerRef}
                        >
                            <Button
                                id={'cypress-login-button'}
                                type={'primary'}
                                htmlType={'submit'}
                            >
                                Login
                            </Button>
                        </div>
                    </Form.Item>
                </div>
            </Form>
        </div>
    )
}
