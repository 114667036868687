import React, { ReactElement } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { reusableCssClass } from '@/utils/reusableCssClasses'
import { viewingStateAtom, ViewingStateType } from '@/atoms/viewingState'
import { sourceMaterialIDsForTypeSelector } from '@/atoms/sourceMaterialTypes'
import { ContentPath } from 'common/src/ContentPath'

interface SourceMaterialIDsListProps {
    type: string
}

export const SourceMaterialIDsList: React.FC<SourceMaterialIDsListProps> = (
    props
): ReactElement => {
    const sourceMaterialIDs = useRecoilValue(
        sourceMaterialIDsForTypeSelector(props.type)
    )

    const setViewingState = useSetRecoilState(viewingStateAtom)

    const redirectToSourceMaterialID = (
        contentPath: ContentPath,
        sourceMaterialID: string
    ): void => {
        setViewingState({
            contentPath,
            viewingStateType: ViewingStateType.sourceMaterial,
            id: sourceMaterialID,
        })
    }

    return (
        <ul className={'source-material-ids-list'}>
            {sourceMaterialIDs.map(({ id, contentPath }) => {
                return (
                    <li
                        key={id}
                        className={`source-material-ids-list-item ${reusableCssClass.clickableText}`}
                        onClick={() =>
                            redirectToSourceMaterialID(contentPath, id)
                        }
                    >
                        {id}
                    </li>
                )
            })}
        </ul>
    )
}
