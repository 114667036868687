import { atom, selector, selectorFamily } from 'recoil'
import {
    getNotecardCategoryStatsForCourse,
    getNotecardCategoriesForCourse,
} from '@/api/cb/contentCreatorContent'
import { frontendDisplayedCourseSelector } from '@/atoms/auth'
import { NotecardCategoryStats } from 'common/src/api/contentFrontendVsContentBackend/responses'

export const notecardCategoryStatsInvalidatorAtom = atom<number>({
    key: 'notecardCategoryStatsInvalidatorAtom',
    default: 0,
})

export const notecardCategoryStatsAtomNonMemoizing =
    atom<NotecardCategoryStats>({
        key: 'notecardCategoryStatsAtomNonMemoizing',
        default: selector<NotecardCategoryStats>({
            key: 'notecardCategoryStatsAtomNonMemoizingFetcher',
            get: async ({ get }) => {
                get(notecardCategoryStatsInvalidatorAtom)
                return (
                    await getNotecardCategoryStatsForCourse({
                        courseName: get(frontendDisplayedCourseSelector),
                    })
                ).data.payload.notecardCategoryStats
            },
        }),
    })

export const notecardIDsForCategorySelector = selectorFamily<string[], string>({
    key: 'notecardIDsForCategorySelector',
    get:
        (category) =>
        ({ get }) => {
            return get(notecardCategoryStatsAtomNonMemoizing)[category]
        },
})

export const notecardCategoriesInvalidatorAtom = atom<number>({
    key: 'notecardCategoriesInvalidatorAtom',
    default: 0,
})
export const notecardCategoriesAtom = atom<string[]>({
    key: 'notecardCategoriesAtom',
    default: selector<string[]>({
        key: 'notecardCategoriesFetcher',
        get: async ({ get }) => {
            get(notecardCategoriesInvalidatorAtom)
            return (
                await getNotecardCategoriesForCourse({
                    courseName: get(frontendDisplayedCourseSelector),
                })
            ).data.payload.notecardCategories
        },
    }),
})
