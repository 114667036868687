import {
    getContentTypeFromOutlineOrSummary,
    OutlineOrSummary,
    UpvoteAction,
} from 'common/src/commentThread/types'
import { ContentPath } from 'common/src/ContentPath'
import {
    AddOrRemove,
    AnnotatedContentMap,
    AnnotatedContentProvider,
    AnnotationSelection,
    CommentThreadData,
    serializeAnnotationSelection,
} from 'magic-text-box-lexical/src/context/AnnotatedContentContext/AnnotatedContent.types'
import {
    getOutlineAndSummaryCommentThreadsForContentPath,
    addCommentToThread,
    enactUpvoteActionOnComment,
} from '@/api/wb/commentThread'
import { encodeOutlineCommentData } from 'common/src/commentThread/OutlineCommentData'

// No starring for content creators
export const buildAnnotatedContentProvider = (
    courseName: string,
    contentPath: ContentPath,
    type: OutlineOrSummary
): AnnotatedContentProvider => {
    const contentType = getContentTypeFromOutlineOrSummary(type)

    return {
        loadAnnotatedContentMap: async (): Promise<AnnotatedContentMap> => {
            // TODO: Alter API to just pull one at a time, separately
            const response =
                await getOutlineAndSummaryCommentThreadsForContentPath({
                    courseName,
                    contentPath,
                })
            const commentThreadDataArray =
                type === 'outline'
                    ? response.data.payload.outlineCommentThreadDataArray
                    : response.data.payload.summaryCommentThreadDataArray
            // convert dates that are actually strings back into dates
            commentThreadDataArray.forEach((commentThread) => {
                commentThread.commentMetadataArray.forEach(
                    (commentMetadata) => {
                        commentMetadata.createdAt = new Date(
                            commentMetadata.createdAt
                        )
                        commentMetadata.updatedAt = new Date(
                            commentMetadata.updatedAt
                        )
                    }
                )
            })
            const commentThreadMap = new Map<string, CommentThreadData>()
            for (const commentThreadData of commentThreadDataArray) {
                const id = serializeAnnotationSelection(
                    commentThreadData.annotationSelection
                )
                commentThreadMap.set(id, {
                    id,
                    comments: commentThreadData.commentMetadataArray,
                    selection: commentThreadData.annotationSelection,
                })
            }
            return { commentThreadMap, starredSectionMap: new Map() }
        },
        // No starring for content creators
        starSection: async (
            annotationSelection: AnnotationSelection,
            addOrRemove: AddOrRemove
        ) => {
            return undefined
        },
        addComment: async (
            annotationSelection: AnnotationSelection,
            stringifiedCommentContent: string,
            isAnonymous: boolean
        ) => {
            const response = await addCommentToThread({
                text: stringifiedCommentContent,
                isAnonymous,
                contentPath,
                courseName,
                contentType,
                contentId: encodeOutlineCommentData({
                    contentPath,
                    type,
                    annotationSelection,
                }),
            })
            return {
                commentID: response.data.payload.commentID,
                timestamp: new Date(response.data.payload.timestampDateStr),
            }
        },
        likeComment: async (
            annotationSelection: AnnotationSelection,
            commentID: string,
            addOrRemove: AddOrRemove
        ) => {
            await enactUpvoteActionOnComment({
                contentPath,
                courseName,
                contentType,
                contentId: encodeOutlineCommentData({
                    contentPath,
                    type,
                    annotationSelection,
                }),
                commentId: commentID,
                upvoteAction:
                    addOrRemove === 'add'
                        ? UpvoteAction.ADD
                        : UpvoteAction.REMOVE,
            })
            return undefined
        },
    }
}
