import styles from './PostComment.module.less'
import { addCommentToThread } from '@/api/wb/commentThread'
import React, { ReactElement, useCallback } from 'react'
import { PostCommentProps } from '@/components/ContentCommentThread/PostComment/PostComment.types'
import { fetchComments } from '@/atoms/contentCommentThreads/utils'
import { CommentThreadEditor } from 'magic-text-box-lexical/src/plugins/ReadOnlyAnnotatedContentPlugin/CommentThreadEditor/CommentThreadEditor'

// DO NOT USE RECOIL HERE
export const PostComment: React.FC<PostCommentProps> = (
    props
): ReactElement => {
    const onSubmit = useCallback(
        async (
            serializedCommentContent: string,
            isAnonymous: boolean
        ): Promise<void> => {
            await addCommentToThread({
                contentType: props.contentType,
                contentId: props.contentId,
                text: serializedCommentContent,
                isAnonymous: false, // no anonymous for content creators
                courseName: props.courseName,
                contentPath: props.contentPath,
            })
            const updatedComments = await fetchComments({
                contentId: props.contentId,
                contentType: props.contentType,
                courseName: props.courseName,
            })
            props.setCommentMetadataArray(updatedComments)
            props.onSubmit && props.onSubmit()
        },
        [props]
    )

    return (
        <div className={styles.postComment}>
            <CommentThreadEditor
                id={props.contentId}
                autoFocus={false}
                placeholder={'Add comment'}
                minHeightInPx={80}
                onSubmit={onSubmit}
                cannotPostAnonymously={true}
                canInsertUniversalContentLink={true}
            />
        </div>
    )
}
