import { CopyLeafNodeButtonProps } from '@/components/ContentTreeNode/ContentTreeLeafNode/CopyLeafNodeButton/CopyLeafNodeButton.types'
import React, { ReactElement } from 'react'
import { CopyLeafNode } from '@/components/ContentTreeNode/ContentTreeLeafNode/CopyLeafNodeButton/CopyLeafNode/CopyLeafNode'
import styles from './CopyLeafNodeButton.module.less'
import { LoadingComponent } from '@/components/utils/LoadingComponent/LoadingComponent'
import { useRecoilValue } from 'recoil'
import { courseNamesForContentCreatorAtom } from '@/atoms/courseNamesForContentCreator'
import { ButtonModalWrapper } from '@/components/utils/ButtonModalWrapper/ButtonModalWrapper'
import { ViewingStateType } from '@/atoms/viewingState'

export const CopyLeafNodeButton: React.FC<CopyLeafNodeButtonProps> = (
    props
): ReactElement => {
    const courseNames = useRecoilValue(courseNamesForContentCreatorAtom)

    return (
        <ButtonModalWrapper
            viewingStateType={ViewingStateType.copyLeafNode}
            buttonText={'Copy to other section (different exam)'}
            size={'large'}
            disabled={courseNames.length < 2}
            buttonWrapperClassName={styles.copyLeafNodeButton}
            contentPath={props.contentPath}
        >
            <React.Suspense
                fallback={<LoadingComponent useWhiteBackground={true} />}
            >
                <CopyLeafNode contentPath={props.contentPath} />
            </React.Suspense>
        </ButtonModalWrapper>
    )
}
