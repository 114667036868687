import styles from './PracticeProblemCategory.module.less'
import React, { ReactElement } from 'react'
import { LoadingComponent } from '@/components/utils/LoadingComponent/LoadingComponent'
import { PracticeProblemIDsList } from '@/components/PracticeProblemCategories/PracticeProblemCategory/PracticeProblemIDsList/PracticeProblemIDsList'
import CollapsePanel from 'antd/lib/collapse/CollapsePanel'
import { DefensiveDeleteButton } from '@/components/utils/DefensiveDeleteButton/DefensiveDeleteButton'
import { deletePracticeProblemCategoryForCourse } from '@/api/cb/contentCreatorContent'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { frontendDisplayedCourseSelector } from '@/atoms/auth'
import { syncErrorMessage, syncSuccessMessage } from '@/utils/syncMessages'
import {
    practiceProblemCategoriesInvalidatorAtom,
    practiceProblemCategoryStatsInvalidatorAtom,
    practiceProblemIDsForCategorySelector,
} from '@/atoms/practiceProblemCategories'
import { useInvalidatePracticeProblems } from '@/atoms/practiceProblem'

interface PracticeProblemCategoryProps {
    category: string
    count: number
}

export const PracticeProblemCategory: React.FC<PracticeProblemCategoryProps> = (
    props
): ReactElement => {
    const { category, count, ...restProps } = props

    const courseName = useRecoilValue(frontendDisplayedCourseSelector)

    const invalidatePracticeProblemCategories = useSetRecoilState(
        practiceProblemCategoriesInvalidatorAtom
    )
    const invalidatePracticeProblemCategoryStats = useSetRecoilState(
        practiceProblemCategoryStatsInvalidatorAtom
    )
    const practiceProblemIDs = useRecoilValue(
        practiceProblemIDsForCategorySelector(props.category)
    )
    const invalidatePracticeProblems =
        useInvalidatePracticeProblems(practiceProblemIDs)
    const deletePracticeProblemCategory = async (): Promise<void> => {
        const response = await deletePracticeProblemCategoryForCourse({
            courseName,
            practiceProblemCategory: category,
        })
        if (response.data.isError) {
            syncErrorMessage(
                `Failed to delete practice problem category: ${category}. Please refresh the page and try again.`
            )
            return
        }

        syncSuccessMessage(
            `Successfully deleted practice problem category: ${category}`
        )
        invalidatePracticeProblemCategories((x) => x + 1)
        invalidatePracticeProblemCategoryStats((x) => x + 1)
        invalidatePracticeProblems()
    }

    return (
        <CollapsePanel
            {...restProps}
            key={category}
            header={
                <div className={styles.practiceProblemCategoryPanelHeader}>
                    <div
                        className={
                            styles.practiceProblemCategoryPanelHeaderLeftSide
                        }
                    >
                        <span className={styles.practiceProblemCategoryTitle}>
                            {category}:
                        </span>
                        <span>{count}</span>
                    </div>
                    <DefensiveDeleteButton
                        onDelete={deletePracticeProblemCategory}
                        areYouSureContent={
                            <p>
                                Are you sure you want to delete the practice
                                problem category <b>{category}</b>? This will
                                remove the category from all <b>{count}</b>{' '}
                                practice problems that currently have this
                                category set.
                            </p>
                        }
                        okText={'Delete'}
                        cancelText={'Cancel'}
                        title={'Are you sure?'}
                    />
                </div>
            }
            className={styles.practiceProblemCategory}
        >
            <React.Suspense
                fallback={<LoadingComponent useWhiteBackground={false} />}
            >
                <PracticeProblemIDsList category={category} />
            </React.Suspense>
        </CollapsePanel>
    )
}
