import { INotecard } from 'common/src/notecards/types'
import { nanoid } from 'nanoid'
import { NotecardPriority } from 'common/src/notecards/consts'
import { ContentPath } from 'common/src/ContentPath'

export const createNewDefaultNotecard = (
    currentNumNotecards: number,
    courseName: string,
    contentPath: ContentPath
): INotecard => ({
    id: nanoid(),
    hints: [],
    categories: [],
    importance: NotecardPriority.high,
    index: currentNumNotecards,
    courseName,
    contentPath,
    isPreviousExamQuestion: false,
    front: undefined,
    back: undefined,
})
