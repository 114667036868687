import {
    AccessUniversalContentLinkFunc,
    ContentType,
    UniversalContentLinkData,
} from 'magic-text-box-lexical/src/universalContentLink/types'
import { useCallback } from 'react'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { viewingStateAtom, ViewingStateType } from '@/atoms/viewingState'
import assertNever from 'assert-never/index'
import { ContentPath, unHashContentPath } from 'common/src/ContentPath'
import {
    getNotecardMeta,
    getPracticeProblemMeta,
} from '@/api/cb/contentCreatorContent'
import { frontendDisplayedCourseSelector } from '@/atoms/auth'

const getViewingStateTypeForContentType = (
    contentType: ContentType
): ViewingStateType => {
    switch (contentType) {
        case ContentType.SUMMARY:
            return ViewingStateType.summary
        case ContentType.OUTLINE:
            return ViewingStateType.outline
        case ContentType.NOTECARD:
            return ViewingStateType.notecards
        case ContentType.PRACTICE_PROBLEM:
            return ViewingStateType.practiceProblems
        default:
            assertNever(contentType)
    }
}

export const useAccessUniversalContentLink =
    (): AccessUniversalContentLinkFunc => {
        const setViewingState = useSetRecoilState(viewingStateAtom)
        const courseName = useRecoilValue(frontendDisplayedCourseSelector)
        const getContentPathForUniversalContentLinkData = useCallback(
            async (data: UniversalContentLinkData): Promise<ContentPath> => {
                switch (data.type) {
                    case ContentType.SUMMARY:
                    case ContentType.OUTLINE: {
                        // extract part before '#', if it exists
                        const hashedContentPathMatch = data.id.match(/^[^#]*/)
                        return unHashContentPath(hashedContentPathMatch[0])
                    }
                    case ContentType.NOTECARD: {
                        const response = await getNotecardMeta(
                            courseName,
                            data.id
                        )
                        return response.data.payload.contentPath
                    }
                    case ContentType.PRACTICE_PROBLEM: {
                        const response = await getPracticeProblemMeta(
                            courseName,
                            data.id
                        )
                        return response.data.payload.contentPath
                    }
                    default:
                        assertNever(data.type)
                }
            },
            [courseName]
        )

        return useCallback(
            async (data: UniversalContentLinkData): Promise<void> => {
                const contentPath =
                    await getContentPathForUniversalContentLinkData(data)
                setViewingState({
                    contentPath,
                    viewingStateType: getViewingStateTypeForContentType(
                        data.type
                    ),
                    id: data.id,
                })
            },
            [getContentPathForUniversalContentLinkData, setViewingState]
        )
    }
