import styles from './AddSourceMaterialType.module.less'
import React, { ReactElement, useState, useCallback } from 'react'
import { Input, Button } from 'antd'
import { PlusCircleOutlined } from '@ant-design/icons'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import {
    sourceMaterialTypesAtom,
    sourceMaterialTypesInvalidatorAtom,
    sourceMaterialTypeStatsInvalidatorAtom,
} from '@/atoms/sourceMaterialTypes'
import { addSourceMaterialTypeForCourse } from '@/api/cb/contentCreatorContent'
import {
    frontendDisplayedCourseSelector,
    isActiveCourseReadOnlySelector,
} from '@/atoms/auth'
import { syncSuccessMessage, syncErrorMessage } from '@/utils/syncMessages'

interface AddSourceMaterialTypeProps {
    closeModal: () => void
}

export const AddSourceMaterialType: React.FC<AddSourceMaterialTypeProps> = (
    props
): ReactElement => {
    const isReadOnly = useRecoilValue(isActiveCourseReadOnlySelector)
    const [newSourceMaterialType, setNewSourceMaterialType] =
        useState<string>('')
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const sourceMaterialTypes = useRecoilValue(sourceMaterialTypesAtom)
    const courseName = useRecoilValue(frontendDisplayedCourseSelector)
    const invalidateSourceMaterialTypes = useSetRecoilState(
        sourceMaterialTypesInvalidatorAtom
    )
    const invalidateSourceMaterialTypeStats = useSetRecoilState(
        sourceMaterialTypeStatsInvalidatorAtom
    )

    const handleError = useCallback((error: string): void => {
        syncErrorMessage(error)
        setIsLoading(false)
    }, [])

    const tryAddSourceMaterialType = async (): Promise<void> => {
        setIsLoading(true)

        if (!newSourceMaterialType?.length) {
            handleError('No source material type set')
            return
        }

        if (sourceMaterialTypes.includes(newSourceMaterialType)) {
            handleError(
                `source material type: ${newSourceMaterialType} already exists.`
            )
            return
        }

        const response = await addSourceMaterialTypeForCourse({
            courseName,
            sourceMaterialType: newSourceMaterialType,
        })
        if (response.data.isError) {
            handleError(`Something unexpected happened: ${response.data.error}`)
            return
        }

        props.closeModal()
        syncSuccessMessage(
            `Successfully added sourceMaterial type: ${newSourceMaterialType}`
        )
        invalidateSourceMaterialTypes((x) => x + 1)
        invalidateSourceMaterialTypeStats((x) => x + 1)
        setIsLoading(false)
    }

    return (
        <div className={styles.addSourceMaterialType}>
            <div className={styles.addSourceMaterialTypeTitle}>
                Add SourceMaterial Type
            </div>
            <div className={styles.addSourceMaterialTypeBody}>
                <Input
                    placeholder={'Enter new sourceMaterial type here'}
                    value={newSourceMaterialType}
                    onChange={(event) =>
                        setNewSourceMaterialType(event.target.value)
                    }
                    onPressEnter={tryAddSourceMaterialType}
                />
                <Button
                    onClick={tryAddSourceMaterialType}
                    loading={isLoading}
                    disabled={!newSourceMaterialType.length || isReadOnly}
                >
                    <PlusCircleOutlined /> Add
                </Button>
            </div>
        </div>
    )
}
