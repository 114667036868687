import { useRecoilCallback } from 'recoil'
import {
    unsavedChangesSetAtom,
    hashSavableContent,
    SavableContent,
} from '@/atoms/unsavedChanges'
import assert from 'assert'

export const useMarkContentWithUnsavedChanges = (): ((
    savableContent: SavableContent
) => void) => {
    return useRecoilCallback(
        ({ set, snapshot }) =>
            (savableContent: SavableContent) => {
                const unsavedChangesSetLoadable = snapshot.getLoadable(
                    unsavedChangesSetAtom
                )
                assert(unsavedChangesSetLoadable.state === 'hasValue')
                set(unsavedChangesSetAtom, (currentUnsavedChanges) =>
                    currentUnsavedChanges.add(
                        hashSavableContent(savableContent)
                    )
                )
            }
    )
}

export const useMarkContentWithSavedChanges = (): ((
    savableContent: SavableContent
) => void) => {
    return useRecoilCallback(({ set }) => (savableContent: SavableContent) => {
        set(unsavedChangesSetAtom, (currentUnsavedChangesAtom) =>
            currentUnsavedChangesAtom.delete(hashSavableContent(savableContent))
        )
    })
}
