import React, {
    ReactElement,
    useState,
    useMemo,
    useCallback,
    useEffect,
    useRef,
} from 'react'
import { DefensiveDeleteButtonProps } from '@/components/utils/DefensiveDeleteButton/DefensiveDeleteButton.types'
import { DeleteOutlined, WarningOutlined } from '@ant-design/icons'
import { Modal, Tooltip, Button } from 'antd'
import { isHotkeyPressed } from 'react-hotkeys-hook'
import { useRecoilValue } from 'recoil'
import { isActiveCourseReadOnlySelector } from '@/atoms/auth'

const HOTKEY = 'shift'
const CASED_HOTKEY = 'Shift'
const blockClicks = (event: React.MouseEvent<HTMLDivElement>) =>
    event.stopPropagation()
export const DefensiveDeleteButton: React.FC<DefensiveDeleteButtonProps> = (
    props
): ReactElement => {
    const isReadOnly = useRecoilValue(isActiveCourseReadOnlySelector)
    const [isAreYouSureModalVisible, setIsAreYouSureModalVisible] =
        useState<boolean>(false)

    const [isDisabled, setIsDisabled] = useState<boolean>(true)
    const isEffectivelyDisabled = useMemo(
        (): boolean => isDisabled || isReadOnly,
        [isDisabled, isReadOnly]
    )

    const disabledClassName = useMemo(
        (): string => (isEffectivelyDisabled ? 'disabled' : ''),
        [isEffectivelyDisabled]
    )

    const isHoveringRef = useRef<boolean>(false)
    const handleKeyDown = useCallback((event: KeyboardEvent): void => {
        if (event.key === CASED_HOTKEY) setIsDisabled(false)
    }, [])
    const handleKeyUp = useCallback((event: KeyboardEvent): void => {
        if (event.key === CASED_HOTKEY) setIsDisabled(true)
    }, [])
    const handleMouseEnter = useCallback((): void => {
        if (isHotkeyPressed(HOTKEY)) setIsDisabled(false)
        isHoveringRef.current = true
        addEventListener('keydown', handleKeyDown)
        addEventListener('keyup', handleKeyUp)
    }, [handleKeyDown, handleKeyUp])

    const handleMouseLeave = useCallback((): void => {
        setIsDisabled(true)
        isHoveringRef.current = false
        removeEventListener('keydown', handleKeyDown)
        removeEventListener('keyup', handleKeyUp)
    }, [handleKeyDown, handleKeyUp])

    const [isTooltipVisible, setIsTooltipVisible] = useState<boolean>(false)
    useEffect(() => {
        if (isTooltipVisible && !isEffectivelyDisabled)
            setIsTooltipVisible(false)
        if (isHoveringRef.current && !isTooltipVisible && isEffectivelyDisabled)
            setIsTooltipVisible(true)
    }, [isTooltipVisible, isEffectivelyDisabled])
    const [isLoading, setIsLoading] = useState<boolean>(false)

    return (
        <div onClick={blockClicks}>
            <Modal
                className={'defensive-delete-button-modal'}
                title={
                    <>
                        <div className={'warning-container'}>
                            <WarningOutlined />
                        </div>
                        <div
                            className={
                                'defensive-delete-button-modal-title-text-container'
                            }
                        >
                            {props.title}
                        </div>
                        <div className={'warning-container'}>
                            <WarningOutlined />
                        </div>
                    </>
                }
                open={isAreYouSureModalVisible}
                onOk={(e) => {
                    e.stopPropagation()
                    setIsLoading(true)
                    props.onDelete().then(() => {
                        setIsLoading(false)
                        setIsAreYouSureModalVisible(false)
                    })
                }}
                closable={false}
                okType={'danger'}
                okText={props.okText}
                okButtonProps={{ type: 'primary' }}
                cancelText={props.cancelText}
                confirmLoading={isLoading}
                onCancel={(e) => {
                    e.stopPropagation()
                    setIsAreYouSureModalVisible(false)
                }}
            >
                {props.areYouSureContent}
            </Modal>
            <Tooltip
                title={
                    isReadOnly ? 'Course is read-only' : 'Hold shift to enable'
                }
                open={isTooltipVisible}
                onOpenChange={setIsTooltipVisible}
                placement={props.placement}
            >
                <div
                    className={`defensive-delete-button ${disabledClassName}`}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    onClick={(e) => {
                        e.stopPropagation()
                        if (!isLoading && !isEffectivelyDisabled)
                            setIsAreYouSureModalVisible(true)
                    }}
                >
                    {props.buttonText ? (
                        <Button
                            danger
                            type={'primary'}
                            disabled={isEffectivelyDisabled}
                        >
                            {props.buttonText}
                        </Button>
                    ) : (
                        <div
                            className={
                                'defensive-delete-button-delete-icon-container'
                            }
                        >
                            <DeleteOutlined />
                        </div>
                    )}
                </div>
            </Tooltip>
        </div>
    )
}
