import styles from './ContentStats.module.less'

import React, { ReactElement } from 'react'

export const ContentStatsItem: React.FC<{ title: string; value: number }> = (
    props
): ReactElement => {
    return (
        <div className={styles.contentStatsItem}>
            <div className={styles.contentStatsItemTitle}>{props.title}:</div>
            <div className={styles.contentStatsItemText}>{props.value}</div>
        </div>
    )
}
