import React, { ReactElement } from 'react'
import { DefensiveDeleteButton } from '@/components/utils/DefensiveDeleteButton/DefensiveDeleteButton'

interface DeleteMultipleChoiceOptionButtonProps {
    deleteMultipleChoiceOption: () => void
}
export const DeleteMultipleChoiceOptionButton: React.FC<
    DeleteMultipleChoiceOptionButtonProps
> = (props): ReactElement => {
    return (
        <div style={{ padding: '1rem 0' }}>
            <DefensiveDeleteButton
                onDelete={async () => props.deleteMultipleChoiceOption()}
                areYouSureContent={
                    'Are you sure you want to delete this multiple choice option? This can only be undone by not saving the problem after deleting the option.'
                }
                okText={'Yes, delete multiple choice option'}
                cancelText={'Cancel'}
                title={'Warning: Dangerous Action'}
                buttonText={'Delete selected multiple choice option'}
                placement={'right'}
            />
        </div>
    )
}
