import styles from './PreviousExamIDEditor.module.less'
import React, { ReactElement, useState, useEffect, useMemo } from 'react'
import { PreviousExamIDEditorProps } from './PreviousExamIDEditor.types'
import { InputNumber, Radio, Select, Switch, Input } from 'antd'
import {
    OriginalExamAbbreviation,
    SittingSeason,
} from 'common/src/previousExamAnalysis/types'
import { startCase } from 'lodash'

export const PreviousExamIDEditor: React.FC<PreviousExamIDEditorProps> = (
    props
): ReactElement => {
    const [isSubProblem, setIsSubProblem] = useState<boolean>(
        !!props.previousExamID?.question?.subPartLetter
    )

    // set defaults
    useEffect(() => {
        if (!props.previousExamID) {
            props.setPreviousExamID({
                year: 2023,
                sittingSeason: SittingSeason.fall,
                originalExamAbbreviation: OriginalExamAbbreviation.GHDP,
                question: {
                    questionNumber: 1,
                },
            })
        }
    }, [props])

    useEffect(() => {
        if (
            !isSubProblem &&
            (props.previousExamID?.question?.subPartLetter ||
                props.previousExamID?.question?.nestedSubPartLetter)
        ) {
            props.setPreviousExamID({
                ...props.previousExamID,
                question: {
                    ...props.previousExamID?.question,
                    subPartLetter: undefined,
                    nestedSubPartLetter: undefined,
                },
            })
        }
    }, [isSubProblem, props])

    const [isNestedSubProblem, setIsNestedSubProblem] = useState<boolean>(
        isSubProblem && !!props.previousExamID?.question?.nestedSubPartLetter
    )

    useEffect(() => {
        if (
            !isNestedSubProblem &&
            props.previousExamID?.question?.nestedSubPartLetter
        ) {
            props.setPreviousExamID({
                ...props.previousExamID,
                question: {
                    ...props.previousExamID?.question,
                    nestedSubPartLetter: undefined,
                },
            })
        }
    }, [isNestedSubProblem, props])

    const currentYear = useMemo(() => new Date().getFullYear(), [])

    return (
        <div className={styles.container}>
            <div className={styles.entry}>
                <div className={styles.entryTitle}>Year</div>
                <InputNumber
                    min={2012}
                    max={currentYear}
                    value={props.previousExamID?.year || currentYear}
                    onChange={(value) =>
                        props.setPreviousExamID({
                            ...props.previousExamID,
                            year: value,
                        })
                    }
                />
            </div>
            <div className={styles.entry}>
                <div className={styles.entryTitle}>Sitting</div>
                <Radio.Group
                    value={
                        props.previousExamID?.sittingSeason ||
                        SittingSeason.fall
                    }
                    onChange={(e) =>
                        props.setPreviousExamID({
                            ...props.previousExamID,
                            sittingSeason: e.target.value,
                        })
                    }
                    buttonStyle={'solid'}
                >
                    {Object.values(SittingSeason).map((sittingSeason) => (
                        <Radio.Button value={sittingSeason} key={sittingSeason}>
                            {startCase(sittingSeason)}
                        </Radio.Button>
                    ))}
                </Radio.Group>
            </div>
            <div className={styles.entry}>
                <div className={styles.entryTitle}>Original Exam</div>
                <Select
                    value={
                        props.previousExamID?.originalExamAbbreviation ||
                        OriginalExamAbbreviation.GHDP
                    }
                    onChange={(newValue) =>
                        props.setPreviousExamID({
                            ...props.previousExamID,
                            originalExamAbbreviation: newValue,
                        })
                    }
                    options={Object.values(OriginalExamAbbreviation).map(
                        (originalExamAbbreviation) => ({
                            value: originalExamAbbreviation,
                            label: originalExamAbbreviation,
                        })
                    )}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        (option?.label ?? '')
                            .toLowerCase()
                            .includes(input.toLowerCase())
                    }
                />
            </div>
            <div className={styles.entry}>
                <div className={styles.entryTitle}>Question Number</div>
                <InputNumber
                    min={1}
                    max={25}
                    value={props.previousExamID?.question?.questionNumber || 1}
                    onChange={(value) =>
                        props.setPreviousExamID({
                            ...props.previousExamID,
                            question: {
                                ...props.previousExamID?.question,
                                questionNumber: value,
                            },
                        })
                    }
                />
                <div className={styles.subProblemSwitchOuter}>
                    <div className={styles.subProblemSwitchInner}>
                        <div className={styles.subProblemSwitchTitle}>
                            {'Is sub problem?'}
                        </div>
                        <div className={styles.subProblemSwitchContainer}>
                            <Switch
                                checked={isSubProblem}
                                onChange={setIsSubProblem}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {isSubProblem && (
                <div className={styles.subProblemAdditionalOptionsContainer}>
                    <div className={styles.entry}>
                        <div className={styles.entryTitle}>
                            {"Sub problem letter (e.g. 'a', 'b', 'c', etc.)"}
                        </div>
                        <Input
                            value={
                                props.previousExamID?.question?.subPartLetter ||
                                'a'
                            }
                            onChange={(e) =>
                                props.setPreviousExamID({
                                    ...props.previousExamID,
                                    question: {
                                        ...props.previousExamID?.question,
                                        subPartLetter: e.target.value,
                                    },
                                })
                            }
                        />
                        <div className={styles.subProblemSwitchOuter}>
                            <div className={styles.subProblemSwitchInner}>
                                <div className={styles.subProblemSwitchTitle}>
                                    {'Is nested sub problem?'}
                                </div>
                                <div
                                    className={styles.subProblemSwitchContainer}
                                >
                                    <Switch
                                        checked={isNestedSubProblem}
                                        onChange={setIsNestedSubProblem}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {isNestedSubProblem && (
                        <div className={styles.entry}>
                            <div className={styles.entryTitle}>
                                {
                                    "Nested sub problem letter (e.g. 'i', 'ii', 'iii', etc.)"
                                }
                            </div>
                            <Input
                                value={
                                    props.previousExamID?.question
                                        ?.nestedSubPartLetter || 'i'
                                }
                                onChange={(e) =>
                                    props.setPreviousExamID({
                                        ...props.previousExamID,
                                        question: {
                                            ...props.previousExamID.question,
                                            nestedSubPartLetter: e.target.value,
                                        },
                                    })
                                }
                            />
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}
