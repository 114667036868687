import styles from './ContentStats.module.less'
import React, { ReactElement } from 'react'
import { ContentStats } from 'common/src/api/core/courses/types'
import { ContentStatsItem } from './ContentStatsItem'

export const ContentStatsComponent: React.FC<{ contentStats: ContentStats }> = (
    props
): ReactElement => {
    const { contentStats } = props

    return (
        <div className={styles.contentStats}>
            <div className={styles.contentStatsTitle}>Course Stats</div>

            <ContentStatsItem
                title={'Outlines'}
                value={contentStats.numOutlines}
            />
            <div className={styles.contentStatsSubitemsList}>
                <ContentStatsItem
                    title={'With Practice Problems'}
                    value={contentStats.numOutlinesWithPracticeProblems}
                />
                <div className={styles.contentStatsSubitemsList}>
                    <ContentStatsItem
                        title={'Without Gold Standards'}
                        value={
                            contentStats.numOutlinesWithPracticeProblemsButNoGoldStandards
                        }
                    />
                </div>
                <ContentStatsItem
                    title={'Without Practice Problems'}
                    value={
                        contentStats.numOutlines -
                        contentStats.numOutlinesWithPracticeProblems
                    }
                />
            </div>
            <ContentStatsItem
                title={'Notecards'}
                value={contentStats.numNotecards}
            />
            <div className={styles.contentStatsSubitemsList}>
                <ContentStatsItem
                    title={'High Importance'}
                    value={contentStats.numNotecardsHighImportance}
                />
                <ContentStatsItem
                    title={'Medium Importance'}
                    value={contentStats.numNotecardsMediumImportance}
                />
                <ContentStatsItem
                    title={'Low Importance'}
                    value={contentStats.numNotecardsLowImportance}
                />
                <ContentStatsItem
                    title={'No Hints'}
                    value={contentStats.numNotecardsWithNoHints}
                />
                <ContentStatsItem
                    title={'No Learning Objective'}
                    value={contentStats.numNotecardsWithNoLearningObjective}
                />
            </div>
            <ContentStatsItem
                title={'Practice Problems'}
                value={contentStats.numPracticeProblems}
            />
            <div className={styles.contentStatsSubitemsList}>
                <ContentStatsItem
                    title={'Math'}
                    value={contentStats.numPracticeProblemsMath}
                />
                <ContentStatsItem
                    title={'Excel'}
                    value={contentStats.numPracticeProblemsWithExcel}
                />
                <ContentStatsItem
                    title={'Excel Solution'}
                    value={contentStats.numPracticeProblemsWithExcelSolution}
                />
                <ContentStatsItem
                    title={'Previous Exam'}
                    value={contentStats.numPracticeProblemsPreviousExam}
                />
                <ContentStatsItem
                    title={'Math and Previous Exam'}
                    value={contentStats.numPracticeProblemsMathAndPreviousExam}
                />
                <ContentStatsItem
                    title={'No Learning Objective'}
                    value={
                        contentStats.numPracticeProblemsWithNoLearningObjective
                    }
                />
            </div>
        </div>
    )
}
