import styles from './LearningObjectiveItem.module.less'
import React, { ReactElement, useCallback } from 'react'
import { LearningObjectiveItemProps } from './LearningObjectiveItem.types'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { ContentTreeState, contentTreeStateAtom } from '@/atoms/contentTree'
import { EditableText } from '@/components/EditableText/EditableText'
import produce from 'immer'
import {
    deleteLearningObjective,
    updateLearningObjective,
} from '@/api/cb/contentCreatorContent'
import { frontendDisplayedCourseSelector } from '@/atoms/auth'
import { DefensiveDeleteButton } from '@/components/utils/DefensiveDeleteButton/DefensiveDeleteButton'
import { syncErrorMessage, syncSuccessMessage } from '@/utils/syncMessages'

const deleteLearningObjectiveLocal = produce(
    (
        contentTreeState: ContentTreeState,
        topicID: string,
        learningObjectiveID: string
    ): void => {
        contentTreeState.topicToLearningObjectivesMap.set(
            topicID,
            contentTreeState.topicToLearningObjectivesMap
                .get(topicID)
                .filter(
                    (learningObjective) =>
                        learningObjective.id !== learningObjectiveID
                )
        )
    }
)

const updateLearningObjectiveLocal = produce(
    (
        contentTreeState: ContentTreeState,
        topicID: string,
        learningObjectiveID: string,
        newDescription: string
    ): void => {
        const existingLearningObjectives =
            contentTreeState.topicToLearningObjectivesMap.get(topicID)
        for (const learningObjective of existingLearningObjectives) {
            if (learningObjective.id === learningObjectiveID) {
                learningObjective.description = newDescription
                return
            }
        }
    }
)

export const LearningObjectiveItem: React.FC<LearningObjectiveItemProps> = (
    props
): ReactElement => {
    const courseName = useRecoilValue(frontendDisplayedCourseSelector)

    const setContentTreeState = useSetRecoilState(contentTreeStateAtom)

    const deleteLearningObjectiveCallback = useCallback(async () => {
        const response = await deleteLearningObjective(
            courseName,
            props.learningObjective.id
        )
        if (response.data.isError) {
            syncErrorMessage(
                'Error deleting learning objective. You may want to try again or refresh the page.'
            )
        } else {
            setContentTreeState((contentTreeState) =>
                deleteLearningObjectiveLocal(
                    contentTreeState,
                    props.learningObjective.topicID,
                    props.learningObjective.id
                )
            )
            syncSuccessMessage('Successfully deleted learning objective')
        }
    }, [
        courseName,
        props.learningObjective.id,
        props.learningObjective.topicID,
        setContentTreeState,
    ])

    const updateLearningObjectiveCallback = useCallback(
        async (newDescription: string) => {
            const response = await updateLearningObjective(
                courseName,
                props.learningObjective.id,
                newDescription
            )
            if (response.data.isError) {
                syncErrorMessage(
                    'Error saving learning objective. Try saving again or refreshing the page.'
                )
            } else {
                setContentTreeState((contentTreeState) =>
                    updateLearningObjectiveLocal(
                        contentTreeState,
                        props.learningObjective.topicID,
                        props.learningObjective.id,
                        newDescription
                    )
                )
                syncSuccessMessage('Successfully saved learning objective')
            }
        },
        [
            courseName,
            props.learningObjective.id,
            props.learningObjective.topicID,
            setContentTreeState,
        ]
    )

    return (
        <div className={styles.learningObjectiveItem}>
            <EditableText
                onSave={updateLearningObjectiveCallback}
                initialText={props.learningObjective.description}
            />
            <DefensiveDeleteButton
                onDelete={deleteLearningObjectiveCallback}
                title={'Warning: Dangerous Action'}
                areYouSureContent={
                    'Are you sure you want to delete this learning objective? This cannot be undone and may have unintended side effects for notecards and practice problems linked to it.'
                }
                okText={'Yes, delete learning objective'}
                cancelText={'Cancel'}
                placement={'right'}
            />
        </div>
    )
}
