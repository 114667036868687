import React, {
    PropsWithChildren,
    ReactElement,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react'
import { DraggableSortingListProps } from '@/components/utils/DraggableSortingList/DraggableSortingList.types'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DraggableItem } from '@/components/utils/DraggableItem/DraggableItem'
import { DraggableIcon } from '@/components/utils/DraggableSortingList/DraggableIcon/DraggableIcon'
import { LoadingComponent } from '@/components/utils/LoadingComponent/LoadingComponent'
import styles from './DraggableIcon/DraggableIcon.module.less'

export const DraggableSortingList = (
    props: DraggableSortingListProps
): ReactElement => {
    return (
        <DndProviderWrapper className={styles.dndProviderWrapper}>
            {props.isLoading ? (
                <LoadingComponent useWhiteBackground={true} />
            ) : (
                props.itemData.map((data, index) => (
                    <DraggableItem
                        index={index}
                        changeIndex={
                            props.isDisabled
                                ? () => null
                                : (
                                      startingIndex: number,
                                      endingIndex: number
                                  ) =>
                                      startingIndex === endingIndex
                                          ? null
                                          : props.changeIndex(
                                                startingIndex,
                                                endingIndex
                                            )
                        }
                        key={index}
                        className={
                            index === props.activeIndex &&
                            styles.activeDraggableIcon
                        }
                        onClick={() => props.setActiveIndex(index)}
                    >
                        <DraggableIcon {...data} />
                    </DraggableItem>
                ))
            )}
        </DndProviderWrapper>
    )
}

/**
 * Fixes issue where DnD provider breaks native drag events outside of the provider
 * https://github.com/react-dnd/react-dnd/issues/3304#issuecomment-1491849537
 */
export const DndProviderWrapper: React.FC<
    PropsWithChildren<{ className: string }>
> = ({ className, children }) => {
    const context = useRef(null)
    // Make the dndArea part of the component state otherwise, some times, the content doesn't render
    const [dndArea, setDnDArea] = useState(context.current)
    // additionally, add a useEffect to track the context reference => might be overkill performance wise but works for my use case
    useEffect(() => {
        setDnDArea(context?.current)
    }, [context])
    const html5Options = useMemo(() => ({ rootElement: dndArea }), [dndArea])
    const fakeContext = {
        clearTimeout: (): void => null,
    }
    return (
        <div className={className} ref={context}>
            {/* it is important that DndProvider doesn't render if dndArea is null */}
            {dndArea && (
                <DndProvider
                    backend={HTML5Backend}
                    options={html5Options}
                    context={fakeContext}
                >
                    {children}
                </DndProvider>
            )}
        </div>
    )
}
