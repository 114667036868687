import styles from './SignupPage.module.less'
import React, { ReactElement, useCallback, useRef, useState } from 'react'
import { Button, Form, FormInstance, Input, Select } from 'antd'
import {
    CourseOrganization,
    CourseTrack,
    COURSE_ORGANIZATION_TO_TRACKS,
    Exam,
    TRACK_TO_EXAMS,
} from 'common/src/api/core/courses/types'
import { signup } from '@/api/cb/contentFrontendAuthentication'
import Router from 'next/router'
import { UiLayoutConstant } from '@/globals/uiLayoutConstant'
import { ContentSignupRequest } from 'common/src/api/contentFrontendVsContentBackend/requests'
import { handleAuthenticationError } from '@/components/authentication/handleAuthenticationError'
import { frontendLogin } from '@/auth/frontendLogin'
import { ApplicationInstructions } from '@/components/ApplicationInstructions/ApplicationInstructions'
import { wbLogin } from '@/api/wb/authentication'
import { getSignedUrlForResumeUpload } from '@/api/cb/publicS3'
import { FileUploader } from '@/components/utils/FileUploader/FileUploader'
import { syncErrorMessage, syncSuccessMessage } from '@/utils/syncMessages'
import { logger } from '@/logging/FrontendLogger'
import { getContentTypeFromDataURL } from 'common/src/api/s3/utils'
import { getRouterQueryFromViewingState } from '@/hooks/useViewingState'
import { fetchContentTreeState } from '@/atoms/contentTree'
import {
    validateConfirmPasswordPromise,
    validatePasswordPromise,
} from '@/utils/passwordValidation'

const ALLOWED_RESUME_FILE_TYPES: string[] = [
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
    'application/msword', // .doc
    'application/pdf', // .pdf
]
const { Option } = Select

// TODO: Handle errors?
export const SignupPage: React.FC<unknown> = (): ReactElement => {
    const formRef = useRef<FormInstance<ContentSignupRequest>>(null)
    const [resumeS3Key, setResumeS3Key] = useState<string>(null)
    const [courseTrackOptions, setCourseTrackOptions] =
        useState<CourseTrack[]>(null)
    const [examOptions, setExamOptions] = useState<Exam[]>(null)

    const handleFileUpload = useCallback(
        async (dataURL: string): Promise<void> => {
            const response = await getSignedUrlForResumeUpload()
            if (response.data.isError) {
                logger.error(response.data.error)
                syncErrorMessage(
                    'Error uploading resume Please try again or refresh the page.'
                )
                return
            }
            const preSignedURL = response.data.payload.signedUrl
            const base64StringData = dataURL.split(',')[1]
            const buffer = Buffer.from(base64StringData, 'base64')

            try {
                await fetch(preSignedURL, {
                    method: 'PUT',
                    headers: new Headers({
                        'Content-Type': getContentTypeFromDataURL(dataURL),
                    }),
                    mode: 'cors',
                    body: buffer,
                })
                setResumeS3Key(response.data.payload.s3Key)

                syncSuccessMessage('Successfully uploaded resume')
            } catch (e) {
                logger.error(`Error uploading resume: ${e}`)
                syncErrorMessage(
                    'Error uploading resume. Please try again or refresh the page.'
                )
            }
        },
        []
    )
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const handleSubmit = async (
        values: ContentSignupRequest
    ): Promise<void> => {
        setIsLoading(true)
        const response = await signup({
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            password: values.password,
            organization: values.organization,
            track: values.track,
            exam: values.exam,
            resumeS3Key,
            website: values.website,
        })

        if (!response.data.success) {
            handleAuthenticationError(response.data.error, {
                message: 'Issue signing up',
                description:
                    response.data.error ??
                    'Something went wrong trying to sign up. Please refresh the page and try again.',
            })
        } else {
            await frontendLogin(response.data.payload, false)
            await wbLogin(values.email, values.password)
            const contentTreeState = await fetchContentTreeState(
                response.data.payload.frontendDisplayedCourseName
            )
            const contentPath = contentTreeState.flattenedContentTree.find(
                (contentPath) =>
                    contentPath.length ===
                    contentTreeState.contentTierTitles.length
            )
            await Router.push({
                pathname: UiLayoutConstant.PRIMARY_DASHBOARD_PAGE,
                query: getRouterQueryFromViewingState({
                    contentPath,
                    viewingStateType: null,
                    id: null,
                }),
            })
        }
        setIsLoading(false)
    }

    const validateConfirmPassword = useCallback(
        (_: unknown, confirmPassword: string): Promise<void> => {
            const otherPassword = formRef.current.getFieldValue('password')
            return validateConfirmPasswordPromise(
                confirmPassword,
                otherPassword
            )
        },
        []
    )

    return (
        <div className={styles.signUpContainer}>
            <div className={styles.signUpInstructionsContainer}>
                <ApplicationInstructions isSignupForm={true} />
            </div>
            <div className={styles.signUpFormContainer}>
                <Form
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 14 }}
                    style={{ maxWidth: 600 }}
                    layout="vertical"
                    onFinish={handleSubmit}
                    ref={formRef}
                >
                    <Form.Item
                        name={'firstName'}
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: 'Please input your first name',
                            },
                        ]}
                    >
                        <Input placeholder="First Name" />
                    </Form.Item>{' '}
                    <Form.Item
                        name={'lastName'}
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: 'Please input your last name',
                            },
                        ]}
                    >
                        <Input placeholder="Last Name" />
                    </Form.Item>{' '}
                    <Form.Item
                        name={'email'}
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: 'Please input your email address',
                            },
                            {
                                type: 'email',
                                message: 'Please enter a valid email address',
                            },
                        ]}
                    >
                        <Input placeholder="Email" />
                    </Form.Item>{' '}
                    <Form.Item
                        name={'password'}
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: 'Please input a strong password',
                            },
                            { validator: validatePasswordPromise },
                        ]}
                    >
                        <Input.Password placeholder="Password" />
                    </Form.Item>
                    <Form.Item
                        name={'confirmPassword'}
                        hasFeedback
                        dependencies={['password']}
                        rules={[
                            {
                                required: true,
                                message: 'Please confirm your password',
                            },
                            { validator: validateConfirmPassword },
                        ]}
                    >
                        <Input.Password placeholder="Confirm Password" />
                    </Form.Item>
                    <Form.Item
                        name={'organization'}
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message:
                                    'Please select an actuary organization',
                            },
                        ]}
                    >
                        <Select
                            onChange={(courseOrganization) => {
                                setCourseTrackOptions(
                                    COURSE_ORGANIZATION_TO_TRACKS[
                                        courseOrganization as CourseOrganization
                                    ]
                                )
                            }}
                            placeholder={'Organization'}
                        >
                            {Object.values(CourseOrganization)
                                .filter(
                                    (organization) =>
                                        ![
                                            CourseOrganization.OTHER,
                                            CourseOrganization.NONE,
                                        ].includes(organization)
                                )
                                .map((organization) => (
                                    <Option
                                        value={organization}
                                        key={organization}
                                    >
                                        {organization}
                                    </Option>
                                ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name={'track'}
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: 'Please select a track',
                            },
                        ]}
                    >
                        <Select
                            onChange={(courseTrack) => {
                                setExamOptions(
                                    TRACK_TO_EXAMS[courseTrack as CourseTrack]
                                )
                            }}
                            placeholder={'Track'}
                            notFoundContent={'Please select an Organization'}
                        >
                            {courseTrackOptions
                                ? courseTrackOptions
                                      .filter(
                                          (courseTrack) =>
                                              ![
                                                  CourseTrack.OTHER,
                                                  CourseTrack.NONE,
                                                  CourseTrack.FSA_HEALTH,
                                              ].includes(courseTrack)
                                      )
                                      .map((courseTrack) => (
                                          <Option
                                              value={courseTrack}
                                              key={courseTrack}
                                          >
                                              {courseTrack}
                                          </Option>
                                      ))
                                : []}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name={'exam'}
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: 'Please select an exam',
                            },
                        ]}
                    >
                        <Select
                            placeholder={'Exam'}
                            notFoundContent={'Please select a Track'}
                        >
                            {examOptions
                                ? examOptions.map((examOption) => (
                                      <Option
                                          value={examOption}
                                          key={examOption}
                                      >
                                          {examOption}
                                      </Option>
                                  ))
                                : []}
                        </Select>
                    </Form.Item>
                    <Form.Item valuePropName="fileList">
                        <FileUploader
                            allowedFileTypes={ALLOWED_RESUME_FILE_TYPES}
                            handleFileUpload={handleFileUpload}
                            buttonText={'Upload Resume'}
                            uploadedFileMessage={'Resume uploaded ✓'}
                        />
                    </Form.Item>
                    <Form.Item name={'website'}>
                        <Input placeholder="LinkedIn/Website" />
                    </Form.Item>{' '}
                    <div className={styles.signUpFormButtonContainer}>
                        <Form.Item>
                            <Button
                                type={'primary'}
                                htmlType={'submit'}
                                loading={isLoading}
                            >
                                Submit
                            </Button>
                        </Form.Item>
                    </div>
                </Form>
            </div>
        </div>
    )
}
