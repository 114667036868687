import {
    atomFamily,
    DefaultValue,
    selectorFamily,
    useRecoilTransaction_UNSTABLE,
    useSetRecoilState,
} from 'recoil'
import { frontendDisplayedCourseSelector } from '@/atoms/auth'
import { IGenericPracticeProblem } from 'common/src/practiceProblems/types'
import { getPracticeProblem } from '@/api/cb/contentCreatorContent'
import { useCallback } from 'react'
import {
    parseSavablePracticeProblemData,
    SavablePracticeProblemData,
} from '@/components/ContentTreeNode/ContentTreeLeafNode/PracticeProblemsEditor/PracticeProblemEditor/saving'
import { PracticeProblemType } from 'common/src/practiceProblems'
import { cleanMultipartPoints } from '@/components/ContentTreeNode/ContentTreeLeafNode/PracticeProblemsEditor/immer'

/**
 * Nulling allows one to pass null and get null to conditionally not use the atom
 */
export const practiceProblemAtomFamily = atomFamily<
    IGenericPracticeProblem | null,
    string | null
>({
    key: 'practiceProblemAtomFamily',
    default: selectorFamily<IGenericPracticeProblem | null, string | null>({
        key: 'practiceProblemDefaultSelectorFamily',
        get:
            (practiceProblemID) =>
            async ({ get }) => {
                get(practiceProblemInvalidatorAtomFamily(practiceProblemID))
                if (!practiceProblemID) return null
                const frontendDisplayedCourse = get(
                    frontendDisplayedCourseSelector
                )
                const response = await getPracticeProblem(
                    frontendDisplayedCourse,
                    practiceProblemID
                )
                return response.data.payload
            },
    }),
    effects: [
        ({ onSet, setSelf }) => {
            onSet((newVal) => {
                if (newVal.type === PracticeProblemType.MULTI_PART) {
                    setSelf((current) => {
                        if (
                            current instanceof DefaultValue ||
                            current.type !== PracticeProblemType.MULTI_PART
                        )
                            return
                        return cleanMultipartPoints(current)
                    })
                }
            })
        },
    ],
})

export const currentPracticeProblemDataSelectorFamily = selectorFamily<
    SavablePracticeProblemData | null,
    string | null
>({
    key: 'currentPracticeProblemDataSelectorFamily',
    get:
        (practiceProblemID) =>
        ({ get }) => {
            if (!practiceProblemID) return null
            return parseSavablePracticeProblemData(
                get(practiceProblemAtomFamily(practiceProblemID)),
                null
            )
        },
})

export const lastSavedPracticeProblemDataAtomFamily = atomFamily<
    SavablePracticeProblemData | null,
    string | null
>({
    key: 'lastSavedPracticeProblemDataAtomFamily',
    default: null,
})

export const isExcelFilePresentSelectorFamily = selectorFamily<
    boolean | null,
    string | null
>({
    key: 'isExcelFilePresentSelectorFamily',
    get:
        (practiceProblemID) =>
        ({ get }) => {
            return (
                !!get(practiceProblemAtomFamily(practiceProblemID))
                    ?.excelS3Key ?? null
            )
        },
})

export const isExcelSolutionFilePresentSelectorFamily = selectorFamily<
    boolean | null,
    string | null
>({
    key: 'isExcelSolutionFilePresentSelectorFamily',
    get:
        (practiceProblemID) =>
        ({ get }) => {
            return (
                !!get(practiceProblemAtomFamily(practiceProblemID))
                    ?.excelSolutionS3Key ?? null
            )
        },
})

const practiceProblemInvalidatorAtomFamily = atomFamily<number, string | null>({
    key: 'practiceProblemInvalidatorAtomFamily',
    default: 0,
})

export const useInvalidatePracticeProblem = (
    practiceProblemID: string | null
): (() => void) => {
    const setPracticeProblemInvalidator = useSetRecoilState(
        practiceProblemInvalidatorAtomFamily(practiceProblemID)
    )
    return useCallback(
        (): void =>
            setPracticeProblemInvalidator((currentValue) => currentValue + 1),
        [setPracticeProblemInvalidator]
    )
}

export const useInvalidatePracticeProblems = (
    practiceProblemIDs: string[]
): (() => void) => {
    return useRecoilTransaction_UNSTABLE(({ set }) => () => {
        for (const practiceProblemID of practiceProblemIDs) {
            set(
                practiceProblemInvalidatorAtomFamily(practiceProblemID),
                (x) => x + 1
            )
        }
    })
}
