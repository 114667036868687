import React, { ReactElement, useMemo } from 'react'
import { ContentCommentThreadWrapperProps } from '@/components/ContentCommentThread/ContentCommentThreadWrapper.types'
import { RecoilState, useRecoilState, useRecoilValue } from 'recoil'
import { ContentType } from 'common/src/commentThread/types'
import assertNever from 'assert-never/index'
import { notecardCommentThreadAtomFamily } from '@/atoms/contentCommentThreads/notecardCommentThreads'
import { practiceProblemCommentThreadAtomFamily } from '@/atoms/contentCommentThreads/practiceProblemCommentThreads'
import { ContentCommentThread } from '@/components/ContentCommentThread/ContentCommentThread'
import { authStateAtom, frontendDisplayedCourseSelector } from '@/atoms/auth'
import { CommentMetadata } from 'magic-text-box-lexical/src/context/AnnotatedContentContext/AnnotatedContent.types'

export const ContentCommentThreadWrapper: React.FC<
    ContentCommentThreadWrapperProps
> = (props): ReactElement => {
    const commentsAtomFamily = useMemo((): ((
        param: string
    ) => RecoilState<CommentMetadata[] | null>) => {
        switch (props.contentType) {
            case ContentType.NOTECARD:
                return notecardCommentThreadAtomFamily
            case ContentType.PRACTICE_PROBLEM:
                return practiceProblemCommentThreadAtomFamily
            case ContentType.OUTLINE:
            case ContentType.SUMMARY:
                // See OutlineCommentThread.tsx
                throw new Error(
                    `Unsupported content type: ${props.contentType}`
                )
            default:
                assertNever(props.contentType)
        }
    }, [props.contentType])

    const [commentMetadataArray, setCommentMetadataArray] = useRecoilState<
        CommentMetadata[]
    >(commentsAtomFamily(props.contentId))
    const courseName = useRecoilValue(frontendDisplayedCourseSelector)
    const authState = useRecoilValue(authStateAtom)

    if (commentMetadataArray === null) {
        return (
            <div>
                WB doesn&apos;t yet have this content, so no comment thread can
                be created!
            </div>
        )
    }
    return (
        <ContentCommentThread
            commentMetadataArray={commentMetadataArray}
            setCommentMetadataArray={setCommentMetadataArray}
            contentType={props.contentType}
            contentId={props.contentId}
            contentPath={props.contentPath}
            courseName={courseName}
            userID={authState.contentCreatorID}
        />
    )
}
