import {
    useMarkContentWithUnsavedChanges,
    useMarkContentWithSavedChanges,
} from '@/hooks/useMarkContentWithUnsavedChanges'
import { useMemo, useEffect } from 'react'
import { SavableContent, SavableContentType } from '@/atoms/unsavedChanges'
import { useRecoilValue, useRecoilState } from 'recoil'
import { isEqual } from 'lodash'
import { ContentPath } from 'common/src/ContentPath'
import {
    currentPracticeProblemDataSelectorFamily,
    lastSavedPracticeProblemDataAtomFamily,
} from '@/atoms/practiceProblem'
import { isUnsavedPracticeProblemSelectorFamily } from '@/atoms/newlyCreatedAndUnsavedContentIDs'

// does nothing if sub problem
export const useMonitorPracticeProblemChanges = (
    practiceProblemID: string,
    contentPath: ContentPath,
    isSubProblem: boolean
): void => {
    const markContentWithUnsavedChanges = useMarkContentWithUnsavedChanges()
    const markContentWithSavedChanges = useMarkContentWithSavedChanges()
    const isUnsaved = useRecoilValue(
        isUnsavedPracticeProblemSelectorFamily(practiceProblemID)
    )
    const savableContent = useMemo(
        (): SavableContent => ({
            contentPath: contentPath,
            contentType: SavableContentType.practiceProblem,
            id: practiceProblemID,
        }),
        [contentPath, practiceProblemID]
    )

    const currentPracticeProblemSavableData = useRecoilValue(
        currentPracticeProblemDataSelectorFamily(practiceProblemID)
    )
    const [lastSavedPracticeProblemData, setLastSavedPracticeProblemData] =
        useRecoilState(
            lastSavedPracticeProblemDataAtomFamily(practiceProblemID)
        )

    useEffect(() => {
        if (isSubProblem || isUnsaved) return
        if (lastSavedPracticeProblemData === null) {
            setLastSavedPracticeProblemData(currentPracticeProblemSavableData)
        }
    }, [
        currentPracticeProblemSavableData,
        isSubProblem,
        isUnsaved,
        lastSavedPracticeProblemData,
        setLastSavedPracticeProblemData,
    ])

    const hasChanges = useMemo((): boolean => {
        return !isEqual(
            currentPracticeProblemSavableData,
            lastSavedPracticeProblemData
        )
    }, [currentPracticeProblemSavableData, lastSavedPracticeProblemData])
    useEffect(() => {
        if (isSubProblem || lastSavedPracticeProblemData === null) return
        if (hasChanges) {
            markContentWithUnsavedChanges(savableContent)
        } else {
            markContentWithSavedChanges(savableContent)
        }
    }, [
        isSubProblem,
        hasChanges,
        markContentWithSavedChanges,
        markContentWithUnsavedChanges,
        savableContent,
        lastSavedPracticeProblemData,
    ])
}
