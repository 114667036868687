import styles from './EditFormulaSheetEntry.module.less'
import React, { ReactElement, useMemo, useState } from 'react'
import { Input, Button, Switch } from 'antd'
import { FormulaSheetEntry } from 'common/src/formulaSheet/types'
import { setFormulaSheetEntryAtIndex } from '@/components/FormulaSheetEditor/immer'
import { useRecoilState } from 'recoil'
import { formulaSheetEntriesAtom } from '@/atoms/formulaSheetEntries'
import { isEqual } from 'lodash'
import { reusableCssClass } from '@/utils/reusableCssClasses'
import { MagicTextBoxEditor } from '@/components/ContentTreeNode/ContentTreeLeafNode/MagicTextBoxEditor/MagicTextBoxEditor'
import { useDebounce } from 'magic-text-box-lexical/src/utils/useDebounce'
import { serializeEditorStateToString } from 'magic-text-box-lexical/src/serialization/serializeEditorStateToString'
import { EditorState } from 'lexical'

export interface EditFormulaSheetEntryProps {
    closeModal: () => void
    formulaSheetEntryId: string
}

export const EditFormulaSheetEntry: React.FC<EditFormulaSheetEntryProps> = (
    props
): ReactElement => {
    const [formulaSheetEntries, setFormulaSheetEntries] = useRecoilState(
        formulaSheetEntriesAtom
    )
    const [formulaSheetEntry, formulaSheetEntryIndex] = useMemo((): [
        FormulaSheetEntry,
        number,
    ] => {
        const index = formulaSheetEntries.findIndex(
            (entry) => entry.id === props.formulaSheetEntryId
        )
        return [formulaSheetEntries[index], index]
    }, [formulaSheetEntries, props.formulaSheetEntryId])

    const [localTitle, setLocalTitle] = useState<string>(
        formulaSheetEntry.title
    )
    const [localDescription, setLocalDescription] = useState<string>(
        formulaSheetEntry.description
    )
    const [localFormulaEditorState, setLocalFormulaEditorState] =
        useState<string>(formulaSheetEntry.formulaEditorState)

    const [
        localShouldForcePageBreakBefore,
        setLocalShouldForcePageBreakBefore,
    ] = useState<boolean>(formulaSheetEntry.shouldForcePageBreakBefore)

    const debouncedLocalFormulaUpdateListener = useDebounce(
        (editorState: EditorState): void => {
            const newLocalFormulaString =
                serializeEditorStateToString(editorState)
            setLocalFormulaEditorState(newLocalFormulaString)
        },
        250,
        1_000
    )
    const localFormulaSheetEntry = useMemo(
        (): FormulaSheetEntry => ({
            id: props.formulaSheetEntryId,
            title: localTitle,
            description: localDescription,
            formulaEditorState: localFormulaEditorState,
            shouldForcePageBreakBefore: localShouldForcePageBreakBefore,
        }),
        [
            localDescription,
            localFormulaEditorState,
            localShouldForcePageBreakBefore,
            localTitle,
            props.formulaSheetEntryId,
        ]
    )
    const isDifferentFromRecoil = useMemo(
        (): boolean => !isEqual(localFormulaSheetEntry, formulaSheetEntry),
        [localFormulaSheetEntry, formulaSheetEntry]
    )

    return (
        <div className={styles.editFormulaSheetEntry}>
            <div className={styles.editFormulaSheetEntryTitle}>
                Edit Formula Entry
            </div>
            <div className={styles.editFormulaSheetEntryBody}>
                <div className={styles.row}>
                    <div
                        className={`${styles.rowTitle} ${reusableCssClass.centerChildrenVertically}`}
                    >
                        Title:
                    </div>
                    <Input
                        placeholder={'Enter title here'}
                        value={localTitle}
                        onChange={(event) => setLocalTitle(event.target.value)}
                    />
                </div>
                <div className={styles.row}>
                    <div
                        className={`${styles.rowTitle} ${reusableCssClass.centerChildrenVertically}`}
                    >
                        Description:
                    </div>
                    <Input.TextArea
                        placeholder={'Enter a short description here'}
                        value={localDescription}
                        onChange={(event) =>
                            setLocalDescription(event.target.value)
                        }
                    />
                </div>
                <div className={`${styles.row} ${styles.switchRow}`}>
                    <div
                        className={`${styles.rowTitle} ${reusableCssClass.centerChildrenVertically}`}
                    >
                        Should force page break before:
                    </div>
                    <div
                        className={`${styles.switchContainer} ${reusableCssClass.centerChildrenVertically}`}
                    >
                        <Switch
                            checked={localShouldForcePageBreakBefore}
                            onChange={setLocalShouldForcePageBreakBefore}
                            checkedChildren={'Yes'}
                            unCheckedChildren={'No'}
                        />
                    </div>
                </div>
                <MagicTextBoxEditor
                    id={props.formulaSheetEntryId}
                    namespace={'formulaSheetEntry'}
                    placeholder={'Enter formula here'}
                    initialStringifiedEditorState={localFormulaEditorState}
                    editorUpdateListener={debouncedLocalFormulaUpdateListener}
                    savableProps={undefined}
                />
            </div>
            <div className={styles.footer}>
                <Button
                    disabled={!isDifferentFromRecoil}
                    onClick={() => {
                        setFormulaSheetEntries((current) =>
                            setFormulaSheetEntryAtIndex(
                                current,
                                localFormulaSheetEntry,
                                formulaSheetEntryIndex
                            )
                        )
                        props.closeModal()
                    }}
                    type={'primary'}
                >
                    Confirm
                </Button>
                <Button onClick={props.closeModal}>Cancel</Button>
            </div>
        </div>
    )
}
