import styles from './EditCourseMetadata.module.less'
import React, { ReactElement } from 'react'
import {
    EditCourseMetadataProps,
    EditCourseMetadataTab,
} from './EditCourseMetadata.types'
import { NextSittingDateEditor } from '@/components/EditCourseMetadata/NextSittingDateEditor/NextSittingDateEditor'
import { ContentTierTitlesEditor } from '@/components/EditCourseMetadata/ContentTierTitlesEditor/ContentTierTitlesEditor'
import { LoadingComponent } from '@/components/utils/LoadingComponent/LoadingComponent'
import { ReleaseInfoEditor } from '@/components/EditCourseMetadata/ReleaseInfoEditor/ReleaseInfoEditor'
import { RecommendedStudyHoursEditor } from '@/components/EditCourseMetadata/RecommendedStudyHoursEditor/RecommendedStudyHoursEditor'
import { FeatureFlagsEditor } from '@/components/EditCourseMetadata/FeatureFlagsEditor/FeatureFlagsEditor'
import { Tabs } from 'antd'

export const EditCourseMetadata: React.FC<
    EditCourseMetadataProps
> = (): ReactElement => {
    return (
        <div className={styles.editCourseMetadata}>
            <div className={styles.editCourseMetadataTitle}>
                Course Metadata
            </div>
            <React.Suspense
                fallback={<LoadingComponent useWhiteBackground={true} />}
            >
                <Tabs
                    items={[
                        {
                            key: EditCourseMetadataTab.nextSittingDate,
                            label: EditCourseMetadataTab.nextSittingDate,
                            children: <NextSittingDateEditor />,
                        },
                        {
                            key: EditCourseMetadataTab.recommendedStudyHours,
                            label: EditCourseMetadataTab.recommendedStudyHours,
                            children: <RecommendedStudyHoursEditor />,
                        },
                        {
                            key: EditCourseMetadataTab.contentTierTitles,
                            label: EditCourseMetadataTab.contentTierTitles,
                            children: <ContentTierTitlesEditor />,
                        },
                        {
                            key: EditCourseMetadataTab.releaseInfo,
                            label: EditCourseMetadataTab.releaseInfo,
                            children: <ReleaseInfoEditor />,
                        },
                        {
                            key: EditCourseMetadataTab.featureFlags,
                            label: EditCourseMetadataTab.featureFlags,
                            children: <FeatureFlagsEditor />,
                        },
                    ]}
                />
            </React.Suspense>
        </div>
    )
}
