import React, {
    ReactElement,
    useMemo,
    useCallback,
    useState,
    useEffect,
} from 'react'
import styles from './SourceMaterialsEditor.module.less'
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil'
import { unHashContentPath } from 'common/src/ContentPath'
import {
    frontendDisplayedCourseSelector,
    isActiveCourseReadOnlySelector,
} from '@/atoms/auth'
import { activeCourseSourceMaterialIDSelector } from '@/atoms/viewingState'
import produce from 'immer'
import { SourceMaterialData } from 'common/src/api/contentFrontendVsContentBackend/sourceMaterial'
import { syncErrorMessage, syncSuccessMessage } from '@/utils/syncMessages'
import { useDoesSpecificContentHaveUnsavedChanges } from '@/hooks/useDoesSpecificContentHaveUnsavedChanges'
import {
    DraggableIconType,
    DraggableSourceMaterialData,
} from '@/components/utils/DraggableSortingList/DraggableIcon/DraggableIcon.types'
import {
    SavableContentType,
    unsavedChangesSetAtom,
} from '@/atoms/unsavedChanges'
import {
    useMarkContentWithSavedChanges,
    useMarkContentWithUnsavedChanges,
} from '@/hooks/useMarkContentWithUnsavedChanges'
import { slimLeafNodeAtomFamily } from '@/atoms/slimLeafNode'
import { useSetLocalOnlySourceMaterialData } from '@/hooks/useSetLocalOnlySourceMaterialData'
import { newlyCreatedAndUnsavedSourceMaterialIDsAtom } from '@/atoms/newlyCreatedAndUnsavedContentIDs'
import { nanoid } from 'nanoid'
import {
    deleteSourceMaterial,
    reorderSourceMaterial,
} from '@/api/cb/contentCreatorContent'
import { ISlimLeafNodeData } from 'common/src/api/backendToBackend/content/types'
import { Button } from 'antd'
import { LoadingComponent } from '@/components/utils/LoadingComponent/LoadingComponent'
import { DraggableSortingList } from '@/components/utils/DraggableSortingList/DraggableSortingList'
import { SourceMaterialEditor } from '@/components/ContentTreeNode/ContentTreeLeafNode/SourceMaterialsEditor/SourceMaterialEditor/SourceMaterialEditor'
import { DeleteSourceMaterialButton } from '@/components/ContentTreeNode/ContentTreeLeafNode/SourceMaterialsEditor/DeleteSourceMaterialButton'

export const SourceMaterialsEditor: React.FC<{ hashedContentPath: string }> = (
    props
): ReactElement => {
    const [{ sourceMaterialIDs }, setSlimLeafNode] = useRecoilState(
        slimLeafNodeAtomFamily(props.hashedContentPath)
    )
    const contentPath = useMemo(
        () => unHashContentPath(props.hashedContentPath),
        [props.hashedContentPath]
    )
    const courseName = useRecoilValue(frontendDisplayedCourseSelector)
    const isReadOnly = useRecoilValue(isActiveCourseReadOnlySelector)

    const [recoilActiveSourceMaterialID, setRecoilActiveSourceMaterialID] =
        useRecoilState(activeCourseSourceMaterialIDSelector)
    const [localActiveSourceMaterialIndex, setLocalActiveSourceMaterialIndex] =
        useState<number>(
            Math.max(sourceMaterialIDs.indexOf(recoilActiveSourceMaterialID), 0)
        )

    // keep effective and active in sync. BE VERY CAREFUL when touching.
    const [localActiveSourceMaterialID, setLocalActiveSourceMaterialID] =
        useState<string>(sourceMaterialIDs[localActiveSourceMaterialIndex])
    useEffect(() => {
        if (isReordering) return
        if (recoilActiveSourceMaterialID !== localActiveSourceMaterialID) {
            setRecoilActiveSourceMaterialID(localActiveSourceMaterialID)
        }
    }, [localActiveSourceMaterialID, sourceMaterialIDs])
    useEffect(() => {
        if (isReordering) return
        if (localActiveSourceMaterialIndex === undefined) return

        const newLocalActiveSourceMaterialIndex =
            sourceMaterialIDs[localActiveSourceMaterialIndex]
        if (newLocalActiveSourceMaterialIndex === undefined) return

        if (newLocalActiveSourceMaterialIndex !== localActiveSourceMaterialID) {
            setLocalActiveSourceMaterialID(newLocalActiveSourceMaterialIndex)
        }
    }, [localActiveSourceMaterialIndex, sourceMaterialIDs])
    useEffect(() => {
        if (isReordering) return
        if (
            recoilActiveSourceMaterialID &&
            recoilActiveSourceMaterialID !==
                sourceMaterialIDs[localActiveSourceMaterialIndex]
        ) {
            const newActiveIndex = sourceMaterialIDs.indexOf(
                recoilActiveSourceMaterialID
            )
            if (newActiveIndex >= 0) {
                setLocalActiveSourceMaterialIndex(newActiveIndex)
            }
        }
    }, [recoilActiveSourceMaterialID, sourceMaterialIDs])

    const markContentWithUnsavedChanges = useMarkContentWithUnsavedChanges()

    const setLocalOnlySourceMaterialData = useSetLocalOnlySourceMaterialData()
    const setNewlyCreatedAndUnsavedSourceMaterialIDs = useSetRecoilState(
        newlyCreatedAndUnsavedSourceMaterialIDsAtom
    )
    const addNewCourseSourceMaterial = useCallback((): void => {
        const newSourceMaterialData = createNewDefaultSourceMaterial()
        setLocalOnlySourceMaterialData(newSourceMaterialData, contentPath)
        setSlimLeafNode((slimLeafNode) =>
            addNewSourceMaterialID(slimLeafNode, newSourceMaterialData.id)
        )
        markContentWithUnsavedChanges({
            contentPath,
            contentType: SavableContentType.sourceMaterial,
            id: newSourceMaterialData.id,
        })
        setNewlyCreatedAndUnsavedSourceMaterialIDs((current) =>
            current.add(newSourceMaterialData.id)
        )
        setLocalActiveSourceMaterialIndex(sourceMaterialIDs.length)
        setLocalActiveSourceMaterialID(newSourceMaterialData.id)
        setRecoilActiveSourceMaterialID(newSourceMaterialData.id)
    }, [
        contentPath,
        markContentWithUnsavedChanges,
        sourceMaterialIDs.length,
        setLocalOnlySourceMaterialData,
        setNewlyCreatedAndUnsavedSourceMaterialIDs,
        setRecoilActiveSourceMaterialID,
        setSlimLeafNode,
    ])
    const [isReordering, setIsReordering] = useState<boolean>(false)
    const reorderCourseSourceMaterialCallback = useCallback(
        async (startingIndex: number, endingIndex: number): Promise<void> => {
            setIsReordering(true)
            const sourceMaterialIDToBeReordered =
                sourceMaterialIDs[startingIndex]
            const response = await reorderSourceMaterial(
                courseName,
                sourceMaterialIDToBeReordered,
                contentPath,
                startingIndex,
                endingIndex
            )
            if (response.data.isError) {
                syncErrorMessage(
                    'Error reordering course source material. You may want to try reordering again or refreshing the page.'
                )
            } else {
                setSlimLeafNode((slimLeafNode) =>
                    reorderSourceMaterialID(
                        slimLeafNode,
                        startingIndex,
                        endingIndex
                    )
                )
                setLocalActiveSourceMaterialIndex(endingIndex)
                setLocalActiveSourceMaterialID(sourceMaterialIDToBeReordered)
                setRecoilActiveSourceMaterialID(sourceMaterialIDToBeReordered)
                syncSuccessMessage('Successfully reordered source materials')
            }
            setIsReordering(false)
        },
        [
            contentPath,
            courseName,
            sourceMaterialIDs,
            setRecoilActiveSourceMaterialID,
            setSlimLeafNode,
        ]
    )

    const unsavedChangesSet = useRecoilValue(unsavedChangesSetAtom)
    const doesSpecificContentHaveUnsavedChanges =
        useDoesSpecificContentHaveUnsavedChanges(unsavedChangesSet)
    const draggableSourceMaterialData = useMemo(
        (): DraggableSourceMaterialData[] =>
            sourceMaterialIDs.map((id) => ({
                type: DraggableIconType.sourceMaterial,
                id,
                contentPath,
                hasUnsavedChanges: doesSpecificContentHaveUnsavedChanges({
                    contentPath,
                    contentType: SavableContentType.sourceMaterial,
                    id,
                }),
            })),
        [contentPath, sourceMaterialIDs, doesSpecificContentHaveUnsavedChanges]
    )
    const markContentWithSavedChanges = useMarkContentWithSavedChanges()
    const deleteSourceMaterialCallback =
        useCallback(async (): Promise<void> => {
            const response = await deleteSourceMaterial(
                courseName,
                localActiveSourceMaterialID,
                contentPath
            )
            if (response.data.isError) {
                syncErrorMessage(
                    'Error deleting source material. You may want to try deleting the source material again or refreshing the page.'
                )
            } else {
                setLocalActiveSourceMaterialIndex(
                    Math.max(0, localActiveSourceMaterialIndex - 1)
                )
                setSlimLeafNode((slimLeafNode) =>
                    deleteSourceMaterialID(
                        slimLeafNode,
                        localActiveSourceMaterialID
                    )
                )
                markContentWithSavedChanges({
                    contentPath,
                    contentType: SavableContentType.sourceMaterial,
                    id: localActiveSourceMaterialID,
                })
                syncSuccessMessage('Source material entry successfully deleted')
            }
        }, [
            contentPath,
            courseName,
            localActiveSourceMaterialID,
            localActiveSourceMaterialIndex,
            markContentWithSavedChanges,
            setSlimLeafNode,
        ])

    return (
        <div className={styles.sourceMaterialsEditor}>
            <div className={styles.sourceMaterialsEditorTitle}>
                Edit Source Materials ({sourceMaterialIDs.length})
            </div>
            <div>
                <Button
                    type={'primary'}
                    onClick={addNewCourseSourceMaterial}
                    disabled={isReadOnly}
                >
                    Add New Source Material Entry
                </Button>
            </div>
            <div>
                Select source material entry to edit (can reorder by dragging
                and dropping):{' '}
                <div className={styles.sourceMaterialList}>
                    <React.Suspense
                        fallback={
                            <LoadingComponent useWhiteBackground={true} />
                        }
                    >
                        <DraggableSortingList
                            itemData={draggableSourceMaterialData}
                            changeIndex={reorderCourseSourceMaterialCallback}
                            activeIndex={localActiveSourceMaterialIndex}
                            setActiveIndex={setLocalActiveSourceMaterialIndex}
                            isLoading={isReordering}
                            isDisabled={isReadOnly}
                        />
                    </React.Suspense>
                </div>
            </div>
            {sourceMaterialIDs.length ? (
                <React.Suspense
                    fallback={<LoadingComponent useWhiteBackground={true} />}
                >
                    <DeleteSourceMaterialButton
                        deleteSourceMaterial={deleteSourceMaterialCallback}
                    />
                    <SourceMaterialEditor
                        key={localActiveSourceMaterialID}
                        contentPath={contentPath}
                        id={localActiveSourceMaterialID}
                    />
                </React.Suspense>
            ) : (
                <div>No active source material to display.</div>
            )}
        </div>
    )
}

const addNewSourceMaterialID = produce(
    (slimLeafNode: ISlimLeafNodeData, newID: string): void => {
        slimLeafNode.sourceMaterialIDs.push(newID)
    }
)

const reorderSourceMaterialID = produce(
    (
        slimLeafNode: ISlimLeafNodeData,
        startingIndex: number,
        endingIndex: number
    ): void => {
        const movedContent = slimLeafNode.sourceMaterialIDs.splice(
            startingIndex,
            1
        )
        slimLeafNode.sourceMaterialIDs.splice(endingIndex, 0, ...movedContent)
    }
)

const createNewDefaultSourceMaterial = (): SourceMaterialData => ({
    id: nanoid(),
    title: '',
    content: '',
})

const deleteSourceMaterialID = produce(
    (slimLeafNode: ISlimLeafNodeData, sourceMaterialID: string): void => {
        slimLeafNode.sourceMaterialIDs = slimLeafNode.sourceMaterialIDs.filter(
            (arrayID) => arrayID !== sourceMaterialID
        )
    }
)
