import produce from 'immer'
import { ContentCreatorEditablePracticeProblemMetadata } from '@/components/ContentTreeNode/ContentTreeLeafNode/PracticeProblemsEditor/PracticeProblemEditor/PracticeProblemMetadataEditor/PracticeProblemMetadataEditor.types'
import {
    IGenericPracticeProblem,
    IGenericSinglePartPracticeProblem,
    IMultiPartProblemBase,
    IMultipleChoiceProblemBase,
} from 'common/src/practiceProblems/types'
import { PracticeProblemType } from 'common/src/practiceProblems'

export const setQuestion = produce(
    (practiceProblem: IGenericPracticeProblem, question: string): void => {
        practiceProblem.question = question
    }
)
export const setSolution = produce(
    (practiceProblem: IGenericPracticeProblem, solution: string): void => {
        practiceProblem.solution = solution
    }
)
export const setSubProblemQuestion = produce(
    (
        practiceProblem: IGenericPracticeProblem,
        subProblemIndex: number,
        question: string
    ): void => {
        ;(practiceProblem as IMultiPartProblemBase).subProblems[
            subProblemIndex
        ].question = question
    }
)
export const setSubProblemSolution = produce(
    (
        practiceProblem: IGenericPracticeProblem,
        subProblemIndex: number,
        solution: string
    ): void => {
        ;(practiceProblem as IMultiPartProblemBase).subProblems[
            subProblemIndex
        ].solution = solution
    }
)
export const setContentCreatorEditablePracticeProblemMetadata = (
    practiceProblem: IGenericPracticeProblem,
    contentCreatorEditablePracticeProblemMetadata: ContentCreatorEditablePracticeProblemMetadata
): IGenericPracticeProblem => {
    const genericPracticeProblem = {
        ...practiceProblem,
        ...contentCreatorEditablePracticeProblemMetadata,
    } as IGenericPracticeProblem
    if (
        practiceProblem.type !== PracticeProblemType.MULTI_PART &&
        contentCreatorEditablePracticeProblemMetadata.type ===
            PracticeProblemType.MULTI_PART
    ) {
        return {
            ...genericPracticeProblem,
            subProblems: [],
        } as IMultiPartProblemBase
    }

    if (
        practiceProblem.type === PracticeProblemType.MULTI_PART &&
        contentCreatorEditablePracticeProblemMetadata.type !==
            PracticeProblemType.MULTI_PART
    ) {
        delete (genericPracticeProblem as IMultiPartProblemBase).subProblems
    }

    if (
        practiceProblem.type !== PracticeProblemType.MULTIPLE_CHOICE &&
        contentCreatorEditablePracticeProblemMetadata.type ===
            PracticeProblemType.MULTIPLE_CHOICE
    ) {
        return {
            ...genericPracticeProblem,
            choices: [],
            shouldShuffleAnswers: true,
        } as IMultipleChoiceProblemBase
    }

    if (
        practiceProblem.type === PracticeProblemType.MULTIPLE_CHOICE &&
        contentCreatorEditablePracticeProblemMetadata.type !==
            PracticeProblemType.MULTIPLE_CHOICE
    ) {
        delete (genericPracticeProblem as IMultipleChoiceProblemBase).choices
        delete (genericPracticeProblem as IMultipleChoiceProblemBase)
            .shouldShuffleAnswers
    }

    return genericPracticeProblem
}

export const setContentCreatorEditablePracticeProblemMetadataForSubProblem =
    produce(
        (
            practiceProblem: IGenericPracticeProblem,
            subProblemIndex: number,
            contentCreatorEditablePracticeProblemMetadata: ContentCreatorEditablePracticeProblemMetadata
        ): void => {
            ;(practiceProblem as IMultiPartProblemBase).subProblems[
                subProblemIndex
            ] = {
                ...(practiceProblem as IMultiPartProblemBase).subProblems[
                    subProblemIndex
                ],
                ...contentCreatorEditablePracticeProblemMetadata,
            } as IGenericSinglePartPracticeProblem
        }
    )

export const setExcelS3Key = produce(
    (practiceProblem: IGenericPracticeProblem, excelS3Key: string): void => {
        practiceProblem.excelS3Key = excelS3Key
    }
)

export const setExcelSolutionS3Key = produce(
    (practiceProblem: IGenericPracticeProblem, excelS3Key: string): void => {
        practiceProblem.excelSolutionS3Key = excelS3Key
    }
)

export const addNewSubProblem = produce(
    (
        practiceProblem: IMultiPartProblemBase,
        newSubProblem: IGenericSinglePartPracticeProblem
    ): void => {
        practiceProblem.subProblems.push(newSubProblem)
    }
)

export const reorderSubProblemID = produce(
    (
        practiceProblem: IMultiPartProblemBase,
        startingIndex: number,
        endingIndex: number
    ): void => {
        const movedContent = practiceProblem.subProblems.splice(
            startingIndex,
            1
        )
        practiceProblem.subProblems.splice(endingIndex, 0, ...movedContent)
    }
)

export const deleteSubProblem = produce(
    (practiceProblem: IMultiPartProblemBase, subProblemIndex: number): void => {
        practiceProblem.subProblems.splice(subProblemIndex, 1)
    }
)

export const cleanMultipartPoints = produce(
    (practiceProblem: IMultiPartProblemBase): void => {
        practiceProblem.points = practiceProblem.subProblems
            .map((subProblem) => subProblem.points)
            .reduce((a, b) => a + b, 0)
    }
)
