import { CB_BASE_PREFIX, APIResponseWrapper } from '@/api/types'
import {
    GetSignedUrlRequestQuery,
    UploadImageRequest,
    DeleteS3FileRequest,
    GetSignedUrlForUploadRequest,
    GetSignedUrlForDownloadRequest,
    DeleteUploadedExcelFileRequest,
    GetSignedUrlForMtbUploadRequest,
} from 'common/src/api/s3/requests'
import {
    GetSignedUrlAndS3KeyResponse,
    GetSignedUrlResponse,
    UploadImageResponse,
} from 'common/src/api/s3/responses'
import { ApiMethod, EmptyResponse } from 'common/src/api/types'
import { callBackend } from '@/api/callBackend'

const PREFIX = CB_BASE_PREFIX + '/privateS3'

export const getSignedUrlForS3Key = async (s3Key: string): Promise<string> => {
    const response = await callBackend<
        GetSignedUrlRequestQuery,
        GetSignedUrlResponse
    >(PREFIX + '/getMagicTextBoxSignedUrl', ApiMethod.GET, { s3Key })
    return response.data.payload.signedUrl
}

export const getPreSignedUrlForPut = async (
    fileExtension: string
): Promise<GetSignedUrlAndS3KeyResponse> => {
    const response = await callBackend<
        GetSignedUrlForMtbUploadRequest,
        GetSignedUrlAndS3KeyResponse
    >(
        PREFIX + '/getSignedUrlAndS3KeyForMagicTextBoxImageUpload',
        ApiMethod.POST,
        { fileExtension }
    )
    return response.data.payload
}

export const deleteUploadedImage = async (s3Key: string): Promise<void> => {
    await callBackend<DeleteS3FileRequest, EmptyResponse>(
        PREFIX + '/deleteMagicTextBoxImage',
        ApiMethod.POST,
        { s3Key }
    )
}

export const uploadB64Image = async (b64Img: string): Promise<string> => {
    const response = await callBackend<UploadImageRequest, UploadImageResponse>(
        PREFIX + '/uploadMagicTextBoxImage',
        ApiMethod.POST,
        { b64Img }
    )
    return response.data.payload.s3Key
}

export const getSignedUrlAndS3KeyForPracticeProblemExcelFileUpload = async (
    courseName: string,
    id: string
): APIResponseWrapper<GetSignedUrlAndS3KeyResponse> => {
    return await callBackend<
        GetSignedUrlForUploadRequest,
        GetSignedUrlAndS3KeyResponse
    >(
        PREFIX + '/getSignedUrlAndS3KeyForPracticeProblemExcelFileUpload',
        ApiMethod.POST,
        { courseName, id, fileExtension: '.xlsx' }
    )
}

export const getSignedUrlForPracticeProblemExcelFileDownload = async (
    courseName: string,
    id: string,
    downloadedFileName: string
): APIResponseWrapper<GetSignedUrlResponse> => {
    return await callBackend<
        GetSignedUrlForDownloadRequest,
        GetSignedUrlResponse
    >(
        PREFIX + '/getSignedUrlForPracticeProblemExcelFileDownload',
        ApiMethod.GET,
        { courseName, id, downloadedFileName }
    )
}

export const deleteUploadedExcelFile = async (
    courseName: string,
    id: string
): APIResponseWrapper<EmptyResponse> => {
    return await callBackend<DeleteUploadedExcelFileRequest, EmptyResponse>(
        PREFIX + '/deleteUploadedExcelFile',
        ApiMethod.POST,
        { courseName, id }
    )
}

export const getSignedUrlAndS3KeyForPracticeProblemExcelSolutionFileUpload =
    async (
        courseName: string,
        id: string
    ): APIResponseWrapper<GetSignedUrlAndS3KeyResponse> => {
        return await callBackend<
            GetSignedUrlForUploadRequest,
            GetSignedUrlAndS3KeyResponse
        >(
            PREFIX +
                '/getSignedUrlAndS3KeyForPracticeProblemExcelSolutionFileUpload',
            ApiMethod.POST,
            { courseName, id, fileExtension: '.xlsx' }
        )
    }

export const getSignedUrlForPracticeProblemExcelSolutionFileDownload = async (
    courseName: string,
    id: string,
    downloadedFileName: string
): APIResponseWrapper<GetSignedUrlResponse> => {
    return await callBackend<
        GetSignedUrlForDownloadRequest,
        GetSignedUrlResponse
    >(
        PREFIX + '/getSignedUrlForPracticeProblemExcelSolutionFileDownload',
        ApiMethod.GET,
        { courseName, id, downloadedFileName }
    )
}

export const deleteUploadedExcelSolutionFile = async (
    courseName: string,
    id: string
): APIResponseWrapper<EmptyResponse> => {
    return await callBackend<DeleteUploadedExcelFileRequest, EmptyResponse>(
        PREFIX + '/deleteUploadedExcelSolutionFile',
        ApiMethod.POST,
        { courseName, id }
    )
}
