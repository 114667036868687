import { CopyableIndividualContentType } from 'common/src/api/contentFrontendVsContentBackend/requests'
import { useCallback } from 'react'
import { useInvalidateNotecard } from '@/atoms/notecard'
import { useInvalidatePracticeProblem } from '@/atoms/practiceProblem'
import assertNever from 'assert-never/index'

export const useInvalidateContent = (
    type: CopyableIndividualContentType,
    id: string
): (() => void) => {
    const invalidateNotecard = useInvalidateNotecard(id)
    const invalidatePracticeProblem = useInvalidatePracticeProblem(id)

    return useCallback(() => {
        switch (type) {
            case 'notecard':
                invalidateNotecard()
                break
            case 'practiceProblem':
                invalidatePracticeProblem()
                break
            default:
                assertNever(type)
        }
    }, [invalidateNotecard, invalidatePracticeProblem, type])
}
