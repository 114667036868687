export enum PracticeProblemEditorContentType {
    question = 'Question',
    solution = 'Solution',
    metadata = 'Metadata',
    subProblems = 'Sub-Problems',
    commentThread = 'Comment Thread',
}

export interface PracticeProblemEditorProps {
    practiceProblemID: string
    superProblemID?: string
}
