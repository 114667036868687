import React, {
    ReactElement,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react'
import styles from './NotecardsEditor.module.less'
import { NotecardsEditorProps } from '@/components/ContentTreeNode/ContentTreeLeafNode/NotecardsEditor/NotecardsEditor.types'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { slimLeafNodeAtomFamily } from '@/atoms/slimLeafNode'
import { Button } from 'antd'
import { NotecardEditor } from '@/components/ContentTreeNode/ContentTreeLeafNode/NotecardsEditor/NotecardEditor/NotecardEditor'
import { DraggableSortingList } from '@/components/utils/DraggableSortingList/DraggableSortingList'
import { unHashContentPath } from 'common/src/ContentPath'
import {
    frontendDisplayedCourseSelector,
    isActiveCourseReadOnlySelector,
} from '@/atoms/auth'
import { DeleteNotecardButton } from '@/components/ContentTreeNode/ContentTreeLeafNode/NotecardsEditor/DeleteNotecardButton/DeleteNotecardButton'
import {
    deleteNotecardAtContentPath,
    reorderNotecards,
} from '@/api/cb/contentCreatorContent'
import {
    deleteNotecardID,
    addNewNotecardID,
    reorderNotecardID,
} from '@/components/ContentTreeNode/ContentTreeLeafNode/immer'
import { createNewDefaultNotecard } from '@/components/ContentTreeNode/ContentTreeLeafNode/NotecardsEditor/createNewDefaultNotecard'
import {
    DraggableIconType,
    DraggableNotecardData,
} from '@/components/utils/DraggableSortingList/DraggableIcon/DraggableIcon.types'
import { LoadingComponent } from '@/components/utils/LoadingComponent/LoadingComponent'
import { syncErrorMessage, syncSuccessMessage } from '@/utils/syncMessages'
import { activeNotecardIDSelector } from '@/atoms/viewingState'
import { useSetLocalOnlyNotecardData } from '@/hooks/useSetLocalOnlyNotecardData'
import { useDoesSpecificContentHaveUnsavedChanges } from '@/hooks/useDoesSpecificContentHaveUnsavedChanges'
import {
    SavableContentType,
    unsavedChangesSetAtom,
} from '@/atoms/unsavedChanges'
import {
    useMarkContentWithUnsavedChanges,
    useMarkContentWithSavedChanges,
} from '@/hooks/useMarkContentWithUnsavedChanges'
import { newlyCreatedAndUnsavedNotecardIDsAtom } from '@/atoms/newlyCreatedAndUnsavedContentIDs'
import { reusableCssClass } from '@/utils/reusableCssClasses'

export const NotecardsEditor: React.FC<NotecardsEditorProps> = (
    props
): ReactElement => {
    const [{ notecardIDs }, setSlimLeafNode] = useRecoilState(
        slimLeafNodeAtomFamily(props.hashedContentPath)
    )

    const contentPath = useMemo(
        () => unHashContentPath(props.hashedContentPath),
        [props.hashedContentPath]
    )
    const courseName = useRecoilValue(frontendDisplayedCourseSelector)
    const isReadOnly = useRecoilValue(isActiveCourseReadOnlySelector)

    const [recoilActiveNotecardID, setRecoilActiveNotecardID] = useRecoilState(
        activeNotecardIDSelector
    )
    const [localActiveNotecardIndex, setLocalActiveNotecardIndex] =
        useState<number>(
            Math.max(notecardIDs.indexOf(recoilActiveNotecardID), 0)
        )

    // keep effective and active in sync. BE VERY CAREFUL when touching.
    const [localActiveNotecardID, setLocalActiveNotecardID] = useState<string>(
        notecardIDs[localActiveNotecardIndex]
    )
    useEffect(() => {
        if (isReordering) return
        if (recoilActiveNotecardID !== localActiveNotecardID) {
            // console.log(
            //     `Setting recoil active notecard id to: ${localActiveNotecardID} because local active notecard id changed to: ${localActiveNotecardID}`
            // )
            setRecoilActiveNotecardID(localActiveNotecardID)
        }
    }, [localActiveNotecardID, notecardIDs])
    useEffect(() => {
        if (isReordering) return
        if (localActiveNotecardIndex === undefined) return

        const newLocalActiveNotecardID = notecardIDs[localActiveNotecardIndex]
        if (newLocalActiveNotecardID === undefined) return

        if (newLocalActiveNotecardID !== localActiveNotecardID) {
            // console.log(
            //     `Setting local active notecard id to: ${newLocalActiveNotecardID} because local active notecard index changed to: ${localActiveNotecardIndex}`
            // )
            setLocalActiveNotecardID(newLocalActiveNotecardID)
        }
    }, [localActiveNotecardIndex, notecardIDs])
    useEffect(() => {
        if (isReordering) return
        if (
            recoilActiveNotecardID &&
            recoilActiveNotecardID !== notecardIDs[localActiveNotecardIndex]
        ) {
            const newActiveIndex = notecardIDs.indexOf(recoilActiveNotecardID)
            if (newActiveIndex >= 0) {
                // console.log(
                //     `Setting local active notecard index to: ${newActiveIndex} because recoil id changed to: ${recoilActiveNotecardID}`
                // )
                setLocalActiveNotecardIndex(newActiveIndex)
            }
        }
    }, [recoilActiveNotecardID, notecardIDs])

    const markContentWithUnsavedChanges = useMarkContentWithUnsavedChanges()

    const setLocalOnlyNotecardData = useSetLocalOnlyNotecardData()
    const setNewlyCreatedAndUnsavedNotecardIDs = useSetRecoilState(
        newlyCreatedAndUnsavedNotecardIDsAtom
    )
    const addNewNotecard = useCallback((): void => {
        const newNotecard = createNewDefaultNotecard(
            notecardIDs.length,
            courseName,
            contentPath
        )
        setLocalOnlyNotecardData(newNotecard)
        setSlimLeafNode((slimLeafNode) =>
            addNewNotecardID(slimLeafNode, newNotecard.id)
        )
        markContentWithUnsavedChanges({
            contentPath: newNotecard.contentPath,
            contentType: SavableContentType.notecard,
            id: newNotecard.id,
        })
        setNewlyCreatedAndUnsavedNotecardIDs((current) =>
            current.add(newNotecard.id)
        )
        setLocalActiveNotecardIndex(notecardIDs.length)
        setLocalActiveNotecardID(newNotecard.id)
        setRecoilActiveNotecardID(newNotecard.id)
    }, [
        contentPath,
        courseName,
        markContentWithUnsavedChanges,
        notecardIDs.length,
        setLocalOnlyNotecardData,
        setNewlyCreatedAndUnsavedNotecardIDs,
        setRecoilActiveNotecardID,
        setSlimLeafNode,
    ])

    const [isReordering, setIsReordering] = useState<boolean>(false)
    const reorderNotecardsCallback = useCallback(
        async (startingIndex: number, endingIndex: number): Promise<void> => {
            setIsReordering(true)
            const notecardIDToBeReordered = notecardIDs[startingIndex]
            const response = await reorderNotecards(
                courseName,
                notecardIDToBeReordered,
                contentPath,
                startingIndex,
                endingIndex
            )
            if (response.data.isError) {
                syncErrorMessage(
                    'Error reordering notecards. You may want to try reordering the notecard again or refreshing the page.'
                )
            } else {
                // console.log(
                //     `NEW SET: new index -> ${endingIndex}, new local / recoil id: ${notecardIDToBeReordered}`
                // )
                setSlimLeafNode((slimLeafNode) =>
                    reorderNotecardID(slimLeafNode, startingIndex, endingIndex)
                )
                setLocalActiveNotecardIndex(endingIndex)
                setLocalActiveNotecardID(notecardIDToBeReordered)
                setRecoilActiveNotecardID(notecardIDToBeReordered)
                syncSuccessMessage('Successfully reordered notecards')
            }
            setIsReordering(false)
        },
        [
            contentPath,
            courseName,
            notecardIDs,
            setRecoilActiveNotecardID,
            setSlimLeafNode,
        ]
    )

    const unsavedChangesSet = useRecoilValue(unsavedChangesSetAtom)
    const doesSpecificContentHaveUnsavedChanges =
        useDoesSpecificContentHaveUnsavedChanges(unsavedChangesSet)
    const notecardsData = useMemo(
        (): DraggableNotecardData[] =>
            notecardIDs.map((id) => ({
                type: DraggableIconType.notecard,
                id,
                hasUnsavedChanges:
                    doesSpecificContentHaveUnsavedChanges({
                        contentPath,
                        contentType: SavableContentType.notecard,
                        id,
                    }) ||
                    doesSpecificContentHaveUnsavedChanges({
                        contentPath,
                        contentType: SavableContentType.practiceProblem,
                        id,
                    }),
            })),
        [contentPath, doesSpecificContentHaveUnsavedChanges, notecardIDs]
    )

    const markContentWithSavedChanges = useMarkContentWithSavedChanges()
    const deleteNotecard = useCallback(async (): Promise<void> => {
        const response = await deleteNotecardAtContentPath(
            courseName,
            contentPath,
            localActiveNotecardID
        )
        if (response.data.isError) {
            syncErrorMessage(
                'Error deleting notecard. You may want to try deleting the notecard again or refreshing the page.'
            )
        } else {
            setLocalActiveNotecardIndex(
                Math.max(0, localActiveNotecardIndex - 1)
            )
            setSlimLeafNode((slimLeafNode) =>
                deleteNotecardID(slimLeafNode, localActiveNotecardID)
            )
            markContentWithSavedChanges({
                contentPath,
                contentType: SavableContentType.notecard,
                id: localActiveNotecardID,
            })
            syncSuccessMessage('Notecard successfully deleted')
        }
    }, [
        courseName,
        contentPath,
        localActiveNotecardID,
        localActiveNotecardIndex,
        setSlimLeafNode,
        markContentWithSavedChanges,
    ])

    const unsavedChangesWarning = useMemo((): ReactElement => {
        if (
            !doesSpecificContentHaveUnsavedChanges({
                contentPath,
                contentType: SavableContentType.notecard,
                id: localActiveNotecardID,
            })
        ) {
            return
        }
        return (
            <div className={styles.warningText}>
                {doesSpecificContentHaveUnsavedChanges &&
                    'WARNING: THERE ARE UNSAVED CHANGES'}
            </div>
        )
    }, [
        contentPath,
        doesSpecificContentHaveUnsavedChanges,
        localActiveNotecardID,
    ])

    return (
        <div className={styles.notecardsEditor}>
            <div className={styles.notecardsEditorTitle}>
                Edit Notecards ({notecardIDs.length})
            </div>
            <div>
                <Button
                    type={'primary'}
                    onClick={addNewNotecard}
                    disabled={isReadOnly}
                >
                    Add New Notecard
                </Button>
            </div>
            <div>
                Select notecard to edit (can reorder by dragging and dropping):{' '}
                <div className={styles.notecardList}>
                    <React.Suspense
                        fallback={
                            <LoadingComponent useWhiteBackground={true} />
                        }
                    >
                        <DraggableSortingList
                            itemData={notecardsData}
                            changeIndex={reorderNotecardsCallback}
                            activeIndex={localActiveNotecardIndex}
                            setActiveIndex={setLocalActiveNotecardIndex}
                            isLoading={isReordering}
                            isDisabled={isReadOnly}
                        />
                    </React.Suspense>
                </div>
            </div>
            {notecardIDs.length ? (
                <React.Suspense
                    fallback={<LoadingComponent useWhiteBackground={true} />}
                >
                    <div className={styles.notecardSubContainer}>
                        <div
                            className={
                                reusableCssClass.centerChildrenVertically
                            }
                        >
                            <DeleteNotecardButton
                                deleteNotecard={deleteNotecard}
                            />
                        </div>
                        {unsavedChangesWarning}
                    </div>
                    <NotecardEditor
                        key={localActiveNotecardID}
                        notecardID={localActiveNotecardID}
                    />
                </React.Suspense>
            ) : (
                <div>No active notecards to display.</div>
            )}
        </div>
    )
}
