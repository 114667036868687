import React, { ReactElement, useMemo } from 'react'
import { useRecoilValue } from 'recoil'
import { frontendDisplayedCourseSelector } from '@/atoms/auth'
import { previousExamAnalysisSignedDownloadUrlAtomFamily } from '@/atoms/previousExamAnalysisSignedDownloadUrl'
import { SAMPLE_PREVIOUS_EXAM_ANALYSIS_COURSE_NAME } from 'common/src/api/contentFrontendVsContentBackend/previousExamContent'

const SUFFIX = '_previous_exam_analysis.csv'
export const PreviousExamAnalysisDownloader: React.FC<{
    isSample: boolean
}> = (props): ReactElement => {
    const courseName = useRecoilValue(frontendDisplayedCourseSelector)
    const prefix = useMemo(
        (): string =>
            props.isSample
                ? SAMPLE_PREVIOUS_EXAM_ANALYSIS_COURSE_NAME
                : courseName,
        [courseName, props.isSample]
    )
    const downloadName = useMemo((): string => {
        return `${prefix}${SUFFIX}`
    }, [prefix])

    const signedDownloadUrl = useRecoilValue(
        previousExamAnalysisSignedDownloadUrlAtomFamily(prefix)
    )

    const content = useMemo((): ReactElement => {
        if (signedDownloadUrl) {
            return (
                <a href={signedDownloadUrl} download={downloadName}>
                    {downloadName}
                </a>
            )
        } else {
            return <span>No excel file has been uploaded yet.</span>
        }
    }, [downloadName, signedDownloadUrl])
    return <div>{content}</div>
}
