import { SavableProps } from 'magic-text-box-lexical/src/components/MagicTextBoxCore/MagicTextBoxCore.types'
import React, { ReactElement, useCallback, useMemo, useState } from 'react'
import styles from './MultipleChoicePracticeProblemSolutionsEditor.module.scss'
import { Button, Select, Switch } from 'antd'
import { SetterOrUpdater, useRecoilState, useRecoilValue } from 'recoil'
import { isActiveCourseReadOnlySelector } from '@/atoms/auth'
import { practiceProblemAtomFamily } from '@/atoms/practiceProblem'
import produce from 'immer'
import {
    generateNewMultipleChoiceOptionId,
    IMultipleChoiceProblemBase,
} from 'common/src/practiceProblems/types'
import { DraggableSortingList } from '@/components/utils/DraggableSortingList/DraggableSortingList'
import {
    DraggableIconType,
    DraggableMultipleChoiceOptionData,
} from '@/components/utils/DraggableSortingList/DraggableIcon/DraggableIcon.types'
import { DeleteMultipleChoiceOptionButton } from '@/components/ContentTreeNode/ContentTreeLeafNode/PracticeProblemsEditor/PracticeProblemEditor/MultipleChoicePracticeProblemSolutionsEditor/DeleteMultipleChoiceOptionButton'
import { extractTextFromStringifiedEditorState } from 'magic-text-box-lexical/src/utils/extractTextFromStringifiedEditorState'
import { MagicTextBoxEditor } from '@/components/ContentTreeNode/ContentTreeLeafNode/MagicTextBoxEditor/MagicTextBoxEditor'
import { useDebounce } from 'magic-text-box-lexical/src/utils/useDebounce'

import { EditorState } from 'lexical'
import { serializeEditorStateToString } from 'magic-text-box-lexical/src/serialization/serializeEditorStateToString'
import assert from 'assert'
import { RequiredAsterisk } from '@/components/utils/RequiredAsterisk/RequiredAsterisk'

const { Option } = Select

interface MultipleChoicePracticeProblemSolutionsEditorProps {
    practiceProblemID: string
    onSave: SavableProps
}

/**
 * Should be able to:
 *  Add / delete / reorder choices
 *  Each choice is an MTB (possibly a special, small version (e.g. inline))
 *  Select which is the correct answer
 *  Select whether answers can be shuffled
 */
export const MultipleChoicePracticeProblemSolutionsEditor: React.FC<
    MultipleChoicePracticeProblemSolutionsEditorProps
> = (props): ReactElement => {
    const isReadOnly = useRecoilValue(isActiveCourseReadOnlySelector)

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const [practiceProblem, setPracticeProblem]: [
        IMultipleChoiceProblemBase,
        SetterOrUpdater<IMultipleChoiceProblemBase>,
    ] = useRecoilState(practiceProblemAtomFamily(props.practiceProblemID))

    const multipleChoiceOptionData = useMemo(
        (): DraggableMultipleChoiceOptionData[] =>
            practiceProblem.choices.map((choice) => ({
                type: DraggableIconType.multipleChoiceOption,
                id: choice.id,
                stringifiedMagicTextBox: choice.stringifiedMagicTextBox,
                hasUnsavedChanges: false,
                isSolution: choice.id === practiceProblem.solution,
            })),
        [practiceProblem.choices, practiceProblem.solution]
    )

    const addNewMultipleChoiceOption = useCallback((): void => {
        setPracticeProblem((practiceProblem) =>
            addNewMultipleChoiceOptionLocal(practiceProblem)
        )
        setActiveMultipleChoiceOptionIndex(practiceProblem.choices.length)
    }, [practiceProblem.choices.length, setPracticeProblem])

    const reorderMultipleChoiceOption = useCallback(
        (startingIndex: number, endingIndex: number): void => {
            setPracticeProblem((practiceProblem) =>
                reorderMultipleChoiceOptionLocal(
                    practiceProblem,
                    startingIndex,
                    endingIndex
                )
            )
        },
        [setPracticeProblem]
    )
    const [
        activeMultipleChoiceOptionIndex,
        setActiveMultipleChoiceOptionIndex,
    ] = useState<number>(0)

    const deleteActiveMultipleChoiceOption = useCallback((): void => {
        if (
            activeMultipleChoiceOptionIndex ===
            multipleChoiceOptionData.length - 1
        ) {
            setActiveMultipleChoiceOptionIndex(
                multipleChoiceOptionData.length - 2
            )
        }
        setPracticeProblem((practiceProblem) =>
            deleteMultipleChoiceOptionLocal(
                practiceProblem,
                activeMultipleChoiceOptionIndex
            )
        )
    }, [
        activeMultipleChoiceOptionIndex,
        multipleChoiceOptionData.length,
        setPracticeProblem,
    ])

    const setShouldShuffleAnswers = useCallback(
        (newShouldShuffleAnswers: boolean): void =>
            setPracticeProblem((practiceProblem) =>
                setShouldShuffleAnswersLocal(
                    practiceProblem,
                    newShouldShuffleAnswers
                )
            ),
        [setPracticeProblem]
    )

    const setSolution = useCallback(
        (newSolutionId: string): void => {
            setPracticeProblem((practiceProblem) =>
                setSolutionLocal(practiceProblem, newSolutionId)
            )
        },
        [setPracticeProblem]
    )

    const setExplanation = useCallback(
        (newExplanation: EditorState): void => {
            const newExplanationSerialized =
                serializeEditorStateToString(newExplanation)
            setPracticeProblem((practiceProblem) =>
                setExplanationLocal(practiceProblem, newExplanationSerialized)
            )
        },
        [setPracticeProblem]
    )

    const debouncedActiveMultipleChoiceOptionUpdateListener = useDebounce(
        (editorState: EditorState): void => {
            const newActiveMultipleChoiceOption =
                serializeEditorStateToString(editorState)
            setPracticeProblem((practiceProblem) =>
                setMultipleChoiceOptionLocal(
                    practiceProblem,
                    activeMultipleChoiceOptionIndex,
                    newActiveMultipleChoiceOption
                )
            )
        },
        250,
        1_000
    )

    return (
        <div className={styles.multipleChoicePracticeProblemSolutionsEditor}>
            {multipleChoiceOptionData.length ? (
                <>
                    <div className={styles.selectCorrectOptionContainer}>
                        <div
                            className={styles.selectCorrectOptionTitleContainer}
                        >
                            Select correct answer:
                            <RequiredAsterisk />
                        </div>
                        <Select
                            value={
                                practiceProblem.choices.find(
                                    (choice) =>
                                        choice.id === practiceProblem.solution
                                )?.id
                            }
                            onChange={setSolution}
                        >
                            {practiceProblem.choices.map((choice) => (
                                <Option value={choice.id} key={choice.id}>
                                    {extractTextFromStringifiedEditorState(
                                        choice.stringifiedMagicTextBox
                                    ) || 'No text'}
                                </Option>
                            ))}
                        </Select>
                    </div>
                    <div className={styles.explanationEditor}>
                        <div className={styles.explanationEditorTitle}>
                            Explanation of correct answer:
                        </div>
                        <MagicTextBoxEditor
                            key={activeMultipleChoiceOptionIndex}
                            id={`${props.practiceProblemID}-explanation`}
                            namespace={'multiple choice solution explanation'}
                            placeholder={
                                'Enter multiple choice solution explanation.'
                            }
                            initialStringifiedEditorState={
                                practiceProblem.explanation
                            }
                            editorUpdateListener={setExplanation}
                            savableProps={props.onSave}
                        />
                    </div>
                    <div className={styles.toggleShouldShuffleAnswersContainer}>
                        <div className={styles.toggleShouldShuffleAnswersTitle}>
                            Should shuffle options?
                        </div>
                        <Switch
                            checkedChildren={'Yes'}
                            unCheckedChildren={'No'}
                            checked={practiceProblem.shouldShuffleAnswers}
                            onChange={setShouldShuffleAnswers}
                            disabled={isReadOnly}
                        />
                    </div>
                </>
            ) : null}
            <div className={styles.addNewMultipleChoiceOptionButtonContainer}>
                <Button
                    type={'primary'}
                    onClick={addNewMultipleChoiceOption}
                    disabled={isReadOnly}
                >
                    Add New Multiple Choice Solution Option
                </Button>
            </div>
            <div className={styles.multipleChoiceOptionsListTitle}>
                Select multiple choice option to edit (can reorder by dragging
                and dropping):
            </div>
            <div className={styles.multipleChoiceOptionsList}>
                <DraggableSortingList
                    itemData={multipleChoiceOptionData}
                    changeIndex={reorderMultipleChoiceOption}
                    activeIndex={activeMultipleChoiceOptionIndex}
                    setActiveIndex={setActiveMultipleChoiceOptionIndex}
                    isLoading={false}
                    isDisabled={isReadOnly}
                />
            </div>
            {multipleChoiceOptionData.length ? (
                <>
                    <DeleteMultipleChoiceOptionButton
                        deleteMultipleChoiceOption={
                            deleteActiveMultipleChoiceOption
                        }
                    />
                    <MagicTextBoxEditor
                        key={activeMultipleChoiceOptionIndex}
                        id={`${props.practiceProblemID}-${practiceProblem.choices[activeMultipleChoiceOptionIndex]?.id}-active-multiple-choice-option`}
                        namespace={'active multiple choice option'}
                        placeholder={'Enter multiple choice option.'}
                        initialStringifiedEditorState={
                            practiceProblem.choices[
                                activeMultipleChoiceOptionIndex
                            ].stringifiedMagicTextBox
                        }
                        editorUpdateListener={
                            debouncedActiveMultipleChoiceOptionUpdateListener
                        }
                        savableProps={props.onSave}
                    />
                </>
            ) : (
                <div>No active multiple choice option to display.</div>
            )}
        </div>
    )
}

const addNewMultipleChoiceOptionLocal = produce(
    (practiceProblem: IMultipleChoiceProblemBase): void => {
        practiceProblem.choices.push({
            id: generateNewMultipleChoiceOptionId(),
            stringifiedMagicTextBox: undefined,
        })
    }
)

const reorderMultipleChoiceOptionLocal = produce(
    (
        practiceProblem: IMultipleChoiceProblemBase,
        startingIndex: number,
        endingIndex: number
    ): void => {
        const movedContent = practiceProblem.choices.splice(startingIndex, 1)
        practiceProblem.choices.splice(endingIndex, 0, ...movedContent)
    }
)

const deleteMultipleChoiceOptionLocal = produce(
    (practiceProblem: IMultipleChoiceProblemBase, index: number): void => {
        practiceProblem.choices.splice(index, 1)
    }
)

const setShouldShuffleAnswersLocal = produce(
    (
        practiceProblem: IMultipleChoiceProblemBase,
        shouldShuffleAnswers: boolean
    ): void => {
        practiceProblem.shouldShuffleAnswers = shouldShuffleAnswers
    }
)

const setSolutionLocal = produce(
    (
        practiceProblem: IMultipleChoiceProblemBase,
        newSolutionId: string
    ): void => {
        practiceProblem.solution = newSolutionId
    }
)

const setExplanationLocal = produce(
    (
        practiceProblem: IMultipleChoiceProblemBase,
        newExplanation: string
    ): void => {
        practiceProblem.explanation = newExplanation
    }
)

const setMultipleChoiceOptionLocal = produce(
    (
        practiceProblem: IMultipleChoiceProblemBase,
        activeIndex: number,
        stringifiedMagicTextBox: string
    ): void => {
        const activeOption = practiceProblem.choices[activeIndex]
        assert(activeOption)
        activeOption.stringifiedMagicTextBox = stringifiedMagicTextBox
    }
)
