import React, { ReactElement } from 'react'
import { DeleteNotecardButtonProps } from '@/components/ContentTreeNode/ContentTreeLeafNode/NotecardsEditor/DeleteNotecardButton/DeleteNotecardButton.types'
import { DefensiveDeleteButton } from '@/components/utils/DefensiveDeleteButton/DefensiveDeleteButton'
import styles from './DeleteNotecardButton.module.less'

export const DeleteNotecardButton: React.FC<DeleteNotecardButtonProps> = (
    props
): ReactElement => {
    return (
        <div className={styles.deleteNotecardButtonContainer}>
            <DefensiveDeleteButton
                onDelete={props.deleteNotecard}
                title={'Warning: Dangerous Action'}
                areYouSureContent={
                    'Are you sure you want to delete this notecard? This cannot be undone.'
                }
                okText={'Yes, delete notecard'}
                cancelText={'Cancel'}
                buttonText={'Delete selected notecard'}
                placement={'right'}
            />
        </div>
    )
}
