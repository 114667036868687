import React, { ReactElement, useCallback } from 'react'
import { FileUploader } from '@/components/utils/FileUploader/FileUploader'
import { uploadPreviousExamAnalysis } from '@/api/cb/previousExamAnalysis'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import {
    frontendDisplayedCourseSelector,
    isActiveCourseReadOnlySelector,
} from '@/atoms/auth'
import { notification } from 'antd'
import { isUnsuccessfulPreviousExamContentResponse } from 'common/src/api/contentFrontendVsContentBackend/previousExamContent'
import { previousExamAnalysisSignedDownloadUrlAtomFamily } from '@/atoms/previousExamAnalysisSignedDownloadUrl'

export const PreviousExamAnalysisUploader: React.FC<
    unknown
> = (): ReactElement => {
    const courseName = useRecoilValue(frontendDisplayedCourseSelector)
    const isReadOnly = useRecoilValue(isActiveCourseReadOnlySelector)

    const setSignedUrl = useSetRecoilState(
        previousExamAnalysisSignedDownloadUrlAtomFamily(courseName)
    )
    const handleFileUpload = useCallback(
        async (dataURL: string): Promise<void> => {
            const response = await uploadPreviousExamAnalysis(
                courseName,
                decodeURIComponent(
                    atob(dataURL.replace('data:text/csv;base64,', ''))
                )
            )
            if (
                isUnsuccessfulPreviousExamContentResponse(response.data.payload)
            ) {
                notification.error({
                    message: 'Error uploading previous exam analysis',
                    description: response.data.payload.errorMsg,
                })
            } else {
                setSignedUrl(response.data.payload.signedUrl)
            }
        },
        [courseName, setSignedUrl]
    )
    return (
        <FileUploader
            allowedFileTypes={['text/csv']}
            handleFileUpload={handleFileUpload}
            buttonText={
                'Click or drop to upload previous exam analysis file (.csv only). Must abide by special formatting rules.'
            }
            disabledMessage={isReadOnly && 'Course is read-only'}
        />
    )
}
