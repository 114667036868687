import { NodeMetadataTree } from './NodeMetadataTree.types'
import { NodeMetadata, AnnotatedNodeMetadata } from 'common/src/outlines/types'
import { flattenContentTree } from 'common/src/ContentTree'
import { hashContentPath, ContentPath } from 'common/src/ContentPath'
import { ContentTree } from 'common/src/ContentTree/types'

export const getMetadataForContentPath = (
    nodeMetadataTree: NodeMetadataTree,
    contentPath: ContentPath
): NodeMetadata | undefined => {
    return nodeMetadataTree.metadataMap.get(hashContentPath(contentPath))
}

export const createNodeMetadataTree = (
    contentMetadataArray: AnnotatedNodeMetadata[],
    contentTree: ContentTree
): NodeMetadataTree => {
    return new NodeMetadataTreeFactory(
        contentMetadataArray,
        contentTree
    ).toObj()
}

class NodeMetadataTreeFactory {
    // using content tree because while a course is in progress of being made, it's not guaranteed to be balanced
    private readonly contentTree: ContentTree
    private readonly metadataMap: Map<string, NodeMetadata>
    private readonly depth: number

    constructor(
        contentMetadataArray: AnnotatedNodeMetadata[],
        contentTree: ContentTree
    ) {
        this.contentTree = contentTree

        const flattenedContentTree = flattenContentTree(contentTree)
        this.depth = flattenedContentTree.reduce(
            (currentMax, contentPath) =>
                Math.max(currentMax, contentPath.length),
            0
        )

        this.metadataMap = new Map()
        this.fillEmptyMetadataMap(contentMetadataArray)
    }

    public toObj = (): NodeMetadataTree => ({
        contentTree: this.contentTree,
        metadataMap: this.metadataMap,
        depth: this.depth,
    })

    private fillEmptyMetadataMap = (
        nodeMetadataArray: AnnotatedNodeMetadata[]
    ): void => {
        nodeMetadataArray.forEach((nodeMetadata): void => {
            this.metadataMap.set(hashContentPath(nodeMetadata.contentPath), {
                title: nodeMetadata.title,
                description: nodeMetadata.description,
                nodeReleaseStatus: nodeMetadata.nodeReleaseStatus,
                videoLink: nodeMetadata.videoLink,
            })
        })
    }
}
