import React, { ReactElement, useMemo, useState } from 'react'
import { MoveIndividualContentButtonProps } from '@/components/ContentTreeNode/ContentTreeLeafNode/MoveIndividualContentButton/MoveIndividualContentButton.types'
import { Button, Modal } from 'antd'
import { LoadingComponent } from '@/components/utils/LoadingComponent/LoadingComponent'
import { MoveIndividualContent } from '@/components/ContentTreeNode/ContentTreeLeafNode/MoveIndividualContentButton/MoveIndividualContent/MoveIndividualContent'
import { useRecoilValue } from 'recoil'
import { contentTreeStateAtom } from '@/atoms/contentTree'
import { isActiveCourseReadOnlySelector } from '@/atoms/auth'

export const MoveIndividualContentButton: React.FC<
    MoveIndividualContentButtonProps
> = (props): ReactElement => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
    const { flattenedContentTree } = useRecoilValue(contentTreeStateAtom)
    const hasMoreThanOneSection = useMemo(
        (): boolean => flattenedContentTree.length > 1,
        [flattenedContentTree]
    )
    const isReadOnly = useRecoilValue(isActiveCourseReadOnlySelector)

    return (
        <>
            <Modal
                footer={null}
                open={isModalOpen}
                onCancel={() => setIsModalOpen(false)}
            >
                <React.Suspense
                    fallback={<LoadingComponent useWhiteBackground={true} />}
                >
                    <MoveIndividualContent
                        id={props.id}
                        contentPath={props.contentPath}
                        type={props.type}
                        closeModal={() => setIsModalOpen(false)}
                    />
                </React.Suspense>
            </Modal>
            <Button
                onClick={() => setIsModalOpen(true)}
                disabled={!hasMoreThanOneSection || isReadOnly}
            >
                Move to other section (same exam)
            </Button>
        </>
    )
}
