import { ReleaseStatus } from 'common/src/api/core/courses/types'
import assertNever from 'assert-never/index'

export const getPrettyReleaseStatusForReleaseStatusEnum = (
    releaseStatus: ReleaseStatus
): string => {
    switch (releaseStatus) {
        case ReleaseStatus.notAvailable:
            return 'Not available'
        case ReleaseStatus.waitlistOnly:
            return 'Waitlist only'
        case ReleaseStatus.hiddenButAvailable:
            return 'Hidden, but available'
        case ReleaseStatus.fullyAvailable:
            return 'Fully available'
        default:
            assertNever(releaseStatus)
    }
}

export const getTooltipForReleaseStatusEnum = (
    releaseStatus: ReleaseStatus
): string => {
    switch (releaseStatus) {
        case ReleaseStatus.notAvailable:
            return "Won't show up at all on website"
        case ReleaseStatus.waitlistOnly:
            return 'Shows up on the waitlist, but not a purchaseable course in any manner.'
        case ReleaseStatus.hiddenButAvailable:
            return 'A pre-release that we can allow those with a link to access the website (but not directly accessible via the website). Also shows up with a waitlist.'
        case ReleaseStatus.fullyAvailable:
            return 'The default for a course that is active and fully accessible to all students'
        default:
            assertNever(releaseStatus)
    }
}
