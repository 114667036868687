import styles from './SourceMaterialTypes.module.less'
import React, { ReactElement, useEffect } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { Collapse } from 'antd'
import {
    sourceMaterialTypeStatsInvalidatorAtom,
    sourceMaterialTypeStatsAtomNonMemoizing,
} from '@/atoms/sourceMaterialTypes'
import { SourceMaterialType } from './SourceMaterialType/SourceMaterialType'
import { AddSourceMaterialTypeButton } from './AddSourceMaterialTypeButton/AddSourceMaterialTypeButton'

export const SourceMaterialTypes: React.FC<unknown> = (): ReactElement => {
    // so it recalculates each time it is opened
    const sourceMaterialTypeStats = useRecoilValue(
        sourceMaterialTypeStatsAtomNonMemoizing
    )
    const invalidate = useSetRecoilState(sourceMaterialTypeStatsInvalidatorAtom)
    useEffect(() => invalidate((x) => x + 1), [invalidate])

    return (
        <div className={styles.sourceMaterialTypes}>
            <div className={styles.sourceMaterialTypesTitle}>
                Source Material Types
            </div>
            <AddSourceMaterialTypeButton />
            <Collapse accordion={true} expandIconPosition={'end'}>
                {Object.entries(sourceMaterialTypeStats).map(
                    ([type, sourceMaterialIDs]) => (
                        <SourceMaterialType
                            key={type}
                            type={type}
                            count={sourceMaterialIDs.length}
                        />
                    )
                )}
            </Collapse>
        </div>
    )
}
