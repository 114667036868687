import { SavableContent, hashSavableContent } from '@/atoms/unsavedChanges'
import { Set } from 'immutable'
import { useCallback } from 'react'

export const useDoesSpecificContentHaveUnsavedChanges = (
    unsavedChangesSet: Set<string>
): ((savableContent: SavableContent) => boolean) => {
    return useCallback(
        (savableContent: SavableContent): boolean => {
            return unsavedChangesSet.has(hashSavableContent(savableContent))
        },
        [unsavedChangesSet]
    )
}
