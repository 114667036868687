import { useRecoilCallback } from 'recoil'
import { SourceMaterialData } from 'common/src/api/contentFrontendVsContentBackend/sourceMaterial'
import { sourceMaterialAtomFamily } from '@/atoms/sourceMaterial'
import { ContentPath } from 'common/src/ContentPath'

/**
 * Useful for adding a blank source material without explicitly saving it to the backend yet
 */
export const useSetLocalOnlySourceMaterialData = (): ((
    sourceMaterialData: SourceMaterialData,
    contentPath: ContentPath
) => void) => {
    return useRecoilCallback(
        ({ set }) =>
            (
                sourceMaterialData: SourceMaterialData,
                contentPath: ContentPath
            ) => {
                set(
                    sourceMaterialAtomFamily({
                        id: sourceMaterialData.id,
                        contentPath,
                    }),
                    sourceMaterialData
                )
            }
    )
}
