import { useEffect, useRef } from 'react'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import {
    formulaSheetEntriesAtom,
    lastSavedFormulaSheetEntriesAtom,
} from '@/atoms/formulaSheetEntries'

export const useInitializeLastSavedFormulaSheetEntries = (): void => {
    const formulaSheetEntries = useRecoilValue(formulaSheetEntriesAtom)
    const setLastSavedFormulaSheetEntries = useSetRecoilState(
        lastSavedFormulaSheetEntriesAtom
    )
    const hasInitiallySetLastSavedFormulaSheetEntriesRef =
        useRef<boolean>(false)
    useEffect(() => {
        if (hasInitiallySetLastSavedFormulaSheetEntriesRef.current) return
        hasInitiallySetLastSavedFormulaSheetEntriesRef.current = true
        setLastSavedFormulaSheetEntries(formulaSheetEntries)
    }, [formulaSheetEntries, setLastSavedFormulaSheetEntries])
}
