import React, { ReactElement, useCallback, useMemo, useState } from 'react'

import { LoginForm } from '../LoginForm/LoginForm'
import SwipeableViews from 'react-swipeable-views'
import { reusableCssClass } from '@/utils/reusableCssClasses'

import { getTwoWayAuthenticatorTabMap } from '@/components/authentication/TwoWayAuthenticatorTabMap'
import { AuthenticatorProps, AuthenticatorTabName } from './Authenticator.types'
import { ForgotPasswordForm } from '@/components/authentication/ForgotPasswordForm/ForgotPasswordForm'

const twoWayAuthenticatorTabMap = getTwoWayAuthenticatorTabMap()

export const Authenticator: React.FC<AuthenticatorProps> = (
    props
): ReactElement => {
    const [activeTab, setActiveTab] = useState<AuthenticatorTabName>(
        props.defaultTab
    )
    const [isForgotPasswordFormActive, setIsForgotPasswordFormActive] =
        useState<boolean>(false)

    const switchTabs = useCallback((newTab: AuthenticatorTabName): void => {
        setActiveTab(newTab)
        if (newTab === AuthenticatorTabName.forgotPassword) {
            setIsForgotPasswordFormActive(true)
        } else {
            setIsForgotPasswordFormActive(false)
        }
    }, [])

    const handleChangeIndex = useCallback(
        (index: number): void => {
            const nextTab = twoWayAuthenticatorTabMap.getBackward(index)
            switchTabs(nextTab)
        },
        [switchTabs]
    )

    const validViews = useMemo((): ReactElement[] => {
        const validViews = [
            <LoginForm
                key={AuthenticatorTabName.Login}
                changeToForgotPassword={() =>
                    switchTabs(AuthenticatorTabName.forgotPassword)
                }
            />,
        ]
        if (isForgotPasswordFormActive) {
            validViews.push(
                <ForgotPasswordForm
                    key={AuthenticatorTabName.forgotPassword}
                    changeToLogin={() => switchTabs(AuthenticatorTabName.Login)}
                />
            )
        }
        return validViews
    }, [isForgotPasswordFormActive, switchTabs])

    return (
        <SwipeableViews
            index={twoWayAuthenticatorTabMap.getForward(activeTab)}
            onChangeIndex={handleChangeIndex}
            slideClassName={`${reusableCssClass.sliderContainer} more-slider-padding`}
        >
            {validViews}
        </SwipeableViews>
    )
}
