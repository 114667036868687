import {
    MagicTextBoxEditableOptions,
    MagicTextBoxReadOnlyOptions,
} from 'magic-text-box-lexical/src/atoms/options/options.types'
import { PracticeProblemLinkProps } from 'magic-text-box-lexical/src/atoms/practiceProblemLink/practiceProblemLink.types'
import {
    AccessUniversalContentLinkFunc,
    UniversalContentLinkData,
} from 'magic-text-box-lexical/src/universalContentLink/types'

export const practiceProblemLinkPropsNoOp: PracticeProblemLinkProps = {
    getPracticeProblemContent: async () => null,
    getPracticeProblemMetadata: async () => null,
    renderIfError: () => null,
}

export const accessUniversalContentLinkNoOp: AccessUniversalContentLinkFunc = (
    _data: UniversalContentLinkData
) => undefined

export const mtbEditableOptionsNoOp: MagicTextBoxEditableOptions = {}
export const mtbReadOnlyOptionsNoOp: MagicTextBoxReadOnlyOptions = {}
