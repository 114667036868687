import {
    GetPracticeProblemMetadataFunc,
    MtbPracticeProblemLinkMetadata,
} from 'magic-text-box-lexical/src/atoms/practiceProblemLink/practiceProblemLink.types'
import { useRecoilCallback, useRecoilValue } from 'recoil'
import { frontendDisplayedCourseSelector } from '@/atoms/auth'
import { getPracticeProblem } from '@/api/cb/contentCreatorContent'
import { activeCourseConfigSelector } from '@/atoms/courseInfo'
import { extractTitleForPracticeProblem } from 'common/src/search/utils'
import { practiceProblemAtomFamily } from '@/atoms/practiceProblem'
import { IGenericPracticeProblem } from 'common/src/practiceProblems/types'

export const useMtbGetPracticeProblemMetadata =
    (): GetPracticeProblemMetadataFunc => {
        const courseName = useRecoilValue(frontendDisplayedCourseSelector)
        const courseConfig = useRecoilValue(activeCourseConfigSelector)

        return useRecoilCallback(
            ({ snapshot, set }) =>
                async (
                    practiceProblemID: string
                ): Promise<MtbPracticeProblemLinkMetadata> => {
                    const loadable = snapshot.getLoadable(
                        practiceProblemAtomFamily(practiceProblemID)
                    )

                    let practiceProblem: IGenericPracticeProblem
                    if (loadable.state === 'hasValue') {
                        // pull from cache
                        practiceProblem = loadable.contents
                    } else {
                        // pull value fresh
                        const response = await getPracticeProblem(
                            courseName,
                            practiceProblemID
                        )
                        practiceProblem = response.data.payload

                        // cache value for subsequent pulls
                        set(
                            practiceProblemAtomFamily(practiceProblemID),
                            practiceProblem
                        )
                    }

                    const title = extractTitleForPracticeProblem(
                        practiceProblem,
                        courseConfig.type
                    )
                    return {
                        title,
                        isPreviousExamQuestion:
                            practiceProblem.isPreviousExamQuestion,
                        points: practiceProblem.points,
                    }
                },
            [courseConfig.type, courseName]
        )
    }
