import React, { ReactElement, useMemo } from 'react'
import { OutlineMagicTextBoxEditorProps } from './OutlineMagicTextBoxEditor.types'
import { MagicTextBoxEditor } from '@/components/ContentTreeNode/ContentTreeLeafNode/MagicTextBoxEditor/MagicTextBoxEditor'
import { useRecoilValue } from 'recoil'
import { authStateAtom, frontendDisplayedCourseSelector } from '@/atoms/auth'
import { UserData } from 'magic-text-box-lexical/src/atoms/annotations/annotations.types'
import { UserType } from 'magic-text-box-lexical/src/context/AnnotatedContentContext/AnnotatedContent.types'
import { AnnotatedContentProps } from 'magic-text-box-lexical/src/components/MagicTextBoxCore/MagicTextBoxCore.types'
import { buildAnnotatedContentProvider } from '@/components/ContentTreeNode/ContentTreeLeafNode/MagicTextBoxEditor/buildAnnotatedContentProvider'

export const OutlineMagicTextBoxEditor: React.FC<
    OutlineMagicTextBoxEditorProps
> = (props): ReactElement => {
    const { contentPath, type, ...restProps } = props
    const authState = useRecoilValue(authStateAtom)
    const userData = useMemo(
        (): UserData => ({
            userID: authState.contentCreatorID,
            userType: UserType.instructor,
            displayName: authState.displayName,
        }),
        [authState]
    )
    const courseName = useRecoilValue(frontendDisplayedCourseSelector)
    const annotatedContentProps = useMemo(
        (): AnnotatedContentProps => ({
            userData,
            annotatedContentProvider: buildAnnotatedContentProvider(
                courseName,
                contentPath,
                type
            ),
        }),
        [contentPath, courseName, type, userData]
    )

    return (
        <MagicTextBoxEditor
            {...restProps}
            annotatedContentProps={annotatedContentProps}
            cannotComment={props.type === 'summary'}
            canCopyAnnotationSelectionToClipboard={true}
        />
    )
}
