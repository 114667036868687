import { useRecoilValue } from 'recoil'
import { hasUnsavedChangesSelector } from '@/atoms/unsavedChanges'
import { useEffect } from 'react'

export const usePreventExitWithUnsavedChanges = (): void => {
    const hasUnsavedChanges = useRecoilValue(hasUnsavedChangesSelector)
    const confirmExit = (): string =>
        `Are you sure you want to exit? You have unsaved changes.`
    useEffect(() => {
        if (hasUnsavedChanges) {
            window.onbeforeunload = confirmExit
        } else {
            window.onbeforeunload = undefined
        }
    }, [hasUnsavedChanges])
}
