import {
    useMarkContentWithUnsavedChanges,
    useMarkContentWithSavedChanges,
} from '@/hooks/useMarkContentWithUnsavedChanges'
import { ContentPath } from 'common/src/ContentPath'
import { useMemo, useEffect } from 'react'
import { SavableContent, SavableContentType } from '@/atoms/unsavedChanges'
import { useRecoilValue, useRecoilState } from 'recoil'
import {
    currentNotecardDataSelectorFamily,
    lastSavedNotecardDataAtomFamily,
} from '@/atoms/notecard'
import { isEqual } from 'lodash'
import { isUnsavedNotecardSelectorFamily } from '@/atoms/newlyCreatedAndUnsavedContentIDs'

export const useMonitorNotecardChanges = (
    notecardID: string,
    contentPath: ContentPath
): void => {
    const markContentWithUnsavedChanges = useMarkContentWithUnsavedChanges()
    const markContentWithSavedChanges = useMarkContentWithSavedChanges()
    const isUnsaved = useRecoilValue(
        isUnsavedNotecardSelectorFamily(notecardID)
    )
    const savableContent = useMemo(
        (): SavableContent => ({
            contentPath: contentPath,
            contentType: SavableContentType.notecard,
            id: notecardID,
        }),
        [contentPath, notecardID]
    )

    const currentNotecardSavableData = useRecoilValue(
        currentNotecardDataSelectorFamily(notecardID)
    )
    const [lastSavedNotecardData, setLastSavedNotecardData] = useRecoilState(
        lastSavedNotecardDataAtomFamily(notecardID)
    )

    useEffect(() => {
        if (isUnsaved) return
        if (lastSavedNotecardData === null) {
            setLastSavedNotecardData(currentNotecardSavableData)
        }
    }, [
        currentNotecardSavableData,
        isUnsaved,
        lastSavedNotecardData,
        setLastSavedNotecardData,
    ])

    const hasChanges = useMemo((): boolean => {
        return !isEqual(currentNotecardSavableData, lastSavedNotecardData)
    }, [currentNotecardSavableData, lastSavedNotecardData])
    useEffect(() => {
        if (lastSavedNotecardData === null) return
        if (hasChanges) {
            markContentWithUnsavedChanges(savableContent)
        } else {
            markContentWithSavedChanges(savableContent)
        }
    }, [
        hasChanges,
        lastSavedNotecardData,
        markContentWithSavedChanges,
        markContentWithUnsavedChanges,
        savableContent,
    ])
}
