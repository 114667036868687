import React, { ReactElement, useCallback } from 'react'
import styles from './SourceMaterialIDsLink.module.less'
import { reusableCssClass } from '@/utils/reusableCssClasses'
import { useSetRecoilState } from 'recoil'
import { viewingStateAtom, ViewingStateType } from '@/atoms/viewingState'
import { ContentPath } from 'common/src/ContentPath'

export const SourceMaterialIDLinks: React.FC<{
    sourceMaterialIDs: string[]
    contentPath: ContentPath
}> = (props): ReactElement => {
    return (
        <div className={styles.sourceMaterialIDLinks}>
            <div className={styles.title}>
                Generated from the following source materials:
            </div>
            <ul>
                {props.sourceMaterialIDs.map((sourceMaterialID) => (
                    <SourceMaterialIDLink
                        key={sourceMaterialID}
                        sourceMaterialID={sourceMaterialID}
                        contentPath={props.contentPath}
                    />
                ))}
            </ul>
        </div>
    )
}

const SourceMaterialIDLink: React.FC<{
    sourceMaterialID: string
    contentPath: ContentPath
}> = (props): ReactElement => {
    const setViewingState = useSetRecoilState(viewingStateAtom)

    const redirectToSourceMaterialID = useCallback((): void => {
        setViewingState({
            contentPath: props.contentPath,
            viewingStateType: ViewingStateType.sourceMaterial,
            id: props.sourceMaterialID,
        })
    }, [props.contentPath, props.sourceMaterialID, setViewingState])

    return (
        <li
            className={`${reusableCssClass.clickableText}`}
            onClick={() => redirectToSourceMaterialID()}
        >
            {props.sourceMaterialID}
        </li>
    )
}
