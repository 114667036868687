export const roundTo = (
    number: number,
    digits: number,
    towardZero = false
): number => {
    const factor = 10 ** digits,
        rounder = towardZero ? Math.trunc : Math.round
    return rounder(number * factor) / factor
}

export const roundToNearestFraction = (
    number: number,
    fraction: number
): number => {
    return Math.round(number / fraction) * fraction
}
