import styles from './AddNotecardCategory.module.less'
import React, { ReactElement, useState } from 'react'
import { Input, Button } from 'antd'
import { PlusCircleOutlined } from '@ant-design/icons'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import {
    notecardCategoriesAtom,
    notecardCategoriesInvalidatorAtom,
    notecardCategoryStatsInvalidatorAtom,
} from '@/atoms/notecardCategories'
import { addNotecardCategoryForCourse } from '@/api/cb/contentCreatorContent'
import {
    frontendDisplayedCourseSelector,
    isActiveCourseReadOnlySelector,
} from '@/atoms/auth'
import { syncSuccessMessage, syncErrorMessage } from '@/utils/syncMessages'

interface AddNotecardCategoryProps {
    closeModal: () => void
}

export const AddNotecardCategory: React.FC<AddNotecardCategoryProps> = (
    props
): ReactElement => {
    const isReadOnly = useRecoilValue(isActiveCourseReadOnlySelector)
    const [newNotecardCategory, setNewNotecardCategory] = useState<string>('')
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const notecardCategories = useRecoilValue(notecardCategoriesAtom)
    const courseName = useRecoilValue(frontendDisplayedCourseSelector)
    const invalidateNotecardCategories = useSetRecoilState(
        notecardCategoriesInvalidatorAtom
    )
    const invalidateNotecardCategoryStats = useSetRecoilState(
        notecardCategoryStatsInvalidatorAtom
    )

    const handleError = (error: string): void => {
        syncErrorMessage(error)
        setIsLoading(false)
    }

    const tryAddNotecardCategory = async (): Promise<void> => {
        setIsLoading(true)

        if (!newNotecardCategory?.length) {
            handleError('No notecard category set')
            return
        }

        if (notecardCategories.includes(newNotecardCategory)) {
            handleError(
                `Notecard category: ${newNotecardCategory} already exists.`
            )
            return
        }

        const response = await addNotecardCategoryForCourse({
            courseName,
            notecardCategory: newNotecardCategory,
        })
        if (response.data.isError) {
            handleError(`Something unexpected happened: ${response.data.error}`)
            return
        }

        props.closeModal()
        syncSuccessMessage(
            `Successfully added notecard category: ${newNotecardCategory}`
        )
        invalidateNotecardCategories((x) => x + 1)
        invalidateNotecardCategoryStats((x) => x + 1)
        setIsLoading(false)
    }

    return (
        <div className={styles.addNotecardCategory}>
            <div className={styles.addNotecardCategoryTitle}>
                Add Notecard Category
            </div>
            <div className={styles.addNotecardCategoryBody}>
                <Input
                    placeholder={'Enter new notecard category here'}
                    value={newNotecardCategory}
                    onChange={(event) =>
                        setNewNotecardCategory(event.target.value)
                    }
                    onPressEnter={tryAddNotecardCategory}
                />
                <Button
                    onClick={tryAddNotecardCategory}
                    loading={isLoading}
                    disabled={!newNotecardCategory.length || isReadOnly}
                >
                    <PlusCircleOutlined /> Add
                </Button>
            </div>
        </div>
    )
}
