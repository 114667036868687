import React, { ReactElement, useCallback, useMemo, useState } from 'react'
import { useRecoilValue } from 'recoil'
import {
    contentTreeStateAtom,
    contentTreeDepthSelector,
} from '@/atoms/contentTree'
import { areContentPathsEqual, ContentPath } from 'common/src/ContentPath'
import { SelectContentPath } from '@/components/utils/SelectContentPath/SelectContentPath'
import { AreYouSureButton } from '@/components/utils/AreYouSureButton/AreYouSureButton'
import { getContentTreeChildForContentPath } from '@/utils/getContentTreeChildForContentPath'
import { moveContentLeafNodeWithinSameCourse } from '@/api/cb/contentCreatorContent'
import { syncErrorMessage, syncSuccessMessage } from '@/utils/syncMessages'
import { frontendDisplayedCourseSelector } from '@/atoms/auth'

export const MoveContentLeafNodeWithinSameCourse: React.FC<{
    contentPath: ContentPath
}> = (props): ReactElement => {
    const frontendDisplayedCourseName = useRecoilValue(
        frontendDisplayedCourseSelector
    )
    const contentTreeState = useRecoilValue(contentTreeStateAtom)
    const [selectedContentPath, setSelectedContentPath] = useState<ContentPath>(
        []
    )
    const contentTreeDepth = useRecoilValue(contentTreeDepthSelector)

    const isSameContentPath = useMemo(
        (): boolean =>
            areContentPathsEqual(
                props.contentPath.slice(0, 2),
                selectedContentPath
            ),
        [props.contentPath, selectedContentPath]
    )

    const moveIndividualContentCallback =
        useCallback(async (): Promise<void> => {
            const response = await moveContentLeafNodeWithinSameCourse({
                courseName: frontendDisplayedCourseName,
                fromContentPath: props.contentPath,
                toContentPath: selectedContentPath,
            })
            if (response.data.isError) {
                syncErrorMessage(
                    'Error moving content. You may want to refresh the page, check what moved, if anything, and try again.'
                )
            } else if (response.data.payload.failureMessageOrNull) {
                syncErrorMessage(
                    `Error moving content: ${response?.data?.payload?.failureMessageOrNull}`
                )
            } else {
                syncSuccessMessage(
                    `Successfully moved "${
                        getContentTreeChildForContentPath(
                            contentTreeState.contentTree,
                            props.contentPath
                        )?.name
                    }" to "${
                        getContentTreeChildForContentPath(
                            contentTreeState.contentTree,
                            selectedContentPath.slice(0, 1)
                        )?.name
                    } / ${
                        getContentTreeChildForContentPath(
                            contentTreeState.contentTree,
                            selectedContentPath.slice(0, 2)
                        )?.name
                    }". Refreshing in 3 seconds...`
                )
                setTimeout(() => window.location.reload(), 3_000)
            }
        }, [
            contentTreeState.contentTree,
            frontendDisplayedCourseName,
            props.contentPath,
            selectedContentPath,
        ])

    return (
        <div>
            <div>Move Outline</div>
            <div>Select content path:</div>
            <SelectContentPath
                contentPath={selectedContentPath}
                setContentPath={setSelectedContentPath}
                contentTreeState={contentTreeState}
                maxDepth={contentTreeDepth - 1}
            />
            <AreYouSureButton
                performAction={moveIndividualContentCallback}
                areYouSureContent={`Are you sure you want to move "${
                    getContentTreeChildForContentPath(
                        contentTreeState.contentTree,
                        props.contentPath
                    )?.name
                }" from "${
                    getContentTreeChildForContentPath(
                        contentTreeState.contentTree,
                        props.contentPath.slice(0, 1)
                    )?.name
                } / ${
                    getContentTreeChildForContentPath(
                        contentTreeState.contentTree,
                        props.contentPath.slice(0, 2)
                    )?.name
                }" to "${
                    getContentTreeChildForContentPath(
                        contentTreeState.contentTree,
                        selectedContentPath.slice(0, 1)
                    )?.name
                } / ${
                    getContentTreeChildForContentPath(
                        contentTreeState.contentTree,
                        selectedContentPath.slice(0, 2)
                    )?.name
                }"? Double check that this is correct before continuing.`}
                buttonText={'Move Content'}
                disabled={selectedContentPath.length !== 2 || isSameContentPath}
                okText={"Yes, I'm sure"}
                cancelText={'Let me rethink'}
            />
        </div>
    )
}
