import React, { ReactElement } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { reusableCssClass } from '@/utils/reusableCssClasses'
import { getNotecardMeta } from '@/api/cb/contentCreatorContent'
import { frontendDisplayedCourseSelector } from '@/atoms/auth'
import { viewingStateAtom, ViewingStateType } from '@/atoms/viewingState'

interface NotecardIDsListProps {
    notecardIDs: string[]
}

export const NotecardIDsList: React.FC<NotecardIDsListProps> = (
    props
): ReactElement => {
    const courseName = useRecoilValue(frontendDisplayedCourseSelector)
    const setViewingState = useSetRecoilState(viewingStateAtom)

    const redirectToNotecardID = async (notecardID: string): Promise<void> => {
        const response = await getNotecardMeta(courseName, notecardID)
        setViewingState({
            contentPath: response.data.payload.contentPath,
            viewingStateType: ViewingStateType.notecards,
            id: notecardID,
        })
    }

    return (
        <ul className={'notecard-ids-list'}>
            {props.notecardIDs.map((notecardID) => {
                return (
                    <li
                        key={notecardID}
                        className={`notecard-ids-list-item ${reusableCssClass.clickableText}`}
                        onClick={() => redirectToNotecardID(notecardID)}
                    >
                        {notecardID}
                    </li>
                )
            })}
        </ul>
    )
}
