import styles from './DownloadExcelFileButton.module.less'
import React, { ReactElement, useMemo, useState, useEffect } from 'react'
import { DownloadExcelFileButtonProps } from '@/components/ContentTreeNode/ContentTreeLeafNode/PracticeProblemsEditor/ExcelFileUploader/DownloadExcelFileButton/DownloadExcelFileButton.types'
import {
    getSignedUrlForPracticeProblemExcelFileDownload,
    getSignedUrlForPracticeProblemExcelSolutionFileDownload,
} from '@/api/cb/s3'
import { useRecoilValue } from 'recoil'
import { frontendDisplayedCourseSelector } from '@/atoms/auth'
import { LoadingOutlined } from '@ant-design/icons'
import {
    practiceProblemAtomFamily,
    isExcelFilePresentSelectorFamily,
    isExcelSolutionFilePresentSelectorFamily,
} from '@/atoms/practiceProblem'

export const DownloadExcelFileButton: React.FC<DownloadExcelFileButtonProps> = (
    props
): ReactElement => {
    const practiceProblem = useRecoilValue(
        practiceProblemAtomFamily(props.practiceProblemID)
    )

    const isExcelFilePresent = useRecoilValue(
        props.isSolution
            ? isExcelSolutionFilePresentSelectorFamily(props.practiceProblemID)
            : isExcelFilePresentSelectorFamily(props.practiceProblemID)
    )
    const courseName = useRecoilValue(frontendDisplayedCourseSelector)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    // topic in snake case, followed by first 4 characters of id
    const downloadName = useMemo((): string => {
        return `${practiceProblem.topic
            .replace(/ /g, '_')
            .toLowerCase()}_${props.practiceProblemID.slice(0, 4)}${
            props.isSolution ? '_solution' : ''
        }.xlsx`
    }, [practiceProblem.topic, props.isSolution, props.practiceProblemID])

    const [localSignedDownloadUrl, setLocalSignedDownloadUrl] = useState<
        string | null
    >(null)

    const getSignedUrlCallback = useMemo(
        () =>
            props.isSolution
                ? getSignedUrlForPracticeProblemExcelSolutionFileDownload
                : getSignedUrlForPracticeProblemExcelFileDownload,
        [props.isSolution]
    )
    useEffect(() => {
        if (!isExcelFilePresent) {
            setLocalSignedDownloadUrl(null)
            return
        }

        if (localSignedDownloadUrl || !downloadName) return

        setIsLoading(true)
        getSignedUrlCallback(
            courseName,
            props.practiceProblemID,
            downloadName
        ).then((response) => {
            setLocalSignedDownloadUrl(response.data.payload.signedUrl)
            setIsLoading(false)
        })
    }, [
        isExcelFilePresent,
        downloadName,
        props.practiceProblemID,
        localSignedDownloadUrl,
        courseName,
        setLocalSignedDownloadUrl,
        getSignedUrlCallback,
    ])

    const content = useMemo((): ReactElement => {
        if (isLoading) {
            return <LoadingOutlined />
        } else if (localSignedDownloadUrl) {
            return (
                <a href={localSignedDownloadUrl} download={downloadName}>
                    {downloadName}
                </a>
            )
        } else {
            return <span>No excel file has been uploaded yet.</span>
        }
    }, [downloadName, isLoading, localSignedDownloadUrl])
    return <div className={styles.downloadExcelFileButton}>{content}</div>
}
