import { ReadOnlyTwoWayMap } from 'common/src/utils/twoWayMap'
import { AuthenticatorTabName } from '@/components/authentication/Authenticator/Authenticator.types'

export const getTwoWayAuthenticatorTabMap = (): ReadOnlyTwoWayMap<
    AuthenticatorTabName,
    number
> => {
    const AuthenticatorTabMap = new Map<AuthenticatorTabName, number>()
    AuthenticatorTabMap.set(AuthenticatorTabName.Login, 0)
    AuthenticatorTabMap.set(AuthenticatorTabName.forgotPassword, 1)
    return new ReadOnlyTwoWayMap(AuthenticatorTabMap)
}
