import { atom, selector, selectorFamily } from 'recoil'
import { frontendDisplayedCourseSelector } from '@/atoms/auth'
import { SourceMaterialTypeStats } from 'common/src/api/contentFrontendVsContentBackend/responses'
import {
    getSourceMaterialTypeStatsForCourse,
    getSourceMaterialTypesForCourse,
} from '@/api/cb/contentCreatorContent'
import { ContentPath } from 'common/src/ContentPath'

export const sourceMaterialTypeStatsInvalidatorAtom = atom<number>({
    key: 'sourceMaterialTypeStatsInvalidatorAtom',
    default: 0,
})

export const sourceMaterialTypeStatsAtomNonMemoizing =
    atom<SourceMaterialTypeStats>({
        key: 'sourceMaterialTypeStatsAtomNonMemoizing',
        default: selector<SourceMaterialTypeStats>({
            key: 'sourceMaterialTypeStatsAtomNonMemoizingFetcher',
            get: async ({ get }) => {
                get(sourceMaterialTypeStatsInvalidatorAtom)
                return (
                    await getSourceMaterialTypeStatsForCourse({
                        courseName: get(frontendDisplayedCourseSelector),
                    })
                ).data.payload.sourceMaterialTypeStats
            },
        }),
    })

export const sourceMaterialIDsForTypeSelector = selectorFamily<
    { id: string; contentPath: ContentPath }[],
    string
>({
    key: 'sourceMaterialIDsForTypeSelector',
    get:
        (type) =>
        ({ get }) => {
            return get(sourceMaterialTypeStatsAtomNonMemoizing)[type]
        },
})

export const sourceMaterialTypesInvalidatorAtom = atom<number>({
    key: 'sourceMaterialTypesInvalidatorAtom',
    default: 0,
})
export const sourceMaterialTypesAtom = atom<string[]>({
    key: 'sourceMaterialTypesAtom',
    default: selector<string[]>({
        key: 'sourceMaterialTypesFetcher',
        get: async ({ get }) => {
            get(sourceMaterialTypesInvalidatorAtom)
            return (
                await getSourceMaterialTypesForCourse({
                    courseName: get(frontendDisplayedCourseSelector),
                })
            ).data.payload.sourceMaterialTypes
        },
    }),
})
