import React, { ReactElement } from 'react'

export const VideoPlayer: React.FC<{ videoLink: string }> = (
    props
): ReactElement => {
    return (
        <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
            <iframe
                src={props.videoLink}
                allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                }}
            />
        </div>
    )
}
