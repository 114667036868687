import {
    validateConfirmPasswordUtil,
    validatePasswordUtil,
} from 'common/src/utils/passwords'

export const validatePasswordPromise = async (
    _: unknown,
    password: string
): Promise<void> => {
    const errorOrNull = validatePasswordUtil(password)
    if (errorOrNull === null) return Promise.resolve()
    return Promise.reject(errorOrNull)
}
export const validateConfirmPasswordPromise = async (
    confirmPassword: string,
    otherPassword: string
): Promise<void> => {
    const errorOrNull = validateConfirmPasswordUtil(
        confirmPassword,
        otherPassword
    )
    if (errorOrNull === null) return Promise.resolve()
    return Promise.reject(errorOrNull)
}
