import { atomFamily, selectorFamily, atom, selector } from 'recoil'
import { CourseInfo } from 'common/src/api/core/courses/types'
import { getCourseInfo } from '@/api/cb/contentCreatorContent'
import { frontendDisplayedCourseSelector } from '@/atoms/auth'
import { CourseConfig } from 'common/src/courseConfig'

export const fetchCourseInfo = async (
    courseName: string
): Promise<CourseInfo> => {
    const response = await getCourseInfo(courseName)
    const courseInfo = response.data.payload
    courseInfo.nextSittingDate = new Date(courseInfo.nextSittingDate)
    return courseInfo
}

export const courseInfoAtom = atomFamily<CourseInfo, string>({
    key: 'courseInfoAtom',
    default: selectorFamily<CourseInfo, string>({
        key: 'courseInfoFetcher',
        get: (courseName) => async () => {
            return await fetchCourseInfo(courseName)
        },
    }),
})

export const activeCourseConfigSelector = selector<CourseConfig>({
    key: 'activeCourseConfigSelector',
    get: ({ get }) =>
        get(courseInfoAtom(get(frontendDisplayedCourseSelector))).courseConfig,
})

export const activeCourseInfoInvalidatorAtom = atom<number>({
    key: 'activeCourseInfoInvalidatorAtom',
    default: 0,
})
export const activeCourseInfoAtomNonMemoizing = atom<CourseInfo>({
    key: 'activeCourseInfoAtomNonMemoizing',
    default: selector<CourseInfo>({
        key: 'activeCourseInfoAtomNonMemoizingFetcher',
        get: async ({ get }) => {
            get(activeCourseInfoInvalidatorAtom)
            return await fetchCourseInfo(get(frontendDisplayedCourseSelector))
        },
    }),
})
