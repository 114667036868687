import React, {
    ReactElement,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react'
import styles from './CopyIndividualContent.module.less'
import { CopyIndividualContentProps } from '@/components/ContentTreeNode/ContentTreeLeafNode/CopyIndividualContentButton/CopyIndividualContent/CopyIndividualContent.types'
import { useRecoilValue } from 'recoil'
import { frontendDisplayedCourseSelector } from '@/atoms/auth'
import { courseNamesForContentCreatorAtom } from '@/atoms/courseNamesForContentCreator'
import { contentTreeStateSelectorFamily } from '@/atoms/contentTree'
import { getContentTreeChildForContentPath } from '@/utils/getContentTreeChildForContentPath'
import { ContentPath } from 'common/src/ContentPath'
import { notification, Select } from 'antd'
import { copyIndividualContent } from '@/api/cb/contentCreatorContent'
import { syncErrorMessage, syncSuccessMessage } from '@/utils/syncMessages'
import { SelectContentPath } from '@/components/utils/SelectContentPath/SelectContentPath'
import { AreYouSureButton } from '@/components/utils/AreYouSureButton/AreYouSureButton'
import { slimLeafNodeForOtherCoursesAtomFamily } from '@/atoms/slimLeafNode'
import assertNever from 'assert-never/index'

const { Option } = Select

export const CopyIndividualContent: React.FC<CopyIndividualContentProps> = (
    props
): ReactElement => {
    const frontendDisplayedCourseName = useRecoilValue(
        frontendDisplayedCourseSelector
    )

    const courseNames = useRecoilValue(courseNamesForContentCreatorAtom).filter(
        (courseName) => courseName !== frontendDisplayedCourseName
    )

    const [selectedCourseName, setSelectedCourseName] = useState<string | null>(
        courseNames[0]
    )
    const selectedContentTreeState = useRecoilValue(
        contentTreeStateSelectorFamily(selectedCourseName)
    )

    const [selectedContentPath, setSelectedContentPath] = useState<ContentPath>(
        []
    )

    const slimLeafNodeForOtherCourses = useRecoilValue(
        slimLeafNodeForOtherCoursesAtomFamily({
            courseName: selectedCourseName,
            contentPath: selectedContentPath,
        })
    )

    const isAlreadyPresent = useMemo((): boolean => {
        switch (props.type) {
            case 'notecard':
                return slimLeafNodeForOtherCourses?.notecardIDs.includes(
                    props.id
                )
            case 'practiceProblem':
                return slimLeafNodeForOtherCourses?.practiceProblemIDs.includes(
                    props.id
                )
            default:
                assertNever(props.type)
        }
    }, [
        props.type,
        props.id,
        slimLeafNodeForOtherCourses?.notecardIDs,
        slimLeafNodeForOtherCourses?.practiceProblemIDs,
    ])

    useEffect(() => {
        if (!isAlreadyPresent) return
        notification.info({
            message: `This ${props.type} already exists at the target destination.`,
        })
    }, [isAlreadyPresent])

    const copyIndividualContentCallback =
        useCallback(async (): Promise<void> => {
            const response = await copyIndividualContent({
                fromCourseName: frontendDisplayedCourseName,
                type: props.type,
                id: props.id,
                toCourseName: selectedCourseName,
                toContentPath: selectedContentPath,
            })

            if (response.data.isError) {
                syncErrorMessage(
                    'Error copying content. You may want to refresh the page, check what copied, if anything, and try again.'
                )
            } else if (response.data.payload.failureMessageOrNull) {
                syncErrorMessage(
                    `Error copying content: ${response.data.payload.failureMessageOrNull}`
                )
            } else {
                syncSuccessMessage(
                    'Successfully copied content. You may need to refresh the page to reflect these changes.'
                )
                props.closeModal()
            }
        }, [
            frontendDisplayedCourseName,
            props,
            selectedContentPath,
            selectedCourseName,
        ])

    return (
        <div className={styles.copyIndividualContent}>
            <div className={styles.copyIndividualContentTitle}>
                Copy {props.type}
            </div>
            <div>Select course:</div>
            <Select
                value={selectedCourseName}
                onChange={(courseName) => {
                    setSelectedContentPath([])
                    setSelectedCourseName(courseName)
                }}
            >
                {courseNames.map((courseName) => (
                    <Option value={courseName} key={courseName}>
                        {courseName}
                    </Option>
                ))}
            </Select>
            <div>Select content path:</div>
            <SelectContentPath
                key={selectedCourseName}
                contentPath={selectedContentPath}
                setContentPath={setSelectedContentPath}
                contentTreeState={selectedContentTreeState}
                maxDepth={3}
            />
            <AreYouSureButton
                performAction={copyIndividualContentCallback}
                areYouSureContent={`Are you sure you want to copy this ${
                    props.type
                } to course: ${selectedCourseName} / ${
                    getContentTreeChildForContentPath(
                        selectedContentTreeState.contentTree,
                        selectedContentPath.slice(0, 1)
                    )?.name
                } / ${
                    getContentTreeChildForContentPath(
                        selectedContentTreeState.contentTree,
                        selectedContentPath.slice(0, 2)
                    )?.name
                } / ${
                    getContentTreeChildForContentPath(
                        selectedContentTreeState.contentTree,
                        selectedContentPath.slice(0, 3)
                    )?.name
                }? Double check that this is correct before continuing.`}
                buttonText={'Copy Content'}
                disabled={selectedContentPath.length !== 3 || isAlreadyPresent}
                okText={"Yes, I'm sure"}
                cancelText={'Let me rethink'}
            />
        </div>
    )
}
