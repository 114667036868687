import React, { ReactElement } from 'react'
import { ButtonModalWrapper } from '@/components/utils/ButtonModalWrapper/ButtonModalWrapper'
import { ViewingStateType } from '@/atoms/viewingState'
import { LoadingComponent } from '@/components/utils/LoadingComponent/LoadingComponent'
import { ContentPath } from 'common/src/ContentPath'
import styles from './ViewVideoButton.module.scss'
import { VideoPlayer } from '@/components/ContentTreeNode/ViewVideo/VideoPlayer'

export const ViewVideoButton: React.FC<{
    videoLink: string
    contentPath: ContentPath
}> = (props): ReactElement => {
    return (
        <ButtonModalWrapper
            viewingStateType={ViewingStateType.videoPreview}
            buttonText={'View Video'}
            size={'large'}
            contentPath={props.contentPath}
            buttonWrapperClassName={styles.viewVideoButton}
            disabled={!props.videoLink}
            modalClassName={styles.viewVideoModal}
        >
            <React.Suspense
                fallback={<LoadingComponent useWhiteBackground={true} />}
            >
                <VideoPlayer videoLink={props.videoLink} />
            </React.Suspense>
        </ButtonModalWrapper>
    )
}
