import React, { useRef, PropsWithChildren } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { DraggableItemProps } from '@/components/utils/DraggableItem/DraggableItem.types'
import styles from './DraggableItem.module.less'

export const DraggableItem: React.FC<PropsWithChildren<DraggableItemProps>> = ({
    index,
    changeIndex,
    className,
    style,
    onClick,
    ...restProps
}) => {
    const type = DraggableItem.name
    const ref = useRef<HTMLDivElement | null>(null)
    const [{ isOver, dropClassName }, drop] = useDrop<
        { index: number },
        unknown,
        { isOver?: boolean; dropClassName?: string }
    >({
        accept: type,
        collect: (monitor) => {
            const { index: dragIndex } = monitor.getItem() || {}
            if (dragIndex === index) {
                return {}
            }
            return {
                isOver: monitor.isOver(),
                dropClassName:
                    dragIndex < index
                        ? ` ${styles.dropOverDownward}`
                        : ` ${styles.dropOverUpward}`,
            }
        },
        drop: (item) => {
            changeIndex(item.index, index)
        },
    })
    const [, drag] = useDrag({
        type,
        item: { index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    })
    drop(drag(ref))

    return (
        <div
            ref={ref}
            className={`${className}${isOver ? dropClassName : ''}`}
            style={{ cursor: 'move', ...style }}
            onClick={onClick}
        >
            {restProps.children}
        </div>
    )
}
