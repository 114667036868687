import React, { ReactElement, useMemo, useCallback, useState } from 'react'
import styles from './FormulaSheetEditor.module.less'
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil'
import {
    formulaSheetEntriesAtom,
    lastSavedFormulaSheetEntriesAtom,
    formulaSheetEntriesWithUnsavedChangesSelector,
    formulaSheetEntriesHaveUnsavedChangesSelector,
} from '@/atoms/formulaSheetEntries'
import { AddFormulaSheetEntryButton } from '@/components/FormulaSheetEditor/AddFormulaSheetEntryButton/AddFormulaSheetEntryButton'
import { DraggableSortingList } from '@/components/utils/DraggableSortingList/DraggableSortingList'
import {
    DraggableFormulaSheetEntryData,
    DraggableIconType,
} from '@/components/utils/DraggableSortingList/DraggableIcon/DraggableIcon.types'
import { reorderFormulaSheetEntries } from '@/components/FormulaSheetEditor/immer'
import {
    isActiveCourseReadOnlySelector,
    frontendDisplayedCourseSelector,
} from '@/atoms/auth'
import { Button } from 'antd'
import { overwriteFormulaSheetEntries } from '@/api/cb/contentCreatorContent'
import { syncSuccessMessage, syncErrorMessage } from '@/utils/syncMessages'
import { logger } from '@/logging/FrontendLogger'
import { FormulaSheetDownloadButton } from '@/components/FormulaSheetEditor/FormulaSheetDownloadButton/FormulaSheetDownloadButton'

export const FormulaSheetEditor: React.FC<unknown> = (): ReactElement => {
    const [formulaSheetEntries, setFormulaSheetEntries] = useRecoilState(
        formulaSheetEntriesAtom
    )
    const setLastSavedFormulaSheetEntries = useSetRecoilState(
        lastSavedFormulaSheetEntriesAtom
    )

    const formulaSheetEntriesWithUnsavedChanges = useRecoilValue(
        formulaSheetEntriesWithUnsavedChangesSelector
    )

    const draggableItems = useMemo(
        (): DraggableFormulaSheetEntryData[] =>
            formulaSheetEntries.map(
                (entry): DraggableFormulaSheetEntryData => ({
                    type: DraggableIconType.formulaSheetEntry,
                    title: entry.title,
                    id: entry.id,
                    hasUnsavedChanges:
                        formulaSheetEntriesWithUnsavedChanges.has(entry.id),
                })
            ),
        [formulaSheetEntries, formulaSheetEntriesWithUnsavedChanges]
    )
    const hasUnsavedChanges = useRecoilValue(
        formulaSheetEntriesHaveUnsavedChangesSelector
    )

    const courseName = useRecoilValue(frontendDisplayedCourseSelector)
    const [isSaving, setIsSaving] = useState<boolean>(false)
    const saveFormulaSheetEntries = useCallback(async (): Promise<void> => {
        setIsSaving(true)
        let error
        try {
            const response = await overwriteFormulaSheetEntries(
                courseName,
                formulaSheetEntries
            )
            if (!response.data.success) {
                error = response.data.error
            }
        } catch (e) {
            error = e
        }

        setIsSaving(false)
        if (error) {
            logger.error(`Failed to save formula sheet with error: ${error}`)
            syncErrorMessage('Failed to save formula sheet. Please try again.')
        } else {
            setLastSavedFormulaSheetEntries(formulaSheetEntries)
            syncSuccessMessage('Formula sheet successfully saved')
        }
    }, [courseName, formulaSheetEntries, setLastSavedFormulaSheetEntries])

    const reorderFormulaSheetEntriesCallback = useCallback(
        (startingIndex: number, endingIndex: number): void => {
            setFormulaSheetEntries((entries) =>
                reorderFormulaSheetEntries(entries, startingIndex, endingIndex)
            )
            setActiveFormulaSheetEntryIndex(endingIndex)
        },
        [setFormulaSheetEntries]
    )
    const [activeFormulaSheetEntryIndex, setActiveFormulaSheetEntryIndex] =
        useState<number>(0)
    const isReadOnly = useRecoilValue(isActiveCourseReadOnlySelector)

    return (
        <div className={styles.formulaSheetEditor}>
            <div className={styles.formulaSheetEditorTitle}>Formula Sheet</div>
            <div className={styles.formulaSheetEditorSaveButtonContainer}>
                <Button
                    loading={isSaving}
                    disabled={!hasUnsavedChanges}
                    onClick={saveFormulaSheetEntries}
                    type={'primary'}
                >
                    Save Changes
                </Button>
            </div>
            <AddFormulaSheetEntryButton />
            <FormulaSheetDownloadButton />
            <div />
            <DraggableSortingList
                itemData={draggableItems}
                changeIndex={reorderFormulaSheetEntriesCallback}
                activeIndex={activeFormulaSheetEntryIndex}
                setActiveIndex={setActiveFormulaSheetEntryIndex}
                isLoading={false}
                isDisabled={isReadOnly}
            />
        </div>
    )
}
