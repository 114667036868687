export enum NotecardEditorContentType {
    front = 'Front',
    back = 'Back',
    metadata = 'Metadata',
    commentThread = 'Comment Thread',
    convertToPracticeProblem = 'Convert to Practice Problem',
}

export interface NotecardEditorProps {
    notecardID: string
}
