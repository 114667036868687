import React, { ReactElement, useState, useMemo, useCallback } from 'react'
import { getContentTreeChildForContentPath } from '@/utils/getContentTreeChildForContentPath'
import { UpSquareOutlined, DownSquareOutlined } from '@ant-design/icons'
import { useRecoilState, useRecoilValue } from 'recoil'
import { contentTreeStateAtom } from '@/atoms/contentTree'
import { ContentNodeReordererProps } from '@/components/ContentTreeNode/ContentTreePanelHeader/ContentNodeReorderer/ContentNodeReorderer.types'
import { Spin } from 'antd'
import { moveContentNode } from '@/api/cb/contentCreatorContent'
import {
    frontendDisplayedCourseSelector,
    isActiveCourseReadOnlySelector,
} from '@/atoms/auth'
import { syncErrorMessage, syncSuccessMessage } from '@/utils/syncMessages'
import { isReorderingNodesAtom } from '@/atoms/isReorderingNodes'
import { moveContentNodeFrontend } from '@/components/ContentTreeNode/ContentTreePanelHeader/ContentNodeReorderer/immer'

export const ContentNodeReorderer: React.FC<ContentNodeReordererProps> = (
    props
): ReactElement => {
    const [isReorderingNodes, setIsReorderingNodes] = useRecoilState(
        isReorderingNodesAtom
    )
    const isReadOnly = useRecoilValue(isActiveCourseReadOnlySelector)

    const [contentTreeState, setContentTreeState] =
        useRecoilState(contentTreeStateAtom)
    const [isUpLoading, setIsUpLoading] = useState<boolean>(false)
    const [isDownLoading, setIsDownLoading] = useState<boolean>(false)
    const nodeSiblings = useMemo((): string[] => {
        const childTree = getContentTreeChildForContentPath(
            contentTreeState.contentTree,
            props.contentPath.slice(0, props.contentPath.length - 1)
        )
        return childTree.children.map((child) => child.id)
    }, [contentTreeState.contentTree, props.contentPath])
    const nodeSiblingsIndex = useMemo((): number => {
        return nodeSiblings.indexOf(
            props.contentPath[props.contentPath.length - 1]
        )
    }, [nodeSiblings, props.contentPath])

    const isMoveContentPathDownDisabled = useMemo((): boolean => {
        return nodeSiblingsIndex === nodeSiblings.length - 1 || isReadOnly
    }, [isReadOnly, nodeSiblings.length, nodeSiblingsIndex])

    const courseName = useRecoilValue(frontendDisplayedCourseSelector)
    const moveContentPathDown = useCallback(
        async (event: React.MouseEvent<HTMLDivElement>): Promise<void> => {
            event.stopPropagation()
            if (isMoveContentPathDownDisabled) return
            setIsReorderingNodes(true)
            setIsDownLoading(true)
            const response = await moveContentNode({
                courseName,
                contentPath: props.contentPath,
                type: 'down',
            })
            if (response.data.isError) {
                syncErrorMessage(
                    'Something went wrong reordering the content. Please refresh and try again.'
                )
            } else {
                setContentTreeState((contentTreeState) =>
                    moveContentNodeFrontend(
                        contentTreeState,
                        props.contentPath,
                        'down'
                    )
                )
                syncSuccessMessage('Successfully reordered content.')
            }
            setIsReorderingNodes(false)
            setIsDownLoading(false)
        },
        [
            courseName,
            isMoveContentPathDownDisabled,
            props.contentPath,
            setContentTreeState,
            setIsReorderingNodes,
        ]
    )

    const isMoveContentPathUpDisabled = useMemo((): boolean => {
        return nodeSiblingsIndex === 0 || isReadOnly
    }, [isReadOnly, nodeSiblingsIndex])
    const moveContentPathUp = useCallback(
        async (event: React.MouseEvent<HTMLDivElement>): Promise<void> => {
            event.stopPropagation()
            if (isMoveContentPathUpDisabled) return
            setIsReorderingNodes(true)
            setIsUpLoading(true)
            const response = await moveContentNode({
                courseName,
                contentPath: props.contentPath,
                type: 'up',
            })
            if (response.data.isError) {
                syncErrorMessage(
                    'Something went wrong reordering the content. Please refresh and try again.'
                )
            } else {
                setContentTreeState((contentTreeState) =>
                    moveContentNodeFrontend(
                        contentTreeState,
                        props.contentPath,
                        'up'
                    )
                )
                syncSuccessMessage('Successfully reordered content.')
            }
            setIsReorderingNodes(false)
            setIsUpLoading(false)
        },
        [
            courseName,
            isMoveContentPathUpDisabled,
            props.contentPath,
            setContentTreeState,
            setIsReorderingNodes,
        ]
    )

    return (
        <div className={'reorder-node-container'}>
            {isUpLoading ? (
                <Spin />
            ) : (
                <UpSquareOutlined
                    className={
                        isMoveContentPathUpDisabled ||
                        isReorderingNodes ||
                        isReadOnly
                            ? 'disabled'
                            : ''
                    }
                    onClick={moveContentPathUp}
                />
            )}
            {isDownLoading ? (
                <Spin />
            ) : (
                <DownSquareOutlined
                    className={
                        isMoveContentPathDownDisabled ||
                        isReorderingNodes ||
                        isReadOnly
                            ? 'disabled'
                            : ''
                    }
                    onClick={moveContentPathDown}
                />
            )}
        </div>
    )
}
