import styles from './ContentCommentThread.module.less'
import React, { ReactElement, useMemo } from 'react'
import { ContentCommentThreadProps } from '@/components/ContentCommentThread/ContentCommentThread.types'
import { List } from 'antd'
import { ReadComment } from '@/components/ContentCommentThread/ReadComment/ReadComment'
import { PostComment } from '@/components/ContentCommentThread/PostComment/PostComment'
import { COMMENT_ANCHOR_LINK } from '@/components/ContentCommentThread/ContentCommentThreadWrapper.types'

// DO NOT USE RECOIL HERE OR ANYWHERE BELOW
export const ContentCommentThread: React.FC<ContentCommentThreadProps> = (
    props
): ReactElement => {
    const commentDisplay = useMemo((): ReactElement => {
        if (props.commentMetadataArray.length === 0) {
            return (
                <div className={styles.contentCommentThreadNoComments}>
                    No Comments
                </div>
            )
        }

        return (
            <List
                itemLayout={'horizontal'}
                dataSource={props.commentMetadataArray}
                renderItem={(item) => (
                    <li>
                        <ReadComment
                            commentMetadata={item}
                            contentType={props.contentType}
                            contentId={props.contentId}
                            contentPath={props.contentPath}
                            courseName={props.courseName}
                            userID={props.userID}
                        />
                    </li>
                )}
            />
        )
    }, [props])

    return (
        <div className={styles.commentThread} id={COMMENT_ANCHOR_LINK}>
            <div className={styles.commentThreadContainer}>
                <div className={styles.commentThreadTitle}>Comments</div>
                {commentDisplay}
                <PostComment
                    contentType={props.contentType}
                    contentId={props.contentId}
                    setCommentMetadataArray={props.setCommentMetadataArray}
                    contentPath={props.contentPath}
                    courseName={props.courseName}
                    onSubmit={props.onSubmit}
                />
            </div>
        </div>
    )
}
